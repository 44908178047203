/**
 * loads sub modules and wraps them up into the main module.
 * This should be used for top-level module definitions only.
 */

define([
    'js.cookie',
    'angular',
    'ngAnimate',
    'gizmoGrid',
    'ui-sortable',
    //'ngNewRouter',
    './../lib/config/angular-config',
    'ui.router',
    'ng-idle',
    'ng-flow',
    'angular-cookies',
    'angular-ui-select',
    'angular-sanitize',
    './Filters',
    './GlobalControllers',
    './GlobalServices',
    './GlobalDirectives',
    './components/workspace/workspace',
    './components/grid/grid',
    './components/reclass/reclass',
    './components/methodSettlement/methodSettlement',
    './components/projectTag/projectTag',
    './components/projectTagging/projectTagging',
	'./components/relatedPartyPayment/relatedPartyPayment',
	'./components/relatedPartyclassification/relatedPartyclassification',
    './components/Forms/RelatedPartyModal/relatedPartyModal',
	'./components/m3PayorAllocate/m3PayorAllocate',
    './components/tbbs/tbbs',
    './components/messages/messages',
    './components/supplementDetails/supplement_details',
    './components/pdfDataManagement/pdf_data_management',
    './components/projectManagement/project_management',
    './components/EntityMgmtApp/entityMgmtApp',
    './components/DST/statement-tracker/statementTracker',
    './components/DST/diagnostics/na-report/naReport',
    './components/DST/diagnostics/data-collect-report/dataCollectReport',
    './components/DST/diagnostics/dups-stmt-report/dupsStmtReport',
    './components/DST/diagnostics/f304-diag-report/f304DiagReport',
    './components/DST/diagnostics/transfers-review-report/transfersReviewReport',
    './components/DST/custom-report/customReport',
    './components/DST/reports/section-351-report/sec351Report',
    './components/DST/reports/section-332-report/sec332Report',
    './components/DST/reports/section-368-report/sec368Report',
    './components/DST/reports/section-926-report/sec926Report',
    './components/DST/reports/form-8594-report/form8594Report',
     './components/DST/reports/form-8883-report/form8883Report',
    './components/DST/Admin/dstAdmin',
    './components/DST/pushToEfile/dataPushToEfile',
    './components/DST/copyLegacyFiles/copyLegacyFiles',
    './components/DST/shutdown-dst-app/systemLockUnlock',
    './components/DST/statements-filed-status/dstStatementsFiledStatus',
    './components/dclConsLosses/DCLdetails',
    './components/tasks/tasks',
    './components/alerts/alerts',
    './components/help/help',
    './components/settings/settings',
    './components/admin/admin',
    './components/admin/actions/actions',
    './components/admin/changeLogJobRunDetails/changeLogJobRunDetails',
    './components/admin/adminResetDSConnection/adminResetDsConnection',
    './components/admin/users/users',
    './components/issueManagement/issues',
    './components/admin/gridBuilder/gridBuilder',
    './components/codeCombinations/codeCombinations',
    './components/dataFilters/dataFilters',
    './components/sampleApplication/sampleApplication',
    './components/ReportableTransaction/reportable',
    'angularjs-dropdown-multiselect',
    './components/cbcReporting/cbcReporting.js',
    './components/irs4797/Irs4797',
    './components/irs4562/irs4562',
    './components/scheduleD/scheduleD',
    './components/scheduleJ/scheduleJ',
    './components/fedforms/form',
    './components/manageInsAcct/InsAcctdetails',
    './components/adjPriorYearData/adjPriorYear',
    './components/eliminationReclass/elimReclass',
    './components/subPartF/subPartF',
    './components/jobDisplay/jobDisplay',
    './components/lockForm/lockForm',
    './components/epAdjustments/epTaxAdjs',
    './components/admin/jdcManager/JdcManager',
    './components/admin/jdcManager/jdcMainSettings/JdcMain',
    './components/admin/client/client',
    './components/efile/efile',
    './components/admin/processMap/procMapControl',
    './components/dcsSchdBC/dcsSchdBC',
    './components/orgChart/orgChart',
    './components/admin/scenarioSetup/scenarioSetup',
    './components/consol-group/consolGroup',
    './components/default-sourcing-override/sourcingOverride',
    './components/gold/gold',
    './components/admin/templates/templates',
    './components/workflow/workflow',
    './components/wwnim3recon/wwniM3Recon',
    //'./components/FIR_965_Workpaper/965-WorkpaperService',
    './components/section965/section965',
    './components/section965TaxStmt/section965TaxStatement',
    './components/workflow/domestic/retainedEarnings/retained',
    './components/FIR-Form5471/Form5471-textInfo',
    './components/FIR-Sanctioned-Countries/FIRSanctionedCountries',
    './components/FIR-Form8858/Form8858-textInformation',
    './components/FIR-Form8865/Form8865-textInfo',
    './components/FIR-8858-Schedule-C1/F8858-schdC1',
    './components/NonFinancialInformation/updateDirectOwner',
    './components/Dashboard-901M/dashboard901M',
    './components/dividends/dividends',
    './components/FIR_5471_Schedule_O/scheduleF5471-O',
    './components/FIR_5471_Schedule_I/schedule-I',
    './components/FIR_5471_Schedule_J/schedule-J',
    './components/FIR_5471_Schedule_F/schedule-F',
    './components/FIR_5471_Schedule_M/schedule-M',
    './components/FIR_8865_Schedule_P/schedule-P',
    './components/FIR_8865_Schedule_O/schedule-O',
    './components/FIR_8858_Schedule_M/scheduleF8858-M',
    './components/FIR_5471_Schedule_E/schedule-E',
    './components/FIR-coa-mapping/foreignLocalAcctDesc/firLocaltAcctDesc',
    './components/FIR-coa-mapping/foreignLocalSysChartAcctMap/firLocalSysChartAcctMap',
    './components/FIR-coa-mapping/foreignSysFormChartAcctMap/firSysFormChartAcctMap',
    './components/FIR-TiralBalance-Local/firReviewTrialBalanceAutomation/firReviewAutomation',
    './components/FIR-Repoint/firRepoint',
    './components/FIR-Diagnostics/firDiagnostics',
    './components/allAdjustments/allAdjustments',
    './components/admin/goldSync/goldSync',
    './components/admin/goldsync-old/goldsync-old',
    './components/taxSensitiveAcct/taxAcct',
    './components/assignPreparer/assignPreparer',
    './components/diagnosticDashboard/detailedDiagnostic/detailedDiagnostic',
    './components/diagnosticDashboard/summaryDiagnostic/summaryDiagnostic',
    './components/changeLogMonitor/changeLogMonitor',
    './components/changeLogMonitor/retriggerMessage/reTriggerMessage',
    './components/efileAdmin/5713/process5713Lines/process5713Lines',
    './components/changeLogMonitor/regenerationProcess/regenerationProcess',
    './components/changeLogMonitor/rerunPdfGeneration/rerunPdfGeneration',
    './components/changeLogMonitor/rerunXmlGeneration/rerunXmlGeneration',
    './components/rolloverDashboard/rolloverDashboard',
    './components/localSysChartAcctMap/localSysChartAcctMap',
    './components/localSysChartAcctMapDesc/localSysChartAcctMapDesc',
    './components/sysFormChartAcctMap/sysFormChartAcctMap',
    './components/admin/rulesengine/validationrules',
    './components/reclassLocalChart/reclassLocalChart',
    './components/allLocalChartAdjustments/allLocalAdjustments',
    './components/eliminationReclassLocal/elimReclassLocal',
    './components/epAdjsLocalChart/epTaxAdjsLocalChart',
    './components/Forms/Form4562/Form4562',
    './components/Forms/Form4626/Form4626',
    './components/Forms/F1125A/Form1125A',
    './components/Forms/Form-scheduleD/FormScheduleD',
    './components/Forms/F1120PC-SCH-E/FormScheduleE',
    './components/Forms/F1120PC-SCH-F/FormScheduleF',
    './components/Forms/F1120L-SCH-B/FormScheduleB',
    './components/Forms/F1120L-SCH-L/FormScheduleL',
    './components/Forms/F1120PC-SCH-I/FormScheduleI',
    './components/Forms/F1120PC-SCH-C/FormScheduleC',
    './components/Forms/F1120-SCH-C/FormSchedule',
    './components/Forms/F1120L-SCH-F/FormScheduleLF',
    './components/Forms/F1120L-SCH-G/FormScheduleG',
    './components/Forms/F1120L-SCH-M/FormScheduleM',
    './components/Forms/F1120L-SCH-A/FormScheduleA',
    './components/Forms/F1120-SCH-M-2/FormScheduleM2',
    './components/Forms/F1120-SCH-M-3/FormScheduleM3',
    './components/Forms/F1120-WS/Form1120WS',
    './components/Forms/F1120-L-PC-HEADERS/Form1120LPC',
    './components/partnership/F1065-Page-One/F1065PageOne',
    './components/partnership/F1065-Sch-B/F1065ScheduleB',
    './components/partnership/F1065-Sch-K/F1065ScheduleK',
    './components/partnership/F1065-Sch-M3/F1065ScheduleM3',
    './components/F1120-Sch-M3/F1120ScheduleM3',
    './components/F1120/F1120-Sch-K/F1120ScheduleK',
    './components/F1120/F8916/F8916',
    './components/F1120/F8916Lim/F8916Lim',
    './components/F1120/F1125-Sch-A/F1125A',
    './components/F1120/F1125-Sch-E/F1125E',
    './components/F1120/F1120-Sch-B/F1120ScheduleB',
    './components/F1120/F1120-Sch-N/F1120ScheduleN',
    './components/F1120/F7004/F7004',
    './components/partnership/F1065-Sch-C/F1065ScheduleC',
    './components/partnership/F1065-Sch-K2/F1065ScheduleK2',
    './components/partnership/F1065-Sch-B2/F1065ScheduleB2',
    './components/Forms/F3800-Credits-Part-III/FormCreditsPartIII',
    './components/F1120/F3800-PIII-Consol/CreditsPartIIIConsol',
    './components/Forms/Form4797/Form4797',
    './components/Forms/F-Schdd/F-Schdd',
    './components/Forms/F-4797/F-4797',
	'./components/Forms/F-4562/F-4562',
	'./components/Forms/F-4626/F-4626',
	'./components/Forms/F-6252/F-6252',
    './components/Forms/F-8824/F-8824',
    './components/Forms/Form8991/Form8991',
    './components/F4626/F4626',
    './components/form8082/form8082',
	'./components/Forms/F965B/F965B',
    './components/ghostSchdmConfig/ghostSchdmConfig',
	'./components/1065M2/1065ScheduleM2',
	'./components/Forms/RepTransaction/ReportableTransaction',
    './components/Forms/SectionDetails/SectionDetails',
	'./components/Forms/CounterParty/CounterParty',
	'./components/Forms/IC-Diagnostics/IC-Diag',
    './components/taxAttributes/taxAttributes',
    './components/admin/cacheControl/cacheControl',
    './components/admin/templateAssign/templateAssignControl',
    './components/taxCycleWorkflow/taxCycleWorkflow',
    './components/trialBalance/trialBalanceA',
    './components/reviewAdjAutomation/reviewAdj',
    './components/entityClassification/entityClassification',
    './components/dcsGhostSettlement/dcsGhostSettlement',
    './components/genericforms/genericForm',
    './components/partnership/partnership',
    './components/ftc/settings/ftc',
    './components/maxLoan/maxLoan',
    './components/RetainedEarnings/retainedEarnings',
    './components/admin/notifEngine/notif',
    './components/admin/auditEngine/audit',
    './components/notificationMessageRouting/notifMsgRouting',
    './components/admin/adminFilters/adminFilters',
    './components/assets/settings/assets',
    './components/Section174/section174',
    './components/dashboardExecutive/dashboardExecutive',
    './components/clientLandingPage/clientLandingPage',
    './components/Custom/bizzHierView/bizzHierView',
    './components/Custom/ghostConsole/ghostConsole',
    './components/Custom/CalcsFilingGroupStatus/CalcsFilingGroupStatus',
    './components/Form-Review/formReviewDashboard',
    './components/newProforma/newProformaDashboard',
    './components/FIR_Edit_GTAPS_Attributes/editAdjustGTAPSAttributes',
    './components/FIR_Post86_EP_History/post86EpHistoryReport',
    './components/FIR_Post86_Tax_History/post86TaxHistoryReport',
    './components/FIR_PTI_Summary_Report/ptiSummaryReport',
    './components/FIR_ConsolidationTrialBalance/consolidationTrialBalance',
    './components/FIR_CFCs_With_Qual_Def/cfcsWithQualDefs',
    './components/FIR_CFCs_With_Recap_Acct/cfcsWithRecapAccts',
    './components/FIR_Hovering_Deficit/hoveringDeficitCalculation',
    './components/FIR_Related_Party_Payment/relatedPartyPaymentSave',
    './components/FIR_Related_Party_Payment_Screen/relatedPartyPaymentScreenSave',
    './components/FIR_5471_Schedule_G/schedule-G',
    './components/FIR_Basket_Maintenance/basketMaintenance',
    './components/FIR_Sourcing_Input/sourcingInputScreen',
    './components/FIR_Save_Service/firGridSave',
    './components/workflow/workflowGrid/workflowGrid',
    './components/MaxLoanBalance/maxLoanBal',
    './components/MaxLoanBalHO/maxLoanHO',
    './components/BookReportByAcctWF/bookToTaxReconcil',
    './components/SummaryTrialBalWF/summarytrialbal.js',
    './components/ConsolidateViewWF/consolidatview',
    // './components/adjustPriorYearwf/adjustPriorYear',
    './components/FIR-Adjust-prior-data/adjustPriorYearData',
    './components/FIR-main-trial-bal/mainTrialBal',
    './components/IntercompanyDiagnoWF/interCompany',
     './components/sltDashboard/sltApiResponse/sltApiResponse',
     './components/sltDashboard/sltSyncError/sltSyncError',
     './components/sltDashboard/sltKeySetup/sltKeySetup',
     './components/sltDashboard/sltIsDuplicate/sltIsDuplicate',
     './components/sltDashboard/sltDataGeneration/sltDataGeneration',
     './components/sltDashboard/retriggerGroupSync/retriggerGroupSync',
     './components/sltApiDashboard/sltDataServiceRequest/sltDataServiceRequest',
     './components/sltApiDashboard/sltDataServiceDashboard',
     './components/sltApiDashboard/sltDataService/sltDataService',
     './components/sltApiDashboard/sltAddDataService/sltAddDataServiceApp',
     './components/efileAdmin/efileAdmin',
    './components/wwniMapping/wwniMapping',
    './components/DataproduceForms5471WF/dataproduce',
    './components/gridAuditCells/gridAuditCell',
    './components/Review-submission-summary/submissionSummary',
    './components/FIR-Review-Book-To-EP/reviewBookToEp',
    './components/FIR-Dividends-payment/dividendsPayment',
    './components/FIR-submitter-diagnostics/submitterDiagnostics',
    './components/tbbsReclass/tbbsReclass',
    './components/qreEntityTransform/qreEntity',
    './components/FIR_EP_Drilldown/EpDrilldown',
    './components/FIR_diagnostic_center/diagnosticDashboard',
    './components/section174Adj/section174Adj',
    './components/section174AdjAllocations/section174AdjAllocations',
    './components/entityChartMap/entityChartMap',
    './components/aggreDashboard/aggreDashboard',
    './components/aggRerun/aggRerun',
    './components/updateChangeList/updateChangeList',
    './components/tblMaintenance/tblMaintenance',
    './components/form851/form851',
    './components/form851/851exclusionList/exclusionList',
    './components/batchMonitoring/batchMonitoring',
    './components/batchRequest/batchRequest',
    './components/tagSourcing/tagSourcing',
    './components/actSyncFtc/actSyncFtc',
    './components/submissionDashboard/submissionDashboard',
    './components/adjOpeningReserveBalances/adjOpeningReserveBalances',
    './components/recurringMultiYearTaxAdjustment/recurringMultiYearTaxAdjustment',
    './components/EST_Module/est-form',
    './components/admin/adminDashboards/adminDashboard',
    './components/admin/masterData/masterData',
    './components/tbPdfPreview/tbPdfPreview',
    './components/DataproduceForms8858WF/8858dataProduce',
    './components/wwniSubCategory/wwniSubCategory',
    './components/recurringEandP/recurringEP',
    './components/FIR_Gilti/giltiCalcGrid',
   './components/FIR_Gilti_Consolidated/giltiConsolidatedGrid',
   './components/FIR_GILTI_QBAI/firgiltiQBAI',
   './components/FIR_FDII_QBAI/firfdiiQBAI',
   './components/FIR_FDII_QBAI/firfdiiQBAIAcctExclude',
   './components/FIR_FDII_QBAI/firfdiiQBAIPTR',
   './components/FIR-HTE/firTestedUnits',
   './components/ghostUpdateCombination/ghostUpdateCombination',
   './components/settelmentRedirection/settelmentRedirection',
   './components/consolidationLedgers/ConsolidationLedgers',
   './components/FIR-AnalyzeCurrentTaxes/signoffUser',
	'./components/FIR_8858_Gaap_Drilldown/gaap8858',
	'./components/qreEntityDetails/qreEntitydetails',
	'./components/qreEntityTransformBusiness/qreEntityBusiness',
	'./components/qrevarianceExplain/qrevarianceExplain',
	'./components/qrevarianceYOY/qrevarianceYoY',
	 './components/qreCodeCombinationTransform/qreCodeCombination',
	 './components/DCS-Diagnostics/dcsDiagnostics',
	 './components/limitationScreen/limitation',
	 './components/1120SubConsolCalcs/1120SubConsol',
	 './components/FIR_TrueUp_Taxes/trueUpTaxes',
	 './components/FIR_Partnership_Sourcing/partnershipSourcing',
     './components/admin/aggregationTrigger/aggregation',
     './components/ControversyApp/controversy',
     './components/ControversyApp/controversy-audits/controversyAudits',
     './components/ControversyApp/controversy-dashboard/controversyDashboardComponent',
     './components/dashboardExecutive/DST/dstLaunch',
     './components/dashboardExecutive/footNotes/footNotes',
     './components/FIR_Partnership_M2/partnershipMtwo',
     './components/FIR_Partnership_M2_Tax/partnershipMtwoTax',
     './components/FIR_Partnership_CapitalAccounts/partnershipCapitalAccounts',
     './components/FIR_Partnership_CapitalAccounts_Tax/partnershipCapitalAccountsTax',
     './components/FIR_Partner_Allocations/partnerAllocations',
     './components/partnership/F1125-Sch-A/F1125ScheduleA',
     './components/admin/firForm/regenerateFirForm',
     './components/FIR_Ghost_System_Status/ghostSystem',
     './components/FIR_Ghost_System_Run_Status/ghostSystemRun',
     './components/updateGhostSettlement/updateGhostSettlement',
     './components/dashboardExecutive/business-signOff-desc/businessSignOffDesc',
     './components/ControversyApp/controversy-projects/controversyProjectDashboard',
     './components/controversyProjectLib/controversyProjectLib',
     './components/ControversyApp/controversy-audits/audit-IDR/auditIDR',
     './components/locking/lockingBuEntityConol',
     './components/partnership/mgmtPartners/PartnerMaintenance',
     './components/partnership/extEntity/extEntityMaintenance',
     './components/locking/businessLockScreen/bizzLock',
     './components/localsyschartdelete/deleteLocalChart',
     './components/EST_Module/EST_Rollover/estRollover',
     './components/EST_Module/EST_QTR_MAINTENANCE/estqtrmaintenance',
     './components/EST_Module/EST_SignOff/estSignOff',
     './components/FIR_GTAPS_Common_Template/gtapsCommonTemplate',
     './components/FIR_Tax_Liability_Dashboard/taxLiabilityDashboard',
     './components/recommendedAutomations/recommendedAutomations',
     './components/FIR_Post86_EP_History/FIR_Post86_EP_History/post86EpHistoryReportNew',
     './components/FIR_Post86_Tax_History/FIR_Post86_Tax_History/post86TaxHistoryReportNew',
     './components/FIR_163j_Tagging/tagging163j',
     './components/stateEntityGroups/stateGroupDetails/stateGroupDetails',
     './components/stateEntityGroups/externalStateEntity/externalStateEntity',
     './components/FIR_163j_Calc_Summary_By_Entity_HO/calc163jSummaryByEntityHO',
     './components/FIR_163j_Calc_Summary_By_Entity_LE_ME/calc163jSummaryByEntityLeMe',
     './components/163J_Schd_A/Schd_A_163J',
     './components/163J_Schd_A/163jSchdADataRefresh/163JSchdADataRefresh',
     './components/Report_module/reportModule',
     './components/163J_Schd_A/Schd_A_163J',
     './components/QARStdDesc/stdDesc',
     './components/entityDetails/entityDetails',


     './components/FIR_Ghost_Ownership_info/ghostOwnership',
     './components/FIR_Ghost_Schedule_C_Nonghost_Screen/ghostcNonghostScreenSave',
     './components/FIR_Ghost_FTCE_Nonghost_Screen/ghostftceNonghostScreenSave',
     './components/FIR_Ghost_Usdbasispti_Nonghost_Screen/ghostUsdbasisptiNonghostScreenSave',
     './components/FIR_Ghost_Earnprofit_Nonghost_Screen/ghostEarnprofitNonghostScreenSave',
     './components/FIR_Ghost_Ownership_Info_Screen/ghostOwnershipInfoScreenSave',
     './components/FIR_8990_Schedule_B/scheduleB8990',
     './components/firghostsourcing/ghostSourcingTrigger',
     './components/163J_Auto_EP/163jAutoEPData',
     './components/firformregen/firFormGenTrigger',
     './components/entityClassification/batchActions/entityClassificationActions',
     './components/admin/systemMessage/systemMessage',
     './components/admin/jobConsole/jobConsole',
    //  './components/entityClassification/scenarioSetup/scenarioSetupActions',
     './components/entityClassification/scenarioSetup/scenarioSetupActions',
     './components/entityClassification/referenceTable/referenceTableActions.js',
      './components/entityClassification/ecConfiguration/ecConfigurationActions.js',
     './components/entityClassification/entityFilling/entityFillingActions.js',
     './components/entityClassification/ecRefreshSetup/ecRefreshSetupActions.js',
     './components/entityClassification/ecThresholdSetup/ecThresholdSetupActions.js',
     './components/entityClassification/ecQuartermapSetup/ecQuarterMapActions.js',
     './components/entityClassification/851configData/configData.js',     
     './components/grid/gridOnModal/gridModal',
     './components/allocBatchRerun/allocBatchRerun',
     './components/basketPost/basketPost',
     './components/secondaryActionDashboard/secondaryActionDashboard',
     './components/maintainanceOwner/maintainanceOwner',
     './components/newProforma/newProformaGtw/newProformaGtw',
     './components/ftr_consol_group_maint/consolGroupEdit',
    './components/pshipSetupDiag/pshipSetupDiag',
    './components/formView/tabWrapper/formViewTab',
    './components/formView/formView',
    './components/FIR_Form_5471_G1/Form5471-G1Info',
    './components/firghostownership/ghostOwnershipTrigger',
    './components/partnership/newAllocation/partnershipNew',
    './components/cosmeticChanges/entityUpdate',
    './components/fir163jsync/sync163jTrigger',
     './components/deltaPostings/deltaPosting',
     './components/partnership/codeMast/PshipCodeMast',
     './components/FIR-Form8865/F8865SchdK/Form8865SchdK',
     './components/FIR-Form8865/F8865SchdK1/Form8865SchdK1',
     './components/FIR_Ghost_Settlement_Reclasses/ghostSettlementReclasses',
     './components/FIR-EP-BUMP/firEpBumpSummary',
     './components/creditDetails/creditDetails',
     './components/settlement/settlementMain',
     './components/partnership/pshipBatchOpenInAmended/batchOpenInAmended',
     './components/mefEfileChanges/mefEfileMain',
     './components/partnership/F8865-Sch-M2K1-sourcing/f8865SchM2K1Sourcing',
     './components/naicsMaintenance/naicsMaintenance',
     './components/entityChartMapCleanup/entityChartMapCleanup',
     './components/partnership/splAlloc/PshipSplAllocation',
     './components/FIR_Sourcing_Default_Me/sourcing-Default',
     './components/partnership/sourcingAmts/sourcingAmounts',
     './components/FIR_Spot_Rates_All_Curr_Sync/spotrates-all-curr-sync',
     './components/DST/reports/count-by-business/countByBusiness',
     './components/FIR_Fx_Amt_Sync/fx-amt-sync',
     './components/sourcingDefault/sourcingDefault',
     './components/schPSave/schPSave',
     './components/firQbuSourcing/firQbuSourcing',
     './components/163jCalcReclassTrigger/163jCalcReclassTrigger',
     './components/DST/reports/diagnostics-report/diagnosticsReport',
	 './components/FIR_Fx_Rates_Sync/fx-rates-sync',
	'./components/FIR_Sourcing_Project_tagging/sourcingProjectTagging',
    './components/DST/reports/disregarded-entities/disregardedEntities',
    './components/admin/s3Management/s3Management',
    './components/DST/uploadPreformattedDisc/uploadPreformattedDisc.js',
    './components/FIR_Calcs_Domestic_Project_tag/domesticProjectTagging',

    './components/partnership/partnershipDashboard/partnershipDashboard',
    './components/admin/dfMappingChanges/dfMappingChanges',
    './components/FIR-F5471-H1/firf5471h1',
    './components/cbcReporting/bepsAdmin/bepsAdminCtrl',
    './components/partnership/dfAssignEntity/dfAssignEntity',
    './components/FIR-163J-OVERRIDES/fir163joverrides',
    './components/admin/postingPartners/postingPartners',
    './components/admin/amended-cycle/amendedCycle',
    './components/DST/Admin/data-push-to-efile/dataPushToEfile',
    './components/DST/Admin/pdf-push-to-efile/pdfPushToEfile',
    './components/DST/Admin/view-pdf-of-efile/viewPdfOfEfile',
    './components/admin/chart-rollover-maintenance/chartRollOver'
],   function (Cookies) {
    'use strict';
    var env = {};

    // Import variables if present (from env.js)
    if(window){
        Object.assign(env, window.__env);
    }
    var app = '';
    app = angular.module('app', [
        'ngAnimate',
        'ui.router',
        'ngIdle',
        'flow',
        'ngCookies',
        'ui.select',
        'ngSanitize',
        'ui.bootstrap',
        'ds.objectDiff',
        'app.constants',
        'app.services',
        'app.controllers',
        'app.directives',
        'app.filters',
        'app.workspace',
        'app.grid',
        'app.reclass',
        'app.methodSettlement',
        'app.projectTag',
        'app.tbbs',
        'app.projectTagging',
		'app.relatedPartyPayment',
		'app.relatedPartyclassification',
        'app.relatedPartyModal',
		'app.m3PayorAllocate',
        'app.section174',
        'app.tasks',
        'app.messages',
        'app.alerts',
        'app.help',
        'app.settings',
        'app.admin',
        'app.adminActions',
        'app.changeLogJobRunDetails',
        'app.adminResetDsConnection',
        'app.adminUsers',
        'app.bizzHierView',
        'app.ghostConsole',
        'app.CalcsFilingGroupStatus',
        'app.codeCombinations',
        'app.dataFilters',
        'angularjs-dropdown-multiselect',
        'app.SampleApp',
        'app.reportableTransaction',
        'app.SectionDetails',
        'app.gridBuilder',
        'app.cbcReporting',
        'app.irs4797',
        'app.irs4562',
        'app.scheduleD',
        'app.scheduleJ',
        'app.fedforms',
        'app.InsAcct',
        'app.adjPriorYearData',
        'app.elimReclass',
        'app.subPartF',
        'app.jobDisplay',
        'app.lockForm',
        'app.epTaxAdjs',
        'app.jdcManager',
        'app.jdcMainSettings',
        'app.client',
        'app.efile',
        'app.procMapControl',
        'app.dcsSchdBC',
        'app.orgChart',
        'app.gold',
        'app.bizzHier',
        'app.adminTemplates',
        'app.Workflow',
        'app.wwniM3ReconData',
        //'app.965-WorkpaperService',
        'app.section965',
        'app.section965TaxStmt',
        'app.retainedEarnings',
        'app.FIR-Form5471',
        'app.FIR-Sanctioned-Countries',
        'app.FIR-8858-Schedule-C1',
        'app.FIR-Form8858',
        'app.FIR-Form8865',
        'app.supplement',
        'app.pdfDataManagement',
        'app.projectManagement',
        'app.entityMgmtApp',
        'app.dstStatementTracker',
        'app.dstNAReport',
        'app.dstDataCollectReport',
        'app.dstDupsStmtReport',
        'app.dstF304DiagReport',
        'app.dstTransfersReviewReport',
        'app.dstCustomReport',
        'app.dstSection351Report',
        'app.dstSection332Report',
        'app.dstSection368Report',
        'app.dstSection926Report',
        'app.dstForm8594Report',
        'app.dstForm8883Report',
        'app.dataPushToEfile',
        'app.copyLegacyFiles',
        'app.systemLockUnlock',
        'app.dstStatementsFiledStatus',
        'app.dcl',
        'app.directOwner',
        'app.m901Dashboard',
        'app.dividends',
        'app.FIR_5471_Schedule_O',
        'app.FIR_5471_Schedule_I',
        'app.FIR_5471_Schedule_J',
        'app.FIR_5471_Schedule_F',
        'app.FIR_5471_Schedule_M',
        'app.FIR_8865_Schedule_P',
        'app.FIR_8865_Schedule_O',
        'app.FIR_8858_Schedule_M',
        'app.FIR_5471_Schedule_G',
        'app.schedule-E',
        'app.assignPreparer',
        'app.detailedDiagnostic',
        'app.summaryDiagnostic',
        'app.changeLogMonitor',
        'app.reTriggerMessage',
        'app.process5713Lines',
        'app.regenerationProcess',
        'app.rerunPdfGeneration',
        'app.rerunXmlGeneration',
        'app.rolloverDashboard',
        'app.firLocaltAcctDesc',
        'app.firLocalSysChartAcctMap',
        'app.firSysFormChartAcctMap',
        'app.firReviewTrialBalanceAutomation',
        'app.firRepoint',
        'app.FIR-Diagnostics',
        'app.goldSync',
        'app.goldSyncOld',
        'app.taxSensitiveAcct',
        'app.localSysChartAcctMap',
        'app.localSysChartAcctMapDesc',
        'app.sysFormChartAcctMap',
        'app.allAdjustments',
        'app.validationrules',
        'app.reclassLocalChart',
        'app.allLocalAdjustments',
        'app.elimReclassLocal',
        'app.epTaxAdjsLocalChart',
        'app.Form4562',
        'app.Form4626',
        'app.form8082',
        'app.Form1125A',
        'app.Form-schedule',
        'app.Form-scheduleD',
        'app.Form-scheduleE',
        'app.Form-scheduleF',
        'app.Form-scheduleI',
        'app.Form-scheduleB',
        'app.Form-scheduleL',
        'app.Form-scheduleC',
        'app.Form-scheduleLF',
        'app.Form-scheduleG',
        'app.Form-scheduleM',
        'app.Form-scheduleA',
        'app.Form-scheduleM2',
        'app.Form-scheduleM3',
        'app.F1065-PageOne',
        'app.F1065-ScheduleB',
		'app.F965-ScheduleB',
        'app.F1065-ScheduleK',
        'app.F1065-ScheduleM3',
        'app.F1120-ScheduleM3',
        'app.F1120-ScheduleK',
        'app.Form1120WS',
        'app.Form1120LPC',
        'app.F8916',
        'app.F8916Lim',
        'app.F1120-ScheduleB',
        'app.F1120-ScheduleN',
        'app.F7004',
        'app.F1065-ScheduleC',
        'app.F1065-ScheduleK2',
        'app.F1065-ScheduleB2',
        'app.Form-creditsPartIII',
        'app.CreditsPartIIIConsol',
        'app.Form4797',
        'app.F-Schdd',
        'app.F-4797',
		'app.F-4562',
		'app.F-4626',
		'app.F-6252',
        'app.F-8824',
        'app.Form8991',
        'app.F4626',
		'app.1065-scheduleM2',
		'app.ReportableTransaction',
        'app.SectionDetails',
		'app.CounterParty',
		'app.IC-Diag',
        'app.taxAttributes',
        'app.cacheControl',
        'app.templateAssignControl',
        'app.TaxCycleWorkflow',
        'app.trailBalanceAmount',
        'app.reviewAdjAutomation',
        'app.entityClassification',
        'app.dcsGhostSettlement',
        'app.genericForms',
        'app.partnership',
        'app.FTC',
        'app.maxLoan',
        'app.RetainedEarn',
        'app.adminnotif',
        'app.audit',
        'app.notifMessages',
        'app.adminFilters',
        'app.assets',
        'app.dashboardExecutive',
        'app.clientLandingPage',
        'app.Form-Review',
        'app.New-Proforma',
        'app.FIR_Edit_GTAPS_Attributes',
        'app.FIR_Post86_EP_History',
        'app.FIR_Post86_Tax_History',
        'app.FIR_PTI_Summary_Report',
        'app.FIR_ConsolidationTrialBalance',
        'app.FIR_CFCs_With_Qual_Def',
        'app.FIR_CFCs_With_Recap_Acct',
        'app.FIR_Hovering_Deficit',
        'app.FIR_Related_Party_Payment',
        'app.FIR_Related_Party_Payment_Screen',
        'app.FIR_Basket_Maintenance',
        'app.FIR_Sourcing_Input',
        'app.FIR_Save_Service',
        'app.workflowImport',
        'app.maxLoanBal',
        'app.maxLoanHOBal',
        'app.bookToTaxRecon',
        'app.summaryTrialBal',
        'app.consolidatedView',
        //'app.adjustPriorYear',
        'app.FIR-Adjust-prior-data',
        'app.FIR-main-trial-bal',
        'app.interCompanyDiagno',
        'app.sltApiResponse',
        'app.sltSyncError',
        'app.sltKeySetup',
        'app.sltIsDuplicate',
        'app.efileAdmin',
        'app.sltDataGeneration',
        'app.retriggerGroupSync',
        'app.sltDataServiceRequest',
        'app.sltDataService',
        'app.sltAddDataServiceApp',
        'app.wwniMapping',
        'app.dataProduce5471Bal',
        'app.cellAudit',
        'app.Review-submission-summary',
        'app.FIR-Review-Book-To-EP',
        'app.FIR-Dividends-payment',
        'app.FIR-submitter-diagnostics',
        'app.tbbsReclass',
        'app.qreEntity',
        'app.FIR_EP_Drilldown',
	    'app.FIR_diagnostic_center',
        'app.section174Adj',
        'app.section174AdjAllocations',
        'app.entityChartMap',
        'app.aggRerun',
        'app.updateChangeList',
        'app.tblMaintenance',
        'app.form851',
        'app.exclusionList',
        'app.batchMonitoring',
        'app.batchRequest',
        'app.tagSourcing',
        'app.actSyncFtc',
        'app.aggreDashboard',
        'app.recurringMultiYearTaxAdjustment',
        'app.EST_Module',
        'app.adjOpeningReserveBalances',
        'app.adminDashboards',
        'app.masterData',
        'app.tbPdfPreview',
        'app.dataProduce8858Bal',
        'app.wwniSubCategory',
        'app.recurringEandP',
        'app.giltiCalcGridData',
        'app.giltiConsolidatedGridData',
        'app.firgiltiQBAI',
        'app.firfdiiQBAI',
        'app.firfdiiQBAIAcctExclude',
        'app.firfdiiQBAIPTR',
        'app.firTestedUnits',
        'app.ghostUpdateCombination',
        'app.settelmentRedirection',
        'app.ConsolidationLedgers',
        'app.FIR-AnalyzeCurrentTaxes',
        'app.FIR_8858_Gaap_Drilldown',
        'app.submissionDashboard',
        'app.qreEntitydetails',
        'app.qreEntityBusiness',
        'app.qrevarianceExplain',
        'app.qrevarianceYoY',
        'app.qreCodeCombination',
        'app.DCS-Diagnostics',
		'app.limitationScreen',
		'app.1120SubConsol',
        'app.FIR_TrueUp_Taxes',
        'app.FIR_Partnership_Sourcing',
        'app.adminaggregationTrigger',
        'app.ControversyApp',
        'app.controversy-audits',
        'app.controversy-dashboard',
        'app.dstLaunch',
        'app.footNotes',
        'app.FIR_Partnership_M2',
        'app.FIR_Partnership_M2_Tax',
        'app.FIR_Partnership_CapitalAccounts',
        'app.FIR_Partnership_CapitalAccounts_Tax',
        'app.FIR_Partner_Allocations',
        'app.F1125-ScheduleA',
        'app.F1125Domestic-ScheduleA',
        'app.F1125-ScheduleE',
        'app.firForm',
        'app.ghostSystemStatus',
        'app.ghostSystemRunStatus',
        'app.business-signOff-desc',
        'app.updateGhostSettlement',
        'app.controversy-projects',
        'app.controversyProjectLib',
        'app.audit-IDR',
        'app.locking',
        'app.business-signOff-desc',
        'app.extEntity',
        'app.mgmtPartners',
        'app.businessLockScreen',
        'app.deleteLocalChart',
        'app.EST_Rollover',
        'app.EST_Qtr_Maintenance',
        'app.estSignOff',
        'app.FIR_GTAPS_Common_Template',
        'app.FIR_Tax_Liability_Dashboard',
        'app.recommendedAutomations',
        'app.FIR_Post86_EP_HistoryNew',
        'app.FIR_Post86_Tax_HistoryNew',
        'app.FIR_163j_Tagging',
        'app.stateGroupDetails',
        'app.externalStateEntity',
        'app.FIR_163j_Calc_Summary_By_Entity_HO',
        'app.FIR_163j_Calc_Summary_By_Entity_LE_ME',
        'app.schda163j',
        'app.schdA63jA',
        'app.reportModule',
        'app.schda163j',
        'app.entityDetails',
        'app.ghostOwnershipInfo',
        'app.FIR_Ghost_Schedule_C_Nonghost_Screen',
        'app.FIR_Ghost_FTCE_Nonghost_Screen',
        'app.FIR_Ghost_Usdbasispti_Nonghost_Screen',
        'app.FIR_Ghost_Earnprofit_Nonghost_Screen',
        'app.FIR_Ghost_Ownership_Info_Screen',
        'app.FIR_8990_Schedule_B',
        'app.ghostSourcingTrigger',
        'app.adminIssues',
        'app.QARStdDesc',
        'app.163J_Auto_EP',
        'app.firFormRegenTrigger',
        'app.entityClassificationActions',
        'app.systemMessage',
        'app.jobConsole',
        // 'app.scenarioSetupActions',
        'app.scenarioSetupActions',
        'app.consolGroup',
        'app.sourcingOverride',
        'app.configData',
        'app.referenceTableActions',
        'app.ecConfigurationActions',
        'app.entityFillingActions',
        // 'app.entityFillingActions'
        'app.ecRefreshSetupActions',
        'app.ecThresholdSetupActions',
        'app.ecQuarterMapActions',
        
        'app.gridonModal',
        'app.allocBatchRerun',
        'app.basketPost',
        'app.secondaryActionDashboard',
        'app.maintainanceOwner',
        'app.newProformaGtw',
        'app.consolGroupEdit',
        'app.pshipSetupDiag',
        'app.dst-admin',
        'app.formViewTab',
        'app.formView',
		'app.FIR_Form_5471_G1',
        'app.ghostOwnershipTrigger',
        'app.partnershipNew',
        'app.entityUpdate',
        'app.sync163jTrigger',
        'app.deltaPosting',
        'app.ghostSchdmConfig',
        'app.codeMast',
        'app.F8865SchdK',
        'app.F8865SchdK1',
        'app.FIR_Ghost_Settlement_Reclasses',
        'app.firEpBumpSummary',
        'app.creditDetails',
        'app.settlementMain',
        'app.batchOpenInAmended',
        'app.mefEfileMain',
        'app.f8865SchM2K1Sourcing',
        'app.naicsMaintenance',
        'app.entityChartMapCleanup',
        'app.PshipSplAllocation',
        'app.FIR_Sourcing_Default_Me',
        'app.sourcingAmountsMain',
        'app.FIR_Spot_Rates_All_Curr_Sync',
        'app.FIR_Fx_Amt_Sync',
        'app.sourcingDefaultController',
        'app.schPSave',
        'app.countByBusinessReport',
        'app.firQbuSourcing',
        'app.163jCalcReclassTrigger',
        'app.diagnosticsReport',
	    'app.FIR_Fx_Rates_Sync',
        'app.FIR_Sourcing_Project_tagging',
        'app.disregardedEntities' ,
        'app.s3Management',
        'app.uploadPreformattedDisc',
        'app.FIR_Calcs_Domestic_Project_tag',
        'app.scenarioSetup',
        'app.partnershipDashboard',
       'app.dfMappingChanges',
       'app.firf5471h1',
        'app.cbcBepsAdmin',
        'app.dfAssignEntity',
        'app.fir163joverrides',
        'app.postingPartners',
        'app.amendedCycle',
        'app.dstDataPushToEfile',
        'app.dstPdfPushToEfile',
        'app.chart-rollover-maintenance',
        'app.dstViewPdfOfEfile'
    ],function(){
        var oldXMLSend = XMLHttpRequest.prototype.send;


        XMLHttpRequest.prototype.send = function(body){
            if(!this.getResponseHeader('X-Requested-With') || this.getResponseHeader('X-Requested-With').length <1){
                this.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            }
            if(!this.getResponseHeader('client_key') || this.getResponseHeader('client_key').length<1){
                this.setRequestHeader('client_key',sessionStorage.getItem('client_key'));
            }
            if(!this.__zone_symbol__xhrURL.includes('gtw-websockets'))
                this.setRequestHeader('Authorization',sessionStorage.getItem('Authorization'));

            //generate a 30 character string
            const randomString = generateRandomString(30);
            this.setRequestHeader('ist-req-tkn', randomString);


            this.withCredentials = true;

            var oldOnReadyStateChange = this.onreadystatechange;

            this.onreadystatechange = function(){
                // if (this.status == 409){
                //     let divElem = document.getElementById('gt-http-request-failed');
                //     divElem.style.display = 'block';
                // }
                
                try{
                    if(this.getAllResponseHeaders().indexOf('is_maintenance') >=0 ){
                        if(this.getResponseHeader('is_maintenance') === "true" ){
                            window.location.href = 'app/templates/maintenance.html';
                        }
                    }
                }catch(e){
                    console.log("maintenance error",e);
                }
                if(oldOnReadyStateChange)
                    oldOnReadyStateChange();
            }
            return oldXMLSend.call(this, body);
        };

        _.contains =_ .includes;

    });
     function generateRandomString(length) {
         const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^*()_+|;:,.<>';
         let result = '';
         const charactersLength = characters.length;
         for (let i = 0; i < length; i++) {
             result += characters.charAt(Math.floor(Math.random() * charactersLength));
         }
         return result;
     }
    // Register environment in AngularJS as constant
    app.constant('__env', env);


    app.config(['$stateProvider','$urlRouterProvider','flowFactoryProvider','GENERAL_CONFIG','SERVER_CONFIG','ROUTES','KeepaliveProvider', 'IdleProvider', '$httpProvider','$provide', 'USER_SETTINGS','uiTreeFilterSettingsProvider',
     function($stateProvider,$urlRouterProvider,flowFactoryProvider,GENERAL_CONFIG,SERVER_CONFIG,ROUTES,KeepaliveProvider,IdleProvider,$httpProvider,$provide, USER_SETTINGS,uiTreeFilterSettingsProvider) {
        console.log("----------------------------------" , SERVER_CONFIG.configSettings);
        delete $httpProvider.defaults.headers.common['X-Requested-With'];
        // Use x-www-form-urlencoded Content-Type
        $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
        // Override $http service's default transformRequest
        $httpProvider.defaults.transformRequest = [function(data){
            /**
             * The workhorse; converts an object to x-www-form-urlencoded serialization.
             * @param {Object} obj
             * @return {String}
             */
            var param = function(obj){
                var query = '';
                var name, value, fullSubName, subName, subValue, innerObj, i;

                for(name in obj){
                    value = obj[name];

                    if(value instanceof Array){
                        for(i=0; i<value.length; ++i){
                            subValue = value[i];
                            fullSubName = name + '[' + i + ']';
                            innerObj = {};
                            innerObj[fullSubName] = subValue;
                            query += param(innerObj) + '&';
                        }
                    }
                    else if(value instanceof Object){
                        for(subName in value){
                            subValue = value[subName];
                            fullSubName = name + '[' + subName + ']';
                            innerObj = {};
                            innerObj[fullSubName] = subValue;
                            query += param(innerObj) + '&';
                        }
                    }
                    else if(value !== undefined && value !== null){
                        query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
                    }
                }

                return query.length ? query.substr(0, query.length - 1) : query;
            };

            return angular.isObject(data) && String(data) !== '[object File]' ? param(data) : data;
        }];

        if(USER_SETTINGS && USER_SETTINGS.user.clients.length > 1) {
            $urlRouterProvider.otherwise(function ($injector, $location) {
                    var state = $injector.get('$state');
                    if($location.$$url.includes('popup') || $location.$$url.includes('user-guide-download')){
                        state.go(state.current.name);
                    }else if(USER_SETTINGS.user.client && USER_SETTINGS.user.client.client_key){
                        state.go('workspace.modules');
                    }else{
                        state.go('client-landing-page');
                    }
            });

            // $urlRouterProvider.otherwise('/client-landing-page');
        } else {

             $urlRouterProvider.otherwise(function ($injector, $location) {
                    var state = $injector.get('$state');
                    if($location.$$url.includes('popup') || $location.$$url.includes('user-guide-download')){
                        state.go(state.current.name);
                    }else if(USER_SETTINGS.user.client && USER_SETTINGS.user.client.client_key){
                        state.go('workspace.modules');
                    }else{
                        state.go('workspace');
                    }
            });
            // $urlRouterProvider.otherwise('/workspace');
        }

        uiTreeFilterSettingsProvider.addresses = ['name'];
        uiTreeFilterSettingsProvider.descendantCollection ='children';
        uiTreeFilterSettingsProvider.regexFlags = 'gi';


        // $httpProvider.interceptors.push('myHttpResponseInterceptor');
        $stateProvider
            .state('404', {
                url: '/404',
                templateUrl: 'app/templates/404-tpl.html',
                access: ""
            })

            .state('accessDenied', {
                url: '/accessDenied',
                templateUrl: 'app/templates/access-denied-tpl.html',
                access: ""
            })

            /*.state('maintenance', {
                url: '/maintenance',
                templateUrl: 'app/templates/maintenance-screen-tpl.html',
                access: ""
            })*/
            .state('maintenance', {
                url: '/maintenance',
                templateUrl: 'app/templates/maintenance.html',
                access: ""
            })
            .state('coming-soon', {
                url: '/coming-soon',
                templateUrl: 'app/templates/coming-soon-tpl.html',
                access: ""
            })
            .state('no-modules', {
                url: '/no-modules',
                templateUrl: 'app/templates/no-modules.html'
            })
            .state('loggedOut', {
                url: '/loggedOut',
                templateUrl: 'app/templates/logout-user.html'
            })


        //init Resize Sensor
        window.ResizeSensor = require('resizeSensor');

        for (var routeName in ROUTES) {
            if (ROUTES.hasOwnProperty(routeName)) {
                $stateProvider.state(routeName, ROUTES[routeName]);
            }
        }

        $httpProvider.interceptors.push(['SERVER_CONFIG','USER_SETTINGS', function(SERVER_CONFIG,USER_SETTINGS){
            return {
                request: function(config){
                    if(config.url.indexOf('app/components/') > -1 && SERVER_CONFIG.configSettings.release_version){
                        var separator ="";
                        if(!GENERAL_CONFIG.cache_templates){
                            separator = config.url.indexOf('?') === -1 ? '?' : '&';
                            config.url = config.url + separator + 'c=' + Math.random().toString(36).slice(2);
                            config.client_key = USER_SETTINGS.user.client_key
                        }else{
                            separator = config.url.indexOf('?') === -1 ? '?' : '&';
                            config.url = config.url + separator + 'c=' + SERVER_CONFIG.configSettings.release_version;
                            config.client_key = USER_SETTINGS.user.client_key /* added clientKey in all api calls 1/24/20120 dvalaboju001 */
                        }
                    }

                    //HS 7/12/2018 - ADDED HEADER to attempt PWC IDAM from creating cookies on each request
                    // config.headers['X-Requested-With'] = 'XMLHttpRequest';
                    // config.headers['client_key'] = USER_SETTINGS.user.client_key; /* added clientKey in all api calls 1/24/20120 dvalaboju001 */
                    return config;
                }
            };
        }]);

        // Intercept http calls.
        $provide.factory('MyHttpInterceptor',['$q','AlertService', function ( $q, AlertService) {
            return {
                // On request success
                request: function (config) {
                    //console.log("request-------------" , config); // Contains the data about the request before it is sent.
                    // Return the config or wrap it in a promise if blank.
                    return config || $q.when(config);
                },

                // On request failure
                requestError: function (rejection) {
                    // console.log("requestError-------------" ,rejection); // Contains the data about the error on the request.
                    // Return the promise rejection.
                    return $q.reject(rejection);
                },

                // On response success
                response: function (response) {
                    // console.log("response-------------" , response); // Contains the data from the response.
                    // Return the response or promise.
                    return response || $q.when(response);
                },

                // On response failture
                responseError: function (rejection) {
                    //console.log("responseError--------------" , rejection); // Contains the data about the error.
                    /*if( rejection.status && rejection.status === 503 ){
                        window.location.href = 'maintenancePage.ge';
                    }*/
                    /*if( rejection.status && rejection.status === 503 ){
                        window.location.href = 'app/templates/maintenance.html';
                    }*/

                    if( rejection.status && rejection.status === 509 ){
                        window.location.href = 'app/templates/denied.html';
                    }

                    if( rejection.status && rejection.status === 404 ){
                        AlertService.add("error", "404 the requested service could not be found");
                    }

                    if( rejection.status && rejection.status === 401 ){
                        sessionStorage.removeItem('Authorization');
                       // window.location.replace('https://dev2.gtw.pwcinternal.com/gateway/oauth2/authorization/gtw-gateway');
                       const gate_way = window.__env.gateWay;
                        window.location.replace(gate_way + '/gtw/oauth2/authorization/gtw-services')
                        if(rejection.config.url.indexOf('getUnreadMesageCount') > -1 || rejection.config.url.indexOf('ajaxKeepAlive') > -1 ){
                            location.reload(true);
                        }else{
                            if(rejection && rejection.data && rejection.data.errorMessage){
                                AlertService.add("error", rejection.data.errorMessage);

                            }else{
                                AlertService.add("error", "Sorry you do not have access to the request action. ");

                            }
                        }

                    }


                    if( rejection.status && rejection.status === 500 ){
                        AlertService.add("error",  "  An internal error has occurred. The "+SERVER_CONFIG.configSettings.api_name+" support team has been notified of this error.");
                    }

                    if( rejection.status && rejection.status === 400 ){
                        if(rejection.data != null && _.includes(rejection.data,'Multi Client')){
                            let divElem = document.getElementById('gt-http-request-failed');
                            divElem.getElementsByClassName("hardReload")[0].style.display = 'none';
                            divElem.style.display = 'block';

                        }else{
                            AlertService.add("error", "The server received an invalid request.Please try refreshing the page ");
                        }
                    }

                    if( rejection.status && rejection.status === 417 ){
                        AlertService.add("error", "The server received an invalid request.Please try refreshing the page ");
                    }
                    if( rejection.status && rejection.status === 409 ){
                        // AlertService.add("error", "The server received an invalid request.Please try refreshing the page ");
                        // $rootScope.httpRequestFailed = true ;
                        let divElem = document.getElementById('gt-http-request-failed');
                        divElem.style.display = 'block';
                    }
                    if( rejection.status && rejection.status === -1 ){
                        /*if(rejection.config.url.indexOf('/getUnreadMesageCount') !== -1 || rejection.config.url.indexOf('/ajaxKeepAlive') !== -1) {
                            window.location = GENERAL_CONFIG.redirect_url;
                            window.location.href = GENERAL_CONFIG.redirect_url;
                        }*/
                        // Hide message for ajaxalive and getMessageUnread count
                        if(rejection.config.url.indexOf('/getUnreadMesageCount') !== -1 && rejection.config.url.indexOf('/ajaxKeepAlive') !== -1) {
                            AlertService.add("error", "The server is not responding. It is possible that your session ended or the server is down. Please try refreshing the page ");
                        }
                    }

                    /*if( rejection.status && rejection.status === 502 ){
                        if(rejection.config.url.indexOf('/getUnreadMesageCount') !== -1 || rejection.config.url.indexOf('/ajaxKeepAlive') !== -1) {
                            window.location = GENERAL_CONFIG.redirect_url;
                            window.location.href = GENERAL_CONFIG.redirect_url;
                        }
                    }*/



                    console.log(" rejection.status  " , rejection.status );
                    return $q.reject(rejection);
                }
            };

        }]);


        $httpProvider.interceptors.push('MyHttpInterceptor');
        console.log(SERVER_CONFIG.configSettings);
        IdleProvider.idle(JSON.parse(SERVER_CONFIG.configSettings.idle));
        IdleProvider.timeout(JSON.parse(SERVER_CONFIG.configSettings.timeout));
        KeepaliveProvider.interval(SERVER_CONFIG.configSettings.keep_alive_interval || 1500);

        flowFactoryProvider.defaults = {
            maxChunkRetries: 0
            , chunkRetryInterval: 5000
            , simultaneousUploads: 5
            , chunkSize: 104857600
            , forceChunkSize: true
            , testChunks: false
        };

    }]);



    // app.run(['$rootScope','$state','$stateParams','GlobalService','Idle','$log','$templateCache','GENERAL_CONFIG','USER_SETTINGS','JCDFactory','WorkflowTCFactory','$cookies', 'SERVER_CONFIG', 'CommonUtilitiesFactory','MessageService',  function($rootScope,$state,$stateParams,globalService,Idle,$log,$templateCache,GENERAL_CONFIG,USER_SETTINGS,JCDFactory,WorkflowTCFactory,$cookies, SERVER_CONFIG, CommonUtilitiesFactory,MessageService) {
    app.run(['$rootScope','$state','$stateParams','GlobalService','Idle','$log','$templateCache','GENERAL_CONFIG','USER_SETTINGS','JCDFactory','$cookies', 'SERVER_CONFIG', 'CommonUtilitiesFactory','$location','ClientSwitch',
    function($rootScope,$state,$stateParams,GlobalService,Idle,$log,$templateCache,GENERAL_CONFIG,USER_SETTINGS,JCDFactory,$cookies, SERVER_CONFIG, CommonUtilitiesFactory,$location,ClientSwitch) {
        $rootScope.$state = $state;
        $rootScope.$stateParams = $stateParams;
        Idle.watch();
        $rootScope.isBrowserBackButton = true;
        //$cookies.put('XSRF-TOKEN', SERVER_CONFIG.configSettings.token);
        //$cookies.put('client_key', USER_SETTINGS.user.client_key);
        // Cookies.set('XSRF-TOKEN',  SERVER_CONFIG.configSettings.token, { expires : 1, secure:true  , samesite: "strict" });
        // Cookies.set('client_key',  USER_SETTINGS.user.client_key, { expires : 1, secure:true  , samesite: "strict" });
        sessionStorage.setItem('client_key', USER_SETTINGS.user.client_key);

        // Establish socket connection. -- moved to AdminController
        // MessageService.establishConnection();

        $templateCache.put('templateId.html');

        //TODO: move this all to service
        if(USER_SETTINGS.userSettingsJson){
            USER_SETTINGS.userSettingsJson.navPanel = "open"
            USER_SETTINGS.userSettingsJson.fixedHeader = "fixed"
            $rootScope.editedSettings = USER_SETTINGS.userSettingsJson;
        }else{
            $rootScope.editedSettings = {};
        }

        ////////////////// THIS IS FOR DEV ONLY //////////////////
        if(!GENERAL_CONFIG.cache_templates){
            $log.info("cache_templates is turned off .................")
            $rootScope.$on('$stateChangeStart',
                function(event, toState, toParams, fromState, fromParams){
                    // $templateCache.removeAll();
                });
        }

        $rootScope.$on('$stateNotFound', function(event, unfoundState, fromState, fromParams){
            event.preventDefault()
            $state.go('404', unfoundState.toParams);
        })

        $rootScope.$on('$locationChangeSuccess',
                function (event, toUrl, toParams, fromState, fromParams) {
                    if (toUrl.includes('user-guide-download')) {
                        GlobalService.downloadUserGuide();
                    }
        })



        console.log("USER_SETTINGS===========================" , USER_SETTINGS);


        var systemAccessLevel = (USER_SETTINGS.user.system_access_level) ? USER_SETTINGS.user.system_access_level : 0;

        //Pre Load JCD SERVICE IF WE HAVE ADMIN
        if(systemAccessLevel >= 3){
            JCDFactory.loadJCDObj();
        }

        // WorkflowTCFactory.loadObject();
        CommonUtilitiesFactory.loadCommonData();





        // todo: move to security service
        $rootScope.$on('$stateChangeStart',
            function(event, toState, toParams, fromState, fromParams,error){


                var modules = USER_SETTINGS.user.modules,
                    systemAccessLevel = (USER_SETTINGS.user.system_access_level) ? USER_SETTINGS.user.system_access_level : 0,
                    routAccessLevel = (toState.access) ? toState.access : 0,
                    moduleMatched = ( toState.access && systemAccessLevel >= routAccessLevel );


                if(toState.name !== 'loggedOut'){

                    const token = sessionStorage.getItem("Authorization");
                    if(!token || token === 'null'){
                        event.preventDefault();
                        location.reload(true);
                    }

                }

                if( moduleMatched === false ){
                    event.preventDefault();
                    $state.transitionTo('accessDenied');
                }
            }
        );
        // this is for query params in client key
        $rootScope.$on('$locationChangeStart', function(event, next, current) {

            const client_key = sessionStorage.getItem('client_key');
            const client_key_url = $location.search()['client_key'];
            // const client_key_url = parseInt($location.path().split('/').pop()); //path param

            //Set session Storage same as client key in url
            if(client_key_url && client_key_url != client_key){
                // Cookies.set('client_key',  client_key_url, { expires : 1, secure:true  , samesite: "strict" });
                sessionStorage.setItem('client_key', client_key_url);
                
            }
            if($location.$$absUrl.includes('/?#/')){
                console.log("location change start", $location.$$absUrl);
                $location.$$absUrl =  $location.$$absUrl.replace('/?#/', '/#/');
                console.log("location change start", $location);

            }

            //Set client key in URL
            if(!client_key_url && client_key){
                // $location.url(next.split('#').pop()+'/'+client_key); //path param
                $location.search('client_key', client_key);
            }
            
            console.log("location change start ends");
        });

        // $rootScope.$on('$locationChangeSuccess', function() {
        //     // $rootScope.actualLocation = $location.path();
        //     console.log({"location":$location.path()});
        // });        
    
       $rootScope.$watch(function () {return $location.search()['client_key']}, function (newClientKey, oldClientKey) {
            console.log("location request param key");
           if(newClientKey && oldClientKey && newClientKey != oldClientKey && $rootScope.isBrowserBackButton ){
                console.log("client key watch",{newClientKey, oldClientKey});
                console.log("url", $location.path());
                ClientSwitch.changeClientAlert(newClientKey,"")
           }
           $rootScope.isBrowserBackButton = true;
        });

 // Pendo app initialize script for Visitor details
        pendo.initialize({
            visitor: {
                id:           USER_SETTINGS.user.email,
                email:        USER_SETTINGS.user.email,
                full_name:    USER_SETTINGS.user.display_name,
                role:         USER_SETTINGS.user.user_type
            },
            account: {
                id:           USER_SETTINGS.user.email // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
            }
        });

        $log.debug('app started.');

    }])
    return app;
});
