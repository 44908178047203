define([
    'angular'
], function () {
    'use strict';
    var app = angular.module('app.dstDataPushToEfileController', []);
    app.controller('DSTDataPushToEfileController', ['GlobalService', 'GENERAL_CONFIG', 'USER_SETTINGS', 'TAX_YEARS', 'SERVER_CONFIG', 'workspaceFactory', 'SystemLockUnlockFactory', 'gridData', 'ModalFactory', 'GridBuilderFactory', '$uibModalInstance','$rootScope','$scope', dataPushToEfileCtrl])
    
    function dataPushToEfileCtrl(GlobalService, GENERAL_CONFIG, USER_SETTINGS, TAX_YEARS, SERVER_CONFIG, workspaceFactory, SystemLockUnlockFactory, gridData,ModalFactory,GridBuilderFactory,$uibModalInstance,$rootScope,$scope) {
        var vm = this;
        vm.webComponentHtml = '';
        vm.clientKey = GlobalService.getClient().client_key;
        vm.baseURLs = {};
        vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
        vm.baseURLs.api = GENERAL_CONFIG.base_url;
        vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;
        vm.baseURLs.pdfEngine = GENERAL_CONFIG.pdf_engine_api_url;
        vm.baseURLs.bulkPdfEngine = GENERAL_CONFIG.bulk_pdf_engine_api_url;
        vm.s3uploadButtonAccessUsers = SERVER_CONFIG.configSettings.dst_pdf_gen_users ? SERVER_CONFIG.configSettings.dst_pdf_gen_users.split(',') : [];
        //get system lock status
        vm.message = "Hello from DataPushToEfileController!";
        var filterParams = workspaceFactory.activeScreen.filters.filterParams;
        vm.dataPushInput = JSON.stringify({
            baseUrl: GENERAL_CONFIG.admin_api_url,
            ssoId: USER_SETTINGS.user.sso_id,
            clientKey: USER_SETTINGS.user.client_key,
            taxYear : filterParams.tax_year,
            dcnId: filterParams.dcn_id,
            submissionId : filterParams.submission_id
          });

          vm.eventCleanup = $rootScope.$on('cancel-data-push-confirmation',(e,type)=>{
            $uibModalInstance.dismiss('cancel');   
            $rootScope.$broadcast('dataFilters:refreshGrid', {
                "refresh": true
            });         
        });

        $scope.$on('$destroy', function () {
            vm.eventCleanup();
           
        });
    }
     

    return app;
}

);
