define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.editDetailsCtrl', ['ui.select']).controller('editDetailsCtrl', ['$scope', 'GoldSyncFactory', 'AlertService', editDetailsCtrl]);

    function editDetailsCtrl($scope, GoldSyncFactory, AlertService) {
        GoldSyncFactory.getDataSources().then((response) => {
            if (response.data) {
                $scope.ds_names = response.data.map(({ ds_name }) => ds_name);
            } else
                throw new Error('Data missing');
        }).catch((error) => {
            console.error(error);
        });

        $scope.addApiDetails = function (apiDetails) {
            apiDetails = (
                ({
                    src_tbl_name, dest_tbl_name, api_url, cron_expr, job_status, src_type, src_ds_name, src_query, jobStatusFlag, grp_type, job_desc
                }) => ({
                    src_tbl_name, dest_tbl_name, api_url, cron_expr, job_status, src_type, src_ds_name, src_query, jobStatusFlag, grp_type, job_desc
                })
            )(apiDetails);

            apiDetails.job_status = apiDetails.jobStatusFlag ? 'A' : 'I';
            apiDetails.job_desc = apiDetails.job_desc ? apiDetails.job_desc : '';
            GoldSyncFactory.addApiDetails(apiDetails).then((response) => {
                if (response.data.callSuccess && response.data.addApiDetails) {
                    $scope.jobToEdit.job_id = response.data.addApiDetails.job_id;
                    $scope.newObject = false;
                    AlertService.add('success', 'Api Details added successfully!', 4000);
                } else {
                    throw new Error(response.data.errorMessage);
                }
            }).catch((error) => {
                console.log(error);
                AlertService.add('error', error.message);
            });
        };

        $scope.doValidate = function () {
            const job = $scope.jobToEdit;
            if (job.dest_tbl_name && job.dest_tbl_name.length > 0 && job.selectedSrcType && !job.invalidCron &&
                (job.selectedSrcType.fromData === 'API' || job.src_ds_name) && job.selectedGrpType) {
                $scope.invalidForm = false;
            } else {
                $scope.invalidForm = true;
            }
        };

        $scope.changeSrcType = function () {
            if (!$scope.newObject)
                $scope.jobToEdit.src_details = $scope.jobToEdit.selectedSrcType.fromData === 'DS' ? $scope.jobToEdit.src_query : $scope.jobToEdit.api_url;
            $scope.doValidate();
        };


        $scope.createNew = function () {
            $scope.invalidForm = true;
            $scope.newObject = true;
            $scope.jobToEdit = {
                jobStatusFlag: true,
                cron_expr: '0 0 2 * * *',
                cronExprEditable: 'At 02:00 AM',
            };
        };

        $scope.save = function () {
            $scope.jobToEdit.src_tbl_name = $scope.jobToEdit.src_tbl_name.toUpperCase();
            $scope.jobToEdit.dest_tbl_name = $scope.jobToEdit.dest_tbl_name.toUpperCase();
            $scope.jobToEdit.grp_type = $scope.jobToEdit.selectedGrpType.toUpperCase()
            if (($scope.jobToEdit.src_type = $scope.jobToEdit.selectedSrcType.fromData) === 'API') {
                $scope.jobToEdit.api_url = $scope.jobToEdit.src_details;
            } else {
                $scope.jobToEdit.src_query = $scope.jobToEdit.src_details;
            }
            if ($scope.newObject)
                $scope.addApiDetails($scope.jobToEdit);
            else
                $scope.updateApiDetails($scope.jobToEdit);
        };
    }

    return controllers;
});