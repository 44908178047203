define(
	[ 'angular'

		],
		function() {
		'use strict';

		var controllers = angular.module('app.FormCreditsPartIIICtrl', [])
		.controller('F3800PartIIICtrl', ['$rootScope','$scope','$http', '$filter', '$timeout', 'GlobalService', '$uibModalInstance','ModalFactory','AlertService','JsonObjectFactory','USER_SETTINGS','GENERAL_CONFIG','workspaceFactory','FormCreditsPartIIIServiceFactory', 'rowData', 'gridData', F3800PartIIICtrl])


		function F3800PartIIICtrl($rootScope, $scope, $http, $filter, $timeout, GlobalService, $uibModalInstance, ModalFactory, AlertService, JsonObjectFactory,USER_SETTINGS, GENERAL_CONFIG, workspaceFactory,FormCreditsPartIIIServiceFactory,rowData, gridData) {

			var vm = this;
			vm.userSettings = USER_SETTINGS;
			vm.logged_in_user = vm.userSettings.user.sso_id;
			vm.defaultsMultipleSelected = [];
			//	vm.crudLoading = false;
			vm.gridData = {};
			var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
			vm.curentyear = filterParams.tax_year;
			vm.scenario  = GlobalService.globalParams.scenario;
			vm.globalScenarioDesc	= workspaceFactory.activeScreen.dataCurFilters[1].valueLabel;
			vm.glbl_issue_short_desc = GlobalService.globalParams.issue_short_desc;
			vm.glbl_issue_id  = GlobalService.globalParams.issue_id;
			vm.local_issue_id  = '';
			vm.isValidScenario = false;
			vm.isSaveClicked = false;
			vm.formDataObj = {};
			vm.formCalcData = [];
			vm.formCalcSchdAData = [];
			vm.list = [];
			vm.formList = [];
			vm.formListSchdA = [];
			vm.tempCheckBoxList= [];
			vm.tableHeader = [];
			vm.allTabsFormHeader = [];
			vm.allTabsTableHeader = [];
			vm.allTabsList = [];
			vm.allTablsOutterList = [];
			vm.originalTabsList = [];
			vm.originalTablsOutterList = [];
			vm.numberOfRow = 0;
			vm.allTabsFormHeaderSchdA = [];
			vm.allTabsTableHeaderSchdA = [];
			vm.allTabsListSchdA = [];
			vm.allTablsOutterListSchdA = [];
			vm.originalTabsListSchdA = [];
			vm.originalTablsOutterListSchdA = [];
//				vm.activeTab = null;
			vm.loadingData = true;
			vm.loadingDataSchdA = false;
			vm.fm = null;
			vm.sn = null;
			vm.pn = null;
			vm.sm = null;
			vm.currentTabs = [];
			vm.subFormHeaderList = new Map();
			vm.subTableHeaderList = new Map();
			vm.formHeaderSchdA = [];
			vm.tableHeaderSchdA = [];	
			vm.partHeaderSchdA = [];		
			vm.form8936SchdADtls = [];	
			vm.filteredForm8936SchdADtls = [];
			vm.final = [];
			vm.filteredArray = [];
			vm.loadingDtlsSchdA = true;
			vm.isEdit = false;
			vm.isNewClicked = false;
			vm.occurrence = 0;
			vm.cancel = function() {
				$uibModalInstance.dismiss('cancel');
			};

			vm.cancelForm = function() {
				if(!vm.batchSelect){
					$uibModalInstance.dismiss('cancel');
				}else{
//						vm.activeTab;
//						vm.defaultsMultipleSelected;
//						vm.subFormOutterAlreadyExists1 = $filter("filter")(vm.defaultsMultipleSelected.subForm, vm.activeTab);
					if(vm.defaultsMultipleSelected.length == 1){
						$uibModalInstance.dismiss('cancel');
					}else{

						for (var i = vm.defaultsMultipleSelected.length - 1; i >= 0; --i) {
							if (vm.defaultsMultipleSelected[i].subForm == vm.activeTab) {
								vm.defaultsMultipleSelected.splice(i,1);
							}

						}
						if(vm.defaultsMultipleSelected && vm.defaultsMultipleSelected.length > 0){
							vm.activeTab = vm.defaultsMultipleSelected[0].subForm;
						}
						vm.fetchModalCalcData();
						vm.fetchModalData1();
					}
				}
			};
			vm.fetchModalData1 = function(order) {
				vm.loadingData = true;
				if(vm.batchSelect){

					if(vm.allTabsList != null && vm.allTabsList.length > 0){
						vm.subFormAlreadyExists = $filter("filter")(vm.allTabsList, vm.activeTab); //allTabsList
						if(vm.subFormAlreadyExists.length != 0){
							vm.subFormOutterAlreadyExists = $filter("filter")(vm.allTablsOutterList, vm.subFormAlreadyExists[0][0].ATTRIB_SHORT_CODE); //allTablsOutterList
							vm.currentFormHeader = $filter("filter")(vm.allTabsFormHeader, vm.subFormAlreadyExists[0][0].FORM_NAME);
							vm.currentTableHeader = $filter("filter")(vm.allTabsTableHeader, vm.subFormAlreadyExists[0][0].FORM_NAME);
						}
					}

					if(vm.currentTabs.length != 0){
						vm.currentTabExists = $filter("filter")(vm.currentTabs, vm.activeTab);
					}
					if(vm.currentTabExists == undefined || vm.currentTabExists.length == 0 ){
						vm.currentTabs.push(vm.activeTab);
					}

					if(vm.subFormAlreadyExists != undefined && vm.subFormAlreadyExists.length > 0 ){
						vm.formHeader = null;
						vm.tableHeader = null;
						vm.formList = {};
						vm.outerList = [];

						vm.formHeader = vm.currentFormHeader[0];
						vm.tableHeader = vm.currentTableHeader[0];
						vm.formList = vm.subFormAlreadyExists[0];
						vm.outerList = (vm.subFormOutterAlreadyExists[0]);
//							vm.originalData = angular.copy(vm.formList);
//vm.originalOutterData = angular.copy(vm.outerList);
					}

					if(vm.activeTab == 'F4136' || vm.activeTab == 'F8941'){
						vm.fetchLOVF4136();
					}
					if(order == null){
						vm.fm = vm.defaultsMultipleSelected[0].formName;
						vm.sn = vm.defaultsMultipleSelected[0].schdName;
						vm.pn = vm.defaultsMultipleSelected[0].partName;
						vm.sm = vm.defaultsMultipleSelected[0].sectionName;
					}else{
						vm.fetchModalCalcData(order);
						vm.fm = vm.defaultsMultipleSelected[order].formName;
						vm.sn = vm.defaultsMultipleSelected[order].schdName;
						vm.pn = vm.defaultsMultipleSelected[order].partName;
						vm.sm = vm.defaultsMultipleSelected[order].sectionName;
					}
				}
				if(!vm.currentTabExists || vm.currentTabExists.length == 0){
					vm.fetchModalData(order);
				}
				else{
					setTimeout(function(){ vm.loadingData = false; });
				}
			}

			/* Added by Jayasree F8941*/
			vm.getFormHeaderName = function() {
				return vm.subFormHeaderList.get(vm.presentTabClicked);
			};
			vm.getTableHeaderName = function(tabClicked) {
				vm.presentTabClicked = tabClicked;
				vm.subTableHeaderSelected = vm.subTableHeaderList.get(tabClicked);
			};
			/* -------- END --------- */

			vm.fetchModalData = function(order) {
				if(!vm.batchSelect){
					vm.fm = vm.formDataObj["formName"];
					vm.sn = vm.formDataObj["schdName"];
					vm.pn = vm.formDataObj["partName"];
					vm.sm = vm.formDataObj["sectionName"];
				}
				//7004
				var params = { "action_code": 'hfyq9t', "sso_id": vm.logged_in_user, "tax_year": vm.curentyear, "scenario": filterParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, "combination_key": vm.combinationKey,//, "jcd_ta_key": null, 
						"form_no":vm.activeTab,"schedule_name":vm.sn,"part_no":vm.pn,"section_name":vm.sm ,"issue_key": rowData.ISSUE_KEY};
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
					if (vm.activeTab == 'F8941') {
						vm.formHeader = $filter("filter")(data.jsonObject, {ROW_TYPE : "FH",PART_NO : 'F8941'});
						vm.tableHeader = $filter("filter")(data.jsonObject, {ROW_TYPE : "TH",PART_NO : 'F8941'});
					} else{
						vm.formHeader = $filter("filter")(data.jsonObject, {ROW_TYPE:"FH"});
						vm.tableHeader = $filter("filter")(data.jsonObject, {ROW_TYPE:"TH"});
					}
					
					vm.formList1 =  data.jsonObject.slice(1);
					if ( vm.activeTab == 'F7207') {
						let preFormIdx = vm.formList1.map((elem) => elem.DESCRIPTION).lastIndexOf('Check to indicate whether eligible components include property produced at a facility taken into account for which\na credit under section 48C is being claimed') + 1;
						vm.preFormList = vm.formList1.slice(0, preFormIdx);
						let postFormIdx = vm.formList1.map((elem) => elem.DESCRIPTION).indexOf('Advanced Manufacturing Production Credit From Other Entities');
						vm.postFormList = vm.formList1.slice(postFormIdx);
						vm.formList1 = vm.formList1.slice(preFormIdx, postFormIdx);
					}
					vm.formList = vm.formList1.filter(function( obj ) {
						return obj.ROW_TYPE !== 'TH';
					});

					if(vm.activeTab != undefined && vm.batchSelect){
						var activeTabCheck = $filter("filter")(vm.formList, {FORM_NAME:vm.activeTab});
						vm.activeTabFormKey = activeTabCheck[0].FORM_KEY;
					}

					/* Added by Jayasree F8941*/
					//added these lines for getting sub-tabs
					if (vm.result != undefined && Array.isArray(vm.result)) {
						for (var i = 0; i < vm.result.length; i++) {
							var name = vm.result[i];
							if (name != vm.formDataObj["formName"]) {
								if (($filter("filter")(data.jsonObject, {ROW_TYPE : "FH",PART_NO : name})) != undefined && Array.isArray($filter("filter")(data.jsonObject, {ROW_TYPE : "FH",PART_NO : name}))){
									var localFilterValue = ($filter("filter")(data.jsonObject, {ROW_TYPE : "FH",PART_NO : name}))[0];
									if (localFilterValue != undefined) {vm.subFormHeaderList.set(name,localFilterValue.DESCRIPTION);
									vm.subTableHeaderList.set(name,($filter("filter")(data.jsonObject, {ROW_TYPE : "TH",PART_NO : name})));
									}
								}
							}
						}

						if(vm.presentTabClicked == undefined) {
							vm.presentTabClicked = vm.result[0];
						}
						if (Array.isArray(vm.subTableHeaderList)&& Array.isArray(vm.subFormHeaderList)) {
							vm.subTableHeaderSelected = vm.subTableHeaderList.get(vm.presentTabClicked);
						}
					}/* -------- END --------- */

					vm.outerList = [];
					for(var i=0;i<vm.formList.length;i++){
						if(vm.formList[i].FORM_LINE_KEY != null){
							//Validate on Scenario
							if(!vm.isValidScenario){
							vm.isValidScenario = vm.checkEditScenario(vm.formList[i].T_SCENARIO_TYPE_CODE,  vm.formList[i].T_SCENARIO,  vm.formList[i].ISSUE_KEY);
							}
							if(vm.outerList != null && vm.outerList.length > 0){
								var tempCheck1 = $filter("filter")(vm.outerList, {FORM_LINE_KEY:vm.formList[i].FORM_LINE_KEY, IS_FORM_DISPLAY:'Y'});
							}
							if(vm.formList[i].EDIT_TYPE == 'custom_date_various' && vm.formList[i].ATTRIB_VALUE != 'VARIOUS'){
								vm.formList[i].ATTRIB_VALUE = new Date(vm.formList[i].ATTRIB_VALUE);
								//Handle Date validations
								var formula = vm.formList[i].ATTRIB_CALC_FORMULA;
								if (formula != null || formula != undefined){
								if(formula.substring(0, 4) == 'json'){ var radio = 'json'; vm.charLength = radio.length;}
								if(formula.substring(0, 4) == 'json'){
									var formulaLength = formula.length;
									formula = formula.slice(vm.charLength, formulaLength);
									var subFormula = JSON.parse(formula);
									if(subFormula[0].DEFAULT_FROM_DATE == 'CURR_RP_BEGIN'){
										vm.formList[i]['taxYearBeginDate'] = new Date(vm.formList[i].TAX_YEAR_BEGIN);
									}else if(subFormula[0].DEFAULT_FROM_DATE == 'CURR_TY_BEGIN'){
										vm.formList[i]['taxYearBeginDate'] = new Date("01/01/" + vm.curentyear);
									}else {
										vm.formList[i]['taxYearBeginDate'] = new Date(subFormula[0].DEFAULT_FROM_DATE);
									}
									
									if(subFormula[1].DEFAULT_TO_DATE == 'CURR_RP_END'){
										vm.formList[i]['taxYearEndDate'] = new Date(vm.formList[i].TAX_YEAR_END);
									}else if(subFormula[1].DEFAULT_TO_DATE == 'CURR_TY_END'){
										vm.formList[i]['taxYearEndDate'] = new Date("12/31/" + vm.curentyear);
									}else {
										vm.formList[i]['taxYearEndDate'] = vm.formDataObj["taxYearEndDate"];
									}

								}
							  }
							}
							if(tempCheck1 == undefined || tempCheck1.length==0){
								vm.outerList.push(vm.formList[i]);
							}
//								else if(tempCheck1[0].EDIT_TYPE == 'radio'){
//								vm.outerList.push(vm.formList[i]);
//								}

						}
					}

					vm.allTabsFormHeader.push(vm.formHeader);
					vm.allTabsTableHeader.push(vm.tableHeader);

					vm.allTabsList.push(vm.formList); //Existing Data
					vm.allTablsOutterList.push(vm.outerList);

					vm.originalData = angular.copy(vm.formList);
					vm.originalOutterData = angular.copy(vm.outerList);

					vm.originalTabsList.push(vm.originalData); // Previous Original Data
					vm.originalTablsOutterList.push(vm.originalOutterData);

					vm.loadingData = false;


				});
			}


			vm.checkEditScenario = function (s_type_code, t_scenario, t_issue){        	
				if(vm.scenario == t_scenario && ((s_type_code == 'EXAM' && vm.glbl_issue_id == t_issue) || s_type_code != 'EXAM')){
					return true;
				}        	
				return false;        	
			}	
			

			vm.fetchLOVF4136 = function(order) {
				var params = { "action_code": 'sjh60s', "sso_id": vm.logged_in_user, "tax_year": vm.curentyear, "scenario": filterParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, "form_no":vm.activeTab };
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
					//vm.formLOV = data.jsonObject;
					/* Added by Jayasree F8941*/
					if(data != null && data.jsonObject != null){
						vm.formLOV = data.jsonObject;
						vm.result = [];
						for (var item, i = 0; item = vm.formLOV[i++];) {
							var name = item.CODE_DESC;
							vm.result.push(name);
						}
					}/* -------- END --------- */

				});
			}

			vm.fetchModalCalcData = function(order) {
				if(vm.batchSelect){
					if(order == null){
						var fm = vm.defaultsMultipleSelected[0].formName;
						var sn = vm.defaultsMultipleSelected[0].schdName;
						var pn = vm.defaultsMultipleSelected[0].partName;
						var sm = vm.defaultsMultipleSelected[0].sectionName;
					}else{
						var fm = vm.defaultsMultipleSelected[order].formName;
						var sn = vm.defaultsMultipleSelected[order].schdName;
						var pn = vm.defaultsMultipleSelected[order].partName;
						var sm = vm.defaultsMultipleSelected[order].sectionName;
					}
				}else{
					var fm = vm.formDataObj["formName"];
					var sn = vm.formDataObj["schdName"];
					var pn = vm.formDataObj["partName"];
					var sm = vm.formDataObj["sectionName"];
				}

				var params = { "action_code": '1ubsuu', "tax_year": vm.curentyear, "scenario": filterParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, 
						"form_no":vm.activeTab,"schedule_name":sn,"part_no":pn,"section_name":sm };
				return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (data) {
					vm.formCalcData =  data.jsonObject;
//						vm.loadingData = false;
				});
			}




			if (gridData != null) {

				var tempGridData = _.clone(gridData), groups = Object
				.create(null), result, finalKeyCheck = 0;

				vm.gridDataMultipleSelect = _.clone(tempGridData);
				vm.batchSelect = (vm.gridDataMultipleSelect[0] && vm.gridDataMultipleSelect[0].row_id) ?true : false;

				if (vm.gridDataMultipleSelect != undefined && vm.gridDataMultipleSelect.rowData != null) {

					vm.defaultsMultipleSelected.push(vm.gridDataMultipleSelect);
					vm.formDataObj["entityName"] = vm.defaultsMultipleSelected[0].rowData.LE_NAME;
					vm.formDataObj["taxCode"] = vm.defaultsMultipleSelected[0].rowData.CDR_NO;
					vm.formDataObj["meName"] = vm.defaultsMultipleSelected[0].rowData.ME_NAME;
					vm.formDataObj["taxYearBegin"] = $filter('date')(vm.defaultsMultipleSelected[0].rowData.TAX_YEAR_BEGIN, "MM/dd");
					vm.formDataObj["taxYearEnd"] = $filter('date')(vm.defaultsMultipleSelected[0].rowData.TAX_YEAR_END, "MM/dd/yyyy");
					var beginYear = $filter('date')(vm.defaultsMultipleSelected[0].rowData.TAX_YEAR_END, "yyyy");
					vm.formDataObj["taxYearBeginDate"] = new Date("01/01/" + beginYear);
					vm.formDataObj["taxYearEndDate"] = new Date("12/31/" + beginYear);
					vm.formDataObj["meCode"] = vm.defaultsMultipleSelected[0].rowData.ME_CODE;
					vm.formDataObj["combination_key"] = vm.defaultsMultipleSelected[0].rowData.COMBINATION_KEY;
					vm.formDataObj["formName"] = vm.defaultsMultipleSelected[0].rowData.SUB_FORM.split('~')[0];
					vm.formDataObj["schdName"] = vm.defaultsMultipleSelected[0].rowData.SUB_FORM.split('~')[1];
					vm.formDataObj["partName"] = vm.defaultsMultipleSelected[0].rowData.SUB_FORM.split('~')[2];
					vm.formDataObj["sectionName"] = vm.defaultsMultipleSelected[0].rowData.SUB_FORM.split('~')[3];
					var a = ((vm.defaultsMultipleSelected[0].rowData.SUB_FORM.split('~')[0] != '') ? (vm.defaultsMultipleSelected[0].rowData.SUB_FORM.split('~')[0]) : ''); 
					var b = ((vm.defaultsMultipleSelected[0].rowData.SUB_FORM.split('~')[1] != '') ? ('-' + vm.defaultsMultipleSelected[0].rowData.SUB_FORM.split('~')[1]) : '');
					var c = ((vm.defaultsMultipleSelected[0].rowData.SUB_FORM.split('~')[2] != '') ? ('-' + vm.defaultsMultipleSelected[0].rowData.SUB_FORM.split('~')[2]) : '');
					var d = ((vm.defaultsMultipleSelected[0].rowData.SUB_FORM.split('~')[3] != '') ? ('-' + vm.defaultsMultipleSelected[0].rowData.SUB_FORM.split('~')[3]) : '');
					vm.subForm = a+b+c+d;
					vm.formDataObj["subForm"] = vm.subForm;

					vm.activeTab = vm.formDataObj.subForm;
					vm.combinationKey = vm.defaultsMultipleSelected[0].rowData.COMBINATION_KEY;

				}else if(vm.gridDataMultipleSelect != undefined && vm.gridDataMultipleSelect.rowData == null){


					//vm.defaultsMultipleSelected.push(vm.gridDataMultipleSelect);

					for(var i=0;i<vm.gridDataMultipleSelect.length;i++){
						vm.formDataObj = {};
						vm.formDataObj["entityName"] = vm.gridDataMultipleSelect[i].data.LE_NAME;
						vm.formDataObj["taxCode"] = vm.gridDataMultipleSelect[i].data.CDR_NO;
						vm.formDataObj["meName"] = vm.gridDataMultipleSelect[i].data.ME_NAME;
						vm.formDataObj["taxYearBegin"] = $filter('date')(vm.gridDataMultipleSelect[i].data.TAX_YEAR_BEGIN, "MM/dd");
						vm.formDataObj["taxYearEnd"] = $filter('date')(vm.gridDataMultipleSelect[i].data.TAX_YEAR_END, "MM/dd/yyyy");
						var beginYear = $filter('date')(vm.gridDataMultipleSelect[i].data.TAX_YEAR_END, "yyyy");
						vm.formDataObj["taxYearBeginDate"] = new Date("01/01/" + beginYear);
						vm.formDataObj["taxYearEndDate"] = new Date("12/31/" + beginYear);
						vm.formDataObj["meCode"] = vm.gridDataMultipleSelect[i].data.ME_CODE;
						vm.formDataObj["combination_key"] = vm.gridDataMultipleSelect[i].data.COMBINATION_KEY;
						vm.formDataObj["formName"] = vm.gridDataMultipleSelect[i].data.SUB_FORM.split('~')[0];
						vm.formDataObj["schdName"] = vm.gridDataMultipleSelect[i].data.SUB_FORM.split('~')[1];
						vm.formDataObj["partName"] = vm.gridDataMultipleSelect[i].data.SUB_FORM.split('~')[2];
						vm.formDataObj["sectionName"] = vm.gridDataMultipleSelect[i].data.SUB_FORM.split('~')[3];

						var a = ((vm.gridDataMultipleSelect[i].data.SUB_FORM.split('~')[0] != '') ? (vm.gridDataMultipleSelect[i].data.SUB_FORM.split('~')[0]) : ''); 
						var b = ((vm.gridDataMultipleSelect[i].data.SUB_FORM.split('~')[1] != '') ? ('-' + vm.gridDataMultipleSelect[i].data.SUB_FORM.split('~')[1]) : '');
						var c = ((vm.gridDataMultipleSelect[i].data.SUB_FORM.split('~')[2] != '') ? ('-' + vm.gridDataMultipleSelect[i].data.SUB_FORM.split('~')[2]) : '');
						var d = ((vm.gridDataMultipleSelect[i].data.SUB_FORM.split('~')[3] != '') ? ('-' + vm.gridDataMultipleSelect[i].data.SUB_FORM.split('~')[3]) : '');
						vm.subForm = a+b+c+d;
						vm.formDataObj["subForm"] = vm.subForm;
						vm.formDataObj["order"] = i;

						if(vm.formDataObj.formName != null){
							var alreadyExist = $filter("filter")(vm.defaultsMultipleSelected, {formName:vm.formDataObj.formName});
						}
						if(alreadyExist.length == 0){
							if(vm.defaultsMultipleSelected.length != 0){
								vm.formDataObj["order"] = i - 1;
							}
							vm.defaultsMultipleSelected.push(vm.formDataObj);
						}

						if(vm.defaultsMultipleSelected && vm.defaultsMultipleSelected.length > 0){
							vm.activeTab = vm.defaultsMultipleSelected[0].subForm;
						}
						vm.combinationKey = vm.gridDataMultipleSelect[0].data.COMBINATION_KEY;

					}
				}

			} 


			vm.validateSubForm = function(line) {
				vm.form8936DataChanged1 = false;
				vm.form8936DataChanged2 = false;
				if(line == null || ((line.DATA_TYPE == 'number' || line.DATA_TYPE == 'decimal' ))){
					if(line && ['F7207'].includes(vm.activeTab)) {
						line.IS_CHANGED_FLAG = 'Y';
					}
					for(var g=0; g<vm.formCalcData.length; g++){
//							var newTemp = $filter("filter")(vm.formList, {LINE_ATTRIB_KEY:vm.formCalcData[i].LINE_ATTRIB_KEY});
						vm.calcAmt(vm.formCalcData[g].ATTRIB_CALC_FORMULA,vm.formCalcData[g].LINE_NO,vm.formCalcData[g].LINE_ATTRIB_KEY,vm.formCalcData[g].ATTRIB_COL,vm.formCalcData[g].EDIT_TYPE);
					}

					var changedTabAttrib = $filter("filter")(vm.formList, {FORM_NAME:vm.activeTab});
					for(var i=0;i<vm.allTabsList.length;i++){
						var z = vm.allTabsList[i];
						if(changedTabAttrib!=null && z!=null){
							if(z[0].FORM_KEY == changedTabAttrib[0].FORM_KEY){
//									vm.formList = angular.copy(vm.originalDataCheck[0]);
//									vm.outerList = angular.copy(vm.originalOutterDataCheck[0]);

								vm.allTabsList.splice(i, 1);
								vm.allTablsOutterList.splice(i, 1);
								vm.allTabsList.push(vm.formList);
								vm.allTablsOutterList.push(vm.outerList);
								break;
							}
						}
					}

				}else{
					line.IS_CHANGED_FLAG = 'Y';

					/* Added by Jayasree F8941*/
					if(vm.activeTab == 'F8941'){
						for(var g=0; g<vm.formCalcData.length; g++){
							vm.calcAmt(vm.formCalcData[g].ATTRIB_CALC_FORMULA,vm.formCalcData[g].LINE_NO,vm.formCalcData[g].LINE_ATTRIB_KEY,vm.formCalcData[g].ATTRIB_COL,vm.formCalcData[g].EDIT_TYPE);
						}
						var changedTabAttrib = $filter("filter")(vm.formList, {FORM_NAME:vm.activeTab});
						for(var i=0;i<vm.allTabsList.length;i++){
							var z = vm.allTabsList[i];
							if(changedTabAttrib!=null && z!=null){
								if(z[0].FORM_KEY == changedTabAttrib[0].FORM_KEY){
									vm.allTabsList.splice(i, 1);
									vm.allTablsOutterList.splice(i, 1);
									vm.allTabsList.push(vm.formList);
									vm.allTablsOutterList.push(vm.outerList);
									break;
								}
							}
						}
					}/* -------- END --------- */
				}

			};

			vm.validateDropdown = function(line, code){
				//vm.selectedCode = code;
				line.ATTRIB_VALUE = code;
				line.IS_CHANGED_FLAG = 'Y';
			}

			vm.fetchModalCalcData();
			vm.fetchModalData1(vm.order);
			vm.fetchLOVF4136();


			//Note to use calcAmt::::---------------------------------- 
			//1). Example to call calcAmt is if line1d = (line1a + line1b + line1c) then you need to call calcAmt("(1a)+(1b)+(1c)", "1d");
			//2). If line17d = line17c*0.40 then you need to call calcAmt("(17c)*40/100", "17d"); (no Float values in formula)
			//3). Need to check if the line has Multiplier
//				function calcAmt(formula, line_no, attrib_key, attrib_col, edit_type){
			vm.calcAmt = function(formula, line_no, attrib_key, attrib_col, edit_type){
				vm.currentFormula = [];
				vm.currentFormula = formula;
				console.log("Formula-----" , vm.currentFormula);
				var calcLineAmt = 0;

				var lineAmt = 0;
				//Calc for radio and checkBox
				if(formula.substring(0, 4) == 'json'){ var radio = 'json'; vm.charLength = radio.length;}
				if(formula.substring(0, 4) == 'json'){
					var formulaLength = formula.length;
					formula = formula.slice(vm.charLength, formulaLength);
					var subFormula = JSON.parse(formula);
//						var isConditionResult =  false;
					for(var d=0; d<subFormula.length; d++){
						var isConditionResult =  false;
						var sourceNameVal = _.filter(vm.formList, function(o) { 
							return o.ATTRIB_SHORT_CODE == subFormula[d].SOURCE_NAME; 
						})
//							if(sourceNameVal[0].ATTRIB_VALUE == subFormula[d].SOURCE_VALUE){
//							var targetNameVal = $filter("filter")(vm.formList, {ATTRIB_SHORT_CODE:subFormula[d].CALC_AMT});
//							var calcAttribVal = $filter("filter")(vm.formList, {LINE_NO : line_no, LINE_ATTRIB_KEY : attrib_key});
//							calcAttribVal[0].ATTRIB_VALUE = targetNameVal[0].ATTRIB_VALUE;
//							calcAttribVal[0].IS_CHANGED_FLAG = 'Y';
//							}

						/* Added by Jayasree F8941*/
						//added json for F8941
						if ( subFormula[d].CONDITION != undefined && subFormula[d].CONDITION != null){
							if(subFormula[d].CONDITION == "<=" && sourceNameVal[0].ATTRIB_VALUE != undefined && parseInt(sourceNameVal[0].ATTRIB_VALUE) <= parseInt(subFormula[d].SOURCE_VALUE) ) {
								isConditionResult = true;
							} else if(subFormula[d].CONDITION == ">" && sourceNameVal[0].ATTRIB_VALUE != undefined && parseInt(sourceNameVal[0].ATTRIB_VALUE) > parseInt(subFormula[d].SOURCE_VALUE) ) {
								isConditionResult = true;
							} else if(subFormula[d].CONDITION == "!=" && sourceNameVal[0].ATTRIB_VALUE != undefined && sourceNameVal[0].ATTRIB_VALUE.trim() != subFormula[d].SOURCE_VALUE) {
								isConditionResult = true;
							}
						}
						else{
							if(sourceNameVal[0].ATTRIB_VALUE != undefined && sourceNameVal[0].ATTRIB_VALUE == subFormula[d].SOURCE_VALUE){
								isConditionResult = true;
							}
						} 

						if(isConditionResult == true){
							var calcAttribVal = $filter("filter")(vm.formList, {LINE_NO : line_no, LINE_ATTRIB_KEY : attrib_key});
							if (subFormula[d].CALC_FORMULA_AMT != undefined && subFormula[d].CALC_FORMULA_AMT != null) {
								calcAttribVal[0].ATTRIB_VALUE = $filter("filter")(vm.formList, {ATTRIB_SHORT_CODE : subFormula[d].CALC_FORMULA_AMT})[0].ATTRIB_CALC_FORMULA;
							}else{
								calcAttribVal[0].ATTRIB_VALUE = $filter("filter")(vm.formList, {ATTRIB_SHORT_CODE : subFormula[d].CALC_AMT})[0].ATTRIB_VALUE;
							}
							calcAttribVal[0].IS_CHANGED_FLAG = 'Y';
							if(subFormula[d].ALERT != undefined && subFormula[d].ALERT != null && subFormula[d].ALERT == 'Y') {
								var targetNameVal = $filter("filter")(vm.formList, {ATTRIB_SHORT_CODE : subFormula[d].ALERT_VALUE});
								AlertService.add("info", targetNameVal[0].ATTRIB_DESCRIPTION, 4000);
							}
						}/* -------- END --------- */
					}
					return;

				}else{
					var matches = formula.match(/\b[^\d\s]+\b/g);
					if(matches ==  null){
						var lineFormulaArray = formula.split(/[+-/\\*\\]/);
					}else{
						var formulaLength = formula.length;
//							var subFormula = formula.substring(9,formulaLength-1)
						var subFormula = "";
						subFormula = formula.substring(formula.indexOf("("),formulaLength-1);
						var lineFormulaArray = subFormula.split(/[+-,/\\*\\]/);
					}
				}

				for(var i=0; i<lineFormulaArray.length; i++){
					if(lineFormulaArray[i].indexOf("(") > -1 && lineFormulaArray[i].indexOf(")") > -1){
						lineFormulaArray[i] = lineFormulaArray[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");

						var currentTabOldAtrribVal = $filter("filter")(vm.originalTabsList, {FORM_NAME:vm.activeTab});
						var oldAttribVal = $filter("filter")(currentTabOldAtrribVal[0], {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
						
						//var currentTabNewAtrribVal = $filter("filter")(vm.formList, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
						var currentTabNewAtrribVal = _.filter(vm.formList, function(o) { 
							return o.ATTRIB_SHORT_CODE == lineFormulaArray[i].trim(); 
						})
						if (['F7207'].includes(vm.activeTab) && oldAttribVal.length == 0) {
							oldAttribVal = $filter("filter")(vm.postFormList, {ATTRIB_SHORT_CODE:lineFormulaArray[i].trim()});
							currentTabNewAtrribVal = _.filter(vm.postFormList, function(o) { 
								return o.ATTRIB_SHORT_CODE == lineFormulaArray[i].trim(); 
							})
						}
						lineAmt = parseFloat(currentTabNewAtrribVal[0].ATTRIB_VALUE);

						if(lineAmt != parseFloat(oldAttribVal[0].ATTRIB_VALUE)){
							currentTabNewAtrribVal[0].IS_CHANGED_FLAG = 'Y';
						}
						if(parseFloat(lineAmt)>= 0){
							formula = formula.replace("("+lineFormulaArray[i].trim()+")",lineAmt);
						}else{
							formula = formula.replace("("+lineFormulaArray[i].trim()+")","("+lineAmt+")");
						}
					}
				}

				if(null!= eval(formula) && typeof eval(formula) !== 'undefined' && parseInt(eval(formula)) === parseInt(eval(formula))){//verifying for NaN by comparing the amount with itself

					var changedFormulaAttribVal = $filter("filter")(vm.formList, {LINE_NO : line_no, LINE_ATTRIB_KEY : attrib_key});
					if (['F7207'].includes(vm.activeTab) && changedFormulaAttribVal.length == 0) {
						changedFormulaAttribVal = $filter("filter")(vm.postFormList, {LINE_NO : line_no, LINE_ATTRIB_KEY : attrib_key});
					}
					var calculatedVal = eval(formula);
//						if(formula.substring(0, 8) == 'Math.min' || formula.substring(0, 8) == 'Math.max'){
//						changedFormulaAttribVal[0].ATTRIB_VALUE = calculatedVal;
//						}


					if(parseFloat(changedFormulaAttribVal[0].ATTRIB_VALUE) != calculatedVal.toFixed()){
//							if(formula.substring(0, 8) == 'Math.min' || formula.substring(0, 8) == 'Math.max'){
						if (formula.substring(0, 4) == 'Math') {
							changedFormulaAttribVal[0].ATTRIB_VALUE = calculatedVal;
						}else{
							changedFormulaAttribVal[0].ATTRIB_VALUE = calculatedVal.toFixed();
						}
						console.log("Changed Flag-----" , changedFormulaAttribVal[0].LINE_ATTRIB_KEY);
						changedFormulaAttribVal[0].IS_CHANGED_FLAG = 'Y';
					}else{
//							if(formula.substring(0, 8) == 'Math.min' || formula.substring(0, 8) == 'Math.max'){
						if (formula.substring(0, 4) == 'Math') {
							changedFormulaAttribVal[0].ATTRIB_VALUE = calculatedVal;
						}else{
							changedFormulaAttribVal[0].ATTRIB_VALUE = calculatedVal.toFixed();
						}
					}


				}



			};

			vm.checkBoxHandler = function(lineKey, attribKey, attribCol, list=undefined){
				if(list == undefined)  {
					list = vm.formList;
				}
				vm.checkedVal = [];
				vm.checkedVal = $filter("filter")(list, {FORM_LINE_KEY:lineKey, ATTRIB_COL:attribCol, IS_FORM_DISPLAY : 'Y'});
				_.forEach(vm.checkedVal,function(el,index){
					if (el.LINE_ATTRIB_KEY == attribKey) {
						if(el.ATTRIB_VALUE != undefined && el.ATTRIB_VALUE != null ){
							el.ATTRIB_VALUE = undefined;
							el.ATTRIB_VALUE = 'true';
						}else{
							el["ATTRIB_VALUE"] = 'true';
						} 
						el.IS_CHANGED_FLAG = 'Y';
					}else{
						if(el.ATTRIB_VALUE != undefined && el.ATTRIB_VALUE != null ){
							el.ATTRIB_VALUE = undefined;
							el.ATTRIB_VALUE = 'false';
						}else{
							el.ATTRIB_VALUE = undefined;
							el["ATTRIB_VALUE"] = 'false';
						} 
						el.IS_CHANGED_FLAG = 'Y';
					}
				});

				//On change of Radio Button few calculations need to run.
				vm.validateSubForm();

			}
			//};

			/* Added by Jayasree F8941*/
			vm.checkBoxHandler8941 = function(lineKey, attribKey, attribCol) {
				vm.checkedVal = [];
				vm.checkedVal = $filter("filter")(vm.formList, {FORM_LINE_KEY : lineKey,ATTRIB_COL : attribCol,IS_FORM_DISPLAY : 'Y'});
				_.forEach(vm.checkedVal, function(el, index) {
					if (el.LINE_ATTRIB_KEY == attribKey) {
						if (el.ATTRIB_VALUE != undefined && el.ATTRIB_VALUE != null) {
							// el.ATTRIB_VALUE = undefined;
							el.ATTRIB_VALUE = 'true';
						} else {
							el["ATTRIB_VALUE"] = 'true';
						}
						el.IS_CHANGED_FLAG = 'Y';
					} else {
						if (el.ATTRIB_VALUE != undefined && el.ATTRIB_VALUE != null) {
							el.ATTRIB_VALUE = undefined;
							el.ATTRIB_VALUE = '';
						} else {
							el.ATTRIB_VALUE = undefined;
							el["ATTRIB_VALUE"] = '';
						}
						el.IS_CHANGED_FLAG = 'Y';
					}
				});
				vm.validateSubForm();
			}/* -------- END --------- */

			vm.checkBoxHandler8912 = function(lineKey, attribKey, attribCol){
				if(lineKey != null){
					vm.checkeBoxChanged = $filter("filter")(vm.formList, {FORM_LINE_KEY:lineKey, ATTRIB_COL:attribCol, LINE_ATTRIB_KEY : attribKey});
					vm.checkeBoxChanged[0].IS_CHANGED_FLAG = 'Y';
				}
				vm.validateSubForm();
			}


			//reset the form
			vm.reset = function(){
				if(vm.batchSelect){
					vm.originalDataCheck = [];
					vm.originalOutterDataCheck= [];
//						var existingCheck = $filter("filter")(vm.allTabsList, {FORM_KEY:vm.formList[0].FORM_KEY});

					var existingDataCheck = $filter("filter")(vm.allTabsList, {FORM_NAME:vm.activeTab});
					vm.originalDataCheck = $filter("filter")(vm.originalTabsList, {FORM_NAME:vm.activeTab});
//						vm.originalDataCheck = vm.originalTabsList.filter(function( obj ) {
//						return obj.FORM_NAME == vm.activeTab;
//						});
					vm.originalOutterDataCheck = $filter("filter")(vm.originalTablsOutterList, {FORM_NAME:vm.activeTab});
//						vm.originalOutterDataCheck = vm.originalTablsOutterList.filter(function( obj ) {
//						return obj.FORM_NAME == vm.activeTab;
//						});

					for(var i=0;i<vm.allTabsList.length;i++){
						var z = vm.allTabsList[i];
						if(existingDataCheck!=null && z!=null){
							if(z[0].FORM_KEY == existingDataCheck[0][0].FORM_KEY){
								vm.formList = angular.copy(vm.originalDataCheck[0]);
								vm.outerList = angular.copy(vm.originalOutterDataCheck[0]);

								vm.allTabsList.splice(i, 1);
								vm.allTablsOutterList.splice(i,1);

								vm.allTabsList.push(angular.copy(vm.originalDataCheck[0]));
								vm.allTablsOutterList.push(angular.copy(vm.originalOutterDataCheck[0]));
								break;
							}
						}
					}

				}else{
					vm.formList = angular.copy(vm.originalData);
					vm.outerList = angular.copy(vm.originalOutterData);

					vm.allTabsList = [];
					vm.allTablsOutterList = [];

					vm.allTabsList.push(vm.originalData);
					vm.allTablsOutterList.push(vm.originalOutterData);

//						vm.allTabsList = angular.copy(vm.originalData);
//						vm.allTablsOutterList = angular.copy(vm.outerList);
				}
			};


			vm.save = function() {
				vm.isSaveClicked = true;
				/*if (vm.crudLoading) {
					AlertService.add("info", "Please wait while we save this request", 4000);
					return;
				  }

				vm.crudLoading = true;

				if(vm.defaultsMultipleSelected.length == 0 ){
					AlertService.add("error", "Please select atleast one Component Default Mapping");
					vm.crudLoading = false;
					return;
				}*/

				var returnClobSettingsObj = {};
				var creditFormSaveDtls = [];
				var combinationKey = 0;
//					for(var key in vm.formList){
				for(var key in vm.allTabsList){


					vm.saveData = $filter("filter")(vm.allTabsList[key], {IS_CHANGED_FLAG:'Y'});
					if(vm.activeTab == 'F7207') {
						vm.saveData.push(...$filter("filter")(vm.preFormList, {IS_CHANGED_FLAG:'Y'}))
						vm.saveData.push(...$filter("filter")(vm.postFormList, {IS_CHANGED_FLAG:'Y'}))
					}
					for(var i=0;i < vm.saveData.length;i++){
						var returnObj = {};
//							if(vm.allTabsList[key][i].IS_CHANGED_FLAG == 'Y'){

//							returnObj["object_id"] = gridData.rowData.object_id;

						returnObj['form_name'] =  vm.saveData[i].formName;
						returnObj["issue_key"] = vm.saveData[i].ISSUE_KEY;
						returnObj['leid'] =  vm.saveData[i].LEID;
						returnObj['cdr_number'] =  vm.saveData[i].CDR_NO;
						returnObj['reporting_period'] =  vm.saveData[i].REPORTING_PERIOD;
						returnObj['me_code'] =  vm.saveData[i].ME_CODE;
						combinationKey = vm.saveData[i].COMBINATION_KEY;
						returnObj['part_no'] =  vm.saveData[i].partName;
						returnObj['schd_no'] =  vm.saveData[i].schdName;
						returnObj['section_name'] =  vm.saveData[i].sectionName;
						returnObj['combination_key'] = vm.saveData[i].COMBINATION_KEY;
						returnObj['form_key'] = vm.saveData[i].FORM_KEY;
						returnObj['form_line_key'] = vm.saveData[i].FORM_LINE_KEY;
						returnObj['line_attrib_key'] = vm.saveData[i].LINE_ATTRIB_KEY;
						returnObj['attrib_type'] = vm.saveData[i].ATTRIB_TYPE;
						returnObj['attrib_prop_key'] = vm.saveData[i].ATTRIB_PROP_KEY;
						returnObj['acct_ref_key'] = vm.saveData[i].ACCT_REF_KEY;
						if(vm.saveData[i].EDIT_TYPE == 'custom_date_various' && vm.saveData[i].ATTRIB_VALUE != 'VARIOUS'){
							var str = $.datepicker.formatDate('mm/dd/yy', vm.saveData[i].ATTRIB_VALUE);
							vm.saveData[i].ATTRIB_VALUE = str;
							returnObj['attrib_value'] = vm.saveData[i].ATTRIB_VALUE;
						}else{
							returnObj['attrib_value'] = vm.saveData[i].ATTRIB_VALUE;
						}
						returnObj['trans_details_key'] = vm.saveData[i].TRANS_DETAILS_KEY;
						returnObj['trans_type_key'] = vm.saveData[i].TRANS_TYPE_KEY;
						returnObj['is_changed_flag'] = vm.saveData[i].IS_CHANGED_FLAG;
						returnObj['occurrence'] = (vm.saveData[i].OCCURRENCE != null) ? parseInt(vm.saveData[i].OCCURRENCE) : 1;

						creditFormSaveDtls.push(returnObj);
//							}
					}
					var message = "Credits has been successfully saved/updated";

				}

				returnClobSettingsObj['sso_id'] = vm.logged_in_user;
				returnClobSettingsObj['screen_key'] = GlobalService.globalParams.screen_key;
				returnClobSettingsObj['form_name'] = vm.formDataObj.formName;
				returnClobSettingsObj['combination_key'] = vm.formDataObj.combination_key;

				sendDetails(returnClobSettingsObj,creditFormSaveDtls, message);

			};

			function sendDetails(returnClobSettingsObj, creditFormSaveDtls, message) {
				FormCreditsPartIIIServiceFactory.saveCreditForms(returnClobSettingsObj,creditFormSaveDtls).then(function(result) {

					if (result.data.errorMessage && result.data.errorMessage !== 'null') {
						vm.isSaveClicked = false;
						AlertService.add("error", result.data.errorMessage, 4000);
					} else {
						//vm.crudLoading = false;
						AlertService.add("success", message, 4000);
						var args = {
								combination_key: returnClobSettingsObj.combination_key,
								issue_key:rowData.ISSUE_KEY,
								gridFilter: {
									combination_key: returnClobSettingsObj.combination_key,
									issue_key:rowData.ISSUE_KEY,
								}
						};
						$uibModalInstance.dismiss('cancel');
						$rootScope.$emit('gridUpdate', args);
					};

				});
			}	
			
			// Form 8936 Schedule A Implementation - START
			vm.onTabChange = function(e) {
				vm.selectedTabName = e;
				$timeout(function() {
					var movingTab = document.querySelector('.wizard-tabs .nav-pills .wizard-tab-hilighter');
					var selectedTab = document.querySelector('.wizard-tabs .uib-tab.nav-item.active');
					if (movingTab && selectedTab) {
						$(movingTab).width(selectedTab.offsetWidth);
						$(movingTab).height(selectedTab.offsetHeight)
							.css('line-height', selectedTab.offsetHeight + 'px')
							.css('transform', 'translate3d(' + selectedTab.offsetLeft + 'px, 0px, 0px');
					}
				}, 50);
				if(vm.selectedTabName == 'Schedule A') {
					vm.activeTab = 'F8936SchA';
					vm.getAllSchdADetails();
				}
				else if (vm.selectedTabName == 'Form 8936'){
					vm.activeTab = vm.subForm;
					vm.fetchModalCalcData();
					vm.fetchModalData1(vm.order);					
				}
			};

			vm.getAllSchdADetails = function (){
				vm.final = [];
				var params = { "action_code": 'j35iiy', "tax_year": vm.curentyear, "scenario": filterParams.scenario.toString(), "jcd_key": GlobalService.globalParams.jcd_key, 
					"combination_key":vm.defaultsMultipleSelected[0].rowData.COMBINATION_KEY.toString(),"issue_key":'1',"form_key":'1080' };
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (resultSchA) {
				vm.form8936SchdADtls =  resultSchA.jsonObject;
				vm.loadingDtlsSchdA = false;
				if(vm.form8936SchdADtls!=null) {
				for (var i = 0, len = vm.form8936SchdADtls.length; i < len; i++){
					vm.form8936SchdADtls[i].OCCURRENCE_DATA = JSON.parse(vm.form8936SchdADtls[i].OCCURRENCE_DATA)
					//This will convert the strings into objects before Ng-Repeat Runs
					
				 }	
				
				}
			});
			}
			vm.fetchSchAData = function (){
				var params = { "action_code": '1ubsuu', "tax_year": vm.curentyear, "scenario": filterParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, 
					"form_no":'F8936SchA',"schedule_name":'A',"part_no":'',"section_name":'' };
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (resultFetchSchA) {
				vm.formCalcSchdAData =  resultFetchSchA.jsonObject;
//						vm.loadingData = false;
			});
			}

			vm.fetchSchANewTemplate = function (item){
				var params = { "action_code": 'tryvqq', "sso_id": vm.logged_in_user, "tax_year": vm.curentyear, "scenario": filterParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, "combination_key": vm.combinationKey,//, "jcd_ta_key": null, 
					"form_no":'F8936SchA',"schedule_name":'A',"part_no":vm.pn,"section_name":vm.sm ,"issue_key": rowData.ISSUE_KEY};
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (dataJson) {
					// vm.loadingDataSchdA = true;
					vm.formHeaderSchdA = $filter("filter")(dataJson.jsonObject, {ROW_TYPE:"FH"});
					vm.tableHeaderSchdA = $filter("filter")(dataJson.jsonObject, {ROW_TYPE:"TH"});
					vm.partHeaderSchdA = $filter("filter")(dataJson.jsonObject, {ROW_TYPE:"PH"});
				
				
				vm.formListSchdA =  dataJson.jsonObject.slice(1);
				vm.formListSchdA = vm.formListSchdA.filter(function( obj ) {
					return obj.ROW_TYPE !== 'TH';
				});
		
				vm.outerListSchdA = [];
				for(var i=0;i<vm.formListSchdA.length;i++){
					if(vm.formListSchdA[i].FORM_LINE_KEY != null){
						// if(vm.isNewClicked && item == undefined){
						// 	vm.formListSchdA[i].ATTRIB_VALUE = null;
						// 	if(vm.formListSchdA[i].EDIT_TYPE == 'custom_date_various' && vm.formListSchdA[i].ATTRIB_VALUE != 'VARIOUS'){
						// 		vm.formListSchdA[i].ATTRIB_VALUE = new Date();
						// 	}
						// }
						//Validate on Scenario
						if(!vm.isValidScenario){
							vm.isValidScenario = vm.checkEditScenario(vm.formListSchdA[i].T_SCENARIO_TYPE_CODE,  vm.formListSchdA[i].T_SCENARIO,  vm.formListSchdA[i].ISSUE_KEY);
						}
						if(vm.outerListSchdA != null && vm.outerListSchdA.length > 0){
							var tempCheck1 = $filter("filter")(vm.outerListSchdA, {FORM_LINE_KEY:vm.formListSchdA[i].FORM_LINE_KEY, IS_FORM_DISPLAY:'Y'});
						}
						if(vm.formListSchdA[i].EDIT_TYPE == 'custom_date_various' && vm.formListSchdA[i].ATTRIB_VALUE != 'VARIOUS'){
							vm.formListSchdA[i].ATTRIB_VALUE = new Date(vm.formListSchdA[i].ATTRIB_VALUE);
							//Handle Date validations
							var formulaSchdA = vm.formListSchdA[i].ATTRIB_CALC_FORMULA;
							if (formulaSchdA != null || formulaSchdA != undefined){
							if(formulaSchdA.substring(0, 4) == 'json'){ var radio = 'json'; vm.charLength = radio.length;}
							if(formulaSchdA.substring(0, 4) == 'json'){
								var formulaSchdALength = formulaSchdA.length;
								formulaSchdA = formulaSchdA.slice(vm.charLength, formulaSchdALength);
								var subFormulaSchdA = JSON.parse(formulaSchdA);
								if(subFormulaSchdA[0].DEFAULT_FROM_DATE == 'CURR_RP_BEGIN'){
									vm.formListSchdA[i]['taxYearBeginDate'] = new Date(vm.formListSchdA[i].TAX_YEAR_BEGIN);
								}else if(subFormulaSchdA[0].DEFAULT_FROM_DATE == 'CURR_TY_BEGIN'){
									vm.formListSchdA[i]['taxYearBeginDate'] = new Date("01/01/" + vm.curentyear);
								}else {
									vm.formListSchdA[i]['taxYearBeginDate'] = new Date(subFormulaSchdA[0].DEFAULT_FROM_DATE);
								}
								
								if(subFormulaSchdA[1].DEFAULT_TO_DATE == 'CURR_RP_END'){
									vm.formListSchdA[i]['taxYearEndDate'] = new Date(vm.formListSchdA[i].TAX_YEAR_END);
								}else if(subFormulaSchdA[1].DEFAULT_TO_DATE == 'CURR_TY_END'){
									vm.formListSchdA[i]['taxYearEndDate'] = new Date("12/31/" + vm.curentyear);
								}else {
									vm.formListSchdA[i]['taxYearEndDate'] = vm.formDataObj["taxYearEndDate"];
								}

							}
						  }
						}
						if(tempCheck1 == undefined || tempCheck1.length==0){
							vm.outerListSchdA.push(vm.formListSchdA[i]);
						}
					}
				}
				if(item != undefined) {
					vm.formListSchdA = vm.formListSchdA.filter(function(ele) {
						return ele.OCCURRENCE === item.OCCURRENCE;
					  });
					  vm.outerListSchdA = vm.outerListSchdA.filter(function(ele) {
						return ele.OCCURRENCE === item.OCCURRENCE;
					  });
			 }
				vm.allTabsFormHeaderSchdA.push(vm.formHeaderSchdA);
				vm.allTabsTableHeaderSchdA.push(vm.tableHeaderSchdA);

				vm.allTabsListSchdA.push(vm.formListSchdA); //Existing Data
				vm.allTablsOutterListSchdA.push(vm.outerListSchdA);

				vm.originalDataSchdA = angular.copy(vm.formListSchdA);
				vm.originalOutterDataSchdA = angular.copy(vm.outerListSchdA);

				vm.originalTabsListSchdA.push(vm.originalDataSchdA); // Previous Original Data
				vm.originalTablsOutterListSchdA.push(vm.originalOutterDataSchdA);

				vm.loadingDataSchdA = false;
			});
			}
			vm.fetchSchAJson = function (item){
				var params = { "action_code": 'hfyq9t', "sso_id": vm.logged_in_user, "tax_year": vm.curentyear, "scenario": filterParams.scenario, "jcd_key": GlobalService.globalParams.jcd_key, "combination_key": vm.combinationKey,//, "jcd_ta_key": null, 
					"form_no":'F8936SchA',"schedule_name":'A',"part_no":vm.pn,"section_name":vm.sm ,"issue_key": rowData.ISSUE_KEY};
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function (dataJson) {
					// vm.loadingDataSchdA = true;
					vm.formHeaderSchdA = $filter("filter")(dataJson.jsonObject, {ROW_TYPE:"FH"});
					vm.tableHeaderSchdA = $filter("filter")(dataJson.jsonObject, {ROW_TYPE:"TH"});
					vm.partHeaderSchdA = $filter("filter")(dataJson.jsonObject, {ROW_TYPE:"PH"});
				
				
				vm.formListSchdA =  dataJson.jsonObject.slice(1);
				vm.formListSchdA = vm.formListSchdA.filter(function( obj ) {
					return obj.ROW_TYPE !== 'TH';
				});
				// if (vm.isNewClicked && vm.form8936SchdADtls.length > 0) {
				// 	vm.formListSchdA = vm.formListSchdA.filter(function( obj ) {
				// 		return obj.OCCURRENCE === vm.form8936SchdADtls.length;
				// 	});
				// 	//vm.formListSchdA = vm.formListSchdA.concat(vm.partHeaderSchdA);
				// }
				if (vm.isEdit && vm.form8936SchdADtls.length > 0) {
					vm.formListSchdA = vm.formListSchdA.filter(function( obj ) {
						return (obj.OCCURRENCE === item.OCCURRENCE || obj.OCCURRENCE == undefined);
					});
					//vm.formListSchdA = vm.formListSchdA.concat(vm.partHeaderSchdA);
				}
				console.log("This is the edit data"+item)
				if(vm.activeTab != undefined && vm.batchSelect){
					var activeTabCheck = $filter("filter")(vm.formListSchdA, {FORM_NAME:vm.activeTab});
					vm.activeTabFormKey = activeTabCheck[0].FORM_KEY;
				}

				vm.outerListSchdA = [];
				for(var i=0;i<vm.formListSchdA.length;i++){
					if(vm.formListSchdA[i].FORM_LINE_KEY != null){
						// if(vm.isNewClicked && item == undefined){
						// 	vm.formListSchdA[i].ATTRIB_VALUE = null;
						// 	if(vm.formListSchdA[i].EDIT_TYPE == 'custom_date_various' && vm.formListSchdA[i].ATTRIB_VALUE != 'VARIOUS'){
						// 		vm.formListSchdA[i].ATTRIB_VALUE = new Date();
						// 	}
						// }
						//Validate on Scenario
						if(!vm.isValidScenario){
							vm.isValidScenario = vm.checkEditScenario(vm.formListSchdA[i].T_SCENARIO_TYPE_CODE,  vm.formListSchdA[i].T_SCENARIO,  vm.formListSchdA[i].ISSUE_KEY);
						}
						if(vm.outerListSchdA != null && vm.outerListSchdA.length > 0){
							var tempCheck1 = $filter("filter")(vm.outerListSchdA, {FORM_LINE_KEY:vm.formListSchdA[i].FORM_LINE_KEY, IS_FORM_DISPLAY:'Y'});
						}
						if(vm.formListSchdA[i].EDIT_TYPE == 'custom_date_various' && vm.formListSchdA[i].ATTRIB_VALUE != 'VARIOUS'){
							vm.formListSchdA[i].ATTRIB_VALUE = new Date(vm.formListSchdA[i].ATTRIB_VALUE);
							//Handle Date validations
							var formulaSchdA = vm.formListSchdA[i].ATTRIB_CALC_FORMULA;
							if (formulaSchdA != null || formulaSchdA != undefined){
							if(formulaSchdA.substring(0, 4) == 'json'){ var radio = 'json'; vm.charLength = radio.length;}
							if(formulaSchdA.substring(0, 4) == 'json'){
								var formulaSchdALength = formulaSchdA.length;
								formulaSchdA = formulaSchdA.slice(vm.charLength, formulaSchdALength);
								var subFormulaSchdA = JSON.parse(formulaSchdA);
								if(subFormulaSchdA[0].DEFAULT_FROM_DATE == 'CURR_RP_BEGIN'){
									vm.formListSchdA[i]['taxYearBeginDate'] = new Date(vm.formListSchdA[i].TAX_YEAR_BEGIN);
								}else if(subFormulaSchdA[0].DEFAULT_FROM_DATE == 'CURR_TY_BEGIN'){
									vm.formListSchdA[i]['taxYearBeginDate'] = new Date("01/01/" + vm.curentyear);
								}else {
									vm.formListSchdA[i]['taxYearBeginDate'] = new Date(subFormulaSchdA[0].DEFAULT_FROM_DATE);
								}
								
								if(subFormulaSchdA[1].DEFAULT_TO_DATE == 'CURR_RP_END'){
									vm.formListSchdA[i]['taxYearEndDate'] = new Date(vm.formListSchdA[i].TAX_YEAR_END);
								}else if(subFormulaSchdA[1].DEFAULT_TO_DATE == 'CURR_TY_END'){
									vm.formListSchdA[i]['taxYearEndDate'] = new Date("12/31/" + vm.curentyear);
								}else {
									vm.formListSchdA[i]['taxYearEndDate'] = vm.formDataObj["taxYearEndDate"];
								}

							}
						  }
						}
						if(tempCheck1 == undefined || tempCheck1.length==0){
							vm.outerListSchdA.push(vm.formListSchdA[i]);
						}
					}
				}
			// 	if(item != undefined) {
			// 		vm.formListSchdA = vm.formListSchdA.filter(function(ele) {
			// 			return ele.OCCURRENCE === item.OCCURRENCE;
			// 		  });
			// 		  vm.outerListSchdA = vm.outerListSchdA.filter(function(ele) {
			// 			return ele.OCCURRENCE === item.OCCURRENCE;
			// 		  });
			//  }
				vm.allTabsFormHeaderSchdA.push(vm.formHeaderSchdA);
				vm.allTabsTableHeaderSchdA.push(vm.tableHeaderSchdA);

				vm.allTabsListSchdA.push(vm.formListSchdA); //Existing Data
				vm.allTablsOutterListSchdA.push(vm.outerListSchdA);

				vm.originalDataSchdA = angular.copy(vm.formListSchdA);
				vm.originalOutterDataSchdA = angular.copy(vm.outerListSchdA);

				vm.originalTabsListSchdA.push(vm.originalDataSchdA); // Previous Original Data
				vm.originalTablsOutterListSchdA.push(vm.originalOutterDataSchdA);

				vm.loadingDataSchdA = false;
			});
			}

			vm.AddNewRow = function(){
			console.log("The Schd A is created under :::::::");
			vm.loadingDataSchdA = true;
			vm.numberOfRow = 0;
			vm.isEdit = false;
			vm.isNewClicked = true;
				vm.fetchSchAData();
				vm.fetchSchANewTemplate();
			}
			vm.cancelFormSchdA = function() {
				if(!vm.batchSelect){
					$uibModalInstance.dismiss('cancel');
				}else{
					if(vm.defaultsMultipleSelected.length == 1){
						$uibModalInstance.dismiss('cancel');
					}else{

						for (var i = vm.defaultsMultipleSelected.length - 1; i >= 0; --i) {
							if (vm.defaultsMultipleSelected[i].subForm == vm.activeTab) {
								vm.defaultsMultipleSelected.splice(i,1);
							}

						}
						if(vm.defaultsMultipleSelected && vm.defaultsMultipleSelected.length > 0){
							vm.activeTab = vm.defaultsMultipleSelected[0].subForm;
						}
						vm.fetchSchAData();
						vm.fetchSchAJson();
					}
				}
			};
			vm.checkBoxHandlerSchdA = function(lineKey, attribKey, attribCol, listSchdA=undefined){
				if(listSchdA == undefined)  {
					listSchdA = vm.formListSchdA;
				}
				vm.checkedVal = [];
				vm.checkedVal = $filter("filter")(listSchdA, {FORM_LINE_KEY:lineKey, ATTRIB_COL:attribCol, IS_FORM_DISPLAY : 'Y'});
				_.forEach(vm.checkedVal,function(el,index){
					if (el.LINE_ATTRIB_KEY == attribKey) {
						if(el.ATTRIB_VALUE != undefined && el.ATTRIB_VALUE != null ){
							el.ATTRIB_VALUE = undefined;
							el.ATTRIB_VALUE = 'true';
						}else{
							el["ATTRIB_VALUE"] = 'true';
						} 
						el.IS_CHANGED_FLAG = 'Y';
					}else{
						if(el.ATTRIB_VALUE != undefined && el.ATTRIB_VALUE != null ){
							el.ATTRIB_VALUE = undefined;
							el.ATTRIB_VALUE = 'false';
						}else{
							el.ATTRIB_VALUE = undefined;
							el["ATTRIB_VALUE"] = 'false';
						} 
						el.IS_CHANGED_FLAG = 'Y';
					}
				});

				//On change of Radio Button few calculations need to run.
				vm.validateSubFormSchdA();

			}
			vm.validateSubFormSchdA = function(line) {
				vm.form8936DataChanged1 = false;
				vm.form8936DataChanged2 = false;
				if(line == null || ((line.DATA_TYPE == 'number' || line.DATA_TYPE == 'decimal' ))){
					for(var g=0; g<vm.formCalcSchdAData.length; g++){
						vm.calcAmtSchdA(vm.formCalcSchdAData[g].ATTRIB_CALC_FORMULA,vm.formCalcSchdAData[g].LINE_NO,vm.formCalcSchdAData[g].LINE_ATTRIB_KEY,vm.formCalcSchdAData[g].ATTRIB_COL,vm.formCalcSchdAData[g].EDIT_TYPE);
					}

					var changedTabAttrib = $filter("filter")(vm.formListSchdA, {FORM_NAME:vm.activeTab});
					for(var i=0;i<vm.allTabsListSchdA.length;i++){
						var z = vm.allTabsListSchdA[i];
						if(changedTabAttrib!=null && z!=null){
							if(z[0].FORM_KEY == changedTabAttrib[0].FORM_KEY){
								vm.allTabsListSchdA.splice(i, 1);
								vm.allTablsOutterListSchdA.splice(i, 1);
								vm.allTabsListSchdA.push(vm.formListSchdA);
								vm.allTablsOutterListSchdA.push(vm.outerListSchdA);
								break;
							}
						}
					}

				}else{
					line.IS_CHANGED_FLAG = 'Y';
				}

			};
			//reset the form
			vm.resetSchdA = function(){
				if(vm.batchSelect){
					vm.originalDataCheckSchdA = [];
					vm.originalOutterDataCheckSchdA = [];

					var existingDataCheckSchdA = $filter("filter")(vm.allTabsListSchdA, {FORM_NAME:vm.activeTab});
					vm.originalDataCheckSchdA = $filter("filter")(vm.originalTabsListSchdA, {FORM_NAME:vm.activeTab});

					vm.originalOutterDataCheckSchdA = $filter("filter")(vm.originalTablsOutterListSchdA, {FORM_NAME:vm.activeTab});


					for(var i=0;i<vm.allTabsListSchdA.length;i++){
						var z = vm.allTabsListSchdA[i];
						if(existingDataCheckSchdA!=null && z!=null){
							if(z[0].FORM_KEY == existingDataCheckSchdA[0][0].FORM_KEY){
								vm.formListSchdA = angular.copy(vm.originalDataCheckSchdA[0]);
								vm.outerListSchdA = angular.copy(vm.originalOutterDataCheckSchdA[0]);

								vm.allTabsListSchdA.splice(i, 1);
								vm.allTablsOutterListSchdA.splice(i,1);

								vm.allTabsListSchdA.push(angular.copy(vm.originalDataCheckSchdA[0]));
								vm.allTablsOutterListSchdA.push(angular.copy(vm.originalOutterDataCheckSchdA[0]));
								break;
							}
						}
					}

				}else{
					vm.formListSchdA = angular.copy(vm.originalDataSchdA);
					vm.outerListSchdA = angular.copy(vm.originalOutterDataSchdA);

					vm.allTabsListSchdA = [];
					vm.allTablsOutterListSchdA = [];

					vm.allTabsListSchdA.push(vm.originalDataSchdA);
					vm.allTablsOutterListSchdA.push(vm.originalOutterDataSchdA);
				}
			};


			vm.saveSchdA = function() {
				vm.isSaveClickedSchdA = true;
				var returnClobSettingsObjSchdA = {};
				var creditFormSaveDtlsSchdA = [];
				var combinationKeySchdA = 0;
				for(var key in vm.allTabsListSchdA){


					vm.saveDataSchdA = $filter("filter")(vm.allTabsListSchdA[key], {IS_CHANGED_FLAG:'Y'});
					for(var i=0;i < vm.saveDataSchdA.length;i++){
						var returnObjSchdA = {};
						returnObjSchdA['form_name'] =  'F8936SchA';
						returnObjSchdA["issue_key"] = vm.saveDataSchdA[i].ISSUE_KEY;
						returnObjSchdA['leid'] =  vm.saveDataSchdA[i].LEID;
						returnObjSchdA['cdr_number'] =  vm.saveDataSchdA[i].CDR_NO;
						returnObjSchdA['reporting_period'] =  vm.saveDataSchdA[i].REPORTING_PERIOD;
						returnObjSchdA['me_code'] =  vm.saveDataSchdA[i].ME_CODE;
						combinationKeySchdA = vm.saveDataSchdA[i].COMBINATION_KEY;
						returnObjSchdA['part_no'] =  vm.saveDataSchdA[i].partName;
						returnObjSchdA['schd_no'] =  'A';
						returnObjSchdA['section_name'] =  vm.saveDataSchdA[i].sectionName;
						returnObjSchdA['combination_key'] = vm.saveDataSchdA[i].COMBINATION_KEY;
						returnObjSchdA['form_key'] = vm.saveDataSchdA[i].FORM_KEY;
						returnObjSchdA['form_line_key'] = vm.saveDataSchdA[i].FORM_LINE_KEY;
						returnObjSchdA['line_attrib_key'] = vm.saveDataSchdA[i].LINE_ATTRIB_KEY;
						returnObjSchdA['line_number'] = vm.saveDataSchdA[i].LINE_NO;
						returnObjSchdA['attrib_type'] = vm.saveDataSchdA[i].ATTRIB_TYPE;
						returnObjSchdA['attrib_prop_key'] = vm.saveDataSchdA[i].ATTRIB_PROP_KEY;
						returnObjSchdA['acct_ref_key'] = vm.saveDataSchdA[i].ACCT_REF_KEY;
						if(vm.saveDataSchdA[i].EDIT_TYPE == 'custom_date_various' && vm.saveDataSchdA[i].ATTRIB_VALUE != 'VARIOUS'){
							var str = $.datepicker.formatDate('mm/dd/yy', vm.saveDataSchdA[i].ATTRIB_VALUE);
							vm.saveDataSchdA[i].ATTRIB_VALUE = str;
							returnObjSchdA['attrib_value'] = vm.saveDataSchdA[i].ATTRIB_VALUE;
						}else{
							returnObjSchdA['attrib_value'] = vm.saveDataSchdA[i].ATTRIB_VALUE;
						}
						returnObjSchdA['trans_details_key'] = vm.saveDataSchdA[i].TRANS_DETAILS_KEY;
						returnObjSchdA['trans_type_key'] = vm.saveDataSchdA[i].TRANS_TYPE_KEY;
						returnObjSchdA['is_changed_flag'] = vm.saveDataSchdA[i].IS_CHANGED_FLAG;
						returnObjSchdA['occurrence'] = (vm.isEdit == true && parseInt(vm.saveDataSchdA[i].OCCURRENCE)) ? parseInt(vm.saveDataSchdA[i].OCCURRENCE) : vm.form8936SchdADtls != null ? (vm.form8936SchdADtls.length + 1) : 1;
						vm.occurrence = returnObjSchdA['occurrence'];
						creditFormSaveDtlsSchdA.push(returnObjSchdA);
//							}
					}
					var message = "Credits has been successfully saved/updated";

				}

				returnClobSettingsObjSchdA['sso_id'] = vm.logged_in_user;
				returnClobSettingsObjSchdA['screen_key'] = GlobalService.globalParams.screen_key;
				returnClobSettingsObjSchdA['form_name'] = 'F8936SchA';
				returnClobSettingsObjSchdA['combination_key'] = vm.formDataObj.combination_key;
				returnClobSettingsObjSchdA['tax_year'] = vm.curentyear;
				returnClobSettingsObjSchdA['scenario'] = vm.scenario;
				returnClobSettingsObjSchdA['jcd_key'] = GlobalService.globalParams.jcd_key;
				returnClobSettingsObjSchdA['occurrence'] = vm.occurrence;				
				sendDetails(returnClobSettingsObjSchdA,creditFormSaveDtlsSchdA, message);

			};

			function sendDetails(returnClobSettingsObjSchdA, creditFormSaveDtlsSchdA, message) {
				FormCreditsPartIIIServiceFactory.saveCreditForms(returnClobSettingsObjSchdA,creditFormSaveDtlsSchdA).then(function(result) {

					if (result.data.errorMessage && result.data.errorMessage !== 'null') {
						vm.isSaveClicked = false;
						AlertService.add("error", result.data.errorMessage, 4000);
					} else {
						//vm.crudLoading = false;
						AlertService.add("success", message, 4000);
						var args = {
								combination_key: returnClobSettingsObjSchdA.combination_key,
								issue_key:rowData.ISSUE_KEY,
								gridFilter: {
									combination_key: returnClobSettingsObjSchdA.combination_key,
									issue_key:rowData.ISSUE_KEY,
								}
						};
						$uibModalInstance.dismiss('cancel');
						$rootScope.$emit('gridUpdate', args);
					};

				});
			}	
				 //Note to use calcAmt::::---------------------------------- 
			//1). Example to call calcAmt is if line1d = (line1a + line1b + line1c) then you need to call calcAmt("(1a)+(1b)+(1c)", "1d");
			//2). If line17d = line17c*0.40 then you need to call calcAmt("(17c)*40/100", "17d"); (no Float values in formula)
			//3). Need to check if the line has Multiplier

			vm.calcAmtSchdA = function(formulaSchdA, line_noSchdA, attrib_keySchdA, attrib_colSchdA, edit_typeSchdA){
				vm.currentFormulaSchdA = [];
				vm.currentFormulaSchdA = formulaSchdA;
				console.log("Formula-----" , vm.currentFormulaSchdA);
				var lineAmtSchdA = 0;
				//Calc for radio and checkBox
				if(formulaSchdA.substring(0, 4) == 'json'){ var radio = 'json'; vm.charLengthSchdA = radio.length;}
				if(formulaSchdA.substring(0, 4) == 'json'){
					var formulaLengthSchdA = formulaSchdA.length;
					formulaSchdA = formulaSchdA.slice(vm.charLengthSchdA, formulaLengthSchdA);
					var subFormulaSchdA = JSON.parse(formulaSchdA);
					for(var d=0; d<subFormulaSchdA.length; d++){
						var isConditionResultSchdA =  false;
						var sourceNameValSchdA = _.filter(vm.formListSchdA, function(o) { 
							return o.ATTRIB_SHORT_CODE == subFormulaSchdA[d].SOURCE_NAME; 
						})

						/* Added by Jayasree F8941*/
						//added json for F8941
						if ( subFormulaSchdA[d].CONDITION != undefined && subFormulaSchdA[d].CONDITION != null){
							if(subFormulaSchdA[d].CONDITION == "<=" && sourceNameValSchdA[0].ATTRIB_VALUE != undefined && parseInt(sourceNameValSchdA[0].ATTRIB_VALUE) <= parseInt(subFormulaSchdA[d].SOURCE_VALUE) ) {
								isConditionResultSchdA = true;
							} else if(subFormulaSchdA[d].CONDITION == ">" && sourceNameValSchdA[0].ATTRIB_VALUE != undefined && parseInt(sourceNameValSchdA[0].ATTRIB_VALUE) > parseInt(subFormulaSchdA[d].SOURCE_VALUE) ) {
								isConditionResultSchdA = true;
							} else if(subFormulaSchdA[d].CONDITION == "!=" && sourceNameValSchdA[0].ATTRIB_VALUE != undefined && sourceNameValSchdA[0].ATTRIB_VALUE.trim() != subFormulaSchdA[d].SOURCE_VALUE) {
								isConditionResultSchdA = true;
							}
						}
						else{
							if(sourceNameValSchdA[0].ATTRIB_VALUE != undefined && sourceNameValSchdA[0].ATTRIB_VALUE == subFormulaSchdA[d].SOURCE_VALUE){
								isConditionResultSchdA = true;
							}
						} 

						if(isConditionResultSchdA == true){
							var calcAttribValSchdA = $filter("filter")(vm.formListSchdA, {LINE_NO : line_noSchdA, LINE_ATTRIB_KEY : attrib_keySchdA});
							if (subFormulaSchdA[d].CALC_FORMULA_AMT != undefined && subFormulaSchdA[d].CALC_FORMULA_AMT != null) {
								calcAttribValSchdA[0].ATTRIB_VALUE = $filter("filter")(vm.formListSchdA, {ATTRIB_SHORT_CODE : subFormulaSchdA[d].CALC_FORMULA_AMT})[0].ATTRIB_CALC_FORMULA;
							}else{
								calcAttribValSchdA[0].ATTRIB_VALUE = $filter("filter")(vm.formListSchdA, {ATTRIB_SHORT_CODE : subFormulaSchdA[d].CALC_AMT})[0].ATTRIB_VALUE;
							}
							calcAttribValSchdA[0].IS_CHANGED_FLAG = 'Y';
							if(subFormulaSchdA[d].ALERT != undefined && subFormulaSchdA[d].ALERT != null && subFormulaSchdA[d].ALERT == 'Y') {
								var targetNameValSchdA = $filter("filter")(vm.formListSchdA, {ATTRIB_SHORT_CODE : subFormulaSchdA[d].ALERT_VALUE});
								AlertService.add("info", targetNameValSchdA[0].ATTRIB_DESCRIPTION, 4000);
							}
						}/* -------- END --------- */
					}
					return;
				}
				else{
					var matchesSchdA = formulaSchdA.match(/\b[^\d\s]+\b/g);
					if(matchesSchdA ==  null){
						var lineFormulaArraySchdA = formulaSchdA.split(/[+-/\\*\\]/);
					}else{
						var formulaLengthSchdA = formulaSchdA.length;
						var subFormulaSchdA = "";
						subFormulaSchdA = formulaSchdA.substring(formulaSchdA.indexOf("("),formulaLengthSchdA-1);
						var lineFormulaArraySchdA = subFormulaSchdA.split(/[+-,/\\*\\]/);
					}
				}

				for(var i=0; i<lineFormulaArraySchdA.length; i++){
					if(lineFormulaArraySchdA[i].indexOf("(") > -1 && lineFormulaArraySchdA[i].indexOf(")") > -1){
						lineFormulaArraySchdA[i] = lineFormulaArraySchdA[i].replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");

						var currentTabOldAtrribValSchdA = $filter("filter")(vm.originalTabsListSchdA, {FORM_NAME:vm.activeTab});
						var oldAttribValSchdA = $filter("filter")(currentTabOldAtrribValSchdA[0], {ATTRIB_SHORT_CODE:lineFormulaArraySchdA[i].trim()});
						
						var currentTabNewAtrribValSchdA = _.filter(vm.formListSchdA, function(o) { 
							return o.ATTRIB_SHORT_CODE == lineFormulaArraySchdA[i].trim(); 
						})
						lineAmtSchdA = parseFloat(currentTabNewAtrribValSchdA[0].ATTRIB_VALUE);

						if(lineAmtSchdA != parseFloat(oldAttribValSchdA[0].ATTRIB_VALUE)){
							currentTabNewAtrribValSchdA[0].IS_CHANGED_FLAG = 'Y';
						}
						if(parseFloat(lineAmtSchdA)>= 0){
							formulaSchdA = formulaSchdA.replace("("+lineFormulaArraySchdA[i].trim()+")",lineAmtSchdA);
						}else{
							formulaSchdA = formulaSchdA.replace("("+lineFormulaArraySchdA[i].trim()+")","("+lineAmtSchdA+")");
						}
					}
				}

				if(null!= eval(formulaSchdA) && typeof eval(formulaSchdA) !== 'undefined' && parseInt(eval(formulaSchdA)) === parseInt(eval(formulaSchdA))){//verifying for NaN by comparing the amount with itself

					var changedFormulaAttribValSchdA = $filter("filter")(vm.formListSchdA, {LINE_NO : line_noSchdA, LINE_ATTRIB_KEY : attrib_keySchdA});

					var calculatedValSchdA = eval(formulaSchdA);
					if(parseFloat(changedFormulaAttribValSchdA[0].ATTRIB_VALUE) != calculatedValSchdA.toFixed()){

						if (formulaSchdA.substring(0, 4) == 'Math') {
							changedFormulaAttribValSchdA[0].ATTRIB_VALUE = calculatedValSchdA;
						}else{
							changedFormulaAttribValSchdA[0].ATTRIB_VALUE = calculatedValSchdA.toFixed();
						}
						console.log("Changed Flag-----" , changedFormulaAttribValSchdA[0].LINE_ATTRIB_KEY);
						changedFormulaAttribValSchdA[0].IS_CHANGED_FLAG = 'Y';
					}else{

						if (formulaSchdA.substring(0, 4) == 'Math') {
							changedFormulaAttribValSchdA[0].ATTRIB_VALUE = calculatedValSchdA;
						}else{
							changedFormulaAttribValSchdA[0].ATTRIB_VALUE = calculatedValSchdA.toFixed();
						}
					}
				}
			};
			vm.EditRow = function(item) {
				vm.isEdit = true;
				vm.fetchSchAData();
				vm.fetchSchAJson(item);
			}
			vm.deleteRow = function(item){
				vm.Stage_Key = item.STAGE_KEY;
				var params = { "action_code": 'h8yl80',"sso_id":vm.logged_in_user, "stage_key":vm.Stage_Key };
				var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_id=33412";
				JsonObjectFactory.saveJSON(url,params).then(function(data){
					if(data.callSuccess == "1"){
						AlertService.add("success","Vehicle Deleted Successfully.",4000); 
						vm.loadingDtlsSchdA = true;   
						vm.getAllSchdADetails();   
						// vm.outerListSchdA = [];
						// vm.formListSchdA = [];          
					}else{
						AlertService.add("error", data.errorMessage);
					}
				});
			}
			
			vm.vinInvalid = function(item) {
				if(item.LINE_NO == '2') {
					const vin = item.ATTRIB_VALUE;
				
					// If the value is empty, consider it valid to allow required validation to kick in
					if (!vin) {
						AlertService.add("error", 'VIN is required');
					}
		  
					// Check length
					if (vin.length !== 17) {
						AlertService.add("error", 'VIN should be 17 characters');
						item.ATTRIB_VALUE = '';
					}
		  
					// Check for forbidden characters (I, O, Q)
					if (/[^A-HJ-NPR-Z0-9a-hj-npr-z]/.test(vin)) {
						AlertService.add("error", 'Invalid VIN');
						item.ATTRIB_VALUE = '';
					}
		  
					// Implement additional checks here, such as checksum validity if needed
		  
					return true; // VIN is valid
				}			
			  };
			// Form 8936 Schedule A Implementation - END
		}

		return controllers;
	});