define(
    [ 'angular'
    ],
    function() {
        'use strict';

        var controllers = angular
                .module('app.FTC1065P12S0Controller', [])
                .controller('FTC1065P12S0Controller',
                        [ '$rootScope', '$scope', '$state', '$log',	'FTC1065K2DataEntryFactory','JsonObjectFactory', 'USER_SETTINGS', 'AlertService','$filter', 'GlobalService','workspaceFactory',
                                '$timeout', '$uibModal','GENERAL_CONFIG', '$http', FTC1065P12S0Controller ])

        function FTC1065P12S0Controller($rootScope, $scope, $state, $log, FTC1065K2DataEntryFactory,
                JsonObjectFactory, USER_SETTINGS, AlertService, $filter, GlobalService, workspaceFactory, $timeout, $uibModal, GENERAL_CONFIG, $http) {

                    var vm = this;	
                    $scope.filter_template = 'app/components/dataFilters/grid-filters-tpl.html';
                    var activeScreen = workspaceFactory.activeScreen;
                    $scope.crudLoadingcapitalanalysis = false;
                        $scope.crudcharitablecontribution = false;
                        workspaceFactory.checkFilters(workspaceFactory.activeScreen);
                    //Show the loading Spinner
                    vm.loadingData = true;		
                    vm.showLoader = true;
                    vm.totalDropDownActions = 0;
                    vm.loadedActionCounter = 0;
                   
                    //Selected Row on the Grid passed to Modal/Popup to display in top table
                    vm.selectedGridRow = [];
        
                    vm.selectedLineKey = 0;
                    vm.lineData = [];
                    vm.newLineData = [];
                    vm.colHeaderData = [];
                    vm.showErrCol = false;
                    vm.isUIErrorSec = '';
        
                    //A flag to enable and disable save button
                    vm.isSaveClicked = false;
                    //Flag for Is_Admin check
                    vm.isAdmin = false; 
                    vm.user_data = USER_SETTINGS;
        
                    vm.currentCodeGroup = '';
                    vm.form_schd = ''
                    
                    vm.currentEntityName = '';
                    vm.currentPartnerName = '';
        
                    vm.currentFilterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};	
                    //Get the Grid filter Scenario
                    vm.scenario = vm.currentFilterParams.scenario;  	
                    //Get the jcd_key from left nav
                    vm.jcd_key  = GlobalService.globalParams.jcd_key;
                    
                    var activeScreen = workspaceFactory.activeScreen;
                     if (activeScreen && activeScreen.data) {
                         vm.loadingData = false;
                    //     //$scope.trees.currentAlertMessage = activeScreen.data.currentAlertMessage;
                     } else {
                         vm.loadingData = true;
                     }
                    $scope.$on('dataFilters:refreshGrid', function(event, data) {
                        vm.mainScreenKey = workspaceFactory.activeScreen.screen_key;
                        vm.currentFilterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
                                    .getFilterParams()
                                    : {};	
                        vm.setCurrentCodeGroup();
                        vm.getK2ColHeaderDetails();
                    });
        
                    workspaceFactory.checkFilters(workspaceFactory.activeScreen);
                    
                    //To get the Lock_Y_N flag from backend
                    var lockparams = {
                            "action_code": 'n10cpv', "p_scenario" : vm.scenario, "p_jcd_key" :vm.jcd_key
                        };				
                    JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', lockparams).then(function(data) {
                        vm.is_locked = data.jsonObject;				
                        //To Block user from Add/Edit based the Default Tax year and Filter Tax Year along with the Lock_Y_N flag
                        $scope.$watch(function() { return vm.is_locked }, function() {
                            if(vm.is_locked != undefined && vm.is_locked !=null && vm.is_locked[0].LOCK_STATUS == 'Y'){
                                    //vm.cancel();										
                                    vm.isAdmin = false; 
                                    AlertService.add("error","Tax Return has already been filed for tax year " + vm.currentFilterParams.tax_year + "." ,'4000');
                            }							
                        });
                    });
        
                    vm.setCurrentCodeGroup = function() {	
                        vm.currentCodeGroup = vm.currentFilterParams.p_form + '_' + vm.currentFilterParams.p_schedule + '_P12_S0';
                        vm.form_schd = vm.currentFilterParams.p_form + '_' + vm.currentFilterParams.p_schedule;
                    }
        
                    function getAfterUnderscore(str) {
                        return str.substring(str.indexOf('_'));
                    }
        
                    function setLableDetails() {
                        vm.currentEntityName = workspaceFactory.activeScreen.filters.getFilters().find(t=>t.param_name ==='p_filer_key').selected.LABEL;
                        if (vm.currentCodeGroup.includes('_K3')) {
                            var tempPartnerFilter = workspaceFactory.activeScreen.filters.getFilters().find(t=>t.param_name ==='p_filer_partner_key');
                            if (tempPartnerFilter != null) {
                                vm.currentPartnerName =   tempPartnerFilter.selected.LABEL;
                            } 
                        } else {
                            vm.currentPartnerName = '';
                        }
                    }
        
                    vm.getK2ColHeaderDetails = function() {	
                        vm.showLoader = true;
                        var isDropdownAvailalbe = false;
                        vm.setCurrentCodeGroup();
                        setLableDetails();
                         var params = {"action_code" : 'ea2llc', "tax_year": vm.currentFilterParams.tax_year,
                                         "code_group": vm.currentCodeGroup}; 	
                        //TODO:K UNCOMMENT ABOVE, HARDCODED FOR TESTING
                        // vm.currentFilterParams.tax_year = 2020;
                        // var params = {"action_code" : 'ea2llc', "tax_year": 2020,
                        //                  "code_group": 'F1065_K2_P3_S4'}; 				
                        JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
                                vm.colHeaderData = data.jsonObject;
                                if (data.jsonObject == '') {
                                    AlertService.add("warn", "Data Error: No results were returned", 4000);
                                    vm.loadingData = true;	
                                } else {
                                    for(var i in vm.colHeaderData) {
                                        setValidationInfo(vm.colHeaderData[i], vm.colHeaderData[i]);
                                        if (vm.colHeaderData[i].DROP_DOWN_ACTION && vm.colHeaderData[i].DROP_DOWN_ACTION != null && vm.colHeaderData[i].DROP_DOWN_ACTION != '') {
                                            isDropdownAvailalbe = true;
                                            setDropdownData(vm.colHeaderData[i]);
                                            vm.totalDropDownActions = vm.totalDropDownActions + 1;
                                        } 		
                                    }
                                    if (isDropdownAvailalbe == false) {
                                        vm.getK2LineDetails();
                                    }
                                }
                        });					
                    }
        
                    function setValidationInfo (colHeader, destObj) {
                        if (colHeader.VALIDATION_RULE != null) {
                            const validationRulesArr = colHeader.VALIDATION_RULE.split(';');
                            for (var r in validationRulesArr) {
                                if (validationRulesArr[r] == 'REQ') {
                                    destObj.IS_MANDATORY = 'Y';
                                } else if (validationRulesArr[r].startsWith('PREF_REQ')) {
                                    destObj.IS_PREF_MANDATORY = 'Y';
                                    if (validationRulesArr[r].includes(':') && validationRulesArr[r].includes('/')) {
                                        destObj.PREF_MAN_COLS = validationRulesArr[r].split(':')[1].split('/'); // SPLIT PREFERED MANDATORY COLUMNS
                                        destObj.VALIDATION_MSG = destObj.PREF_MAN_COLS[destObj.PREF_MAN_COLS.length-1];
                                        destObj.PREF_MAN_COLS.splice(destObj.PREF_MAN_COLS.length-1, 1);
                                    }
                                } else if(validationRulesArr[r].includes('MAX_LEN:')) {
                                    destObj.MAX_LENGTH = Number.parseInt(validationRulesArr[r].split(':')[1]);
                                }
                            }
                        }
                    }
        
                    function setDropdownData(colData) {
                        const actionDetails = colData.DROP_DOWN_ACTION.split(':');
                        var params = {"action_code" : actionDetails[0], "p_code_group" : actionDetails[1], "p_tax_year": vm.currentFilterParams.tax_year}; 					
                        JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
                                let dropDownData = data.jsonObject;
                                colData.actionData = dropDownData;
                                vm.loadedActionCounter = vm.loadedActionCounter + 1;
                                if (vm.loadedActionCounter == vm.totalDropDownActions) {
                                    vm.getK2LineDetails();
                                }
                        });		
                    }
        
                    vm.getK2LineDetails = function() {	
                        vm.showLoader = true;
                        vm.currentFilterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters
                                    .getFilterParams()
                                    : {};	
                         if (vm.currentFilterParams.p_filer_key == null || vm.currentFilterParams.p_filer_key < 0) {
                             AlertService.add("error", "Please select all the filters", 4000);
                             return;
                         }
        
                         if (vm.currentCodeGroup.includes('_K3') && (vm.currentFilterParams.p_filer_partner_key == null || vm.currentFilterParams.p_filer_partner_key < 0)) {
                            AlertService.add("error", "Please select all the filters", 4000);
                            return;
                        }
        
                         var params = {"action_code" : 'w5bepp', "p_tax_year": vm.currentFilterParams.tax_year,
                                        "p_filer_key" : vm.currentFilterParams.p_filer_key,
                                         "code_group": vm.currentCodeGroup}; 	
                        //TODO:K UNCOMMENT ABOVE, HARDCODED FOR TESTING				
                        // var params = {"action_code" : 'w5bepp', "p_tax_year": 2020,
                        // "p_filer_key" : 1812822,
                        //  "code_group": 'F1065_K2_P3_S4'}; 
        
                        JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
                                vm.lineData = data.jsonObject;
                                vm.groupedFormLines = processData(vm.lineData);

                                _.forEach(vm.groupedFormLines, function(obj, index){
                                    if(obj.LINE_NO == '5' && obj.SNO == '0'){
                                        _.forEach(obj.items, function(item, index){
                                            item.IS_ENABLED = 'N';
                                        });
                                    }else if(obj.LINE_NO == '6'){
                                        obj.items.splice(1, 0, {
                                            "SCHD_KEY": '',
                                            "LINE_KEY": '',
                                            "COL_KEY": '',
                                            "COL_DESC": "",
                                            "COL_DESC_INFO": "a",
                                            "COL_ORDER": 1,
                                            "IS_ENABLED": "N",
                                            "SNO": 0,
                                            "IS_COL_MULTI": "N",
                                            "ACTION_Y_N": "N",
                                            "IS_MANDATORY": "N",
                                            "OPERATION_TYPE": "N"
                                        });
                                    }
                                    obj.items[obj.items.length-1].IS_ENABLED = 'N';
                                });

                                
                                // //calculate totals on form load
                                //  for (var i in vm.groupedFormLines) {
                                //     // if (vm.groupedFormLines[i].LINE_SEC == 'UI_S2') {
                                //     // vm.calcTotalsCol(vm.groupedFormLines[i].items);
                                //     // }
                                //     // if(vm.groupedFormLines[i].LINE_NO == '5'){

                                //     // }
                                //     vm.groupedFormLines[i].items[vm.groupedFormLines[i].items.length-1].IS_ENABLED = 'N';
                                // }
                                vm.groupedFormLines[vm.groupedFormLines.length-1].items[0].IS_ENABLED = 'N';
                                vm.showLoader = false;
                                vm.loadingData = false;	
                                vm.showErrCol = false;
                        });					
                    }
        
                    function processData(formLines) {
        
                        if(formLines!=undefined || formLines!=null){					
                            //assign the is_admin flag
                            // if(formLines[0] && formLines[0].IS_ADMIN == 'Y'){
                                vm.isAdmin = true;
                             //}
                        }
                        var init = 0;
                        let colObj;	
                        return _.reduce(
                            formLines, 
                            function(memo, property) {
                                // Find the correct bucket for the property
                                var bucket = _.find(memo, {
                                    SCHD_KEY: property.SCHD_KEY,	
                                    LINE_KEY: property.LINE_KEY, 
                                    LINE_NO: property.LINE_NO,
                                    LINE_DESC: property.LINE_DESC,
                                    LINE_ORDER: property.LINE_ORDER,
                                    IS_DISPLAY: 'Y',			        							        	
                                    SNO: property.SNO,
                                    IS_LINE_MULTI: property.IS_LINE_MULTI,
                                    LINE_SEC: property.LINE_SEC
                                });							        
                                
                                // Create a new bucket if needed.
                                if (!bucket) {
                                    bucket = {
                                            SCHD_KEY: property.SCHD_KEY,	
                                            LINE_KEY: property.LINE_KEY,
                                            LINE_NO: property.LINE_NO,
                                            LINE_DESC: property.LINE_DESC,
                                            LINE_ORDER: property.LINE_ORDER,
                                            IS_DISPLAY: 'Y',
                                            SNO: property.SNO,
                                            IS_LINE_MULTI: property.IS_LINE_MULTI,	
                                            LINE_SEC: property.LINE_SEC,						        	
                                            items: []};
                                    //if(bucket.LINE_NO != 'N/A'){
                                        memo.push(bucket);
                                    //}
                                }
                                var attribs = {
                                        SCHD_KEY: property.SCHD_KEY,	
                                        LINE_KEY: property.LINE_KEY, 
                                        COL_KEY: property.COL_KEY,
                                        COL_DESC: property.COL_DESC,
                                        COL_DESC_INFO: property.COL_DESC_INFO,
                                        COL_ORDER: property.COL_ORDER,
                                        IS_ENABLED: 'Y',	
                                        SNO: property.SNO,
                                        ADJ_KEY: property.ADJ_KEY,	 
                                        IS_COL_MULTI: property.IS_COL_MULTI,
                                        UI_COMP: property.UI_COMP,
                                        LINE_COMP: property.LINE_COMP,	/**LINE_COMP CAN BE USED ONLY IF LINE HAS ONLY ONE COMPONENT/CELL*/
                                        ACTION_Y_N: property.ACTION_Y_N,	
                                        IS_MANDATORY: 'N',        		
                                        CELL_VALUE: property.CELL_VALUE
                                };
        
                                if (attribs.ACTION_Y_N == 'Y') {
                                    colObj = vm.colHeaderData.find(col => col.COL_KEY === attribs.COL_KEY);
                                    attribs.actionData = colObj.actionData;
                                }
        
                                setValidationInfo(property, attribs);
        
                                // if (property.VALIDATION_RULE != null) {
                                //     const validationRulesArr = property.VALIDATION_RULE.split(';');
                                //     for (var r in validationRulesArr) {
                                //         if (validationRulesArr[r] == 'REQ') {
                                //             attribs.IS_MANDATORY = 'Y';
                                //         } else if(validationRulesArr[r].includes('MAX_LEN:')) {
                                //             attribs.MAX_LENGTH = validationRulesArr[r].split(':')[1];
                                //         }
                                //     }
                                // }
                                
                                if(attribs.ADJ_KEY != undefined && attribs.ADJ_KEY != null) {
                                    attribs.OPERATION_TYPE = 'U';
                                } else {
                                    attribs.OPERATION_TYPE = null;
                                }	
                                //PLACING IT HERE IS IMPORTANT
                                 if (property.IS_LINE_MULTI == 'Y' && isEmptyVal(attribs.CELL_VALUE)) {
                                    //property.SNO == 0 &&  
                                    setUICompDefaults(attribs);
                                }
                                
                                if (attribs.UI_COMP == 'DATE' && isUICompNotEmpty(attribs)) {
                                    attribs.CELL_VALUE = new Date(property.CELL_VALUE);
                                }
        
                                if (isSkipFromSaving(bucket, attribs)) {
                                    //attribs.IS_SUB_TOTAL = true;
                                    attribs.OPERATION_TYPE = 'N'; // no crud
                                } 
                                // Add the property to the correct bucket
                                bucket.items.push(attribs);
                                return memo;
                            }, 
                            [] // The starting memo
                        );
                    }
        
                    function isSkipFromSaving(bucket, attribs) {
                        if (attribs.IS_ENABLED == 'N') {
                            return true;
                        } 
                        return false; 
                    }
        
                    function setUICompDefaults (attrib) {
        
                        if (attrib.UI_COMP == 'NUMERIC') {
                            attrib.CELL_VALUE = null;
                        }
                        else if (attrib.UI_COMP == 'TEXT' || attrib.UI_COMP == 'DATE' ||  attrib.UI_COMP == 'CHK_BX' || attrib.UI_COMP == 'DROP_DOWN') {
                            attrib.CELL_VALUE = '';
                        }
                        // else if (attrib.UI_COMP == 'DROP_DOWN') {
                        //     attrib.CELL_VALUE = '-1';
                        // } 
                    }
        
                    function isUICompEmpty(attrib) {
                        if (attrib.CELL_VALUE == undefined || attrib.CELL_VALUE == null) {
                            return true;
                        }
                        else if (attrib.UI_COMP == 'NUMERIC' && attrib.CELL_VALUE == null) {
                            return true;
                        }
                        else if ((attrib.UI_COMP == 'TEXT' || attrib.UI_COMP == 'DATE' ||  attrib.UI_COMP == 'CHK_BX' || attrib.UI_COMP == 'DROP_DOWN') 
                                    && attrib.CELL_VALUE == '') {
                            return true;
                        }
                        // else if ( && attrib.CELL_VALUE == '-1') {
                        //     return true;
                        // } 
                        console.log('attrib: ' + attrib.UI_COMP + ', ' + attrib);
                        return false;
                        
                    }
        
                    function isUICompNotEmpty (attrib) {
                        return !isUICompEmpty(attrib);
                    }
        
                    vm.reset = function() {
                        resetSection(vm.groupedFormLines);
                        vm.getK2LineDetails();
                    }
        
                    function resetSection(groupedFormLines) {
                        for(var d in groupedFormLines){						
                            for(var a in groupedFormLines[d].items){						
                                groupedFormLines[d].items[a].CELL_VALUE = null;
                            }
                        }
                    }
        
                    //Adding a new row as a copy of Selected Row
                    vm.addRow = function(selectedRowData) {
                        
                        var index = vm.groupedFormLines.indexOf(selectedRowData);
                        var tempObj = angular.copy(selectedRowData);	
                        var multiRow = $filter('filter')(vm.groupedFormLines, {'LINE_KEY':selectedRowData.LINE_KEY, 'IS_DISPLAY':'Y'});
                        var sno = multiRow[multiRow.length - 1].SNO + 1;
                        //var tempArr = angular.copy(selectedRowData);	
                        tempObj.LINE_DESC = 'Income type:';
                        tempObj.LINE_NO = '';
                        tempObj.ERR_MSG = null;
                       //tempObj.IS_LINE_MULTI = 'N';
                        tempObj.SNO = sno ;	

                        //Enable the dynamic rows to be added
                        _.forEach(tempObj.items, function(item, index){
                            if(index != 2){
                                item.IS_ENABLED = 'Y';
                            }
                        });

                        let tempArr = tempObj.items;//angular.copy(tempObj.items);
                        for (var i in tempArr) {
                            tempArr[i].OPERATION_TYPE = 'I';
                            tempArr[i].ADJ_KEY = null;
                            tempArr[i].CELL_VALUE = '';
                            setUICompDefaults(tempArr[i]);
                            tempArr[i].SNO = tempObj.SNO;
                        }
                        vm.groupedFormLines.splice(vm.groupedFormLines.length - 1, 0, tempObj);
                    };
        
                vm.deleteRow = function(selectedRowData) {
                    vm.groupedFormLines.splice(vm.groupedFormLines.indexOf(selectedRowData), 1);
                    vm.addColoumns();
                }
        
                vm.calcTotalsCol = function (row, columnValues) {
                    var multiply = 0;
                    multiply = columnValues[0].CELL_VALUE * columnValues[1].CELL_VALUE;
                    columnValues[columnValues.length - 1].CELL_VALUE =  multiply;

                    vm.addColoumns();
                }

                vm.addColoumns = function(){
                    var col_a = 0;
                    var col_b = 0;
                    angular.forEach(vm.groupedFormLines, function(formLines, index){
                        if(formLines.LINE_NO != 6){
                            col_a = col_a + (formLines.items[0].CELL_VALUE ? parseInt(formLines.items[0].CELL_VALUE) : 0);
                            col_b = col_b + (formLines.items[2].CELL_VALUE ? parseInt(formLines.items[2].CELL_VALUE) : 0);
                        }
                    })
                
                    vm.groupedFormLines[vm.groupedFormLines.length - 1].items[0].CELL_VALUE =  col_a;
                    vm.groupedFormLines[vm.groupedFormLines.length - 1].items[2].CELL_VALUE =  col_b;
                }
        
                   //Save the Amounts
                vm.saveData = function(){			
                    //Disable the Save button till the processing completes
                    vm.isSaveClicked = true;
                    
                    var dataToSave = [];								
                    
                    var returnClobSettingsObj = {};
                    returnClobSettingsObj['sso_id'] = vm.logged_in_user;	
                    returnClobSettingsObj['jcd_key'] = vm.jcd_key;	
                    returnClobSettingsObj['jcd_ta_key'] = vm.jcd_ta_key;
                    returnClobSettingsObj['p_form_schd'] = vm.form_schd;
                    returnClobSettingsObj['p_code_group'] = vm.currentCodeGroup;
                    console.log("returnClobSettingsObj-----" + returnClobSettingsObj);
        
                    let groupedFormLinesObj = vm.groupedFormLines;
                    validateForm(groupedFormLinesObj);
                    
                    if (vm.isValidationError == true) {
                        AlertService.add("error", "Please fix all error(s)", 4000);
                        vm.isSaveClicked = false;
                    } else {
                        showConfirmDialog().then((proceedWithSave) => {
                            if (proceedWithSave) {
                                pushToDataToSave(groupedFormLinesObj, dataToSave);
                                var message = "Data have been saved/updated";	
                                //send to save the Data					
                                sendDetails(returnClobSettingsObj, dataToSave, message);
                            } else {
                                vm.isSaveClicked = false;
                            }
                        });
                    }
                                
                }
        
                function isAnyOtherValueEntered(item, itemList) {
                    return itemList.some(x => (x != item) && x.IS_ENABLED == 'Y' && isUICompNotEmpty(x));
                }
        
                // function isNotEmpty(x) {
                //     return x.CELL_VALUE != undefined && x.CELL_VALUE != null && x.CELL_VALUE != 0;
                // }
        
                function isEmptyVal(val) {
                    return val == null || val == '' || val == undefined;
                }
        
                function validatePrefMandatoryCols(line, currentCell) {
                    if (currentCell.IS_PREF_MANDATORY == 'Y' && currentCell.PREF_MAN_COLS && currentCell.PREF_MAN_COLS.length > 0) {
                        var tempArr = [];
                        tempArr[0] = '';
                        tempArr[1] = '';
            
                        var firstSetOfCols = line.items.filter(col => col.COL_DESC_INFO.startsWith(currentCell.PREF_MAN_COLS[0]));
                        var secondSetOfCols = line.items.filter(col => col.COL_DESC_INFO.startsWith(currentCell.PREF_MAN_COLS[1]));
                        tempArr[0] = tempArr[0] + firstSetOfCols.map(val => val.CELL_VALUE).join('');
                        tempArr[1] = tempArr[1] + secondSetOfCols.map(val => val.CELL_VALUE).join('');
        
                        if ((tempArr[0].trim().length <= 0 && tempArr[1].trim().length <= 0)) { // both empty
                            setErrorAttribs(line, null, currentCell.VALIDATION_MSG, false);//passing null for current cell, since setting error value will be handled in below code
                            
                            var allCols = line.items;
                            for (var i in allCols) {
                                if (allCols[i].IS_PREF_MANDATORY && allCols[i].IS_PREF_MANDATORY == 'Y') {
                                    if (allCols[i].COL_DESC_INFO.startsWith(currentCell.PREF_MAN_COLS[0])) {
                                        allCols[i].IS_ERROR = 'Y';
                                    }
                                    if (allCols[i].COL_DESC_INFO.startsWith(currentCell.PREF_MAN_COLS[1])) {
                                        allCols[i].IS_ERROR = 'Y';
                                    }
                                }
                            }
                        } else if ((tempArr[0].trim().length > 0 && tempArr[1].trim().length > 0)) { // both filled
                            setErrorAttribs(line, null, currentCell.VALIDATION_MSG, false);
                            
                            var allCols = line.items;
                            for (var i in allCols) {
                                if (allCols[i].IS_PREF_MANDATORY && allCols[i].IS_PREF_MANDATORY == 'Y') {
                                    if (allCols[i].COL_DESC_INFO.startsWith(currentCell.PREF_MAN_COLS[0]) && isUICompNotEmpty(allCols[i])) {
                                        allCols[i].IS_ERROR = 'Y';
                                    }
                                    if (allCols[i].COL_DESC_INFO.startsWith(currentCell.PREF_MAN_COLS[1]) && isUICompNotEmpty(allCols[i])) {
                                        allCols[i].IS_ERROR = 'Y';
                                    }
                                }
                            }
                        } else {// check one set of PREF_MANDATORY fileds are filled
                            var count = 0;
                            if (tempArr[0].trim().length > 0) {
                                var allCols = line.items;
                                for (var i in allCols) {
                                    if (allCols[i].IS_PREF_MANDATORY && allCols[i].IS_PREF_MANDATORY == 'Y') {
                                        if (allCols[i].COL_DESC_INFO.startsWith(currentCell.PREF_MAN_COLS[0]) && isUICompEmpty(allCols[i])) {
                                            allCols[i].IS_ERROR = 'Y';
                                            if (count == 0) {
                                                setErrorAttribs(line, null, currentCell.VALIDATION_MSG, false);
                                                count = 1;
                                            }
                                        }
                                    }
                                }
                            } else {
                                var allCols = line.items;
                                for (var i in allCols) {
                                    if (allCols[i].IS_PREF_MANDATORY && allCols[i].IS_PREF_MANDATORY == 'Y') {
                                        if (allCols[i].COL_DESC_INFO.startsWith(currentCell.PREF_MAN_COLS[1]) && isUICompEmpty(allCols[i])) {
                                            allCols[i].IS_ERROR = 'Y';
                                            if (count == 0) {
                                                setErrorAttribs(line, null, currentCell.VALIDATION_MSG, false);
                                                count = 1;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                }
        
                function validateForm(groupedFormLinesObj) {
                    vm.isValidationError = false;
                    var requiredMsgAlreadyAdded = false;
                    vm.isUIErrorSec = '';
                    //let colLength = groupedFormLinesObj[0].items.length;
                    for(var d in groupedFormLinesObj){	
                        groupedFormLinesObj[d].ERR_MSG = null;
                        requiredMsgAlreadyAdded = false;
                         // Mark IS_ERROR AS 'N' INITIALLY         
                        for (var z in groupedFormLinesObj[d].items) {
                            groupedFormLinesObj[d].items[z].IS_ERROR = 'N';
                        }
                        for(var a in groupedFormLinesObj[d].items){		
        
                            if (groupedFormLinesObj[d].IS_DISPLAY == 'Y') {
                                //Mandatory validation: if any of the cell value is entered in the line, then fields marked with IS_MANDATORY = 'Y' should be entered
                                if (groupedFormLinesObj[d].items[a].IS_ENABLED != 'N') {
                                    if (groupedFormLinesObj[d].items[a].IS_MANDATORY == 'Y' 
                                        && isUICompEmpty(groupedFormLinesObj[d].items[a])
                                        && isAnyOtherValueEntered(groupedFormLinesObj[d].items[a], groupedFormLinesObj[d].items)) {
                                            
                                            setErrorAttribs(groupedFormLinesObj[d], groupedFormLinesObj[d].items[a], 'Mandatory filed(s) are missing', requiredMsgAlreadyAdded);
                                            requiredMsgAlreadyAdded = true;
                                    }
        
                                    //validatePrefMandatoryCols(groupedFormLinesObj[d], groupedFormLinesObj[d].items[a]);
        
                                    
                                    //DATA_CUST: Customized for each data entry screen
                                    if (groupedFormLinesObj[d].items[a].UI_COMP == 'DATE' && groupedFormLinesObj[d].items[a].COL_DESC.startsWith('(i).')) {
                                        if (groupedFormLinesObj[d].items[a].CELL_VALUE != undefined && groupedFormLinesObj[d].items[a].CELL_VALUE != 0 
                                            && groupedFormLinesObj[d].items[+a+1].CELL_VALUE != undefined && groupedFormLinesObj[d].items[+a+1].CELL_VALUE != 0
                                            && groupedFormLinesObj[d].items[a].CELL_VALUE > groupedFormLinesObj[d].items[+a+1].CELL_VALUE ) {
        
                                            setErrorAttribs(groupedFormLinesObj[d], groupedFormLinesObj[d].items[a], '\'(i). Beginning of allocation period\' should be earlier than \'(ii). End of allocation period\'', false);
                                        }
                                    }
                                }
        
                            }
                            if (groupedFormLinesObj[d].items.length -1 == a) {//last column
                                var currErrElement = document.getElementById('errCol_' +  groupedFormLinesObj[d].LINE_KEY + '_' + groupedFormLinesObj[d].SNO);	
                                if (currErrElement != null) {
                                    currErrElement.innerHTML = groupedFormLinesObj[d].ERR_MSG;
                                }
                            }
                            
                        }
                            
                    }
        
                    if (vm.isUIErrorSec.length > 0) {
                        if (vm.isUIErrorSec.includes('UI_S1')) {
                            vm.isUIErrorSec = vm.isUIErrorSec + 'UI_S1';
                        }
                        if (vm.isUIErrorSec.includes('UI_S2')) {
                            vm.isUIErrorSec = vm.isUIErrorSec + 'UI_S2';
                        }
                    }
                    
                }
        
                function setErrorAttribs(line, col, errMsg, requiredMsgAlreadyAdded) {
                    if (line.ERR_MSG == null) {
                        line.ERR_MSG = '<ol></ol>';
                        vm.isUIErrorSec = vm.isUIErrorSec + ',' + line.LINE_SEC;
                    }
                    if (col != null) {
                        col.IS_ERROR = 'Y';
                    }
                    vm.isValidationError = true;
                    if (requiredMsgAlreadyAdded == false) {
                        line.ERR_MSG = line.ERR_MSG.replace('</ol>', '<li>' + errMsg + '</li></ol>') ;
                    }
                    vm.showErrCol = true;
                }
        
                function pushToDataToSave(groupedFormLinesObj, dataToSave) {
                    
                    var cellValue;
                    var tempDate;
                    for(var d in groupedFormLinesObj){	
                                            
                        for(var a in groupedFormLinesObj[d].items){		
                                        
                            var returnObj = {};	
                            cellValue = groupedFormLinesObj[d].items[a].CELL_VALUE;
                            // if (groupedFormLinesObj[d].items[a].IS_ENABLED != 'N') {
                            //     if (groupedFormLinesObj[d].items[a].UI_COMP == 'DATE') {
                            //         if (isUICompEmpty(groupedFormLinesObj[d].items[a]) || (isUICompNotEmpty(groupedFormLinesObj[d].items[a]) && Number.isNaN(cellValue.getTime()))) {
                            //             returnObj["CELL_VALUE"] = '';
                            //         } else {
                            //             tempDate = new Date(cellValue);
                            //             returnObj["CELL_VALUE"] = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate();
                            //         }
                            //     } else {
                            //         returnObj["CELL_VALUE"] = cellValue;					
                            //     }	
                            // }	
                            returnObj["CELL_VALUE"] = cellValue;	
        
                            returnObj["SCHD_KEY"] = groupedFormLinesObj[d].items[a].SCHD_KEY;
                            returnObj["LINE_NO"] = groupedFormLinesObj[d].LINE_NO;	
                            returnObj["LINE_KEY"] = groupedFormLinesObj[d].items[a].LINE_KEY;
                            returnObj["COL_KEY"] = groupedFormLinesObj[d].items[a].COL_KEY;	
                            returnObj["ADJ_KEY"] = groupedFormLinesObj[d].items[a].ADJ_KEY;	
                            returnObj["SNO"] = groupedFormLinesObj[d].items[a].SNO;	
        
                            returnObj["OPERATION_TYPE"] = groupedFormLinesObj[d].items[a].OPERATION_TYPE;
        
                            
                            if(groupedFormLinesObj[d].items[a].OPERATION_TYPE == null){
                                returnObj["OPERATION_TYPE"] = 'I';
                                groupedFormLinesObj[d].items[a].OPERATION_TYPE = 'U';
                            }
                            else if (groupedFormLinesObj[d].items[a].OPERATION_TYPE == 'I') {
                                groupedFormLinesObj[d].items[a].OPERATION_TYPE = 'U';
                            } 
                            else if(groupedFormLinesObj[d].items[a].OPERATION_TYPE == 'N') {
                                returnObj.OPERATION_TYPE = null;
                            } 	
                            
                            if(returnObj.OPERATION_TYPE!=null){
                                dataToSave.push(returnObj);
                            }
                        }
                    }	
                }
                
                //Send Details to the Service Layer for persisting the data
                function sendDetails(returnClobSettingsObj, returnClobDataObj, message) {
                    var params = {"action_code": 'n10cpv', "tax_year": vm.currentFilterParams.tax_year
                    , "jcd_key": vm.jcd_key};				
                    JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject', params).then(function(data) {
                        vm.is_locked = data.jsonObject;		
                        console.log(" vm.is_locked :", vm.is_locked);
                        
                        //TODO:K FOR 2021, NOT RETURINING DATA PROPERLY
                        //if(vm.is_locked[0].LOCK_STATUS == 'N'){ 
        
                            FTC1065K2DataEntryFactory.saveData(returnClobSettingsObj, returnClobDataObj).then(function(result) {
                                if (result.data.callSuccess != 1) {
                                    AlertService.add("error", result.data.errorMessage, 4000);
                                    vm.isSaveClicked = false;
                                } else {
                                    AlertService.add("success", message, 4000);
                                    vm.isSaveClicked = false;
                                };
                                vm.showErrCol = false;
                            });
                        // } else{
                        //     AlertService.add("error", "Cannot proceed with Save. Tax Return has already been filed for selected Tax Year.", 4000);
                        // }
                    });
                }
                    
        
                function showConfirmDialog() {
                    return $uibModal.open({
                        templateUrl: 'app/templates/modals/confirm-action.html',
                        windowClass: 'custom',
                        controller: ['$scope', '$uibModalInstance',
                            function ($scope, $uibModalInstance) {
                                $scope.header = 'Save Data';
                                $scope.message = 'Are you sure to save data for: ' + vm.currentEntityName;
                                if (vm.currentPartnerName.length > 0) {
                                    $scope.message = $scope.message + ' / ' + vm.currentPartnerName;
                                }	
                                new Audio('assets/sound/voice_alert.mp3').play().then();
                                $scope.cancel = function () {
                                    $uibModalInstance.close(false);
                                }
                                $scope.confirm = function () {
                                    $uibModalInstance.close(true);
                                }
                            }
                        ]
                    }).result;
                }
      
 
        }
    }
);