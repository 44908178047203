define([
    'angular',
    './Section965TaxStmtController',
    './Section965TaxStmtService'

], function () {
    'use strict';
    return angular.module('app.section965TaxStmt', ['app.section965TaxStmtController','app.section965TaxStmtService'])

    .config(['$stateProvider','$urlRouterProvider', function ($stateProvider,$urlRouterProvider) {
        $stateProvider
            .state('irc_965_tax_return', {
                url: '/irc_965_tax_return',
                templateUrl: 'app/components/section965TaxStmt/section-965-tax-db-tpl.html',
                access: ""
            })

            .state('irc_965_tax_return_q1', {
                url: '/irc_965_tax_return_q1',
                templateUrl: 'app/components/section965TaxStmt/section-965-tax-db-tpl.html',
                access: ""
            })
    }])

});