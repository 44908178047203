const { data } = require("jquery");
const { replace } = require("lodash");

define(["angular"], function () {
    "use strict";
    angular
        .module("app.EfileElementsController", [])
        .controller("SubmissionsDashboardController",["USER_SETTINGS","GENERAL_CONFIG","$scope","EfileFactory","$state","$rootScope",SubmissionsDashboardController])
        .controller("LoadIRSSchemaController",["USER_SETTINGS","GENERAL_CONFIG",LoadIRSSchemaController])
        .controller('SubmissionListCtrl', ['TAX_YEARS', '$rootScope', '$scope', '$state', '$log', 'GridFactory', 'AlertService', '$timeout', '$uibModal', 'workspaceFactory', 'GlobalService','EfileFactory','USER_SETTINGS',SubmissionListCtrl])
        .controller('SubmissionController', ['TAX_YEARS', 'GENERAL_CONFIG', '$rootScope', '$scope', '$state', '$log', 'GridFactory', 'AlertService', '$timeout', '$uibModal', 'workspaceFactory', 'GlobalService','EfileFactory','JsonObjectFactory','submissionLoaded','SERVER_CONFIG','USER_SETTINGS',SubmissionController])
        .controller("SubmitController",["USER_SETTINGS","GENERAL_CONFIG","SERVER_CONFIG","EfileFactory","$stateParams","AlertService","$state","$sce","JsonObjectFactory","$uibModal","$scope","GlobalService","$rootScope",SubmitController])
        .controller("DataController",["USER_SETTINGS","GENERAL_CONFIG","EfileFactory","$stateParams","AlertService","$state","$scope",DataController])
        .controller("DataErrorsController",["USER_SETTINGS","GENERAL_CONFIG","EfileFactory","$stateParams","AlertService","$state","$scope",'workspaceFactory','GridFactory','$timeout','$uibModal','$rootScope','JsonObjectFactory',DataErrorsController])
        .controller("EfileDashboardController",["$scope","workspaceFactory","EfileFactory","$rootScope","$uibModal","USER_SETTINGS","GENERAL_CONFIG","$timeout","$filter","AlertService",EfileDashboardController])
        .controller("EfileDashboardPopupController",["api_url","params","columns","title","JsonObjectFactory","GENERAL_CONFIG","$compile","USER_SETTINGS","$rootScope","$scope","AlertService","EfileFactory","SERVER_CONFIG","$uibModal",EfileDashboardPopupController])
        .controller("PDFUploadController",["EfileFactory","USER_SETTINGS","AlertService","JsonObjectFactory","GENERAL_CONFIG","$compile","$scope",PDFUploadController])
        .controller("AddEditController",["EfileFactory","USER_SETTINGS","AlertService","JsonObjectFactory","GENERAL_CONFIG","$scope","$timeout","SERVER_CONFIG","GlobalService","$uibModal",AddEditController])
        .controller("UploadFilesController",["EfileFactory","USER_SETTINGS","AlertService","JsonObjectFactory","GENERAL_CONFIG","$compile","$scope",UploadFilesController])
        .controller("XMLConsolUploadController",["EfileFactory","USER_SETTINGS","AlertService","JsonObjectFactory","GENERAL_CONFIG","$compile","$scope",XMLConsolUploadController])
        .controller('sbmsnMnfstAddEditController', ['$rootScope', '$scope', 'TAX_YEARS', "EfileFactory",'GlobalService', '$uibModalInstance', 'USER_SETTINGS', 'AlertService', 'rowData', 'gridData' ,'JsonObjectFactory','$timeout','workspaceFactory','GENERAL_CONFIG', sbmsnMnfstAddEditController])
        .controller('sbmsnAddEditController', ['$rootScope', '$scope', 'TAX_YEARS', "EfileFactory",'GlobalService', '$uibModalInstance', 'USER_SETTINGS', 'AlertService', 'rowData', 'gridData' ,'JsonObjectFactory','$timeout','workspaceFactory','GENERAL_CONFIG', sbmsnAddEditController])
        .controller('keyAddEditController', ['$rootScope', '$scope', 'TAX_YEARS', "EfileFactory",'GlobalService', '$uibModalInstance', 'USER_SETTINGS', 'AlertService', 'rowData', 'gridData' ,'JsonObjectFactory','$timeout','workspaceFactory','GENERAL_CONFIG', keyAddEditController])
        .controller('overridesController', ['$scope','$rootScope','$state','$log','GridFactory','AlertService','$timeout','$uibModal', 'GlobalService','EfileFactory', overridesController])
        .controller('overrideModalController', ['$uibModalInstance', 'rowData', 'EfileFactory','AlertService','$rootScope','JsonObjectFactory','USER_SETTINGS','GENERAL_CONFIG','GlobalService', overrideModalController])
        .controller("DeleteFormController",["EfileFactory","USER_SETTINGS","AlertService","JsonObjectFactory","GENERAL_CONFIG","$compile","$scope",DeleteFormController])
        .controller("LockUnlockController",["EfileFactory","GlobalService","USER_SETTINGS","AlertService","JsonObjectFactory","GENERAL_CONFIG",LockUnlockController])
        .controller("EnabledDisabledController",["EfileFactory","GlobalService","USER_SETTINGS","AlertService","JsonObjectFactory","GENERAL_CONFIG","$stateParams","$state","$scope","workspaceFactory","GridFactory","$timeout","$uibModal",EnabledDisabledController])
        .controller("XMLSyncController",["EfileFactory","SERVER_CONFIG","USER_SETTINGS","AlertService","JsonObjectFactory","GENERAL_CONFIG","GlobalService",XMLSyncController])
        .controller("UnlockValidationController",["EfileFactory","GlobalService","USER_SETTINGS","AlertService","JsonObjectFactory","GENERAL_CONFIG","$stateParams","$state","$scope","workspaceFactory","GridFactory","$timeout","$uibModal",UnlockValidationController])
        .controller("FormElementsReportController",["EfileFactory","GlobalService","USER_SETTINGS","AlertService","JsonObjectFactory","GENERAL_CONFIG","$stateParams","$state","$scope","workspaceFactory","GridFactory","$timeout","$uibModal",FormElementsReportController])
        .controller("AmendSupersededReturnsController",["EfileFactory","GlobalService","USER_SETTINGS","AlertService","JsonObjectFactory","GENERAL_CONFIG","$stateParams","$state","$scope","workspaceFactory","GridFactory","$timeout","$uibModal",AmendSupersededReturnsController])
        .controller("EfileScrubbingController",["EfileFactory","GlobalService","USER_SETTINGS","AlertService","JsonObjectFactory","GENERAL_CONFIG","$stateParams","$state","$scope","workspaceFactory","GridFactory","$timeout","$uibModal",EfileScrubbingController])
        .controller("EfileKeyXmlGenController",["EfileFactory","GlobalService","USER_SETTINGS","AlertService","JsonObjectFactory","GENERAL_CONFIG","$stateParams","$state","$scope","workspaceFactory","GridFactory","$timeout","$uibModal","FormViewFactory","$rootScope",EfileKeyXmlGenController])
        .directive('endOfRepeatTable', ['$timeout',endOfRepeatTable])
        function endOfRepeatTable($timeout) {
            return function(scope, element, attrs) {
                if (scope.$last) {
                    
                    $timeout(function(){
                        var efileFormTable = $('#efileFormTable').DataTable({})
                    }, 0);
                }
                
            }
        };
     function SubmissionsDashboardController(USER_SETTINGS,GENERAL_CONFIG,$scope,EfileFactory,$state,$rootScope){
        var vm = this;
        console.log("Efile Submission");
        vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';
        $rootScope.$on('currentTheme', function (ev, theme) {
            vm.currentTheme = theme;
        });
        vm.baseURLs = {};
        vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
        vm.baseURLs.api = GENERAL_CONFIG.base_url;
        vm.baseURLs.efile = GENERAL_CONFIG.gtw_efile_api_url;
        vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;

        vm.client_key = 0;
        vm.tabs = EfileFactory.submissionTabs;
        vm.selectedSubmissionData = EfileFactory.selectedSubmission;

        $scope.$on("submission-dashboard.addTab", function (event, data) {
            vm.addTab(data);
        });

        vm.addTab = function(data) {
            var tooltip = data.rowData.TAX_PAYER_NAME;
            let tab = {
                name: data.params.tax_year + '-' + data.rowData.TAX_PAYER,
                code: data.rowData.SUBMISSION_ID,
                data: data,
                tooltip: tooltip
            };
            vm.tabs.push(tab)
            vm.goToTab(tab);
            $state.transitionTo('submissions-dasboard.submission.dashboard',{id:tab.code})

            setTimeout(function () {
                $scope.$apply();
            }, 500);
        }

        vm.goToTab = function (tabData, $event) {
            if (tabData) {
                
                vm.selectedSubmission = tabData.code;

                vm.selectedSubmissionData = EfileFactory.selectedSubmission = tabData.data.rowData;

                $state.transitionTo('submissions-dasboard.submission.dashboard',{id:tabData.code})
            }

        };
        vm.clearSelectedLE = function() {
            vm.selectedSubmission = null;

            vm.selectedSubmissionData = EfileFactory.selectedSubmission = {};
        }
        vm.removeTab = function (tab) {
            var index = _.findIndex(vm.tabs, tab);
            vm.tabs.splice(index, 1);
            
            $state.transitionTo('submissions-dasboard.list');
        };
    }

    function SubmissionListCtrl(TAX_YEARS, $rootScope, $scope, $state, $log, GridFactory, AlertService, $timeout, $uibModal,
        workspaceFactory, GlobalService,EfileFactory,USER_SETTINGS){
        let vm = this;

        vm.taxYears = GlobalService.inputs.tax_year.values;//TAX_YEARS;
        vm.submissionType = "federal";
        vm.ssoId = USER_SETTINGS.user.sso_id;
        var custom_data_key = "EFILE_SUBMISSIONS";
        $scope.filter_template = 'app/components/dataFilters/grid-filters-tpl.html';
        $scope.filter = {};
        vm.filterObjs = {};
        var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);
        const PRODUCTION_ACCEPTED = 'Production Accepted';

        if (cachedData && cachedData.data) {
            vm.filterObjs.loading = false;
            vm.taxYear = _.find(GlobalService.inputs.tax_year.values, {
                value: cachedData.data.params.tax_year
            })
            vm.filterMatch = true;
        } else {
            vm.filterObjs.loading = false;
            var selectedTaxYear = _.find(GlobalService.inputs.tax_year.values,{label:GlobalService.inputs.tax_year.selected.value})
            vm.taxYear = selectedTaxYear;// GlobalService.inputs.tax_year.selected;
            vm.filterMatch = true;
        }

        vm.refresh = function () {
            getData(true);
        };

        var getData = function (refresh) {
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];
            var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);

            if (cachedData && cachedData.data && !refresh) {
                vm.data = cachedData.data.data;
                vm.taxYear = _.find(GlobalService.inputs.tax_year.values, {
                    value: cachedData.data.params.tax_year
                })
                vm.filterMatch = true;
                // $rootScope.$broadcast("collapseLeft", function (event, data) {});
                $scope.$broadcast("dataLoaded", vm.data);
                $scope.$broadcast("showLoader", false);
                return true;
            }
            /*  if filters are changed, deleting the existing cached Grid Data*/
            else if (cachedData && cachedData.data && refresh) {
                for (var i = 0; i < workspaceFactory.customDataCache.length > 0; i++) {
                    if (workspaceFactory.customDataCache[i].data_key === cachedData.data_key) {
                        workspaceFactory.customDataCache.splice(i, 1);
                    }
                }
                console.log("cusomCache", workspaceFactory.customDataCache);
            }

            $scope.$broadcast("showLoader", true);
                vm.filterMatch = true;
                var params = {
                    tax_year: vm.taxYear.value,
                    type:vm.submissionType
                }

                GridFactory.getDataCustom($state.current.api.get, params).then(function (data) {
                    if (data.callSuccess === "1") {
                        _.each(data.data,item=>item.ROLE=USER_SETTINGS.user.client.user_type_key);
                        vm.data = data;
                        // $rootScope.$broadcast("collapseLeft", function (event, data) {});
                        $scope.$broadcast("dataLoaded", data);
                        $scope.$broadcast("showLoader", false);

                        workspaceFactory.setCustomDataCache(custom_data_key, {
                            params: params,
                            data: vm.data
                        });
                    } else {
                        AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                    }
                });
        };

        vm.onTypeChange = function(type){
            console.log(type);
            vm.errorParams = '&tax_year='+vm.taxYear.value+'&type='+type;  
            // console.log("Type Change : "+vm.errorParams);
        }; 
        vm.onTaxYearChange = function(yearValue){
            console.log(yearValue);
            vm.errorParams = '&tax_year='+yearValue.value+'&type='+vm.submissionType;  
            // console.log("Tax Year Change : "+vm.errorParams);
        }; 
        
        vm.errorParams = '&tax_year='+vm.taxYear.value+'&type='+vm.submissionType;
        // console.log("No Change : "+vm.errorParams);
        $timeout(function () {
            var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);
            if (cachedData && cachedData.data) {
                getData(false);
            }
        }, 5);

        /* pencil icon function */
        $scope.ctrlFn = function (_callName, _data, _type) {
            console.log("_callName ", _callName);
            
            if((_callName === "efileBatchSubmission" || _callName === "generateBatch") && _data[0].data.ROLE < 2){
                AlertService.add("warning","You do not have access to this feature.");
                $rootScope.$apply();
                return;
            }
            if((_callName === "efileBatchSubmission") ){
                let PROD_ACCEPTED = false;
                _.each(_data,function(row) {
                    if(row.data.SUBMISSION_STATUS === PRODUCTION_ACCEPTED){
                        PROD_ACCEPTED = true;
                        return;
                    }
                })

                if(PROD_ACCEPTED){
                    AlertService.add("warning","Atleast one submission from the selected list was already production accepted. Please un-select those submission(s) and try again.");
                    $rootScope.$apply();
                    return;
                }
            }
            if(_callName === "efileBatchSubmission"){
                doBatchSubmission(_data);
                return;
            }
            
            if(_callName !== "loadSubmission") {
                if(EfileFactory[_callName]){
                    $uibModal.open({
                        templateUrl: 'app/templates/modals/confirm-action.html',
                        windowClass: 'modal-warning',
                        resolve:{
                            _data:function() {
                                return _data;
                            },
                            _callName:function() {
                                return _callName;
                            }
                        },
                        controller: ['$scope','$rootScope', '$stateParams', '$uibModalInstance', 'GENERAL_CONFIG','_data','EfileFactory','AlertService','_callName','$filter',
                            confirmationCtrl
                        ]
                    });
                }
                return;
            }

            _data.params = {
                tax_year: vm.taxYear.value
            };

            _data.rowData.submissionType = vm.submissionType;

            $rootScope.$broadcast("submission-dashboard.addTab", _data);
            // $rootScope.$broadcast("entity-mgmt.entity", _data);
        };

        function doBatchSubmission(_data){
            // return if the the data has MAIN_CONSOL === 'Y'
            if(_.find(_data,["data.MAIN_CONSOL","Y"])){
                AlertService.add("warning","Consolidated submission cannot be submitted in Batch.");
                $rootScope.$apply();
                return;
            }

            $uibModal.open({
                templateUrl: 'app/components/efile/efileElements/batchSubmission.html',
                size:'sm',
                controllerAs:'ctrl',
                resolve:{
                    _data:function() {
                        return _data;
                    }
                },
                controller: ['USER_SETTINGS','SERVER_CONFIG','_data','EfileFactory','AlertService','$uibModalInstance',
                    batchSubmissionCtrl
                ]
            });
        }
        
        function batchSubmissionCtrl(USER_SETTINGS,SERVER_CONFIG,_data,EfileFactory,AlertService,$uibModalInstance){
            var vm = this;
            vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';
            vm.canDoProdSubmit =  SERVER_CONFIG.clientConfig.efile_prod_submission_users ?
                                        SERVER_CONFIG.clientConfig.efile_prod_submission_users.includes(USER_SETTINGS.user.sso_id) : false;
            vm.submitting = false;
            vm.env = "TEST";
            vm.subIds = _.chain(_data).map(d=>_.get(d,'data.SUBMISSION_ID')).join(',').value();
            vm.submit = function() {
                if(vm.env !== 'TEST'){
                    openConfirmation();
                    return;
                }
                doSubmit();
            }

            function doSubmit() {
                vm.submitting = true;
                EfileFactory.efileBatchSubmission(vm.subIds, vm.env).then((res) => {
                    vm.submitting = false;
                    if (res.data.callSuccess === "1"){
                        AlertService.add("success", "Successfully submitted to IRS!");
                        $uibModalInstance.close();
                    }else
                        AlertService.add("error", "Failed to submit to IRS!");
                });
            }

            function openConfirmation(){
                let confirmation1 = $uibModal.open({
                    templateUrl: 'app/templates/modals/confirm-action.html',
                    windowClass: 'modal-warning',
                    resolve:{
                        message:function(){
                            return "Are you sure you want to submit to IRS Prod?"
                        }
                    },
                    controller: ['$scope', '$uibModalInstance','message',simpleConfirmationCtrl]
                });

                confirmation1.result.then(function (selectedItem) {
                    let confirmation2 = $uibModal.open({
                        templateUrl: 'app/templates/modals/confirm-action.html',
                        windowClass: 'modal-warning',
                        resolve:{
                            message:function(){
                                return "Are you sure you want to submit to IRS Prod?"
                            }
                        },
                        controller: ['$scope', '$uibModalInstance','message',simpleConfirmationCtrl]
                    });
                    confirmation2.result.then(function () {
                        doSubmit();
                    });
                  }, function () {
                    
                  });

                  
            }
        }
        
        function confirmationCtrl($scope,$rootScope, $stateParams, $uibModalInstance, GENERAL_CONFIG,_data,EfileFactory,AlertService,_callName,$filter) {
        	let actionMap = {
                validate:{
                    confirmationMessage:'Do you want to proceed with the validation for this submission?',
                    successMessage: 'Validation has started' //' for ' + EfileFactory.selectedSubmissionData.TAX_PAYER_NAME
                    	+ '. This process could take several minutes and you will be notified via email once completed.'
                    //'Successfully validated the submission.'
                },
                validateBatch:{
                    confirmationMessage:'Do you want to proceed with the validation for the selected submissions?',
                    successMessage:'Successfully validated the selected submissions.'
                },
                generateBatch:{
                    confirmationMessage:'Do you want to proceed with the XML Generation for the selected submissions?',
                    successMessage:'Successfully generated XMLs for the selected submissions.'
                },
                submitBatch:{
                    confirmationMessage:'Do you want to proceed with the submission to IRS for the selected submissions?',
                    successMessage:'Successfully submitted to IRS for the selected submissions.'
                },
                efileBatchPollAck:{
                    confirmationMessage:'Do you want to proceed to get the acknowledgements for the selected submissions?',
                    successMessage:'Successfully received acknowledgements from IRS for the selected submissions.'
                },
                downloadBatchAcks:{
                    confirmationMessage:'Do you want to proceed to download the latest acknowledgements for the selected submissions?',
                    successMessage:'Successfully downloaded the latest acknowledgements for the selected submissions.',
                    callBack : function (data){
                         vm.file = new Blob([data], {type: 'application/zip'});
                         vm.pdfPath = URL.createObjectURL(vm.file);
                        vm.fileName = $filter('date')(new Date(), "MM-dd-yyyy");
                        var link = document.createElement('a');
                        link.href = vm.pdfPath;
                        link.download = vm.fileName+'.zip';
                        link.dispatchEvent(new MouseEvent('click'));
                    }
                },
                enabledDisabledFormData:{
                    confirmationMessage:'Are you sure you want to Toggle Form Exclusion for Selected Row??',
                    successMessage:'Successfully Toggle Form Exclusion.'
                },
            }                   
            $scope.header = "Confirm";
            $scope.message = actionMap[_callName].confirmationMessage;
            $scope.confirm = function () {
                $uibModalInstance.close();
                let subIds = _data.rowData ? _data.rowData.SUBMISSION_ID : _.chain(_data).map(d=>_.get(d,'data.SUBMISSION_ID')).join(',').value();
                $rootScope.$broadcast("showLoader", true);
                EfileFactory[_callName](subIds).then(function(response) {
                	if(response.config.responseType === 'arraybuffer'){
                		if(actionMap[_callName].callBack){
                    		actionMap[_callName].callBack(response.data);
                    		
                    	}
                		getData(true);
                        AlertService.add('success',actionMap[_callName].successMessage);
                        return;
                	}                	
                    if(response.data.callSuccess === '1'){
                        getData(true);
                        AlertService.add('success',actionMap[_callName].successMessage);
                    }
                    else if(response.data.callSuccess === '2'){
                   	 	$rootScope.$broadcast("showLoader", false);
                   	 	AlertService.add('warning', response.data.errorMessage);
                    }
                    else {
                        $rootScope.$broadcast("showLoader", false);
                        AlertService.add('error','Something went wrong! Please contact support.');
                    }
                }).catch(function(e) {
                    $rootScope.$broadcast("showLoader", false);
                })
            }
            $scope.cancel = function () {
                $uibModalInstance.close();
            }

            $scope.$on('$destroy', function () {
                ////////////console.log("ngReally Destroyed");
            });
        }

    }

    function SubmissionController(TAX_YEARS,GENERAL_CONFIG, $rootScope, $scope, $state, $log, GridFactory, AlertService, $timeout, $uibModal,
        workspaceFactory, GlobalService,EfileFactory,JsonObjectFactory,submissionLoaded,SERVER_CONFIG,USER_SETTINGS){
        let vm = this;
        vm.selectedSubmissionData = EfileFactory.selectedSubmission;
        vm.PRODUCTION_ACCEPTED = 'Production Accepted';
        vm.canDoProdSubmit = SERVER_CONFIG.clientConfig.efile_prod_submission_users ?
            SERVER_CONFIG.clientConfig.efile_prod_submission_users.includes(USER_SETTINGS.user.sso_id) : false;
        vm.enableReviewProcess = SERVER_CONFIG.configSettings?.enable_review_process?.includes(USER_SETTINGS.user.sso_id) || SERVER_CONFIG.configSettings?.enable_review_process?.toLowerCase() === 'all';
        vm.showHeirarchy = false;
        let treeDataSet = new Set;
        let parentNodesAhead = [];

        vm.toggleHeirarchy = function()
        {
            vm.showHeirarchy = !this.showHeirarchy;
        }
        
        function loadHierarchy() {
            vm.loadingKeys = true;
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=71n7lj',
                {
                    submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID,
                    tax_year:EfileFactory.selectedSubmission.TAX_YEAR,
                    scenario:_.find(GlobalService.inputs.tax_year.values,["value",EfileFactory.selectedSubmission.TAX_YEAR.toString()]).scenario,
                    jcd_key:250,
                    sso_id:'400384621'
                }).then(function (response) {
                    vm.loadingKeys = false;
                    vm.data = sanatize(response.jsonObject);
                    vm.entityTreeCopy = angular.copy(vm.data);
                });
        }

        function sanatize(data){
            for (let index = 0; index < 2; index++) {//run 2 times for sanatizing 2 levels
                data = _.filter(data,function(item){
                    if(item.IS_LEAF && !item.IS_ENTITY){
                        let parent = _.find(data,["KEY_DESC",item.PARENT])
                           parent && (parent.IS_LEAF = 1);//make parent as leaf
                   }
                  
                   return !(item.IS_LEAF && !item.IS_ENTITY);//remove folders acting as leafs
                })      
            }   
            return data;         
        }

        loadHierarchy();

        vm.entitySelected = function(node){
            if(node.IS_LEAF === 0){
                vm.toggle(vm.data,node);
                return;
            }
            let lastActive = _.find(vm.data,["active",true]);
            if(lastActive) lastActive.active = false;
            
            node.active = true;

            $scope.$broadcast('efile:entity-selected',node);
        }
        vm.isHidden = function(item) {
            return !item.hidden;
        }
        vm.toggle = function (tree, item) {
            if (item.IS_LEAF === 1)
                return;

            let index = tree.indexOf(item) + 1;
            // Collapse
            if (!item.childrenHidden) {
                item.childrenHidden = true;
                for (let i = index; i < tree.length; i++) {
                    let prev = i - 1;
                    if (tree[i].LVL <= item.LVL) {
                        return;
                    } else {
                        tree[i].hidden = true;
                        // tree[i].childrenHidden = false;
                    }
                    // collapse folder icon if children are hidden
                    if (tree[prev].LVL < tree[i].LVL) {
                        tree[prev].childrenHidden = true;
                    }
                }
            }
            // Expand
            else {
                item.childrenHidden = false;
                for (let i = index; i < tree.length; i++) {
                    if (tree[i].LVL <= item.LVL) {
                        return;
                    } else if (tree[i].LVL - 1 === item.LVL) {
                        tree[i].hidden = false;
                    } else {
                        tree[i].hidden = true;
                    }
                }
            }
        };


        vm.filterTreeData = function() {
            let searchLabel = 'KEY_DESC';

            if (vm.searchTerm.length === 0) {
                vm.data = angular.copy(vm.entityTreeCopy);
                vm.data.forEach(node => clearHiddenFlag(node));
                return;
            }

            treeDataSet.clear();
            parentNodesAhead = vm.entityTreeCopy.filter(item => !item.is_leaf);

            for (let i = 0; i < vm.entityTreeCopy.length; i++) {
                let node = vm.entityTreeCopy[i];
                clearHiddenFlag(node);
                // if (!node.is_leaf) {
                //     parentNodesAhead.push(node);
                // }
                if ((node[searchLabel]).toLowerCase().includes(vm.searchTerm.toLowerCase())) {
                    findParents(node.PATH, searchLabel); // add parents
                    treeDataSet.add(node); // add itself
                    if (!node.is_leaf) {
                        i = findChildren(node.LVL, i); // add children and update index
                    }
                }
            }

            vm.data = Array.from(treeDataSet);
        };

        function clearHiddenFlag(node) {
            node.hidden = false;
            node.childrenHidden = false;
        }

        function findParents(path, searchLabel) {
            let nodes = path.split('/');
            if (nodes.length > 2) {
                nodes = nodes.slice(1, -1);
                nodes.forEach(node => {
                    let parentNode = parentNodesAhead.find((item) => item[searchLabel].includes(node.trim()));
                    parentNode !== undefined && (treeDataSet.add(parentNode));
                });
            }
        }

        function findChildren(parentLvl, index) {
            while (index < vm.entityTreeCopy.length) {
                let node = vm.entityTreeCopy[index];
                if (node.lvl > parentLvl) {
                    treeDataSet.add(node);
                    index++;
                } else {
                    break;
                }
            }
            return index;
        }
        vm.download8453cPdf = function() {
        	console.log("8453c controller");
            vm.download8453cPdf = true;
            EfileFactory.download8453cPdf(EfileFactory.selectedSubmission.SUBMISSION_ID).then((response)=>{
            	                 
                let blob = new Blob([response.data], {type: "application/pdf"});
                let link = document.createElement('a');
                let url = window.URL.createObjectURL(blob);
                window.open(url,'_blank');
            })
        }
        
        vm.download8879cfPdf = function() {
        	console.log("8879c controller");
            vm.download8879cfPdf = true;
            EfileFactory.download8879cfPdf(EfileFactory.selectedSubmission.SUBMISSION_ID).then((response)=>{
            	                 
                let blob = new Blob([response.data], {type: "application/pdf"});
                let link = document.createElement('a');
                let url = window.URL.createObjectURL(blob);
                window.open(url,'_blank');
            })
        }
    }

    function SubmitController(USER_SETTINGS,GENERAL_CONFIG,SERVER_CONFIG,EfileFactory,$stateParams,AlertService,$state,$sce,JsonObjectFactory,$uibModal,$scope,GlobalService,$rootScope){
        let vm = this;
        const PRODUCTION_ACCEPTED = 'Production Accepted';
        vm.enableGetAck = false;
        EfileFactory.taxDocUrl = '';
        vm.canDoProdSubmit = SERVER_CONFIG.clientConfig.efile_prod_submission_users ?
                                SERVER_CONFIG.clientConfig.efile_prod_submission_users.includes(USER_SETTINGS.user.sso_id) : false;
        vm.isExtension = EfileFactory.selectedSubmission.RETURN_TYPE.includes("Extension");
        if(!EfileFactory.hasAccess("EDITOR") || EfileFactory.selectedSubmission.SUBMISSION_STATUS === PRODUCTION_ACCEPTED){
            $state.go('submissions-dasboard.submission.dashboard',{id:$stateParams.id});
            return;
        } 
        vm.ENV_FOR_PROCESS_SPLIT = EfileFactory.ENV_FOR_PROCESS_SPLIT;
        
        const cleanup = $rootScope.$on('get-ack-some',function(e,data) {
            vm.enableGetAck = true;
        })

        const cleanup2 = $rootScope.$on('get-ack-only',function(e,data) {
            vm.onlyGetAck = (data.ACKNOWLEDGEMENT_EXISTS === "No" && data.SUBMISSION_RECEIPT_EXISTS === "Yes");
        })

        const cleanup3 = $rootScope.$on('submit-valid-return',function(e,data){
            vm.submitValidReturn(data.env,data.irsId);
        })

        const cleanup4 = $rootScope.$on('pending-submission',function(e,data) {
            vm.pendingSubmission = true;//vm.envType === vm.ENV_FOR_PROCESS_SPLIT;
        })
        const cleanup5 = $rootScope.$on('no-pending-submission',function(e,data) {
            vm.pendingSubmission = false;//vm.envType === vm.ENV_FOR_PROCESS_SPLIT;
        })

        const pendingCriticalDiag = $rootScope.$on('pending-critical-diagnostics', function(e, data){
            vm.pendingCriticalDiagnostics = true;
        })

        const noPendingCriticalDiag = $rootScope.$on('no-pending-critical-diagnostics', function(e, data){
            vm.pendingCriticalDiagnostics = false;
        })
        const cleanup6 = $rootScope.$on('on-efile-review-attachment-upload', function(e,data) {

            if(data.callSuccess){
                const pathChunks = data.filePath.split("/");
                EfileFactory.taxDocUrl = pathChunks[pathChunks.length - 1];

                vm.showReqHeaderEle();

            }else{
                AlertService.add("error","Failed to upload the review attachment");
            }
        });

        let irsSubmissionGridParams = {
            submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID,
            sso_id:USER_SETTINGS.user.sso_id
        }

        let reqHeaderListColumns =[
            {title:'Element Name', data:'ELEMENT_NAME'},
            {title: 'Element Value', data: 'ELEMENT_VALUE'},
            {title: 'Choice Group Id', data : 'CHOICE_GROUP_ID'},
            {title: 'choice seq', data:'CHOICE_SEQ'}
        ]

        vm.submitting = false;
        vm.pinging = false;
        vm.acking = false;
        vm.envType = "TEST";
        vm.downAcking = false;
        vm.sqlInjing = false;
        vm.minVal=0;
        vm.maxVal=80;
        vm.submission = EfileFactory.selectedSubmission;
        const JCD_KEY = 250;
        const FINAL_SUBMISSION_MESSAGE = "Successfully Submitted to IRS!"
        vm.submissionSuccessMessage = FINAL_SUBMISSION_MESSAGE;
        
        vm.envTypeChanged = function(){

            $rootScope.$emit('env-type-changed',vm.envType);
        }

        function doSubmitValidReturn(env,irsId){

            $rootScope.$emit('submitting',true);
            EfileFactory.submitValidatedReturn(env,$stateParams.id,irsId).then((res)=>{
                $rootScope.$emit('submitting',false);
                if(res.data.callSuccess === "1"){
                    AlertService.add("success", FINAL_SUBMISSION_MESSAGE );
                    refreshSubmissionDetails();
                }
                else
                    AlertService.add("error","Failed to Submit to IRS!"+res.data.errorMessage);
            },(err)=>{
                    AlertService.add("error","Failed to Submit to IRS!");
            })
        }

        vm.submitValidReturn = function(env,irsId) {
                openConfirmation(doSubmitValidReturn,env,irsId);
        }
        vm.submitToIRS = function() {
            if(vm.envType !== "TEST"){
                openConfirmation(doSubmit);
                return;
            }
            
            doSubmit();
        }
        function doSubmit(){
            let shouldUpdateTaxDocUrl = false;
            if(!EfileFactory.selectedSubmission.RETURN_TYPE.includes("Extension") && vm.envType.toLowerCase() === vm.ENV_FOR_PROCESS_SPLIT.toLowerCase()){
                vm.submissionSuccessMessage = "eFile Team is notified of the newly created XML for Validation. The Submission will be transmitted to IRS post-validation.";
                shouldUpdateTaxDocUrl = true;

            }else{
                vm.submissionSuccessMessage = FINAL_SUBMISSION_MESSAGE;
            }
            vm.submitting = true;
            EfileFactory.submit(vm.envType,$stateParams.id).then((res)=>{
                vm.submitting = false;
                if(res.data.callSuccess === "1"){
                    AlertService.add("success", vm.submissionSuccessMessage );
                    refreshSubmissionDetails(shouldUpdateTaxDocUrl);
                }
                else
                    AlertService.add("error","Failed to Submit to IRS!"+res.data.errorMessage);
            })
        }
        function refreshSubmissionDetails(shouldUpdateTaxDocUrl) {
            vm.pendingSubmission = false;
            $rootScope.$emit("reload-submissions",shouldUpdateTaxDocUrl);
            // vm.loadingSubmission = true;
            // JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=nvc2sx',
            //     { submission_id: $stateParams.id }).then(function (response) {
            //         vm.loadingSubmission = false;
            //         vm.submission = EfileFactory.selectedSubmission = response.jsonObject[0];
            //     });
        }

        vm.showUploadTaxDocPopup = function() {

            if(!EfileFactory.selectedSubmission.RETURN_TYPE.includes("Extension") && vm.envType.toLowerCase() === vm.ENV_FOR_PROCESS_SPLIT.toLowerCase() && !EfileFactory.taxDocUrl){
                $rootScope.$emit('show-upload-tax-doc-popup');
                return;
            }

            vm.showReqHeaderEle();

        }

        vm.showReqHeaderEle = function() {
            let params = {
                submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID
                //sso_id:USER_SETTINGS.user.sso_id
            }
            openreqHeaderPopup(params,'yzgy3w',reqHeaderListColumns,"Review Mandatory Header Elements",vm.envType)
           
        }
       
        function openreqHeaderPopup(params,action_code,columns,title,submitType){
            vm.headerList=[];
           var reqElePopUp =  $uibModal.open({
                templateUrl: 'app/components/efile/efileElements/reqHeaderElements.html',
                size: 'lg',
                resolve:{
                    api_url:function(){
                        return '/loadJsonObject?action_code='+action_code;
                        // return '/loadGridJson?grid_id='+grid_id+'&action_code='+action_code;
                    },
                    params:function() {
                        return params;
                    },
                    columns:function() {
                        return columns;
                    },
                    title:function() {
                        return title;
                    }
                },
                controllerAs: 'ctrl',
                controller:["api_url","params","columns","title","JsonObjectFactory","GENERAL_CONFIG","$compile","USER_SETTINGS","$rootScope","$scope","EfileFactory",function(api_url,params,columns,title,JsonObjectFactory,GENERAL_CONFIG,$compile,USER_SETTINGS,$rootScope,$scope,EfileFactory) {
                    let vm = this;
                    vm.api_url = api_url;
                    vm.params = params;
                    vm.title = title;
                    vm.showTaxDocInput = submitType === EfileFactory.ENV_FOR_PROCESS_SPLIT;
                    vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';
                    $rootScope.$on('currentTheme', function (ev, theme) {
                        vm.currentTheme = theme;
                    });

                    $scope.confirm = function () {
                        reqElePopUp.close($scope.selection);

                        if(submitType !== "TEST"){
                            openConfirmation(doSubmit);
                            return;
                        }

                        doSubmit();
                     }
                     $scope.cancel = function () {
                        reqElePopUp.close($scope.selection);
                        
                     }
                    vm.options = {
                        extraOptions:{
                            componentSettings:{
                                tableOptions:{
                                    searching:true,
                                    columns:columns,
                                    buttons: [
                                        //'excel', 'print'
                                        {
                                            extend:    'print',
                                            text:      '<i class="fa fa-print"></i>',
                                            titleAttr: 'Print'
                                        },
                                        {
                                            extend:    'excel',
                                            text:      '<i class="fa fa-file-excel-o"></i>',
                                            titleAttr: 'Excel'
                                        }
                                    ],
                                    fnCreatedRow:function(row, data, index) {
                                        api_url.includes('81j7mv') && $compile(row)($scope);//remove this if data is huge
                                    }
                                }
                            }
                        }
                    }

                    vm.loading = true;
                    JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + api_url,
                        params).then(function(response) {
                            vm.loading = false;
                            vm.headerList = response.jsonObject;
                        });
                }]
            });
                        
        }
        function openConfirmation(doSubmit,env,irsId){
            let confirmation1 = $uibModal.open({
                templateUrl: 'app/templates/modals/confirm-action.html',
                windowClass: 'modal-warning',
                resolve:{
                    message:function(){
                        return "Are you sure you want to submit to IRS Prod?"
                    }
                },
                controller: ['$scope', '$uibModalInstance','message',simpleConfirmationCtrl]
            });

            confirmation1.result.then(function (selectedItem) {
                let confirmation2 = $uibModal.open({
                    templateUrl: 'app/templates/modals/confirm-action.html',
                    windowClass: 'modal-warning',
                    resolve:{
                        message:function(){
                            return "Are you sure you want to submit to IRS Prod?"
                        }
                    },
                    controller: ['$scope', '$uibModalInstance','message',simpleConfirmationCtrl]
                });
                confirmation2.result.then(function () {
                    doSubmit(env,irsId);
                });
              }, function () {
                
              });

              
        }

        vm.pingToIRS = function() {
            vm.pinging = true;
            EfileFactory.pingIRS("TEST").then((res)=>{
                vm.pinging = false;
                if(res.data.callSuccess === "1")
                    AlertService.add("success","Successfully Pinged IRS!");
                else
                    AlertService.add("error","Failed to Ping IRS!");
            })
        }
        vm.getAck = function() {
            vm.acking = true;
            const selectedTaxYear = _.find(GlobalService.inputs.tax_year.values,{label:EfileFactory.selectedSubmission.TAX_YEAR + ''})
            const scenario = selectedTaxYear.scenarios.find(function(s) {
                return s.jcd_key == JCD_KEY && s.default_scenario === 'Y';
            }).scenario;
            EfileFactory.getAck($stateParams.id,EfileFactory.selectedSubmission.TAX_YEAR,scenario,JCD_KEY).then((res)=>{
                vm.acking = false;
                if(res.data.callSuccess === "1"){
                    AlertService.add("success","Successfully Fetched Acknowledgment from IRS!");
                    vm.enableGetAck = false;
                }
                else
                    AlertService.add("warn",res.data.errorMessage);

                refreshSubmissionDetails();
            })
        }
        
        vm.downloadAck = function() {
            vm.downAcking = true;
            EfileFactory.downloadAck($stateParams.id).then((response)=>{
            	 vm.downAcking = false;
                 vm.file = new Blob([response.data], {type: 'application/pdf'});
                 vm.pdfPath = URL.createObjectURL(vm.file);
                 vm.content = $sce.trustAsResourceUrl(vm.pdfPath);
                
                var link = document.createElement('a');
                link.href = vm.pdfPath;
                link.download = $stateParams.id+'_Ack.pdf';
                link.dispatchEvent(new MouseEvent('click'));
            })
        }
        
        vm.sqlInjection = function() {
            vm.sqlInjing = true;
            EfileFactory.sqlInjection($stateParams.id,vm.minVal,vm.maxVal).then((res)=>{
                vm.sqlInjing = false;
                if(res.data.callSuccess === "1")
                    AlertService.add("success","SQL Injection is Success!");
                else
                    AlertService.add("error","Something went wrong during SQL Injection.!" );
            })
        }
        
        $scope.$on('$destroy', function () {
           cleanup();
           cleanup2();
           cleanup3();
           cleanup4();
           cleanup5();
           cleanup6();
           pendingCriticalDiag();
           noPendingCriticalDiag();
        });
    }
    function LoadIRSSchemaController(USER_SETTINGS,GENERAL_CONFIG){
        var vm = this;
        console.log("Efile Submission");
        vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';

        vm.baseURLs = {};
        vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
        vm.baseURLs.api = GENERAL_CONFIG.base_url;
        vm.baseURLs.custom = GENERAL_CONFIG.custom_api_url;
        vm.baseURLs.efile = GENERAL_CONFIG.gtw_efile_api_url;

        vm.client_key = 0;

        let $modal = $('modal[ng-reflect-id=upload-modal]');
        // let $backdrop = $($modal.children()[0]);
        // let $aModal = $($modal.children()[1]);
        // $backdrop.removeClass('show');
        $modal.hide();
        $modal.addClass('gtw-web-components');
    }

    function DataController(USER_SETTINGS,GENERAL_CONFIG,EfileFactory,$stateParams,AlertService,$state,$scope){
        let vm = this;

        !EfileFactory.hasAccess("EDITOR") && $state.go('submissions-dasboard.submission.dashboard',{id:$stateParams.id});

        vm.expandView = () => $scope.$broadcast('efile:expand-view');
            
    }
    function EfileDashboardController($scope,workspaceFactory,EfileFactory,$rootScope,$uibModal,USER_SETTINGS,GENERAL_CONFIG,$timeout,$filter,AlertService){
        let vm = this;
        vm.dashboard_api = GENERAL_CONFIG.base_url + '/getDashboardByKey?dashboard_id=d5chqs';
        vm.submission = EfileFactory.selectedSubmission;
        
        
        workspaceFactory.activeScreen.filters = {
            filterParams :{
                submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID
            },
            getFilterParams: function() {
                return this.filterParams;
            },
            getFilterParamsFromCache:function() {
                return this.filterParams;
            }
        }
        $scope.$on('dataFilters:waitingForFilters',function() {
            $timeout(function() {
                $scope.$broadcast('dataFilters:refreshGrid');                
            })
        })
            

        let listener = $rootScope.$on('efile:get-form-error-list',function(ev, data) {
            let params = {
                submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID,
                source:data.category,
                sso_id:USER_SETTINGS.user.sso_id,
                tax_year:EfileFactory.selectedSubmission.TAX_YEAR
            }
            if(data.series.name === "ERROR_COUNT"){
                openPopup(params,'imz4j5',EfileFactory.errorListColumns, 'IRS Submission Errors for ' + camelize(data.category));
            }else{
                openPopup(params,data.category === "DST" ? "m0i39c" :'up4cq5',EfileFactory.formListColumns, 'IRS Submission Forms for ' + camelize(data.category));
            }

        })

        let entityListener = $rootScope.$on('efile:get-entity-by-count',function(ev,data) {
            // console.log('bar clicked',data.series.name,data.name);
            if(data.name === null){
                return;
            }
            let params = {
                submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID,
                form_name:data.name,
                entity_desc:data.series.name,
                tax_year:EfileFactory.selectedSubmission.TAX_YEAR
            }
            openPopup(params,'pxwl8n',EfileFactory.entityListColumns,params.form_name +' Form By Entity Level');
        })
        let diagListener = $rootScope.$on('efile:get-diagnostics-count',function(ev,data) {
            // console.log('bar clicked',data.series.name,data.name);
            if(data.name === null){
                return;
            }
            let params = {
                submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID,
                in_diag_type:data.name,
                tax_year:EfileFactory.selectedSubmission.TAX_YEAR
            }
            openPopup(params,'5hvo2c',EfileFactory.diagListColumns,data.name);
        })
        vm.showSubmissions = function() {
            let params = {
                submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID,
                sso_id:USER_SETTINGS.user.sso_id
            }
            openPopup(params,'81j7mv',EfileFactory.submissionListColumns,"IRS Submissions Status")
        }

        function openPopup(params,action_code,columns,title){
            $uibModal.open({
                templateUrl: 'app/components/efile/efileElements/dashboard-popup.html',
                size: 'lg',
                resolve:{
                    api_url:function(){
                        return '/loadJsonObject?action_code='+action_code;
                        // return '/loadGridJson?grid_id='+grid_id+'&action_code='+action_code;
                    },
                    params:function() {
                        return params;
                    },
                    columns:function() {
                        return columns;
                    },
                    title:function() {
                        return title;
                    }
                },
                controllerAs: 'ctrl',
                controller:'EfileDashboardPopupController'
            });
        }

        $scope.$on('$destroy', function () {
            listener();
            entityListener();
            diagListener();
        })

        function camelize(str) {
            return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
              return index === 0 ? word.toUpperCase() : word.toLowerCase();
            }).replace(/\s+/g, '');
          }
    
    }

    function EfileDashboardPopupController(api_url,params,columns,title,JsonObjectFactory,GENERAL_CONFIG,$compile,USER_SETTINGS,$rootScope,$scope,AlertService,EfileFactory,SERVER_CONFIG,$uibModal) {
        let vm = this;
        vm.api_url = api_url;
        vm.params = params;
        vm.title = title;
        vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';
        $rootScope.$on('currentubheme', function (ev, theme) {
            vm.currentTheme = theme;
        });
        
        vm.canDoProdSubmit =  SERVER_CONFIG.configSettings.efile_consol_prod_submission_users ?
            SERVER_CONFIG.configSettings.efile_consol_prod_submission_users.includes(USER_SETTINGS.user.sso_id) : false;
        /* if(!EfileFactory.selectedSubmission.RETURN_TYPE.includes("Extension") && vm.canDoProdSubmit){
            const actionCol = columns.find((col) => col.title === 'Actions');
            if(actionCol)
                actionCol.visible = true;
        }else{
            const actionCol = columns.find((col) => col.title === 'Actions');
            if(actionCol)
                actionCol.visible = false;
        } */
        
        vm.options = {
            extraOptions:{
                componentSettings:{
                    tableOptions:{
                        searching:true,
                        columns:columns,
                        buttons: [
                            //'excel', 'print'
                            {
                                extend:    'print',
                                text:      '<i class="fa fa-print"></i>',
                                titleAttr: 'Print'
                            },
                            {
                                extend:    'excel',
                                text:      '<i class="fa fa-file-excel-o"></i>',
                                titleAttr: 'Excel'
                            }
                        ],
                        fnCreatedRow:function(row, data, index) {
                            api_url.includes('81j7mv') && $compile(row)($scope);//remove this if data is huge
                        }
                    }
                }
            }
        }

        vm.sendToIRS = function(env,irsId){
            $rootScope.$emit('submit-valid-return',{env, irsId});
        }

        const cleanup2 = $rootScope.$on('submitting',function(e,data) {
            vm.submitting = data;
        })

        vm.markAsReviewed = function(irsId){

            let confirmation1 = $uibModal.open({
                templateUrl: 'app/templates/modals/confirm-action.html',
                windowClass: 'modal-warning',
                resolve:{
                    message:function(){
                        return "Are you sure you want to mark this return as reviewed?"
                    }
                },
                controller: ['$scope', '$uibModalInstance','message',simpleConfirmationCtrl]
            });

            confirmation1.result.then(function () {
                JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=rwh1ul',{ irsId }).then(response =>{
                    if(response.callSuccess === "1"){
                        AlertService.add("success","Successfully marked as reviewed!");
                        loadSubmissions();
                    }else{
                        AlertService.add("error","Failed to mark as reviewed!");
                    }
                });
            });
        }

        vm.uploadReviewAttachments = function(irsId,isReadOnly){

            let confirmation1 = $uibModal.open({
                templateUrl: 'app/components/efile/efileElements/upload-review-attachments.html',
                windowClass: 'modal-warning',
                controllerAs:'ctrl',
                resolve:{
                    irsId:function(){
                        return irsId;
                    },
                    submissionId:function(){
                        return EfileFactory.selectedSubmission.SUBMISSION_ID;
                    }
                },
                controller: ['$scope','GlobalService', '$uibModalInstance','irsId','submissionId','GENERAL_CONFIG','$rootScope',function($scope,GlobalService,$uibModalInstance,irsId,submissionId,GENERAL_CONFIG,$rootScope){

                    const vm = this;

                    vm.efileReviewAttachmentUploadInput = 
                        JSON.stringify({
                            clientKey:GlobalService.getClient().client_key,
                            baseUrls:{
                                admin: GENERAL_CONFIG.admin_api_url,
                                api:GENERAL_CONFIG.base_url
                            },
                            submissionId:submissionId,
                            irsSubmissionId:irsId,
                            isProdSubmissionUser:EfileFactory.canDoProdSubmit,
                            title:"Upload Signed Tax Doc File",
                        });


                    vm.close = function(){
                        $uibModalInstance.dismiss();
                    }


                    const cleanup = $rootScope.$on('on-efile-review-attachment-upload', function(e,data) {
                            vm.close();
                    });


                    $scope.$on('$destroy', function () {
                        cleanup();
                    });

                }]
            });
        }

        

        vm.discardReturn = function(irsId){
            let confirmation1 = $uibModal.open({
                templateUrl: 'app/templates/modals/confirm-action.html',
                windowClass: 'modal-warning',
                resolve:{
                    message:function(){
                        return "Are you sure you want to discard this return?"
                    }
                },
                controller: ['$scope', '$uibModalInstance','message',simpleConfirmationCtrl]
            });

            confirmation1.result.then(function () {
                EfileFactory.discardReturn(params.submission_id, irsId).then(response => {
                    if(response.data.callSuccess == '1'){
                        AlertService.add('success',"Successfully discarded the return");
                        loadSubmissions();
                    }else{
                        AlertService.add('error',"Something went wrong!");
                    }

                },() => {
                        AlertService.add('error',"Something went wrong!");
                })
            });
        }

        vm.downloadFile = function(type,irsId) {
            EfileFactory.downloadSubStatusDoc(irsId,type).then(function(response) {

                if(response.data && response.data.byteLength > 0){
                    type +='_';
                    let ext;
                    switch(type){
                        case 'manifest_':
                        case 'receipt_':
                            ext = 'xml';
                            break;
                        case 'ack_':
                            ext = 'pdf';
                            break;
                        case 'ifa_':
                        	ext = 'tf';
                        	break;
                        default:
                            type = '';
                            ext='zip';
                    }
                    let file = new Blob([response.data],{type:'application/'+ext});
                    let pdfPath = URL.createObjectURL(file);
                    var link = document.createElement('a');
                    link.href = pdfPath;
                    link.download = `${type}${irsId}.${ext}`;
                    link.dispatchEvent(new MouseEvent('click'));
                }else{
                    AlertService.add('warning',"No file available to download");
                }
            })
        }


        loadSubmissions();

        const cleanup = $rootScope.$on('reload-submissions',function(e,shouldUpdateTaxDocUrl) {
            loadSubmissions(shouldUpdateTaxDocUrl);
        })

        const cleanup3 = $rootScope.$on('env-type-changed',function(e,data) {
            if(_.some(vm.options.data,["SUBMISSION_RECEIPT_EXISTS","No"])){
                $rootScope.$emit("pending-submission");
            }
        });

        const cleanup4 = $rootScope.$on('show-upload-tax-doc-popup',function(e,data) {
            vm.uploadReviewAttachments();
        });

        function loadSubmissions(shouldUpdateTaxDocUrl) {
            vm.loading = true;
            vm.options.data = null;
            
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + "/loadJsonObject?action_code=787b9e",
                params).then(function (response) {
                console.log("critical diag res is : "+JSON.stringify(response));
                JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + api_url,
                params).then(function (response) {
                    vm.loading = false;
                    vm.options.data = response.jsonObject;

                    if (response.jsonObject && response.jsonObject.length > 0) {

                        if(_.some(response.jsonObject,["ACKNOWLEDGEMENT_EXISTS","No"]) && _.every(response.jsonObject,["SUBMISSION_RECEIPT_EXISTS","Yes"])){
                            $rootScope.$emit("get-ack-some");
                        }

                        if(_.some(response.jsonObject,["SUBMISSION_RECEIPT_EXISTS","No"])){
                            $rootScope.$emit("pending-submission");
                            if(shouldUpdateTaxDocUrl)
                                EfileFactory.updateTaxDocUrl(response.jsonObject[0].IRS_ID);
                        }else{
                            $rootScope.$emit("no-pending-submission");
                        }
                        $rootScope.$emit("get-ack-only",response.jsonObject[0]);
                    }
                });
                if(response.jsonObject && response.jsonObject.length > 0) {
                    if(_.gt(response.jsonObject[0].CRITICAL_DIAG_COUNT, 0)) {
                        $rootScope.$emit("pending-critical-diagnostics");
                    } else {
                        $rootScope.$emit("no-pending-critical-diagnostics");
                    }
                } else {
                    $rootScope.$emit("no-pending-critical-diagnostics");
                }
            });
        }

        $scope.$on('$destroy', function () {
            cleanup();
            cleanup2();
            cleanup3();
            cleanup4();
         });
    }
    function DataErrorsController(USER_SETTINGS,GENERAL_CONFIG,EfileFactory,$stateParams,AlertService,$state,$scope,workspaceFactory,GridFactory,$timeout,$uibModal,$rootScope,JsonObjectFactory){
        let vm = this;
        vm.formCheckbox = [];
        console.log($state);
        vm.form_id = '';
        let custom_data_key = 'EFILE_ERRORS_'+EfileFactory.selectedSubmission.SUBMISSION_ID;
        vm.loadingErrors = true;
        vm.selectAllToggle = true;
        vm.ssoId = USER_SETTINGS.user.sso_id;
        $scope.$on("efile:expand-view",function() {
            vm.expandView = !vm.expandView;
        })
        
        loadErrorList();

        $scope.ctrlFn = function (_callName, _data, _type) {
            console.log("_callName ", _callName);
            
            
            if(_callName === "validate") {
                if(EfileFactory[_callName]){
                    let confirmation = $uibModal.open({
                        templateUrl: 'app/templates/modals/confirm-action.html',
                        windowClass: 'modal-warning',
                        controller: ['$scope', '$uibModalInstance',function ($scope,$uibModalInstance) {                
                            $scope.header = "Confirm";
                            $scope.message = "Are you sure you want to validate?";
                            $scope.confirm = function () {
                                $uibModalInstance.close();
                            }
                            $scope.cancel = function () {
                                $uibModalInstance.dismiss();
                            }
                    
                            $scope.$on('$destroy', function () {
                                ////////////console.log("ngReally Destroyed");
                            });
                        }]
                    });
                    confirmation.result.then(function () {
                        vm.validating = true;
                        EfileFactory.validate(EfileFactory.selectedSubmission.SUBMISSION_ID).then(response=>{
                            vm.validating = false;
                            if(response.data.callSuccess === '1'){
                                loadErrorList();
                                AlertService.add('success','Validation has started. This process could take several minutes and you will be notified via email once completed.');
                            }
                        },err=>{
                            vm.validating = false;
                            AlertService.add('error','Something went wrong!');
                        });
                    });
                }
                return;
            }
        };

        var getData = function (refresh) {
            vm.form_id = '';
            for(let i = 0;i<vm.formCheckbox.length; i++) {
                if(vm.formCheckbox[i])
                {
                    vm.form_id += vm.errors[i].FORMIDS + ",";
                }
            }
            if(vm.form_id === '')
            {
                vm.showErrors = false;
                return;
            }
            vm.form_id = vm.form_id.slice(0,-1);

            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];
            var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);

            if (cachedData && cachedData.data && !refresh) {
                setTimeout(function() {
                    vm.data = cachedData.data.data;
                
                    $scope.$broadcast("dataLoaded", vm.data);
                    $scope.$broadcast("showLoader", false);
                        
                });
                return true;
            }
            /*  if filters are changed, deleting the existing cached Grid Data*/
            else if (cachedData && cachedData.data && refresh) {
                for (var i = 0; i < workspaceFactory.customDataCache.length > 0; i++) {
                    if (workspaceFactory.customDataCache[i].data_key === cachedData.data_key) {
                        workspaceFactory.customDataCache.splice(i, 1);
                    }
                }
                console.log("cusomCache", workspaceFactory.customDataCache);
            }

            
            
            $scope.$broadcast("showLoader", true);
            vm.errorParams = '&submission_id='+EfileFactory.selectedSubmission.SUBMISSION_ID+'&form_id='+vm.form_id;
            GridFactory.getDataCustom(GENERAL_CONFIG.base_url + "/loadGridJson?grid_id=5779&action_code=p35kth", {submission_id:EfileFactory.selectedSubmission.SUBMISSION_ID, form_id: vm.form_id}).then(function (data) {
                if (data.callSuccess === "1") {
                    vm.data = data;
                    // $rootScope.$broadcast("collapseLeft", function (event, data) {});
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);

                    workspaceFactory.setCustomDataCache(custom_data_key, {
                        data: vm.data
                    });


                    
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
            
               
        };

        vm.uncheckAll = function() {
            for(let i  = 0; i<vm.formCheckbox.length; i++) {
                vm.formCheckbox[i] = false;
            }
        }

        vm.clickIcon = function(form_id, index) {
            vm.uncheckAll();
            vm.formCheckbox[index] = true;
            vm.checkAllChecked()?vm.selectAllToggle = true:vm.selectAllToggle = false;
            vm.showErrors = true;
            $timeout(function() {
                getData(true);
            },300)
        }

        vm.checkForm = function(index, formData) {
            vm.checkAllChecked() ? vm.selectAllToggle = true: vm.selectAllToggle = false;
        }

        vm.checkAllChecked = function() {
            return  _.every(vm.formCheckbox);
        }


        vm.selectAll = function() {
            if(vm.formCheckbox.length ===0) return;

            for(let i = 0;i<vm.formCheckbox.length; i++) {
                if(vm.selectAllToggle)
                {
                    vm.formCheckbox[i] = true;
                }
                else{
                    vm.formCheckbox[i] = false;
                }
            }
            vm.showErrors = true;
            $timeout(function() {
                getData(true);
            },300)
        }

        vm.refresh = function() {
            
            loadErrorList();
        }
    

        function loadErrorList() {
            vm.errors = null;
            vm.loadingErrors = true;
            GridFactory.getDataCustom(GENERAL_CONFIG.base_url + "/loadGridJson?grid_id=5779&action_code=9cv61f", { submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID, key_id: null }).then(function (data) {
                vm.loadingErrors = false;
                if (data.callSuccess === "1") {
                    if (data.data.length > 0 && data.data[0].FORM === "IS_NOT_FIRST_RUN") {
                        const isNotFirstRun = data.data.splice(0, 1);
                        vm.firstRun = !isNotFirstRun[0].ERROR_COUNT;
                    }
                    vm.errors = data.data;

                    if (vm.formCheckbox.length === 0) {
                        for (let i = 0; i < vm.errors.length; i++) {
                            vm.formCheckbox.push(false);
                        }
                    }
                    console.log(data);

                    vm.selectAll();

                    if($scope?.$parent?.ctrl?.steps){

                        let status = 'FAILED';
                        if (data.data.length === 0) {
                            status = 'RESOLVED'
                        }

                        JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=q05d2n',
                        {
                            sso_id: USER_SETTINGS.user.sso_id,
                            submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID,
                            step_id: $scope.$parent.ctrl.steps.find(s => s.STATE_URL === $state.current.name).WORKFLOW_STEP_ID,
                            step_status: status
                        }).then(function (response) {

                            if (response.callSuccess === "1") {
                                $rootScope.$emit("reload-steps");
                            }
                        });
                    }
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }
    }
 
    function XMLConsolUploadController(EfileFactory,USER_SETTINGS,AlertService,JsonObjectFactory,GENERAL_CONFIG,$compile,$scope){
        var vm = this;
        vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';
        vm.taxYear = EfileFactory.selectedSubmission.TAX_YEAR;
        
        function clearInputs(){
            vm.fileName = '';
        }
        vm.uploadXMLKeyId = function () {
            let file = vm.xmlFile;
            vm.loading = true;
            EfileFactory.uploadXMLKeyId(EfileFactory.selectedSubmission.SUBMISSION_ID, vm.xmlFileName,  vm.xmlFile,vm.taxYear).then((res) => {
                vm.loading = false;
                if(res.data.callSuccess === "1"){
                    AlertService.add('success', "The xml was successfully uploaded!");
                    vm.options.data = null;
                    clearInputs();
                    
                }
                else{
                    AlertService.add('error', res.data.errorMessage);
                }
            }).catch((err) => {
                AlertService.add('error', "Something went wrong! Please try again.");
                vm.loading = false;
            });

        }
        
            }
    function DeleteFormController(EfileFactory,USER_SETTINGS,AlertService,JsonObjectFactory,GENERAL_CONFIG,$compile,$scope){
        console.log("inside delete form controller");   
        var vm = this;
        vm.taxYear = EfileFactory.selectedSubmission.TAX_YEAR;
        vm.submissionId = EfileFactory.selectedSubmission.submission_id;
        vm.options = {
                extraOptions:{
                    componentSettings:{
                        tableOptions:{
                            searching:true,
                            columns:[
                                { title: 'Element Name', data:'ELEMENT_NAME'},
                                { title: 'SNO', data:'SNO'},
                                { title: 'Element Value', data:'ELEMENT_VALUE'},
                                
                                ],
                            fnCreatedRow:function(row, data, index) {
                                $compile(row)($scope);//remove this if data is huge
                            }
                        }
                    }
                }
            }
        vm.formChanged =  function (formIdVal) {
            vm.loadingGrid = true;
            console.log(formIdVal);
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=sd6tf4',
                {
                    form_id : formIdVal,
                    sso_id : USER_SETTINGS.user.sso_id
                }).then(function (response) {
               	 console.log("----------------------"+JSON.stringify(response));
                    vm.loadingGrid = false;
                    vm.options.data = response.jsonObject;
                });
        }
         function loadForms(){
       	  console.log("load the drop down menu");
       	  vm.loadingGrid = true;
             JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=tfmy4n',
                 {
           	  	submission_id : EfileFactory.selectedSubmission.SUBMISSION_ID,
                    sso_id : USER_SETTINGS.user.sso_id
                 }).then(function (response) {
               	  $scope.availableList = response.jsonObject;
               	  vm.loadingGrid = false;
               	  
                 });
         }
       loadForms();
        vm.deleteForm = function () {
            let file = vm.pdfFile;
            vm.loading = true;
            vm.subStatus = EfileFactory.selectedSubmission.SUBMISSION_STATUS;
            vm.PRODUCTION_ACCEPTED = 'Production Accepted';
                   
            EfileFactory.deleteForm(EfileFactory.selectedSubmission.SUBMISSION_ID,vm.formId).then((res) => {
                vm.loading = false;
                if(res.data.callSuccess === "1"){
                    AlertService.add('success', "The form was successfully deleted!");
                    vm.options.data = null;
                    loadForms();
                }
                else{
                    AlertService.add('error', res.data.errorMessage);
                }
            }).catch((err) => {
                AlertService.add('error', "Something went wrong! Please try again.");
                vm.loading = false;
            });
            
        }

       }
    function PDFUploadController(EfileFactory,USER_SETTINGS,AlertService,JsonObjectFactory,GENERAL_CONFIG,$compile,$scope){
        var vm = this;
        vm.currentTheme = USER_SETTINGS.user.selected_theme || 'theme-black';
        vm.taxYear = EfileFactory.selectedSubmission.TAX_YEAR;
        vm.PRODUCTION_ACCEPTED = 'Production Accepted';
        vm.SUBMISSION_STATUS = EfileFactory.selectedSubmission.SUBMISSION_STATUS;
        vm.fileTypeListFromDb;
        var sigDoc = false;
        vm.show = false;
        vm.readOnlyField = false;
        vm.options = {
            extraOptions:{
                componentSettings:{
                    tableOptions:{
                        searching:true,
                        columns:[
                            { title: 'File Name', data:'FILE_NAME'},
                            { title: 'Comments', data:'COMMENTS'},
                            { title: 'Created By', data:'USER_NAME'},
                            { title: 'Created Date', data:'FORMATTED_CREATE_DATE'},
                            { title: 'Actions',className:'text-center', data:null,render:function(data, type, row, meta) {
                                return `<i ng-if="!ctrl.options.data[${meta.row}].loadingPdf" class="fa fa-file-pdf-o" ng-click="ctrl.viewPdf('${data.FILE_NAME}')"></i>
                                        <i ng-if="!ctrl.options.data[${meta.row}].loadingPdf && ctrl.SUBMISSION_STATUS !== ctrl.PRODUCTION_ACCEPTED" class="fa fa-remove" ng-click="ctrl.deletePdf('${data.FILE_NAME}')"></i>
                                        <div ng-if="ctrl.options.data[${meta.row}].loadingPdf">
                                            <i class="fa fa-spinner fa-spin"></i>
                                        </div>`
                            }}
                        ],
                        fnCreatedRow:function(row, data, index) {
                            $compile(row)($scope);//remove this if data is huge
                        }
                    }
                }
            }
        }
        function clearInputs(){
            vm.fileType = '';
            vm.fileName = '';
            vm.comments = '';
            vm.otherFileType = '';
            vm.isSignatureDocument = false;
            $("input[type='file']").val('');
        }
        vm.uploadPdf = function () {
            let file = vm.pdfFile;
            vm.loading = true;
            vm.subStatus = EfileFactory.selectedSubmission.SUBMISSION_STATUS;
            vm.PRODUCTION_ACCEPTED = 'Production Accepted';
            
            /* If the entered file name does not have the .pdf extension at the end, add it. */
            if(vm.fileName != null) {
            	var tokens = vm.fileName.split(".");
            	if(tokens == null || tokens.length < 1 || tokens.pop().toLowerCase() != 'pdf') {
            		vm.fileName += ".pdf";
            	}
            }
            
            EfileFactory.uploadPdf(EfileFactory.selectedSubmission.SUBMISSION_ID,vm.fileType, vm.fileName, vm.comments, file, vm.isSignatureDocument).then((res) => {
                vm.loading = false;
                if(res.data.callSuccess === "1"){
                    AlertService.add('success', "The PDF was successfully uploaded!");
                    vm.options.data = null;
                    clearInputs();
                    loadGrid();
                }
                else{
                    AlertService.add('error', res.data.errorMessage);
                }
            }).catch((err) => {
                AlertService.add('error', "Something went wrong! Please try again.");
                vm.loading = false;
                clearInputs();
            });

        }
        vm.viewPdf = function (fileName) {
            if(vm.loadingPdf) return;
            let row = _.find(vm.options.data,["FILE_NAME", fileName]);
            row.loadingPdf = true;
            EfileFactory.viewPdf(EfileFactory.selectedSubmission.SUBMISSION_ID, fileName).then((res) => {
                row.loadingPdf = false;
                var file = new Blob([res.data], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL,"_blank", 'toolbar=0,location=0,menubar=0,height='+window.outerHeight);
            }).catch((err) => {
                AlertService.add('error', "Something went wrong! Please try again.");
                row.loadingPdf = false;
            });

        }
        vm.deletePdf = function (fileName) {
            if(vm.loadingPdf) return;
            let row = _.find(vm.options.data,["FILE_NAME", fileName]);
            row.loadingPdf = true;
            EfileFactory.deletePdf(EfileFactory.selectedSubmission.SUBMISSION_ID, fileName).then((res) => {
                row.loadingPdf = false;
                if(res.data.callSuccess === "1"){
                    AlertService.add('success', "The PDF was successfully deleted!");
                    vm.options.data = null;
                    loadGrid();
                }
                else{
                    AlertService.add('error', res.data.errorMessage);
                }
            }).catch((err) => {
                AlertService.add('error', "Something went wrong! Please try again.");
                row.loadingPdf = false;
            });

        }

        vm.fileTypeChanged = function(isSignature) {
            vm.fileName = vm.fileType;
            if(vm.fileName == "Other"){
            	if(vm.otherFileType==""){
            	vm.show = true;
            	vm.fileName = '';
                vm.comments = '';
                vm.isSignatureDocument = false;
                vm.pdfFile = null;
            	return;
            	}else{
            		vm.show= true;
            		 vm.fileName = vm.otherFileType;
            		 vm.comments =  vm.otherFileType;
            		 if(vm.otherFileType.match("&")){
            			 AlertService.add('error', "Special character & is not allowed.");
            			 return;
            		 }
            	}
            }else{
            	vm.show = false;
            	 vm.comments = vm.fileTypeList.filter(i => i.ID === vm.fileType)[0].NAME;
            
           
            console.log("************************************"+vm.comments);
            if(vm.fileName == "8453SignatureDoc.pdf" || isSignature) {
            	vm.comments = '8453 Signature Document';
            	vm.isSignatureDocument = true;
            	vm.readOnlyField =true;
            }else{
            	//vm.comments = vm.fileType.replace('.pdf','');
            	vm.isSignatureDocument = false;
                vm.readOnlyField = false;
            }
            }
            if (vm.fileName.length > 65) {                                                                           
                AlertService.add('warning', "Max length of filename is 65,Trimming to 65.");
                vm.fileName = vm.fileName.substr(0, 65);
            }
            if(_.some(vm.options.data,["FILE_NAME",vm.fileName])){
                vm.fileName = vm.fileName.replace('.pdf','') + `(${_.filter(vm.options.data,function(item){
                    return item.FILE_NAME.includes(vm.fileName.replace('.pdf',''));
                }).length + 1}).pdf`;   
            }
 
           EfileFactory.getPdfFileName(EfileFactory.selectedSubmission.SUBMISSION_ID, vm.fileName).then((res) => {
                if(res.data.callSuccess === "1"){
                	vm.fileName = res.data.fileName;
                }
                else{
                    AlertService.add('error', res.data.errorMessage);
                    sigDoc = true;
                }
            }).catch((err) => {
                AlertService.add('error', "Something went wrong! Please try again.");
                sigDoc = true;
            });
            
        }
        
        function loadGrid() {
        	
     	  console.log("load the drop down menu");
     	  vm.loadingGrid = true;
           JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=bmhnvz',
               {
         	  	 sso_id : USER_SETTINGS.user.sso_id
               }).then(function (response) {
             	  vm.fileTypeList = response.jsonObject;
             	 //fileTypeListFromDb = response.jsonObject;
             	  vm.loadingGrid = false;
              });
              
        	
            vm.loadingGrid = true;
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=bma33d',
                {
                    submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID
                }).then(function (response) {
                    vm.loadingGrid = false;
                    vm.options.data = response.jsonObject;
                 //   fileTypeListFromDb = response.jsonObject;
                });
        }

        loadGrid();
    }



    function AddEditController(EfileFactory,USER_SETTINGS,AlertService,JsonObjectFactory,GENERAL_CONFIG,$scope,$timeout,SERVER_CONFIG,GlobalService,$uibModal){

        let vm = this;
        vm.formCheckbox = [];
        vm.all_sources = [];
        
        // Get the selected submission that we can check to see if it is production accepted.
        // If it is, we do not want to show the Save button.
        vm.selectedSubmissionData = EfileFactory.selectedSubmission;
        vm.canDoProdSubmit =  SERVER_CONFIG.clientConfig.efile_prod_submission_users ?
                                        SERVER_CONFIG.clientConfig.efile_prod_submission_users.includes(USER_SETTINGS.user.sso_id) : false;
        vm.PRODUCTION_ACCEPTED = 'Production Accepted';
        vm.LOCKED = 'Locked';

        $scope.$on("efile:expand-view",function() {
            vm.expandView = !vm.expandView;
        })
        
        function loadLookup(actionCode,property,params,loader) {
            vm[loader] = true;
            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject?action_code='+actionCode,params).then(function (response) {
                    vm[loader] = false;
                    vm[property] = response.jsonObject;
                });
        }

        $scope.$on('efile:entity-selected',function(ev,entity) {
            vm.showData = false;
            vm.formCheckbox = [];
            if(vm.selectedEntityType && vm.selectedEntity.ENTITY_TYPE_ID === entity.ENTITY_TYPE_ID){
                return;
            }
            vm.selectedEntity = entity;
            vm.loadForms();

            loadLookup('onozya','allForms',{
                entity_type_id:vm.selectedEntity.ENTITY_TYPE_ID,
                schema_set_id:EfileFactory.selectedSubmission.SCHEMA_SET_ID,
            },'loadingAllForms');
        })

        vm.loadForms = function() {
            vm.forms = null;
            return loadLookup('jvpk52','forms',{
                submission_id:EfileFactory.selectedSubmission.SUBMISSION_ID,
                key_id:vm.selectedEntity.KEY_ID,
            },'loadingForms').then((response)=>{
                vm.uniqForms = _.chain(vm.forms)
                                    // .map(item=>_.pick(item,"FORM"))
                                    .uniqBy("FORM")
                                    .value();

                vm.formsMap = _.groupBy(vm.forms,"FORM");
            });
        }

        // vm.elemSelected = function() {
        //     loadLookup('87lggh','seqs',{
        //         form:vm.selectedForm,
        //         submission_id:EfileFactory.selectedSubmission.SUBMISSION_ID,
        //         key_id:vm.selectedEntity.KEY_ID,
        //         form_sub:vm.form_sub,
        //         parent_element_name:vm.parent_element_name,
        //     },'loadingSeqs');
        // }
        $scope.treeData = {
            totalTreeData :[],
            cachedTreeData: []
        }
        var listIndx = 0;
        vm.loadForm = function() {
            vm.formTree = [];
            vm.changes = [];
            if(!vm.selectedForm || !vm.form_sub || !vm.parent_element_name){
                return;
            }
            loadLookup('s3hr77','formData',{
                form:vm.selectedForm,
                submission_id:EfileFactory.selectedSubmission.SUBMISSION_ID,
                key_id:vm.selectedEntity.KEY_ID,
                form_sub:vm.form_sub,
                parent_element_name:vm.parent_element_name,
                psq_no:0
            },'loadingForm').then(function() {
                $scope.treeData = {
                    totalTreeData :[],
                    cachedTreeData: []
                }
                const DOT = '.';
               
                vm.formId = _.chain(vm.formData).map("FORM_ID").uniq().compact().value();

                let clickedForm = _.find(vm.uniqForms,["FORM",vm.selectedForm]);
                if(!clickedForm){
                    vm.forms.unshift({
                        FORM:vm.selectedForm,
                        isActive: true,
                        unsaved:true
                    })
                }
                
                for (let i = 0; i < vm.formData.length; i++) {
                    const element = vm.formData[i];
                    
                    let nodeNames = element.ELEMENTNAME.split(DOT);
                    let treeIds = element.TREEID.split(DOT);
                    let maxOccurs = element.TREEMAXOCCURS.split(DOT);
                    let isRequired = element.ISREQUIRED.split(DOT);

                    for (let j = 0; j < nodeNames.length; j++) {
                        const nodeName = nodeNames[j];

                        

                        if(vm.formTree.length > 1 &&
                                vm.formTree[vm.formTree.length - 1].isTable //for is repeated
                                && element.ELEMENTNAME.includes(vm.formTree[vm.formTree.length - 1].ELEMENTNAME)
                                && _.some(maxOccurs,item =>  item != '1')//establishes parent child relation
                            ){
                            //Case 2: repeated data
                            

                            if(j === nodeNames.length - 1){
                                createTableHeader(vm.formTree,nodeName, element, nodeNames,maxOccurs,i, j, isRequired);
                                
                                if(!element.SNO.includes(".") && (maxOccurs[j] === '1' || nodeNames.length === 2)){
                                    console.debug("SNo does not contain . so creating repeated Data object",element.ELEMENTNAME,element.SNO);
                                    vm.formTree[vm.formTree.length - 1].repeatedData[element.SNO] = 
                                        vm.formTree[vm.formTree.length - 1].repeatedData[element.SNO] || {};
                                    vm.formTree[vm.formTree.length - 1].repeatedData[element.SNO]["SNO"] = element.SNO;
                                    vm.formTree[vm.formTree.length - 1].repeatedData[element.SNO]["MAX_OCCURS"] = maxOccurs[j] !== '1'? maxOccurs[j] : maxOccurs[j-1];

                                }
                                if(vm.formTree[vm.formTree.length - 1].ELEMENTNAME.includes(nodeNames[j - 1])  && (maxOccurs[j] === '1' || nodeNames.length === 2) ){
                                    console.debug("max occurs is 1 so pushing a normal element to repeated data object",element.ELEMENTNAME)
                                    vm.formTree[vm.formTree.length - 1].repeatedData[element.SNO][nodeName] = {
                                        ELEMENTNAME:nodeName,
                                        FULLELEMENTNAME:element.ELEMENTNAME,
                                        ELEMENTVALUE: element.ELEMENTVALUE,                                    
                                        SNO:element.SNO,
                                        MAXLENGTH: element.MAXLENGTH,
                                        VALIDATIONEXPLANATION:element.VALIDATIONEXPLANATION,
                                        isRequired:isRequired[j] === 'REQ',
                                        VALIDATIONPATTERN:element.VALIDATIONPATTERN //? element.VALIDATIONPATTERN.replace(/&/g, "&amp;").replace(/-#/g, "\\-#").split("apos").join("'") : ''
                                    };
                                }else if(vm.formTree[vm.formTree.length - 1].ELEMENTNAME.includes(nodeNames[j - 1])  && maxOccurs[j] !== '1' ){
                                    //direct single column repeating child
                                    console.debug("max occurs is not 1 but the node parent("+nodeNames[j - 1]+") is included in the last inserted table so creating child for column",element.ELEMENTNAME);
                                    createPopupElement(element, maxOccurs, j, nodeNames, nodeName, isRequired);
                                }else{
                                    //repeating child under a different group
                                    console.debug("max occurs is not 1 and the parent("+nodeNames[j - 1]+") of node is not included in last inserted parent so creating a new group column",element.ELEMENTNAME);
                                    createPopupElement(element, maxOccurs, j-1, nodeNames, nodeName, isRequired);
                                }
                            }else{

                            }

                                
                                
                        }else if(j === nodeNames.length - 1 ){
                            console.debug("Inserting normal element row",element.ELEMENTNAME);
                            //case 1: Single row without repetition
                                //push full object if it is at the last split in "Return.ReturnHeader.*subsidiaryReturnCount*"
                                element.ENUMVALUES && (element.ENUMVALUES = " ;"+element.ENUMVALUES);//for extra empty entre on top
                                let elemObject = _.merge(element,{
                                    ELEMENTNAME:nodeName,
                                    FULLELEMENTNAME:element.ELEMENTNAME,
                                    ENUMVALUES: element.ENUMVALUES ? element.ENUMVALUES.split(';') : undefined,
                                    level:j+1,
                                    isRequired:isRequired[j] === 'REQ',
                                    MAXLENGTH: element.MAXLENGTH,
                                    VALIDATIONPATTERN:element.VALIDATIONPATTERN, //? element.VALIDATIONPATTERN.replace(/&/g, "&amp;").replace(/-#/g, "\\-#").split("apos").join("'") : ''
                                    VALIDATIONEXPLANATION:element.VALIDATIONEXPLANATION 
                                });
                                vm.formTree.push(elemObject);
                        }else if(
                            _.findIndex(vm.formTree,["ELEMENTNAME",nodeName]) === -1 ||
                            (maxOccurs[j + 1] != '1' && !vm.formTree[vm.formTree.length - 1].isHeader)
                        ){
                            console.debug("creating a header row",element.ELEMENTNAME)
                            let headerObject = {
                                ELEMENTNAME:nodeName,
                                FULLELEMENTNAME:element.ELEMENTNAME,
                                isHeader:true,
                                level:j+1
                            };
                            if(maxOccurs[j] != '1' || (j + 1 === maxOccurs.length -1  && maxOccurs[j + 1] != '1') ){
                                //Insert repeated data parent
                                headerObject.isTable = true;
                                headerObject.repeatedData = [];
                                headerObject.tableHeaders = [];//
                                console.debug("note: header row is repeated",element.ELEMENTNAME)
                            }
                            vm.formTree.push(headerObject);
                        }

                    }
                }
                console.log("Total Record Count -->",vm.formData.length);
                console.log("Form tree -->",vm.formTree)
                 $scope.treeData.totalTreeData = vm.formTree;
                $scope.lazyEfileLoadList(1);
                console.log("Form tree -->",vm.formTree)
            });
        }
        vm.fieldEdited = function(dataRow,control) {
            if(!control.$dirty)return;
            const ELEMENTNAME = control.$name.split("_")[0];
            //const SNO = control.$name.split("_")[1] || "0";
            const SNO = control.$name.split("_")[1]  || "0";
            vm.changes = vm.changes || [];
            const index = _.findIndex(vm.changes,item => item.elementName === ELEMENTNAME && item.sno === SNO);
            if(index != -1){
                vm.changes[index].elementValue = control.$modelValue ? control.$modelValue.trim() : undefined;
                return;
            }
            vm.changes.push({
                elementName:ELEMENTNAME,
                sno:SNO,
                elementValue: control.$modelValue ? control.$modelValue.trim() : undefined
            })
        }
        vm.addOrOpenChildRow = function(repData,repHeadBody,parentRepeatedData) {
            repData.currentParentNode= repHeadBody.nodeName;//(repData.currentParentNode && repData.currentParentNode === repHeadBody.nodeName? null : repHeadBody.nodeName);

            // if(!repData.currentParentNode) return;
            let i = 0;
            let firstParentRow = null;
            do{
                firstParentRow = angular.copy(parentRepeatedData[i]);
                i++;
            }while(!firstParentRow[repData.currentParentNode]);

            if(repData.currentParentNode && !repData[repData.currentParentNode]){
                //no child rows


                let firstRow = null;//angular.copy(firstParentRow[repData.currentParentNode].repeatedData[0]);
                let j = 0;
                do{
                    firstRow = angular.copy(firstParentRow[repData.currentParentNode].repeatedData[j]);
                    j++;
                }while(!firstRow);

                const SNOParts = firstRow.SNO.split('.');
                firstRow.SNO = SNOParts.length > 1 ? repData.SNO +'.'+ SNOParts[1] : repData.SNO;
                for(let elementName in firstRow){
                    if(!firstRow[elementName] || typeof firstRow[elementName] !== "object" ) continue;
                    firstRow[elementName].ELEMENTVALUE = undefined;
                    firstRow[elementName].SNO = firstRow.SNO;
                }    
                // SNOParts.unshift((parseInt(SNOParts.shift()) + 1))
                const newChildRow =  {
                     isTable:true,
                     isHeader:true,
                     SNO:repData.SNO,
                     tableHeaders:firstParentRow[repData.currentParentNode].tableHeaders,
                     repeatedData:[firstRow]
                 };
                 repData[repData.currentParentNode] = newChildRow;
            }
            (repData[repData.currentParentNode].MAX_OCCURS && repData[repData.currentParentNode].MAX_OCCURS != "1") 
                && (_.last(repData[repData.currentParentNode].repeatedData).IS_REPEATED = true);
            repData[repData.currentParentNode].tableHeaders = firstParentRow[repData.currentParentNode].tableHeaders;//_.unionBy(repData[repData.currentParentNode].tableHeaders,firstParentRow[repData.currentParentNode].tableHeaders,'nodeName');

            vm.currentNodeInPopup = repData[repData.currentParentNode];
            vm.popUpTitle = vm.currentNodeInPopup.SNO+' - '+ repData.currentParentNode;
            $uibModal.open({
                animation: true,
                templateUrl: 'app/components/efile/efileElements/data/add-edit-inner-grid.html',
                // controller: [function(){

                // }],
                scope:$scope,
                size: 'md',
                // resolve: {
                //     data: function () {
                //         return repData[repData.currentParentNode];
                //     },
                //     addRepeatedData:function(){
                //         return vm.addRepeatedData;
                //     }
                // }
            });
        }

      
        $scope.lazyEfileLoadList = function(firstTime){
            var count = 0;
            vm.isscrolling = true;
                _.each($scope.treeData.totalTreeData,function(d,index){
                        if(d && d.repeatedData){
                            let localRepeatedData = d.repeatedData.filter(function(e){return e!==undefined});
                            d.cachedRepeatedData = _.clone(localRepeatedData);
                            if(d.repeatedData && d.repeatedData.length>5){
                                d.visibleRepeatedData = localRepeatedData.slice(0,9);
                            }else{
                                d.visibleRepeatedData = d.cachedRepeatedData;
                            }
                           d.height =  (localRepeatedData.length)  * 20 ;
                           d.visibleRepeatedDataLength = d.visibleRepeatedData.length;

                            $scope.treeData.cachedTreeData[index] = d;
                        }
                        else{       
                            $scope.treeData.cachedTreeData.push(d);
                            count++;
                        }
                        return;
                });
                console.log('$scope.treeData',$scope.treeData);
        }
        vm.id = '';
        $scope.$on('tableScroll',function(event,data){
            setTimeout(function(){
                if(data && data.data && data.data['FULLELEMENTNAME'] != undefined){
                    vm.id = data.data['FULLELEMENTNAME']
                    vm.startRowHeight = data.startNodeIndex< 20 ? 0 : data.startNodeHeight;//data.startNodeHeight >50 ? data.startNodeHeight : 0;
                    vm.endRowHeight = data.endRowHeight;
                    if(data.visibleData && data.visibleData.length>0){
                        _.find($scope.treeData.cachedTreeData,function(key,i){
                            if(key['FULLELEMENTNAME'] == vm.id && key.isTable){
                             key.visibleRepeatedData = data.visibleData;//visibleList;
                             data.data = key;
                            }
                        });
                    }
                    console.log('event',data);
                    $scope.$apply();          
                }                   
            },500)            
        })
        vm.deleteRow = function(addEditForm,row,dataRow) {      
            let parentName = '';     
            vm.dataObj = _.clone(dataRow); 
            for(let elementName in row){
                if(!row[elementName] || typeof row[elementName] !== "object" ) continue;
                if(addEditForm[row[elementName].FULLELEMENTNAME +'_'+ row.SNO]){
                    addEditForm[row[elementName].FULLELEMENTNAME +'_'+ row.SNO].$setDirty();
                }
                if(row[elementName].isTable){
                    for (let i = 0; i < row[elementName].repeatedData.length; i++) {
                        if(!row[elementName].repeatedData[i]) continue;
                        vm.deleteRow(addEditForm,row[elementName].repeatedData[i],vm.dataObj);                       
                    }
                }else{
                    row[elementName].ELEMENTVALUE = "";
                    parentName = row[elementName].FULLELEMENTNAME;
                    vm.fieldEdited(row[elementName],{$name:row[elementName].FULLELEMENTNAME +'_'+ row.SNO,$dirty:true});
                }
            }
            row.toDelete = true;
            let obj = {
                "dataRow": vm.dataObj,
                "row": row
            }
            console.log('dataRow dataObj',obj);
            vm.scrollToBottom('','delete',obj)
        }
        vm.viewStyleSheet = function(){
            console.log("inside view stylesheet");
            AlertService.add("success","Generating StyleSheet.Please wait..",4000);
            EfileFactory.viewStyleSheet(EfileFactory.selectedSubmission.SUBMISSION_ID,vm.formId).then((response)=>{
               let baseUrl = GENERAL_CONFIG.base_url.replace("/gateway","");
               baseUrl = GENERAL_CONFIG.base_url.replace("com/gtw","com"); //For Azure purposes only
               response.data.html = response.data.html.replace(/mef\//g, baseUrl+"/assets/img/efile/mef/")
               const winUrl = URL.createObjectURL(
               new Blob([response.data.html], { type: "text/html" })
            );
            var win = window.open(winUrl, "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=800");
            })
        }

        vm.askComment = function(addEditForm) {
            //Comment out the check for invalid fields before allowing a save.
            if (addEditForm.$invalid) {
                angular.forEach(addEditForm.$error, function (field) {
                    angular.forEach(field, function(errorField){
                        errorField.$setTouched();
                    })
                });

                AlertService.add("warn","Please fix the data errors shown below!",4000);
                return;
            }
            
            const commentResult = $uibModal.open({
                templateUrl: 'app/components/efile/efileElements/data/comments-modal-tpl.html',
                size:'sm',
                controllerAs:'ctrl',
                controller: ['$uibModalInstance',
                    function($uibModalInstance) {
                        const vm = this;

                        vm.save = function () {
                            $uibModalInstance.close(vm.comment);
                        }
                        vm.cancel = function () {
                            $uibModalInstance.dismiss();
                        }
                    }
                ]
            });

            commentResult.result.then(function (comment) {
                vm.saveForm(addEditForm,comment);
            });
        }
        
        vm.saveForm = function(addEditForm,comment) {
            console.log(vm.changes);



//            let toDelete = _.pickBy(addEditForm,item=>item && item.$dirty && item.$modelValue.trim() === "");
//            let toSave = _.pickBy(addEditForm,item=>item && item.$dirty && item.$modelValue.trim() !== "");
            // let toDelete = _.pickBy(addEditForm,item=>item && item.$dirty && (!item.$modelValue || item.$modelValue.trim() === ""));
            // let toSave = _.pickBy(addEditForm,item=>item && item.$dirty && (item.$modelValue && item.$modelValue.trim() !== ""));
            
            // if(_.isEmpty(toSave) && _.isEmpty(toDelete)){
            if(vm.changes.length === 0){
                AlertService.add("warn","No changes found to save!",4000);
                return;
            }
            // let changes = [];


            // for(let elementName in toDelete){
            //     let elem_sno = elementName.split("_");
            //     changes.push({
            //         elementName:elem_sno[0],//for repeated data split with _ to remove SNO
            //         sno:(elem_sno.length > 1 ? elem_sno[1] :"0")//until repeated data is implemented.
            //     })
            // }
            // for(let elementName in toSave){
            //     let elem_sno = elementName.split("_");

            //     changes.push({
            //         elementName:elem_sno[0],//for repeated data split with _ to remove SNO
            //         sno:(elem_sno.length > 1 ? elem_sno[1] :"0"),//until repeated data is implemented.
            //         elementValue:toSave[elementName].$modelValue
            //     })
            // }
            vm.saving = true;

            let clob_data = {
                submissionId: EfileFactory.selectedSubmission.SUBMISSION_ID,
                processName:'SAVE_EFILE_ELEMENT_OVERRIDE',
                keyId:vm.selectedEntity.KEY_ID,
                form:vm.selectedForm,
                formSub:vm.form_sub,
                comment:comment,
                pen:vm.parent_element_name,
                formId:vm.formId.length > 0 ? vm.formId[0] : '',
                parentSno:'0',//null?
                changes:vm.changes
            }

            JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=rv8nq0',
            {
                clob_settings:JSON.stringify({
                    tax_year:EfileFactory.selectedSubmission.TAX_YEAR,
                    scenario:_.find(GlobalService.inputs.tax_year.values,["value",EfileFactory.selectedSubmission.TAX_YEAR.toString()]).scenario,
                    jcd_key:250,
                }),
                tax_year:EfileFactory.selectedSubmission.TAX_YEAR,
                scenario:_.find(GlobalService.inputs.tax_year.values,["value",EfileFactory.selectedSubmission.TAX_YEAR.toString()]).scenario,
                jcd_key:250,
                clob_data:JSON.stringify(clob_data),
                sso_id:USER_SETTINGS.user.sso_id,
            }).then(function (response) {
                let callSuccess = response.callSuccess;
                if(callSuccess==="0"){
                    AlertService.add("error","Something went wrong while saving the data!",4000);
                }else{
                    vm.changes = [];
                    AlertService.add("success","Data saved successfully!",4000);
                    if(vm.formId.length > 0){
                        vm.loadForm();
                    }else{
                        vm.loadForms().then(()=>vm.loadForm());
                    }
                }
                vm.saving = false;
                vm.lockEdit = true;
                // $scope.$apply();
            });

        }

        vm.removeQoutes = function(desc) {
         return desc ? desc.replace(new RegExp('"','g'),'') : '';   
        }

        vm.addRepeatedData = function(data) {
            let nonDeletedRows = _.filter(data.repeatedData,r=>r && !r.toDelete);
            let newRow = angular.copy(nonDeletedRows.length ===0 ? data.repeatedData[0] : nonDeletedRows[nonDeletedRows.length - 1]);
            const originalSNO = newRow.SNO;
            const SNOParts = newRow.SNO.split('.');
            // if(newRow.MAX_OCCURS && newRow.MAX_OCCURS != "n" && SNOParts[SNOParts.length - 1 ] == parseInt(newRow.MAX_OCCURS) - 1){
            //     return;
            // }
            SNOParts.push((parseInt(SNOParts.pop()) + 1));
            newRow.SNO = nonDeletedRows.length ===0 ? '0' : SNOParts.join('.');
            for(let elementName in newRow){

                if(!newRow[elementName] || typeof newRow[elementName] !== "object" ) continue;

                newRow[elementName].ELEMENTVALUE = undefined;
                newRow[elementName].SNO = newRow.SNO;
                if(newRow[elementName].isTable) newRow[elementName] = undefined;
            }
            newRow.toDelete = undefined;
            data.repeatedData.push(newRow);
                 
            let localRepeatedData = data.repeatedData.filter(function(e){return e!==undefined});
            data.cachedRepeatedData = localRepeatedData;      
            vm.scrollToBottom(data,'add');            
        }
        vm.scrollToBottom = function(data,from,row){
            let count=0, limit = 10, arr = [];
            if(from == 'add'){
                let start = data.repeatedData.length-1;
               
              //  while(start <= data.repeatedData.length && count < limit && start > -1 && count > -1){
                while(start <= data.repeatedData.length && count < limit && start > -1 && count > -1){
                    if(data.repeatedData[start]!=undefined){
                        arr.unshift(data.repeatedData[start]);
                        if(data.repeatedData[start]['toDelete']){
                            limit++;
                        }
                        start --;
                        count++;
                    }else{
                        start --;

                    }
                }
                data.visibleRepeatedData = arr;    
            }else if(from == 'delete'){
                let cachedRepeatedData = row.dataRow.repeatedData.filter(function(e){return e!==undefined});
                row.dataRow.cachedRepeatedData = cachedRepeatedData;
                let start = row.dataRow.visibleRepeatedData[0]['SNO']-1;

                for(var i=0;i< row.dataRow.visibleRepeatedData.length;i++){
                    if(row.dataRow.visibleRepeatedData[i]['SNO'] == row['SNO']){
                        row.dataRow.visibleRepeatedData[i]['toDelete'] = row.toDelete;
                        _.each(row.dataRow.repeatedData,function(item){
                            if(item['SNO'] == row['SNO']){
                                item['toDelete'] = row['toDelete'];
                            }
                        })
                    }

                }
                let deletedRowCount = _.filter(row.dataRow.visibleRepeatedData,{'toDelete':true});
                let last_item = _.last(row.dataRow.visibleRepeatedData);//row.dataRow.visibleRepeatedData.length - 1;
                console.log('start',start);
                console.log('lastlast',last_item);
                /* 
                case visibleRepeated.length != repeatedData.length
                        check the start of the visibleRepeatedData =0
                        push next elements till reach the limit                      
                i*/
               if(row.dataRow.visibleRepeatedData.length != row.dataRow.repeatedData.length){
                   let count = 1;
                   let lastSNO = parseInt(row.dataRow.repeatedData[last_item.SNO]['SNO']) + 1;
                   let visibleNonDeletedCount = _.filter(row.dataRow.visibleRepeatedData,function(i) {
                                                    return i['toDelete'] == undefined
                                                }).length;
                                        
                                                console.log('visibleNonDeletedCount',visibleNonDeletedCount);
                    if(deletedRowCount.length>0){
                            if(lastSNO <= row.dataRow.repeatedData.length-1 && count>0) {
                                /* if(_.filter(row.dataRow.visibleRepeatedData,{'SNO':row.dataRow.repeatedData[lastSNO]['SNO']}).length == 0){
                                    row.dataRow.visibleRepeatedData.push(row.dataRow.repeatedData[lastSNO]);
                                    count--;
                                    lastSNO++;
                                } */
                               // while(lastSNO <= row.dataRow.repeatedData.length-1 > -1 && count>-1){
                                while(lastSNO <= row.dataRow.repeatedData.length-1 > -1 && visibleNonDeletedCount < 10){
                                    if(row.dataRow.repeatedData[lastSNO] && 
                                        row.dataRow.repeatedData[lastSNO]['SNO'] != undefined ){
                                        if(_.filter(row.dataRow.visibleRepeatedData,{'SNO':row.dataRow.repeatedData[lastSNO]['SNO']}).length == 0
                                            && visibleNonDeletedCount <= 10){
                                            row.dataRow.visibleRepeatedData.push(row.dataRow.repeatedData[lastSNO]);  
                                            lastSNO++;
                                        }
                                       // count--;
                                       visibleNonDeletedCount ++;
                                    }else{
                                        lastSNO++;
                                    }
                                }  
                            }else if(start>0) {
                                let firstPos = start;

                                if(firstPos > -1){
                                  //  while(firstPos >0 && count>-1){
                                    while(firstPos > -1 && visibleNonDeletedCount < 10){
                                        if(row.dataRow.repeatedData[firstPos] && 
                                            row.dataRow.repeatedData[firstPos]['SNO'] != undefined ){
                                            if(_.filter(row.dataRow.visibleRepeatedData,{'SNO':row.dataRow.repeatedData[firstPos]['SNO']}).length == 0
                                                && visibleNonDeletedCount < 10){
                                                row.dataRow.visibleRepeatedData.unshift(row.dataRow.repeatedData[firstPos]);  
                                                firstPos--;
                                            }
                                            visibleNonDeletedCount ++;

                                        }else{
                                            firstPos--;
                                        }
                                    }                                   
                                }
                            }      
                            console.log('$scope.treeData.totalTreeData',row.dataRow);               
                    }
               }               
            }   

        }
        vm.unlockForm = function() {
            vm.lockEdit = false;
        }
        vm.clickIcon = function(mode,form,source) {
            vm.showData = true;
            vm.mode = mode;
            vm.form_sub = '';
            vm.parent_element_name = '';
            vm.currentSource = source;

            if(!form) {
                vm.formTree = [];
                vm.formData = [];
                vm.selectedForm = '';  
                return
            };

            loadLookup('7bkpvk','all_sources',{
                sso_id:USER_SETTINGS.user.sso_id,
            },'loadingSources').then(()=>{
                vm.lockEdit = vm.all_sources.findIndex(s=>s.SOURCE === source) > -1;
            });

            let activeForm = _.find(vm.uniqForms,["isActive",true]);
            activeForm && (activeForm.isActive = false);

            if(mode === 'add'){

                loadLookup('a2j0xa','form_subs',{
                    form:vm.selectedForm,
                    submission_id:EfileFactory.selectedSubmission.SUBMISSION_ID,
                    key_id:vm.selectedEntity.KEY_ID,
                },'loadingSubs').then(function() {
                    if(vm.form_subs.length === 1){
                        vm.form_sub = vm.form_subs[0].FORM_SUB;
                    }
                });
    
                loadLookup('0uem72','parent_elems',{
                    form:vm.selectedForm,
                    entity_type_id:vm.selectedEntity.ENTITY_TYPE_ID,
                },'loadingParentElems').then(function() {
                    if(vm.parent_elems.length === 1){
                        vm.parent_element_name = vm.parent_elems[0].PARENT_ELEMENT_NAME;
                    }
                });
            }else{
                vm.selectedForm = typeof form === 'object' ? (form.FORM) : form;
                let clickedForm = _.find(vm.uniqForms,["FORM",vm.selectedForm]);
                if(clickedForm)
                    clickedForm.isActive = true;


                vm.form_subs = _.map(vm.formsMap[vm.selectedForm],formData => _.pick(formData,"FORM_SUB"));
                vm.parent_elems = _.map(vm.formsMap[vm.selectedForm],formData => _.pick(formData,"PARENT_ELEMENT_NAME"));
                vm.form_sub = vm.form_subs[0].FORM_SUB;//index should always be atleast 1
                vm.parent_element_name = vm.parent_elems[0].PARENT_ELEMENT_NAME;//index should always be atleast 1

                

                vm.loadForm();
            }
        }
        vm.open = function(event) {
            $timeout(function() {
                event.currentTarget.previousElementSibling.children[1].focus();
            })
        }

       

        vm.refresh = function() {
            
            vm.showData = true;
           
        }

        function createPopupElement(element, maxOccurs, j, nodeNames, nodeName, isRequired) {
            const SNOSplit = element.SNO.split('.');
            const parentSNO = SNOSplit[0];
            const childSNO = SNOSplit[SNOSplit.length - 1];
            vm.formTree[vm.formTree.length - 1].repeatedData[parentSNO] = vm.formTree[vm.formTree.length - 1].repeatedData[parentSNO] || {};
            vm.formTree[vm.formTree.length - 1].repeatedData[parentSNO]["SNO"] = parentSNO;
            vm.formTree[vm.formTree.length - 1].repeatedData[parentSNO]["MAX_OCCURS"] = maxOccurs[j - 1];
            //override repeated data column below if inner data found
            vm.formTree[vm.formTree.length - 1].repeatedData[parentSNO][nodeNames[j]] =
                vm.formTree[vm.formTree.length - 1].repeatedData[parentSNO][nodeNames[j]] && vm.formTree[vm.formTree.length - 1].repeatedData[parentSNO][nodeNames[j]].tableHeaders ?
                    vm.formTree[vm.formTree.length - 1].repeatedData[parentSNO][nodeNames[j]] :
                    {
                        ELEMENTNAME: nodeName,
                        FULLELEMENTNAME: element.ELEMENTNAME,
                        ELEMENTVALUE: vm.formTree[vm.formTree.length - 1].repeatedData[parentSNO][nodeNames[j]] ? vm.formTree[vm.formTree.length - 1].repeatedData[parentSNO][nodeNames[j]].ELEMENTVALUE : undefined,
                        isTable: true,
                        isHeader: true,
                        SNO: parentSNO,
                        tableHeaders: [],
                        repeatedData: []
                    };

            if (_.findIndex(vm.formTree[vm.formTree.length - 1].repeatedData[parentSNO][nodeNames[j]].tableHeaders, ["nodeName", nodeName]) === -1) {
                let tableHeaders = {
                    nodeName: nodeName,
                    isRequired: isRequired[j] === 'REQ',
                    fullElementName: element.ELEMENTNAME,
                    ENUMVALUES: element.ENUMVALUES ? element.ENUMVALUES.split(';') : undefined,
                    parentNodeName: null,
                    DESCRIPTION:element.DESCRIPTION,
                    TYPESCHEMA:element.TYPESCHEMA,
                    CHOICEGROUPID:element.CHOICEGROUPID,
                    CHOICESEQ:element.CHOICESEQ,
                };
                vm.formTree[vm.formTree.length - 1].repeatedData[parentSNO][nodeNames[j]].tableHeaders.push(tableHeaders);
            }

            let childFullSNO = parentSNO + '.' + childSNO;
            vm.formTree[vm.formTree.length - 1].repeatedData[parentSNO][nodeNames[j]].repeatedData[childSNO] = vm.formTree[vm.formTree.length - 1].repeatedData[parentSNO][nodeNames[j]].repeatedData[childSNO] || {};
            vm.formTree[vm.formTree.length - 1].repeatedData[parentSNO][nodeNames[j]].repeatedData[childSNO]['SNO'] = maxOccurs[j] != '1' ? childFullSNO : childSNO;
            vm.formTree[vm.formTree.length - 1].repeatedData[parentSNO][nodeNames[j]].repeatedData[childSNO]['IS_REPEATED'] = maxOccurs[j] != '1';
            vm.formTree[vm.formTree.length - 1].repeatedData[parentSNO][nodeNames[j]].repeatedData[childSNO]['MAX_OCCURS'] = maxOccurs[j];
            vm.formTree[vm.formTree.length - 1].repeatedData[parentSNO][nodeNames[j]].repeatedData[childSNO][nodeName] = {
                ELEMENTNAME: nodeName,
                FULLELEMENTNAME: element.ELEMENTNAME,
                ELEMENTVALUE: element.ELEMENTVALUE,
                isRequired: isRequired[j] === 'REQ',
                SNO: maxOccurs[j] != '1' ? childFullSNO : childSNO,
                MAXLENGTH: element.MAXLENGTH,
                VALIDATIONPATTERN: element.VALIDATIONPATTERN //? new RegExp(element.VALIDATIONPATTERN.replace(/&/g, "&amp;").replace(/-#/g, "\\-#").split("apos").join("'")) : ''
            };
        }

        function createTableHeader(formTree,nodeName, element, nodeNames,maxOccurs,rowIndex, nodeIndex, isRequired) {
            if (_.findIndex(formTree[formTree.length - 1].tableHeaders, ["nodeName", nodeName]) === -1) {
                console.debug("creating headers for: ",element.ELEMENTNAME);
                element.ENUMVALUES && (element.ENUMVALUES = " ;" + element.ENUMVALUES); //for extra empty entre on top
                let FULLHEADERELEMENTNAME = formTree[formTree.length - 1].FULLELEMENTNAME;
                if (FULLHEADERELEMENTNAME) {
                    FULLHEADERELEMENTNAME = FULLHEADERELEMENTNAME.split(".");
                    while (FULLHEADERELEMENTNAME[FULLHEADERELEMENTNAME.length - 1] !== formTree[formTree.length - 1].ELEMENTNAME) {
                        FULLHEADERELEMENTNAME.pop();
                    }
                    FULLHEADERELEMENTNAME = FULLHEADERELEMENTNAME.join(".");
                }
                let tableHeader = {
                    nodeName: formTree[formTree.length - 1].ELEMENTNAME.includes(nodeNames[nodeIndex - 1])
                        ? nodeName : nodeNames[nodeIndex - 1],
                    isRequired: isRequired[nodeIndex] === 'REQ',
                    ENUMVALUES: element.ENUMVALUES ? element.ENUMVALUES.split(';') : undefined,
                    parentNodeName: 
                        formTree[formTree.length - 1].ELEMENTNAME.includes(nodeNames[nodeIndex - 1]) 
                            &&  (maxOccurs[nodeIndex] === "1" || nodeNames.length === 2)//if the node is immediate repeater under root then dont create group row
                            ? null : nodeNames[nodeIndex - 1],
                    hide: _.filter(formTree[formTree.length - 1].tableHeaders, ["parentNodeName", nodeNames[nodeIndex - 1]]).length > 0,
                    DESCRIPTION:element.DESCRIPTION,
                    TYPESCHEMA:element.TYPESCHEMA,
                    CHOICEGROUPID:element.CHOICEGROUPID,
                    CHOICESEQ:element.CHOICESEQ,
                };
                const headerIndex = _.findIndex(formTree[formTree.length - 1].tableHeaders, ["nodeName", nodeNames[nodeIndex - 1]]);
                if (headerIndex === -1) {
                    tableHeader.fullElementName = FULLHEADERELEMENTNAME + (tableHeader.parentNodeName ? '.' + tableHeader.parentNodeName : '') + '.' + nodeName;
                    formTree[formTree.length - 1].tableHeaders.push(tableHeader); //push only if there is no parent that was pushed.
                } else {
                    tableHeader.fullElementName = FULLHEADERELEMENTNAME + (tableHeader.parentNodeName ? '.' + tableHeader.parentNodeName : '');
                    
                    if(!formTree[formTree.length - 1].tableHeaders[headerIndex].parentNodeName){
                        //parent itself was an element so declaring it its own parent
                        formTree[formTree.length - 1].tableHeaders[headerIndex].parentNodeName = nodeNames[nodeIndex - 1];
                        formTree[formTree.length - 1].tableHeaders[headerIndex].selfEdit = true;
                    }
                }

                if (tableHeader.parentNodeName) {
                    // vm.formTree[vm.formTree.length - 1].showParentHeader = true;
                    // vm.formTree[vm.formTree.length - 1].colspan = vm.formTree[vm.formTree.length - 1].colspan || {};
                    // vm.formTree[vm.formTree.length - 1].colspan[nodeNames[j - 1]] = _.filter(vm.formTree[vm.formTree.length - 1].tableHeaders,["parentNodeName",nodeNames[j - 1]]).length
                }
            }
        }
    }

    function UploadFilesController(EfileFactory,USER_SETTINGS,AlertService,JsonObjectFactory,GENERAL_CONFIG,$compile,$scope){
        let vm = this;
        vm.files = [];
        vm.listPath = '';
        vm.upload = function() {
            vm.uploading = true;
            EfileFactory.uploadFilesToDirectory(vm.listPath,vm.files).then(function(response) {
                vm.uploading = false;
                viewFiles();
            })
        }
        vm.folderClicked = function(file) {
            if(!file.isFolder) return;

            vm.listPath += '/'+file.name;

            viewFiles();
        }
        vm.goBack = function() {
            vm.listPath = vm.listPath.split('/').slice(0, -1).join('/');

            viewFiles();
        }

        viewFiles();

        vm.delete = function(file){
           const pathToDelete = `${vm.listPath}/${file.name}`;
           file.isDeleting = true;
           EfileFactory.delete(pathToDelete).then(function(response){
                viewFiles();
           });

        }

        function viewFiles() {
            vm.loadingFiles = true;
            EfileFactory.viewFiles(vm.listPath).then(function (response) {
                vm.list = response.data.files;
                vm.loadingFiles = false;
            });
        }
    }

    function simpleConfirmationCtrl ($scope,$uibModalInstance,message) {                
        $scope.header = "Confirm";
        $scope.message = message;
        $scope.confirm = function () {
            $uibModalInstance.close();
        }
        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        }

        $scope.$on('$destroy', function () {
            ////////////console.log("ngReally Destroyed");
        });
    }
    
    function sbmsnMnfstAddEditController($rootScope, $scope, TAX_YEARS,EfileFactory, GlobalService, $uibModalInstance, USER_SETTINGS, AlertService, rowData, gridData, JsonObjectFactory, $timeout, workspaceFactory, GENERAL_CONFIG) {
        let vm = this;
        vm.mode = rowData ? 'edit' : 'create';
        vm.title = vm.mode === 'create' ? 'Create Entry' : 'Edit Entry';
        var filters = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
        vm.selectedTaxYear = filters.tax_year;
        console.log("selected tax year for submission manifest addedit controller::::::::::::::::::::::::::::::::;"+vm.selectedTaxYear);
       
       vm.readOnly = false;
       vm.loadSubmissions = function() {
    	   console.log("loading submissions"+vm.selectedTaxYear);
           var params = {
               'action_code': 'pfnojm',
               'sso_id': USER_SETTINGS.user.sso_id,
               'tax_year': vm.selectedTaxYear
           };

           vm.selectedSubId = null;
           vm.submissions= [];
           return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
                                   .then(function (data) {
                                       if (data.callSuccess !== "1") {
                                           AlertService.add("error", data.errorMessage);
                                       } else {
                                           vm.submissions = data.jsonObject;
                                          // console.log(JSON.stringify(vm.submissions));
                                          
                                           if (vm.mode === 'edit') {
                                        	   vm.readOnly = true;
                                        	   vm.submissionId = rowData.SUBMISSION_ID;
                                        	   vm.elementName = rowData.ELEMENT_NAME;
                                               vm.elementValue = rowData.ELEMENT_VALUE;
                                               vm.orderId = rowData.ORDER_ID;
                                               vm.selectedSubId = rowData.SUBMISSION_ID;
                                           }
                                       }
                                   })
                                   .catch((error) => {
                                       console.log(error);
                                       AlertService.add('error', 'Something went wrong while loading submission manifest table!');
                                   })
                                   .finally(() => {
                                       
                                   });
       };
       vm.loadSubmissions();
        function prepareActionCall(mode) {
            const CREATE_ACTION_CODE = 'A';
            const UPDATE_ACTION_CODE = 'E';
            const DELETE_ACTION_CODE = 'D';
            let actionCode;
            if (mode === 'create') {
                actionCode = CREATE_ACTION_CODE;
            } else if (mode === 'edit') {
                actionCode = UPDATE_ACTION_CODE;
            } else {
                actionCode = DELETE_ACTION_CODE;
            }
            const url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=jr60g0';
           
            const clobSettings = [{
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                scenario: workspaceFactory.activeScreen.filters.getFilterParams().scenario,
                jcd_key: workspaceFactory.activeScreen.jcd_key
            }];
            if (vm.mode === 'edit') {
         	   vm.submissionId = rowData.SUBMISSION_ID;
         	}
                       
            const clobData = {
            			'element_name': vm.elementName ?  vm.elementName : rowData.ELEMENT_NAME ,
                        'element_value': vm.elementValue ?vm.elementValue : rowData.ELEMENT_VALUE ,
                        'order_id': vm.orderId,
                        'submission_id': vm.submissionId ? rowData.SUBMISSION_ID : vm.selectedSubId.SUBMISSION_ID };
            
            /* This is used to pass the data in terms of parameters.
             * const filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let params = _.merge({}, GlobalService.globalParams, filterParams);
            params = _.extend(clobData, params);
            //params = _.extend(clobSettings, params);
            params.sso_id = USER_SETTINGS.user.sso_id;*/

            const filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            //let params = _.merge({}, GlobalService.globalParams, filterParams);
            let params = _.merge(GlobalService.isIssueIDEnabled(GlobalService.globalParams.tax_year, GlobalService.globalParams.scenario), GlobalService.globalParams, filterParams);
            params = _.extend({'clob_data': JSON.stringify(clobData)}, params);
            params = _.extend({'clob_settings': JSON.stringify(clobSettings)}, params);
            params.sso_id = USER_SETTINGS.user.sso_id;
            params.mode = actionCode;
            
            return {url: url, params: params};
        }

        vm.save = function () {
        	console.log("inside save method of submission manifest");
            const urlParamsObj = prepareActionCall(vm.mode);
            vm.crudLoading = true;
            vm.saving = true;
            JsonObjectFactory.saveJSON(urlParamsObj.url, urlParamsObj.params)
                             .then((data) => {
                                 if (data.callSuccess === '1') {
                                     AlertService.add('success', 'Data saved successfully.', 4000);
                                     $rootScope.$broadcast('dataFilters:refreshGrid', {
                                         "refresh": true
                                     });
                                     vm.cancel();
                                 } else {
                                     AlertService.add('error', data.errorMessage);
                                 }
                             })
                             .catch((error) => {
                                 console.log(error);
                                 AlertService.add('error', 'Something went wrong while saving data, please try again!');
                             })
                             .finally(() => {
                                 vm.saving = false;
                                 vm.crudLoading = false;
                             });
        };

        vm.delete = function () {
        	console.log("inside delete of submission manifest factory")
            const urlParamsObj = prepareActionCall('delete');
            vm.crudLoading = true;
            vm.deleting = true;
            JsonObjectFactory.saveJSON(urlParamsObj.url, urlParamsObj.params)
                             .then((data) => {
                                 if (data.callSuccess === '1') {
                                     AlertService.add('success', 'Data deleted successfully.', 4000);
                                     $rootScope.$broadcast('dataFilters:refreshGrid', {
                                         "refresh": true
                                     });
                                     vm.cancel();
                                 } else {
                                     AlertService.add('error', data.errorMessage);
                                 }
                             })
                             .catch((error) => {
                                 console.log(error);
                                 AlertService.add('error', 'Something went wrong while deleting the data, please try again!');
                             })
                             .finally(() => {
                                 vm.deleting = true;
                                 vm.crudLoading = false;
                             });
        };
        
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
    
    function keyAddEditController($rootScope, $scope, TAX_YEARS,EfileFactory, GlobalService, $uibModalInstance, USER_SETTINGS, AlertService, rowData, gridData, JsonObjectFactory, $timeout, workspaceFactory, GENERAL_CONFIG) {
        let vm = this;
        vm.mode = rowData ? 'edit' : 'create';
        vm.title = vm.mode === 'create' ? 'Create Entry' : 'Edit Entry';
        var filters = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
        vm.selectedTaxYear = filters.tax_year;
        console.log("selected tax year for key addedit controller::::::::::::::::::::::::::::::::;"+vm.selectedTaxYear);
      
       function getSummaryObj(summaryValue) {
           return vm.submissions.find((ele) => {
        	   const cbcSummaryValue = ele.SUBMISSION_ID;
               return cbcSummaryValue === summaryValue;
           });
       }
       $scope.$watch('ctrl.selectedSubId', (newValue, oldValue) => {
           if (!oldValue && !newValue ) return;
       });
       vm.loadSubmissions = function() {
    	   console.log("loading submissions"+vm.selectedTaxYear);
           var params = {
               'action_code': 'pfnojm',
               'sso_id': USER_SETTINGS.user.sso_id,
               'tax_year': vm.selectedTaxYear
           };

           vm.applyToAllJuris = false;
           vm.hideJurisSelection = false;
           vm.selectedSubId = null;
           vm.submissions= [];
           return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params)
                                   .then(function (data) {
                                       if (data.callSuccess !== "1") {
                                           AlertService.add("error", data.errorMessage);
                                       } else {
                                           vm.submissions = data.jsonObject;
                                          // console.log(JSON.stringify(vm.submissions));
                                          console.log(JSON.stringify(rowData));
                                           if (vm.mode === 'edit') {
                                        	   vm.selectedElement = getSummaryObj(rowData.SUBMISSION_ID);
                                        	   vm.submissionId = rowData.SUBMISSION_ID;
                                        	   vm.selectedSubId = rowData.SUBMISSION_ID;
                                        	   vm.keyId = rowData.KEY_ID;
                                        	   vm.entity = rowData.ENTITY;
                                        	   vm.entitySub = rowData.ENTITY_SUB;
                                        	   vm.parentId = rowData.PARENT_ID;
                                        	   vm.entityTypeId = rowData.ENTITY_TYPE_ID;
                                        	   vm.oracleUserId = rowData.ORACLE_USER_ID;
                                           }
                                       }
                                   })
                                   .catch((error) => {
                                       console.log(error);
                                       AlertService.add('error', 'Something went wrong while loading key table!');
                                   })
                                   .finally(() => {
                                       
                                   });
       };
      
     	   vm.loadSubmissions();
        function prepareActionCall(mode) {
            const CREATE_ACTION_CODE = 'A';//'wkn3u3';
            const UPDATE_ACTION_CODE = 'E';//'tuiuso';
            const DELETE_ACTION_CODE = 'D';//'l4oci6';
            let actionCode;
            if (mode === 'create') {
                actionCode = CREATE_ACTION_CODE;
            } else if (mode === 'edit') {
                actionCode = UPDATE_ACTION_CODE;
            } else {
                actionCode = DELETE_ACTION_CODE;
            }
            const url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=wkn3u3';
           
            const clobSettings = [{
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                scenario: workspaceFactory.activeScreen.filters.getFilterParams().scenario,
                jcd_key: workspaceFactory.activeScreen.jcd_key
            }];
            if (vm.mode === 'edit') {
         	   vm.submissionId = rowData.SUBMISSION_ID;
         	}
                
            const clobData = {
            			'submission_id': vm.submissionId ? rowData.SUBMISSION_ID : vm.selectedSubId.SUBMISSION_ID,
                        'key_id': vm.keyId ? vm.keyId : rowData.keyId,
                        'entity': vm.entity ? vm.entity : rowData.entity,
                        'entity_sub':vm.entitySub ? vm.entitySub : rowData.entitySub,
                        'parent_id':vm.parentId ,
                        'entity_type_id': vm.entityTypeId ? vm.entityTypeId : rowData.entityTypeId,
                        'oracle_user_id' : vm.oracleUserId ? vm.oracleUserId : rowData.oracleUserId,
                        'created_by' : USER_SETTINGS.user.sso_id
            }
            const filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
           // let params = _.merge({}, GlobalService.globalParams, filterParams);
            let params = _.merge(GlobalService.isIssueIDEnabled(GlobalService.globalParams.tax_year, GlobalService.globalParams.scenario), GlobalService.globalParams, filterParams);
            params = _.extend({'clob_data': JSON.stringify(clobData)}, params);
            params = _.extend({'clob_settings': JSON.stringify(clobSettings)}, params);
            params.sso_id = USER_SETTINGS.user.sso_id;
            params.mode = actionCode;
            return {url: url, params: params};
        }

        vm.save = function () {
        	console.log("inside save method of key table ");
            const urlParamsObj = prepareActionCall(vm.mode);
            vm.crudLoading = true;
            vm.saving = true;
            JsonObjectFactory.saveJSON(urlParamsObj.url, urlParamsObj.params)
                             .then((data) => {
                                 if (data.callSuccess === '1') {
                                     AlertService.add('success', 'Data saved successfully.', 4000);
                                     $rootScope.$broadcast('dataFilters:refreshGrid', {
                                         "refresh": true
                                     });
                                     vm.cancel();
                                 } else {
                                     AlertService.add('error', data.errorMessage);
                                 }
                             })
                             .catch((error) => {
                                 console.log(error);
                                 AlertService.add('error', 'Something went wrong while saving data, please try again!');
                             })
                             .finally(() => {
                                 vm.saving = false;
                                 vm.crudLoading = false;
                             });
        };

        vm.delete = function () {
        	console.log("inside delete of key table data ")
            const urlParamsObj = prepareActionCall('delete');
            vm.crudLoading = true;
            vm.deleting = true;
            JsonObjectFactory.saveJSON(urlParamsObj.url, urlParamsObj.params)
                             .then((data) => {
                                 if (data.callSuccess === '1') {
                                     AlertService.add('success', 'Data deleted successfully.', 4000);
                                     $rootScope.$broadcast('dataFilters:refreshGrid', {
                                         "refresh": true
                                     });
                                     vm.cancel();
                                 } else {
                                     AlertService.add('error', data.errorMessage);
                                 }
                             })
                             .catch((error) => {
                                 console.log(error);
                                 AlertService.add('error', 'Something went wrong while deleting the data, please try again!');
                             })
                             .finally(() => {
                                 vm.deleting = true;
                                 vm.crudLoading = false;
                             });
        };
        
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
    
    function sbmsnAddEditController($rootScope, $scope, TAX_YEARS,EfileFactory, GlobalService, $uibModalInstance, USER_SETTINGS, AlertService, rowData, gridData, JsonObjectFactory, $timeout, workspaceFactory, GENERAL_CONFIG) {
        let vm = this;
        vm.mode = rowData ? 'edit' : 'create';
        vm.title = vm.mode === 'create' ? 'Create Entry' : 'Edit Entry';
        var filters = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
        vm.selectedTaxYear = filters.tax_year;
        console.log("selected tax year for submission addedit controller::::::::::::::::::::::::::::::::;"+vm.selectedTaxYear);
        vm.showSub = true;

        vm.submissionData = [];

        vm.headers = workspaceFactory.activeScreen.data.header;
        if(rowData) {
            var keys = Object.keys(rowData);
            var values = Object.values(rowData);
            for(var i in vm.headers) {
                var header = vm.headers[i];
                var label = header.label;
                if( label && label !== null && header.map !== 'CREATED_TS'){
                    let dataObj = {};
                    var value = values[keys.indexOf(header.map)] ? values[keys.indexOf(header.map)] : null;
                    dataObj['key'] = label;
                    dataObj['name'] = header.map.toLowerCase();
                    dataObj['value'] = header.map === 'CREATED_BY' ? USER_SETTINGS.user.sso_id : value;
                    dataObj['disabled'] = header.map === 'SUBMISSION_ID' && vm.mode === 'edit' ? true : false;
                    dataObj['hide'] = header.map === 'SUBMISSION_ID' && vm.mode !== 'edit' ? true : false;
                    vm.submissionData.push(dataObj);
                }
            }
        } else {
            for(var i in vm.headers) {
                var header = vm.headers[i];
                var label = header.label;
                if( label && label !== null && header.map !== 'CREATED_TS'){
                    let dataObj = {};
                    dataObj['key'] = label;
                    dataObj['name'] = header.map.toLowerCase();
                    dataObj['value'] = header.map === 'CREATED_BY' ? USER_SETTINGS.user.sso_id : null;
                    dataObj['disabled'] = false;
                    dataObj['hide'] = header.map === 'SUBMISSION_ID' && vm.mode !== 'edit' ? true : false;
                    vm.submissionData.push(dataObj);
                }
            }
        }
        
        // for(let i in keys) {
        //     if(keys[i] !== 'object_id') {
        //         let obj = {};
        //         obj['key'] = keys[i];
        //         obj['name'] = _.startCase(keys[i].replace("_", " "));
        //         obj['value'] = values[i];
        //         obj['disabled'] = keys[i] === 'SUBMISSION_ID' ? true : false;
        //         obj['hide'] = false;
        //         vm.submissionData.push(obj);
        //     }
        // }

        // if (vm.mode === 'edit') {
	    //        vm.showSub = false;
	        //    vm.submissionId = rowData.SUBMISSION_ID;
	       	//    vm.taxPayer = rowData.TAX_PAYER;
	       	//    vm.returnTypeId = rowData.RETURN_TYPE_ID;
	       	//    vm.consolEntity = rowData.CONSOLIDATED_ENTITY;
	       	//    vm.parentEntity = rowData.PARENT_ENTITY;
	       	//    vm.taxPayerName = rowData.TAX_PAYER_NAME;
	       	//    vm.taxYear = rowData.TAX_YEAR;
	       	//    vm.schemaSetId = rowData.SCHEMA_SET_ID;
	       	//    vm.archInd = rowData.ARCHIVE_IND;
	       	//    vm.useGold = rowData.USE_GOLD;
	       	//    vm.mainConsol = rowData.MAIN_CONSOL;
	       	//    vm.external = rowData.EXTERNAL;
	       	//    vm.lockedVal = rowData.IS_LOCKED;
	       	//    vm.generateXml = rowData.GENERATE_XML;
	       	//    vm.gtwGroupKey = rowData.GTW_GROUP_KEY;
	       	//    vm.filingResp = rowData.FILING_RESP;
	       	//    vm.returnPeriodId = rowData.RETURN_PERIOD_ID;
      	// }
        
      
        function prepareActionCall(mode) {
            const CREATE_ACTION_CODE = 'A';//'1x8oqq';
            const UPDATE_ACTION_CODE = 'E';//'d3l13c';
            const DELETE_ACTION_CODE = 'D';//'yysvr1';
            let actionCode;
            if (mode === 'create') {
                actionCode = CREATE_ACTION_CODE;
            } else if (mode === 'edit') {
                actionCode = UPDATE_ACTION_CODE;
            } else {
                actionCode = DELETE_ACTION_CODE;
            }
            const url = GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=1x8oqq' ;//+ actionCode;
           
            const clobSettings = [{
                tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                scenario: workspaceFactory.activeScreen.filters.getFilterParams().scenario,
                jcd_key: workspaceFactory.activeScreen.jcd_key
            }];

            let clobInput = {};
            for(let k in vm.headers) {
                var data = vm.headers[k];
                if( data.label && data.label !== null && data.map !== 'CREATED_TS'){
                    var key = data.map.toLowerCase();
                    var value = '';
                    var valueArr = vm.submissionData.filter(i => i.name === key);
                    if(valueArr && valueArr.length > 0) {
                        value = valueArr[0].value;
                    }
                    clobInput[key] = value;
                }
            }
            // for(let i in vm.submissionData) {
            //     var data = vm.submissionData[i];
            //     clobInput[data.name] = data.value;
            // }                 
            // const clobData = {
            // 			'submission_id': vm.submissionId,
            //             'tax_payer': vm.taxPayer ? vm.taxPayer : rowData.taxPayer,
            //             'return_type_id': vm.returnTypeId ? vm.returnTypeId : rowData.rtnTypeId,
            //             'consolidated_entity':vm.consolEntity,
            //             'parent_entity':vm.parentEntity,
            //             'created_by' : USER_SETTINGS.user.sso_id,
            //             'tax_payer_name':vm.taxPayerName ? vm.taxPayerName : rowData.taxPayerName,
            //             'tax_year': vm.taxYear ? vm.taxYear : rowData.taxYear,
            //             'schema_set_id':vm.schemaSetId ? vm.schemaSetId : rowData.schemaSetId,
            //             'archive_ind': vm.archInd,
            //             'use_gold': vm.useGold,
            //             'main_consol': vm.mainConsol,
            //             'external':vm.external,
            //             'ins_locked':vm.lockedVal,
            //             'generate_xml' : vm.generateXml,
            //             'gtw_group_key': vm.gtwGroupKey ,
            //             'filing_resp' : vm.filingResp,
            //             'return_period_id':vm.returnPeriodId                       
            //   }
            
            const filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            let params = _.merge(GlobalService.isIssueIDEnabled(GlobalService.globalParams.tax_year, GlobalService.globalParams.scenario), GlobalService.globalParams, filterParams);
            // params = _.extend({'clob_data': JSON.stringify(clobData)}, params);\
            params = _.extend({'clob_data': JSON.stringify(clobInput)}, params);
            params = _.extend({'clob_settings': JSON.stringify(clobSettings)}, params);
            params.sso_id = USER_SETTINGS.user.sso_id;
            params.mode = actionCode;

            return {url: url, params: params};
        }

        vm.save = function () {
        	console.log("inside save method of submission ");
        	const urlParamsObj = prepareActionCall(vm.mode);
            vm.crudLoading = true;
            vm.saving = true;
            JsonObjectFactory.saveJSON(urlParamsObj.url, urlParamsObj.params)
                             .then((data) => {
                                 if (data.callSuccess === '1') {
                                     AlertService.add('success', 'Data saved successfully.', 4000);
                                     $rootScope.$broadcast('dataFilters:refreshGrid', {
                                         "refresh": true
                                     });
                                     vm.cancel();
                                 } else {
                                     AlertService.add('error', data.errorMessage);
                                 }
                             })
                             .catch((error) => {
                                 console.log(error);
                                 AlertService.add('error', 'Something went wrong while saving data, please try again!');
                             })
                             .finally(() => {
                                 vm.saving = false;
                                 vm.crudLoading = false;
                             });
        };

        vm.delete = function () {
        	console.log("inside delete of submission ")
            const urlParamsObj = prepareActionCall('delete');
            vm.crudLoading = true;
            vm.deleting = true;
            JsonObjectFactory.saveJSON(urlParamsObj.url, urlParamsObj.params)
                             .then((data) => {
                                 if (data.callSuccess === '1') {
                                     AlertService.add('success', 'Data deleted successfully.', 4000);
                                     $rootScope.$broadcast('dataFilters:refreshGrid', {
                                         "refresh": true
                                     });
                                     vm.cancel();
                                 } else {
                                     AlertService.add('error', data.errorMessage);
                                 }
                             })
                             .catch((error) => {
                                 console.log(error);
                                 AlertService.add('error', 'Something went wrong while deleting the data, please try again!');
                             })
                             .finally(() => {
                                 vm.deleting = true;
                                 vm.crudLoading = false;
                             });
        };
        
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
    function overridesController($scope,$rootScope,$state,$log,GridFactory,AlertService,$timeout,$uibModal,GlobalService,EfileFactory) {
        var vm = this;
        vm.taxYears = GlobalService.inputs.tax_year.values;//TAX_YEARS;
        // vm.filterObjs.loading = false;
        var selectedTaxYear = _.find(GlobalService.inputs.tax_year.values,{label:EfileFactory.selectedSubmission.TAX_YEAR + ''})
        vm.taxYear = selectedTaxYear;// GlobalService.inputs.tax_year.selected;
        vm.filterMatch = true;

       var submID = EfileFactory.selectedSubmission.SUBMISSION_ID;
       var _taxYear = EfileFactory.selectedSubmission.TAX_YEAR;
       
        vm.refresh = function(){
            getData();
        };
       
        var getData = function() {
            $scope.$broadcast("showLoader", true);
            vm.filterMatch = true;
            var params = {
                tax_year: EfileFactory.selectedSubmission.TAX_YEAR,//vm.taxYear.value,
                type:vm.submissionType,
                submission_id:EfileFactory.selectedSubmission.SUBMISSION_ID
            }
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];

            GridFactory.getDataCustom($state.current.api.get, params).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);
                } else {
                    AlertService.add("error", data.errorMessage);
                }
            });
        }
        

        var gridUpdateListener = $rootScope.$on('gridUpdate', function(event, args) {
            var filterObj = (typeof  args.gridFilter !== 'undefined') ? args.gridFilter   : null ;
            delete args['gridFilter'];
            getUpDate(args,filterObj);
        });

        $scope.$on('$destroy', function iVeBeenDismissed() {
            console.log("SCREEN WAS THIS DESTROYED ")
            gridUpdateListener();
        });

        var getUpDate = function(_params,_filterObj) {
            var params = {
                tax_year: vm.taxYear.value,
                type:vm.submissionType,
                submission_id: _params.submission_id
            }
            $scope.$broadcast("showDataRefresh", true);

            GridFactory.getDataUpdate($state.current.api.get,params).then(function (data) {
                if (data.callSuccess === "1") {
                    $scope.$broadcast("showDataRefresh", false);
                    $scope.$broadcast("dataUpdate", {filter:_filterObj,data:data.data});
                } else {
                    $scope.$broadcast("showDataRefresh", false);
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
        }
        $scope.ctrlFn = function(_callName,_data,_type) {
            var modalObj = {};
            modalObj.template = "app/components/efile/efileElements/overrides/override-modal-tpl.html";
            modalObj.controler = "overrideModalController as ctrl";
            modalObj.size = "md";
            modalObj.backdrop = "static";
            modalObj.keyboard = false;
            vm.openModal(modalObj,_data);
        };

        vm.openModal = function (modalObj,_data) {
            vm.rowData = _data.rowData;
            vm.gridData = _data;

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: modalObj.template,
                controller: modalObj.controler,
                size: modalObj.size,
                backdrop: modalObj.backdrop,
                keyboard: modalObj.keyboard,
                resolve: {
                    rowData: function () {
                        return vm.rowData;
                    },
                    gridData: function () {
                        return vm.gridData;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
                $log.info('$scope.rowData' , $scope.rowData);
            });
        };
       // vm.refresh();

    }
    function overrideModalController($uibModalInstance,rowData, EfileFactory,AlertService,$rootScope,JsonObjectFactory,USER_SETTINGS,GENERAL_CONFIG,GlobalService){
        var vm = this;
        vm.rowData = rowData;
        console.log('rowData',rowData);
        vm.system_selected = false;
        vm.override_selected = false;
        vm.overrideValuesSpinner = false;
        vm.overideHisTableData = {
            header: ["Active", "Element Value", "Date Created", "Created By", "Source Type"],

            body:[]
        };

        vm.getOverideHis = function() {
            vm.overrideValuesSpinner = true;

            EfileFactory.getSysOverideHis(rowData.TAX_YEAR, rowData.JOB_ID, rowData.KEY_ID, 
                rowData.ELEMENT_NAME, rowData.SNO, rowData.FORM_SUB, rowData.PARENT_ELEMENT_NAME,
                rowData.SUBMISSION_ID).then((data) => {
                if (data.callSuccess === '1') {
                    vm.overideHisTableData.body = data.jsonObject;
                    vm.getSysAmtValue();
                    vm.getIsSubmissionLocked();

                }
                })
                .catch((error) => {
                })
                .finally(() => {
                });
        }
        vm.getOverideHis();

        vm.getSysAmtValue = function() {

            EfileFactory.getSysAmtValue(rowData.TAX_YEAR, rowData.JOB_ID, rowData.KEY_ID, rowData.ELEMENT_NAME, rowData.SNO, rowData.FORM_SUB, rowData.PARENT_ELEMENT_NAME).then((data) => {
                if (data.callSuccess === '1') {
                    vm.system_value = (data.jsonObject && data.jsonObject.length>0 ) ? (data.jsonObject[0].VALUE) : '';
                    console.log(vm.system_value, 'dataaaaaaa');
                    let activeValue = _.find(vm.overideHisTableData.body,{IS_ACTIVE:'True'});

                    if(vm.system_value != ''){                       
                            vm.system_value = (vm.system_value);    
                           // vm.amount_type = 'override';
                            //vm.amountType = 'override';                        
                    }
                    // }else{
                        //vm.amount_type = 'manual';
                        //vm.amountType = 'manual'
                        //vm.radioSelected({isManual:true});
                   // }
                    if(!_.isEmpty(activeValue.ELEMENT_VALUE)){
                            vm.manual_value = activeValue ? (activeValue.ELEMENT_VALUE) : '';
                            vm.amount_type = 'manual';
                    }
                  }
                })
                .catch((error) => {
               })
                .finally(() => {
                });
        }
        vm.isLocked = false;
        vm.is_locked_msg = '';
        vm.getIsSubmissionLocked = function(){
            EfileFactory.getSubmissionLocked( rowData.SUBMISSION_ID).then((data) => {
                if (data.callSuccess === '1') {
                    vm.isLocked = data.jsonObject[0] && data.jsonObject[0].LOCK_IND == 1 ?true:false;
                    if(vm.isLocked){
                        vm.is_locked_msg = 'The selected submission is already submited to IRS or locked';
                        AlertService.add("warning",vm.is_locked_msg,4000)
                    }
                    console.log(data, 'dataaaaaaa');

                   
                    vm.overrideValuesSpinner = false;

                }
                })
                .catch((error) => {
               })
                .finally(() => {
                });
        }

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };
        vm.amountType = null;
        vm.getVal = function(){
            vm.amountType = vm.amount_type
            if(vm.amountType == 'override'){
                _.each(vm.overideHisTableData.body,function(i){
                    i.IS_ACTIVE = "False";
                })
            }else{
                _.each(vm.overideHisTableData.body,function(i){
                    if(vm.version == i.VERSION){
                        i.IS_ACTIVE = "True";
                    }
                    
                }) 
            }
        }

        vm.getActiveVal = function(item, index) {
            console.log(item, 'itemmm');
            vm.isMatch = false;
            _.each(vm.overideHisTableData.body,function(ob,i){
               // if(i == index && ob.ELEMENT_VALUE == item.ELEMENT_VALUE && item.SOURCE == "OVERRIDE"){

                if(i == index && ob.ELEMENT_VALUE == item.ELEMENT_VALUE){   
                         ob.IS_ACTIVE = "True";
                         vm.manual_value = item.ELEMENT_VALUE;
                         vm.version =  item.VERSION
                         vm.amount_type = 'manual';
                         vm.amountType = 'manual';
                    
                }

                // else if(i == index && ob.ELEMENT_VALUE == item.ELEMENT_VALUE && item.SOURCE == "DOM_AUDIT") {
                //     ob.IS_ACTIVE = "True";
                //     vm.system_value = item.ELEMENT_VALUE;
                //     vm.manual_value = "";
                //     vm.amount_type = 'override';
                //     vm.amountType = 'override';
                // }
                else{
                    ob.IS_ACTIVE = "False";
                }
            })
          
        }

        vm.crudLoading = false;
        vm.isSaving = false;
        vm.save = function(){
            if(vm.amountType == null){
               vm.warningMsg = "Please choose type of Element Value";
                return;
            }
            if(vm.isSaving){
                AlertService.add("warning","Please wait until existing save completes",4000);
                return;
            }
            var params = {
                mef_obj_key:rowData.MEF_OBJECT_KEY,
                tax_year:rowData.TAX_YEAR,
                job_id:rowData.JOB_ID,
                key_id:rowData.KEY_ID,
                sub_form:rowData.FORM_SUB,
                parent_element_name: rowData.PARENT_ELEMENT_NAME,
                parent_sno: rowData.PARENT_SNO?rowData.PARENT_SNO:'',
                element_name:rowData.ELEMENT_NAME,
                sno:rowData.SNO,
                amount: vm.amountType == 'manual'? (vm.manual_value) : (vm.system_value == '')? null: vm.system_value,
                source_type: "OVERRIDE", //vm.amountType == 'manual' ? 'OVERRIDE' : 'DOM_AUDIT',
                is_user_generated: vm.amountType == 'manual' ?'Y' : 'N',
                is_active:vm.amountType == 'manual'? 'Y' : 'N',
                is_archived: rowData.IS_ARCHIVED
            }
            vm.crudLoading = true;
            vm.isSaving = true;
            vm.changes ={
                elementName: rowData.ELEMENT_NAME,
                sno: rowData.SNO,
                elementValue: vm.amountType == 'manual'? (vm.manual_value) : (vm.system_value == '')? null: vm.system_value ,
                def_to_sys: vm.amountType == 'manual' ? 'N' : 'Y',
                active_version :vm.amountType == 'manual' ? vm.version : 0
            }
            let clob_data = {
                submissionId: EfileFactory.selectedSubmission.SUBMISSION_ID,
                processName:'SAVE_EFILE_ELEMENT_OVERRIDE',
                keyId:vm.rowData.KEY_ID,
                form:vm.rowData.FORM,
                formSub:rowData.FORM_SUB,
                pen:vm.rowData.PARENT_ELEMENT_NAME,
                formId: rowData.FORM_ID, //16592061,
                parentSno:'0',//null?,
                job_id:rowData.JOB_ID,
                key_id:rowData.KEY_ID,
                changes:vm.changes
            }

            JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=rv8nq0',
            {
                clob_settings:JSON.stringify({
                    tax_year:EfileFactory.selectedSubmission.TAX_YEAR,
                    scenario:_.find(GlobalService.inputs.tax_year.values,["value",EfileFactory.selectedSubmission.TAX_YEAR.toString()]).scenario,
                    jcd_key:250,
                }),
                tax_year:EfileFactory.selectedSubmission.TAX_YEAR,
                scenario:_.find(GlobalService.inputs.tax_year.values,["value",EfileFactory.selectedSubmission.TAX_YEAR.toString()]).scenario,
                jcd_key:250,
                clob_data:JSON.stringify(clob_data),
                sso_id:USER_SETTINGS.user.sso_id,
            }).then(function (response) {
                let callSuccess = response.callSuccess;
                if(callSuccess==="0"){
                    AlertService.add("error","Something went wrong while saving the data!",4000);
                }else{
                    AlertService.add("success","Data saved successfully!",4000);
                    vm.isSaving = false;
                    vm.crudLoading = false;
                    var args = {
                        tax_year: vm.rowData.TAX_YEAR,
                        submission_id: vm.rowData.SUBMISSION_ID,
                        gridFilter: {
                            TAX_YEAR: vm.rowData.TAX_YEAR,
                            SUBMISSION_ID: vm.rowData.SUBMISSION_ID
                        }
                    };
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', args);
                   
                }
                vm.saving = false;
                // $scope.$apply();
            });

       // }
           /*  EfileFactory.saveFormOverrideValue(params).then((data) => {
                if (data.callSuccess === '1') {
                    AlertService.add("success",'saved successfully',4000);
                    vm.isSaving = false;
                    vm.crudLoading = false;
                    vm.getOverideHis();
                    var args = {
                        tax_year: vm.rowData.TAX_YEAR,
                        submission_id: vm.rowData.SUBMISSION_ID,
                        gridFilter: {
                            TAX_YEAR: vm.rowData.TAX_YEAR,
                            SUBMISSION_ID: vm.rowData.SUBMISSION_ID
                        }
                    };
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', args);
                }else{
                    AlertService.add("error",data.errorMessage,4000);

                }
                })
                .catch((error) => {
                })
                .finally(() => {
                }); */
        
        }
    }


    function LockUnlockController(EfileFactory,GlobalService,USER_SETTINGS,AlertService,JsonObjectFactory,GENERAL_CONFIG){
        const vm = this;
        vm.is_locked = EfileFactory.selectedSubmission.IS_LOCKED;

        vm.lockUnlock = function() {
            if(vm.saving) return;

            vm.saving = true;
            JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=kzksix',
            {
                submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID,
                lock_unlock:vm.is_locked ? 0 : 1,
                tax_year:EfileFactory.selectedSubmission.TAX_YEAR,
                scenario:_.find(GlobalService.inputs.tax_year.values,["value",EfileFactory.selectedSubmission.TAX_YEAR.toString()]).scenario,
                jcd_key:250,
                sso_id:USER_SETTINGS.user.sso_id,
            }).then(function (response) {
                let callSuccess = response.callSuccess;
                if(callSuccess==="0"){
                    AlertService.add("error","Something went wrong while saving the data!",4000); 
                }else{
                    vm.is_locked = vm.is_locked ? 0 : 1;
                    EfileFactory.selectedSubmission.IS_LOCKED = vm.is_locked;
                    AlertService.add("success",`${vm.is_locked ? "Locked" : "Unlocked"} successfully!`,4000);
                }
                vm.saving = false;
                // $scope.$apply();
            });
        }

    }

    
    function XMLSyncController(EfileFactory,SERVER_CONFIG,USER_SETTINGS,AlertService,JsonObjectFactory,GENERAL_CONFIG,GlobalService){
        const vm = this;
        vm.loading = false;
        const PRODUCTION_ACCEPTED = 'Production Accepted';
        const JCD_KEY = 250;
        vm.canDoProdSubmit = SERVER_CONFIG.clientConfig.efile_prod_submission_users ?
                                SERVER_CONFIG.clientConfig.efile_prod_submission_users.includes(USER_SETTINGS.user.sso_id) : false;

        if(vm.canDoProdSubmit && EfileFactory.selectedSubmission.SUBMISSION_STATUS === PRODUCTION_ACCEPTED){
            getUnSyncedXMLs();
        }

        function getUnSyncedXMLs() {
            vm.loading = true;
            vm.error = false;
            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=xmqu0n',
            { submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID }).then(function (response) {
                    vm.loading = false;
                    if(response.callSuccess === "1"){
                        if(response.jsonObject.length ===0){vm.noDataFound = true;return};
                        vm.is_synced =  _.some(response.jsonObject,(row=>row.FILED_IRS_SUBMISSION_ID === row.S3_IRS_SUBMISSION_ID));
                    }
                    else
                        vm.error = true;
                });
        }

        vm.syncXML = function(){
            if(vm.is_synced === true) return;

            vm.syncingXML = true;
            const selectedTaxYear = _.find(GlobalService.inputs.tax_year.values,{label:EfileFactory.selectedSubmission.TAX_YEAR + ''})
            const scenario = selectedTaxYear.scenarios.find(function(s) {
                return s.jcd_key == JCD_KEY && s.default_scenario === 'Y';
            }).scenario;
            EfileFactory.syncXML(EfileFactory.selectedSubmission.TAX_YEAR,EfileFactory.selectedSubmission.SUBMISSION_ID,scenario,JCD_KEY).then(response=>{
                vm.syncingXML = false;
                if(response.data && response.data.callSuccess === "1"){
                    AlertService.add("success","Successfully Synced the XML with SLT!" );
                }
                else
                    AlertService.add("error","Failed to Synced the XML with SLT!"+res.data.errorMessage);

                getUnSyncedXMLs()
            },()=>{vm.syncingXML = false;getUnSyncedXMLs()})
        }

    }
    //USER_SETTINGS,GENERAL_CONFIG,EfileFactory,AlertService
    function EnabledDisabledController(EfileFactory,GlobalService,USER_SETTINGS,AlertService,JsonObjectFactory,GENERAL_CONFIG,$stateParams,$state,$scope,workspaceFactory,GridFactory,$timeout,$uibModal){
        
        let vm = this;
        vm.formCheckbox = [];
        console.log($state);
        vm.form_id = '';
        let custom_data_key = 'EFILE_ENABLE_DISABLE_'+EfileFactory.selectedSubmission.SUBMISSION_ID;
        vm.loadingErrors = true;
        vm.selectAllToggle = true;
        vm.ssoId = USER_SETTINGS.user.sso_id;
        $scope.$on("efile:expand-view",function() {
            vm.expandView = !vm.expandView;
        })
        const JCD_KEY = 250;

        if(!EfileFactory.hasAccess("ADMIN") || EfileFactory.selectedSubmission.IS_LOCKED){
            $state.go('submissions-dasboard.submission.dashboard',{id:$stateParams.id});
            return;
        } 
        
        var getData = function (refresh) {
            vm.form_id = '';
            
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];
            var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);

            if (cachedData && cachedData.data && !refresh) {
                setTimeout(function() {
                    vm.data = cachedData.data.data;
                
                    $scope.$broadcast("dataLoaded", vm.data);
                    $scope.$broadcast("showLoader", false);
                        
                });
                return true;
            }
            /*  if filters are changed, deleting the existing cached Grid Data*/
            else if (cachedData && cachedData.data && refresh) {
                for (var i = 0; i < workspaceFactory.customDataCache.length > 0; i++) {
                    if (workspaceFactory.customDataCache[i].data_key === cachedData.data_key) {
                        workspaceFactory.customDataCache.splice(i, 1);
                    }
                }
                console.log("cusomCache", workspaceFactory.customDataCache);
            }

            
            
            $scope.$broadcast("showLoader", true);
            vm.errorParams = '&submission_id='+EfileFactory.selectedSubmission.SUBMISSION_ID;

            const params =  {
                submission_id:EfileFactory.selectedSubmission.SUBMISSION_ID
            };
            GridFactory.getDataCustom(GENERAL_CONFIG.base_url + "/loadGridJson?grid_id=6033&action_code=m7kbob",params)
            .then(function (data) {
                if (data.callSuccess === "1") {
                    vm.data = data;
                    // $rootScope.$broadcast("collapseLeft", function (event, data) {});
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);

                    workspaceFactory.setCustomDataCache(custom_data_key, {
                        data: vm.data
                    });

                    
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
            
               
        };

        
        vm.refresh = function() {
            
            vm.showErrors = true;
            $timeout(function() {
                getData(true);
            },300)
        }
        vm.refresh();

        /* pencil icon function */
        $scope.ctrlFn = function (_callName, _data, _type) {
            console.log("_callName ", _callName);
            
            if((_callName === "enabledDisabledFormData") ){
                //if(EfileFactory[_callName]){
                    const popup = $uibModal.open({
                        templateUrl: 'app/templates/modals/confirm-action.html',
                        windowClass: 'modal-warning',
                        resolve:{
                            _data:function() {
                                return _data;
                            },
                            _callName:function() {
                                return _callName;
                            }
                        },
                        controller: ['$scope','$rootScope', '$stateParams', '$uibModalInstance', 'GENERAL_CONFIG','_data','EfileFactory','AlertService','_callName','$filter',
                            confirmationCtrl
                        ]
                    });

               // }
                return;
            }
        };

        function confirmationCtrl($scope,$rootScope, $stateParams, $uibModalInstance, GENERAL_CONFIG,_data,EfileFactory,AlertService,_callName,$filter) {
        	let actionMap = {
                enabledDisabledFormData:{
                    confirmationMessage:'Are you sure you want to Toggle Form Exclusion for Selected Row??',
                    successMessage:'Successfully Toggle Form Exclusion.'
                },
            }                   
            $scope.header = "Confirm";
            $scope.message = actionMap[_callName].confirmationMessage;
            $scope.confirm = function () {
                $uibModalInstance.close();
                console.log("Confirmed");
                $rootScope.$broadcast("showLoader", true);
                enabledDisabledForm(_data);
            }
            $scope.cancel = function () {
                $uibModalInstance.close();
            }

            $scope.$on('$destroy', function () {
                ////////////console.log("ngReally Destroyed");
            });
        }

        var enabledDisabledForm = function (_data){
            console.log("Delete function called");
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            
            var gridData = [];
            var total = _data.length;
            //_data[0].data
            //let rowData = gridData[_data.length-1].data;
            
            for (var i=0; i<total; i++) {
                gridData.push(_data[i].data);
              }
           // var rowData = gridData.map(({_data}) => _data);
            var jsonObj = JSON.stringify(gridData);

            const selectedTaxYear = _.find(GlobalService.inputs.tax_year.values,{label:EfileFactory.selectedSubmission.TAX_YEAR + ''})
            const scenario = selectedTaxYear.scenarios.find(function(s) {
                return s.jcd_key == JCD_KEY && s.default_scenario === 'Y';
            }).scenario;

            let params = filterParams;
            // params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                json_data: jsonObj,
                tax_year: EfileFactory.selectedSubmission.TAX_YEAR,
                scenario: scenario,
                jcd_key:JCD_KEY,
                sso_id:USER_SETTINGS.user.sso_id
			}, params);

            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=w016n5";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSavingDelete = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    vm.refresh();
                    AlertService.add("success", "Toggle Exclusion successfully", 4000);
                    console.log("Toggle Successfully", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };
    }

    function UnlockValidationController(EfileFactory,GlobalService,USER_SETTINGS,AlertService,JsonObjectFactory,GENERAL_CONFIG,$stateParams,$state,$scope,workspaceFactory,GridFactory,$timeout,$uibModal){
        
        let vm = this;
        vm.formCheckbox = [];
        console.log($state);
        vm.form_id = '';
        let custom_data_key = 'EFILE_LOCK_UNLOCK_'+EfileFactory.selectedSubmission.SUBMISSION_ID;
        vm.loadingErrors = true;
        vm.selectAllToggle = true;
        vm.ssoId = USER_SETTINGS.user.sso_id;
        $scope.$on("efile:expand-view",function() {
            vm.expandView = !vm.expandView;
        })
        const JCD_KEY = 250;

        if(!EfileFactory.hasAccess("ADMIN")){
            $state.go('submissions-dasboard.submission.dashboard',{id:$stateParams.id});
            return;
        } 
        
        var getData = function (refresh) {
            vm.form_id = '';
            
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];
            var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);

            if (cachedData && cachedData.data && !refresh) {
                setTimeout(function() {
                    vm.data = cachedData.data.data;
                
                    $scope.$broadcast("dataLoaded", vm.data);
                    $scope.$broadcast("showLoader", false);
                        
                });
                return true;
            }
            /*  if filters are changed, deleting the existing cached Grid Data*/
            else if (cachedData && cachedData.data && refresh) {
                for (var i = 0; i < workspaceFactory.customDataCache.length > 0; i++) {
                    if (workspaceFactory.customDataCache[i].data_key === cachedData.data_key) {
                        workspaceFactory.customDataCache.splice(i, 1);
                    }
                }
                console.log("cusomCache", workspaceFactory.customDataCache);
            }

            
            
            $scope.$broadcast("showLoader", true);
            vm.errorParams = '&submission_id='+EfileFactory.selectedSubmission.SUBMISSION_ID;

            const params =  {
                submission_id:EfileFactory.selectedSubmission.SUBMISSION_ID
            };
            GridFactory.getDataCustom(GENERAL_CONFIG.base_url + "/loadGridJson?grid_id=6046&action_code=xjcg8f",params)
            .then(function (data) {
                if (data.callSuccess === "1") {
                    vm.data = data;
                    // $rootScope.$broadcast("collapseLeft", function (event, data) {});
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);

                    workspaceFactory.setCustomDataCache(custom_data_key, {
                        data: vm.data
                    });

                    
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
            
               
        };

        
        vm.refresh = function() {
            
            vm.showErrors = true;
            $timeout(function() {
                getData(true);
            },300)
        }
        vm.refresh();

        /* pencil icon function */
        $scope.ctrlFn = function (_callName, _data, _type) {
            console.log("_callName ", _callName);
            
            if((_callName === "unlockValidationData") ){
                //if(EfileFactory[_callName]){
                    const popup = $uibModal.open({
                        templateUrl: 'app/templates/modals/confirm-action.html',
                        windowClass: 'modal-warning',
                        resolve:{
                            _data:function() {
                                return _data;
                            },
                            _callName:function() {
                                return _callName;
                            }
                        },
                        controller: ['$scope','$rootScope', '$stateParams', '$uibModalInstance', 'GENERAL_CONFIG','_data','EfileFactory','AlertService','_callName','$filter',
                            confirmationCtrl
                        ]
                    });

               // }
                return;
            }
        };

        function confirmationCtrl($scope,$rootScope, $stateParams, $uibModalInstance, GENERAL_CONFIG,_data,EfileFactory,AlertService,_callName,$filter) {
        	let actionMap = {
                unlockValidationData:{
                    confirmationMessage:'Are you sure you want to Toggle Unlock Validation for Selected Row?',
                    successMessage:'Successfully Toggle Unlock Validation.'
                },
            }                   
            $scope.header = "Confirm";
            $scope.message = actionMap[_callName].confirmationMessage;
            $scope.confirm = function () {
                $uibModalInstance.close();
                console.log("Confirmed");
                $rootScope.$broadcast("showLoader", true);
                unlockValidationForm(_data);
            }
            $scope.cancel = function () {
                $uibModalInstance.close();
            }

            $scope.$on('$destroy', function () {
                ////////////console.log("ngReally Destroyed");
            });
        }

        var unlockValidationForm = function (_data){
            console.log("Delete function called");
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            
            var gridData = [];
            var total = _data.length;
            //_data[0].data
            //let rowData = gridData[_data.length-1].data;
            
            for (var i=0; i<total; i++) {
                gridData.push(_data[i].data);
              }
           // var rowData = gridData.map(({_data}) => _data);
            var jsonObj = JSON.stringify(gridData);

            const selectedTaxYear = _.find(GlobalService.inputs.tax_year.values,{label:EfileFactory.selectedSubmission.TAX_YEAR + ''})
            const scenario = selectedTaxYear.scenarios.find(function(s) {
                return s.jcd_key == JCD_KEY && s.default_scenario === 'Y';
            }).scenario;

            let params = filterParams;
            // params = _.merge({}, GlobalService.globalParams, params);
            params = _.extend({
                json_data: jsonObj,
                tax_year: EfileFactory.selectedSubmission.TAX_YEAR,
                scenario: scenario,
                jcd_key:JCD_KEY,
                sso_id:USER_SETTINGS.user.sso_id
			}, params);

            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=rysucw";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSavingDelete = false;
                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    vm.refresh();
                    AlertService.add("success", "Successfully Toggle Unlock Validation", 4000);
                    console.log("Toggle Successfully", data);
                } else {
                    console.log("error");
                    AlertService.add("error",data.errorMessage,4000);
                }
            });
        };
    }

    function FormElementsReportController(EfileFactory,GlobalService,USER_SETTINGS,AlertService,JsonObjectFactory,GENERAL_CONFIG,$stateParams,$state,$scope,workspaceFactory,GridFactory,$timeout,$uibModal){
        
        let vm = this;
        vm.formCheckbox = [];
        console.log($state);
        vm.form_id = '';
        let custom_data_key = 'EFILE_FORMELEMENTS_'+EfileFactory.selectedSubmission.SUBMISSION_ID;
        vm.loadingErrors = true;
        vm.selectAllToggle = true;
        vm.ssoId = USER_SETTINGS.user.sso_id;
        $scope.$on("efile:expand-view",function() {
            vm.expandView = !vm.expandView;
        })
        const JCD_KEY = 250;

        // if(!EfileFactory.hasAccess("ADMIN")){
        //     $state.go('submissions-dasboard.submission.dashboard',{id:$stateParams.id});
        //     return;
        // } 
        
        var getData = function (refresh) {
            vm.form_id = '';
            
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];
            var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);

            if (cachedData && cachedData.data && !refresh) {
                setTimeout(function() {
                    vm.data = cachedData.data.data;
                
                    $scope.$broadcast("dataLoaded", vm.data);
                    $scope.$broadcast("showLoader", false);
                        
                });
                return true;
            }
            /*  if filters are changed, deleting the existing cached Grid Data*/
            else if (cachedData && cachedData.data && refresh) {
                for (var i = 0; i < workspaceFactory.customDataCache.length > 0; i++) {
                    if (workspaceFactory.customDataCache[i].data_key === cachedData.data_key) {
                        workspaceFactory.customDataCache.splice(i, 1);
                    }
                }
                console.log("cusomCache", workspaceFactory.customDataCache);
            }

            
            
            $scope.$broadcast("showLoader", true);
            vm.errorParams = '&submission_id='+EfileFactory.selectedSubmission.SUBMISSION_ID;

            const params =  {
                submission_id:EfileFactory.selectedSubmission.SUBMISSION_ID
            };
            GridFactory.getDataCustom(GENERAL_CONFIG.base_url + "/loadGridJson?grid_id=6055&action_code=70u8yi",params)
            .then(function (data) {
                if (data.callSuccess === "1") {
                    vm.data = data;
                    // $rootScope.$broadcast("collapseLeft", function (event, data) {});
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);

                    workspaceFactory.setCustomDataCache(custom_data_key, {
                        data: vm.data
                    });

                    
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
            
               
        };

        
        vm.refresh = function() {
            
            vm.showErrors = true;
            $timeout(function() {
                getData(true);
            },300)
        }
        vm.refresh();

        
    }


    function AmendSupersededReturnsController(EfileFactory,GlobalService,USER_SETTINGS,AlertService,JsonObjectFactory,GENERAL_CONFIG,$stateParams,$state,$scope,workspaceFactory,GridFactory,$timeout,$uibModal){
        
        let vm = this;
        console.log($state);
        //let custom_data_key = 'EFILE_ERRORS_'+EfileFactory.selectedSubmission.SUBMISSION_ID;
        vm.submitting = false;
        vm.ssoId = USER_SETTINGS.user.sso_id;
        const JCD_KEY = 250;
        vm.amendsupersededreturn = "amend";

        if(!EfileFactory.hasAccess("ADMIN")){
            $state.go('submissions-dasboard.submission.dashboard',{id:$stateParams.id});
            return;
        } 
       
        vm.createNewReturns = function() {
            vm.submitting = true;
            const selectedTaxYear = _.find(GlobalService.inputs.tax_year.values,{label:EfileFactory.selectedSubmission.TAX_YEAR + ''})
            const scenario = selectedTaxYear.scenarios.find(function(s) {
                return s.jcd_key == JCD_KEY && s.default_scenario === 'Y';
            }).scenario;

            let params = {
                submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID,
                sso_id:USER_SETTINGS.user.sso_id,
                tax_year: EfileFactory.selectedSubmission.TAX_YEAR,
                scenario: scenario,
                jcd_key:JCD_KEY

            }
            console.log(params);
            let url = '';
            if(vm.amendsupersededreturn === 'amend'){
                url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=4hdkul";
            }else {
                url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=fyvfhy";
            }
            
            params.is_issue_key_enabled = 'N';

            showConfirmDialog().then((proceedWithSave) => {
                if (proceedWithSave) {
                    JsonObjectFactory.saveJSON(url, params).then(function (data) {
                        vm.submitting = false;
                        console.log("data : "+data.newSubmission_id);
                        if (data.callSuccess === "1" && data.errorMessage == "null") {
                            if(vm.amendsupersededreturn === 'amend'){
                                AlertService.add("success", "Successfully Created New Amendments Return", 4000);
                                console.log("Created Amendments Return Successfully", data);
                            }else{
                                AlertService.add("success", "Successfully Created New Superseded Return", 4000);
                                console.log("Created Superseded Return Successfully", data);
                            }
                            
                        } else {
                            console.log("error");
                            AlertService.add("error",data.errorMessage,4000);
                        }
                    });
                } else {
                    vm.submitting = false;
                }
            });

            
        };

        function showConfirmDialog() {
            return $uibModal.open({
                templateUrl: 'app/templates/modals/confirm-action.html',
                windowClass: 'custom',
                controller: ['$scope', '$uibModalInstance',
                    function ($scope, $uibModalInstance) {
                        $scope.header = 'Amendments/Superseded Returns';
                        if(vm.amendsupersededreturn === 'amend'){
                            $scope.message = 'Are you sure you want to Create New Amendments Returns ';
                        }else {
                            $scope.message = 'Are you sure you want to Create New Superseded Returns ';
                        }
                        $scope.cancel = function () {
                            $uibModalInstance.close(false);
                        }
                        $scope.confirm = function () {
                            $uibModalInstance.close(true);
                        }
                    }
                ]
            }).result;
        }
    }

    function EfileScrubbingController(EfileFactory,GlobalService,USER_SETTINGS,AlertService,JsonObjectFactory,GENERAL_CONFIG,$stateParams,$state,$scope,workspaceFactory,GridFactory,$timeout,$uibModal){
        
        let vm = this;
        vm.formCheckbox = [];
        console.log($state);
        vm.form_id = '';
        let custom_data_key = 'EFILE_SCRUBBING_'+EfileFactory.selectedSubmission.SUBMISSION_ID;
        vm.loadingErrors = true;
        vm.selectAllToggle = true;
        vm.ssoId = USER_SETTINGS.user.sso_id;
        $scope.$on("efile:expand-view",function() {
            vm.expandView = !vm.expandView;
        })

        const JCD_KEY = 250;

        if(!EfileFactory.hasAccess("ADMIN")){
            $state.go('submissions-dasboard.submission.dashboard',{id:$stateParams.id});
            return;
        } 

        vm.ctrlFn = function (_callName, _data, _type) {

            if(_callName === 'enableDisableScrubbing'){
                enableDisableScrubbing(_data);
                return;
            }

            if(_callName === 'searchKeyToReplaceKey'){
                replace(true,_data);
                return;
            }
            if(_callName === 'replaceKeyToSearchKey'){
                replace(false,_data);
                return;
            }

            $uibModal.open({
                templateUrl: 'app/components/efile/efileElements/create-new-scrubbing.html',
                size:'sm',
                controllerAs:'ctrl',
                resolve:{
                    data:function() {
                        return _data;
                    },
                    refresh:function(){
                        return vm.refresh;
                    }
                },
                controller: ['USER_SETTINGS','GlobalService','data','EfileFactory','AlertService','$uibModalInstance','JsonObjectFactory','refresh',
                    function(USER_SETTINGS,GlobalService,data,EfileFactory,AlertService,$uibModalInstance,JsonObjectFactory,refresh) {
                        const vm = this;

                        if(data.length > 0){
                            vm.searchKey = data[0].data.SEARCH_KEY;
                            vm.replaceWith = data[0].data.REPLACE_KEY;
                            vm.isEdit = true;
                        }


                        vm.save = () => {
                            JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=mq3y7x',
                                {
                                    submission_id:EfileFactory.selectedSubmission.SUBMISSION_ID,
                                    search_word: vm.searchKey,
                                    replace_word:vm.replaceWith,
                                    tax_year:EfileFactory.selectedSubmission.TAX_YEAR,
                                    scenario:_.find(GlobalService.inputs.tax_year.values,["value",EfileFactory.selectedSubmission.TAX_YEAR.toString()]).scenario,
                                    jcd_key:250,
                                    sso_id:USER_SETTINGS.user.sso_id,
                                }).then(function (response) {
                                    let callSuccess = response.callSuccess;
                                    if(callSuccess==="0"){
                                        AlertService.add("error","Something went wrong while saving the data!",4000);
                                    }else{
                                        AlertService.add("success","Data saved successfully!",4000);
                                        refresh();
                                        $uibModalInstance.close();
                                    }
                                });
                        }


                        vm.cancel = () => $uibModalInstance.dismiss();
                    }
                ]
            });
        }
        
        function replace(searchToTextReplace,data){
            
            let confirmation = $uibModal.open({
                templateUrl: 'app/components/efile/efileElements/scrubbing-confirmation-popup.html',
                windowClass: 'modal-warning',
                resolve:{
                    searchKeys:function() {
                        return  data.map(item => item.data.SEARCH_KEY).join(',');
                    }
                },
                controller: ['$scope', '$uibModalInstance','searchKeys','EfileFactory',function($scope, $uibModalInstance,searchKeys,EfileFactory) {
                    $scope.header = "Confirm";
                    $scope.message = "Are you sure you want to replace?";
                    $scope.api = "/loadGridJson?action_code=m4qqsh&grid_id=6078";//$state.current.api.get;
                    $scope.params = {submission_id:EfileFactory.selectedSubmission.SUBMISSION_ID,search_key:searchKeys};

                    $scope.confirm = function () {
                        $uibModalInstance.close();
                    }
                    $scope.cancel = function () {
                        $uibModalInstance.dismiss();
                    }

                    $scope.$on('$destroy', function () {
                        ////////////console.log("ngReally Destroyed");
                    });
                }]
            });
            confirmation.result.then(function () {
                doReplace(searchToTextReplace,data);
            });
        }

        function doReplace(searchToTextReplace,data){
            JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url + '/saveJsonObject?action_code='+ (searchToTextReplace ? 'ck1uzf' : 'ixwopg'),
            {
                submission_id:EfileFactory.selectedSubmission.SUBMISSION_ID,
                tax_year:EfileFactory.selectedSubmission.TAX_YEAR,
                scenario:_.find(GlobalService.inputs.tax_year.values,["value",EfileFactory.selectedSubmission.TAX_YEAR.toString()]).scenario,
                jcd_key:250,
                sso_id:USER_SETTINGS.user.sso_id,
                search_key: data.map(item => item.data.SEARCH_KEY).join(','),
                replace_key: data.map(item => item.data.REPLACE_KEY).join(',')
            }).then(function (response) {
                let callSuccess = response.callSuccess;
                if(callSuccess==="0"){
                    AlertService.add("error","Something went wrong while saving the data!",4000);
                }else{
                    AlertService.add("success","Data scrubbed successfully!",4000);
                }
            });
        }

        function enableDisableScrubbing (data) {
            JsonObjectFactory.saveJSON(GENERAL_CONFIG.base_url + '/saveJsonObject?action_code=kjkq9s',
            {
                submission_id:EfileFactory.selectedSubmission.SUBMISSION_ID,
                search_word: data.map(item => item.data.SEARCH_KEY).join(','),
                tax_year:EfileFactory.selectedSubmission.TAX_YEAR,
                scenario:_.find(GlobalService.inputs.tax_year.values,["value",EfileFactory.selectedSubmission.TAX_YEAR.toString()]).scenario,
                jcd_key:250,
                sso_id:USER_SETTINGS.user.sso_id,
            }).then(function (response) {
                let callSuccess = response.callSuccess;
                if(callSuccess==="0"){
                    AlertService.add("error","Something went wrong while saving the data!",4000);
                }else{
                    AlertService.add("success","Data saved successfully!",4000);
                    vm.refresh();
                }
            });
        }
        
        var getData = function (refresh) {
            vm.form_id = '';
            
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];
            var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);

            if (cachedData && cachedData.data && !refresh) {
                setTimeout(function() {
                    vm.data = cachedData.data.data;
                
                    $scope.$broadcast("dataLoaded", vm.data);
                    $scope.$broadcast("showLoader", false);
                        
                });
                return true;
            }
            /*  if filters are changed, deleting the existing cached Grid Data*/
            else if (cachedData && cachedData.data && refresh) {
                for (var i = 0; i < workspaceFactory.customDataCache.length > 0; i++) {
                    if (workspaceFactory.customDataCache[i].data_key === cachedData.data_key) {
                        workspaceFactory.customDataCache.splice(i, 1);
                    }
                }
                console.log("cusomCache", workspaceFactory.customDataCache);
            }

            
            
            $scope.$broadcast("showLoader", true);
            vm.errorParams = '&submission_id='+EfileFactory.selectedSubmission.SUBMISSION_ID;

            const params =  {
                submission_id:EfileFactory.selectedSubmission.SUBMISSION_ID
            };
            GridFactory.getDataCustom(GENERAL_CONFIG.base_url + "/loadGridJson?grid_id=6074&action_code=9eu90y",params)
            .then(function (data) {
                if (data.callSuccess === "1") {
                    vm.data = data;
                    // $rootScope.$broadcast("collapseLeft", function (event, data) {});
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);

                    workspaceFactory.setCustomDataCache(custom_data_key, {
                        data: vm.data
                    });

                    
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
            
               
        };

        
        vm.refresh = function() {
            
            vm.showErrors = true;
            $timeout(function() {
                getData(true);
            },300)
        }
        vm.refresh();

        
    }
    
    function EfileKeyXmlGenController(EfileFactory,GlobalService,USER_SETTINGS,AlertService,JsonObjectFactory,GENERAL_CONFIG,$stateParams,$state,$scope,workspaceFactory,GridFactory,$timeout,$uibModal,FormViewFactory,$rootScope){
        
        let vm = this;
        vm.formCheckbox = [];
        console.log($state);
        vm.form_id = '';
        let custom_data_key = 'EFILE_SCRUBBING_'+EfileFactory.selectedSubmission.SUBMISSION_ID;
        vm.loadingErrors = true;
        vm.selectAllToggle = true;
        vm.ssoId = USER_SETTINGS.user.sso_id;
        const PRODUCTION_ACCEPTED = 'Production Accepted';
        $scope.$on("efile:expand-view",function() {
            vm.expandView = !vm.expandView;
        })

        const JCD_KEY = 250;

        if(!EfileFactory.hasAccess("ADMIN")){
            $state.go('submissions-dasboard.submission.dashboard',{id:$stateParams.id});
            return;
        } 

        vm.ctrlFn = function (callName, data, type) {
            
            if(callName === 'generateXML'){
                if(EfileFactory.selectedSubmission.SUBMISSION_STATUS === PRODUCTION_ACCEPTED){
                    AlertService.add("warning","Cannot generate XML for Production Accepted Submission");
                    $rootScope.$apply();
                    return;
                }
                let confirmation1 = openConfirmation("Are you sure you want to generate the XML?");
                confirmation1.result.then(function () {
                    generateXML(data);
                });
                return;
            }
            if(callName === 'generateSubmissionXML'){
                if(EfileFactory.selectedSubmission.SUBMISSION_STATUS === PRODUCTION_ACCEPTED){
                    AlertService.add("warning","Cannot generate XML for Production Accepted Submission");
                    $rootScope.$apply();
                    return;
                }
                let confirmation1 = openConfirmation("Are you sure you want to generate the Submission XML?");
                confirmation1.result.then(function () {
                    generateSubmissionXML(data);
                });
                return;
            }

            if(callName === 'downloadXML'){
                downloadXML(data);
                return;
            }

        }

        function downloadXML(data){
            FormViewFactory.downloadXMLorZIP(EfileFactory.selectedSubmission.SUBMISSION_ID,data.rowData.KEY_ID,data.rowData.LE_TYPE,"xml")
                .then(function(response) {
                    if(response.data.byteLength === 0){
                        AlertService.add('warning',"No file available to download");
                        return;
                    }

                    let blob = new Blob([response.data], {type: "application/xml"});
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${EfileFactory.selectedSubmission.TAX_YEAR}_${EfileFactory.selectedSubmission.RETURN_TYPE}_${EfileFactory.selectedSubmission.TAX_PAYER_NAME}(${EfileFactory.selectedSubmission.TAX_PAYER}).xml`;
                    link.click();
                    link.remove();
                }).catch((error) => {
                    console.log(error);
                    AlertService.add('error', 'Something went wrong!');
                })

        }

        function openConfirmation(message){
            return $uibModal.open({
                    templateUrl: 'app/templates/modals/confirm-action.html',
                    windowClass: 'modal-warning',
                    resolve:{
                        message:function(){
                            return message;
                        }
                    },
                    controller: ['$scope', '$uibModalInstance','message',simpleConfirmationCtrl]
                });

        }

        function generateSubmissionXML(data){
            EfileFactory.generateSubmissionXML(EfileFactory.selectedSubmission.SUBMISSION_ID).then(response => {
                if(response.data && response.data.callSuccess === "1"){
                    AlertService.add("success","XML Generation was completed!");
                }else{
                    AlertService.add("error",response.data.errorMessage);
                }
            });
        }
        function generateXML(data){
            const jcdKey = EfileFactory.selectedSubmission.submissionType === "federal" ? 250 : 253;

            const taxEntity = {
                submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID,
                key_id: data.rowData.KEY_ID,
                tax_year: EfileFactory.selectedSubmission.TAX_YEAR,
                scenario: data.rowData.SCENARIO_ID,
                jcd_key: jcdKey,
                jobsList:[{
                    job_id: data.rowData.JOB_ID
                }]
            }
            EfileFactory.validate(EfileFactory.selectedSubmission.SUBMISSION_ID, data.rowData.KEY_ID, taxEntity).then(response => {
                if(response.data && response.data.callSuccess === "1"){
                    AlertService.add("success","XML Generation was Initiated!");
                }else{
                    AlertService.add("error",response.data.errorMessage);
                }
            });
        }
        
        var getData = function (refresh) {
            vm.form_id = '';
            
            vm.loading = true;
            vm.gridData = [];
            vm.gridData.DATA = [];
            var cachedData = workspaceFactory.getCustomDataCache(custom_data_key);

            if (cachedData && cachedData.data && !refresh) {
                setTimeout(function() {
                    vm.data = cachedData.data.data;
                
                    $scope.$broadcast("dataLoaded", vm.data);
                    $scope.$broadcast("showLoader", false);
                        
                });
                return true;
            }
            /*  if filters are changed, deleting the existing cached Grid Data*/
            else if (cachedData && cachedData.data && refresh) {
                for (var i = 0; i < workspaceFactory.customDataCache.length > 0; i++) {
                    if (workspaceFactory.customDataCache[i].data_key === cachedData.data_key) {
                        workspaceFactory.customDataCache.splice(i, 1);
                    }
                }
                console.log("cusomCache", workspaceFactory.customDataCache);
            }

            
            
            $scope.$broadcast("showLoader", true);
            vm.errorParams = '&submission_id='+EfileFactory.selectedSubmission.SUBMISSION_ID;

            const params =  {
                submission_id:EfileFactory.selectedSubmission.SUBMISSION_ID
            };
            GridFactory.getDataCustom(GENERAL_CONFIG.base_url + "/loadGridJson?grid_id=6127&action_code=ccfa3x",params)
            .then(function (data) {
                if (data.callSuccess === "1") {
                    vm.data = data;
                    // $rootScope.$broadcast("collapseLeft", function (event, data) {});
                    $scope.$broadcast("dataLoaded", data);
                    $scope.$broadcast("showLoader", false);

                    workspaceFactory.setCustomDataCache(custom_data_key, {
                        data: vm.data
                    });

                    
                } else {
                    AlertService.add("error", "Error occurred fetching data. If this continues please contact support");
                }
            });
            
               
        };

        
        vm.refresh = function() {
            
            vm.showErrors = true;
            $timeout(function() {
                getData(true);
            },300)
        }
        vm.refresh();

        
    }

    
});
