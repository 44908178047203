define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.giltiCalcGridService',[])
        .factory("giltiCalcGridFactory",['$q','$http','$rootScope','$log','JsonObjectFactory',
        'AlertService','$injector','workspaceFactory','GlobalService','USER_SETTINGS','$stateParams',
        'GENERAL_CONFIG', function($q,$http,$rootScope, $log,JsonObjectFactory,AlertService, $injector,workspaceFactory,GlobalService,USER_SETTINGS,$stateParams,GENERAL_CONFIG) {
        	var giltiCalcGridFactory = {};
            var returnObj = {};
            var logged_in_user = USER_SETTINGS.user.sso_id;
            giltiCalcGridFactory.saveManualData = function(_callName ,_data, columnName){
            	 console.log("data", _data);
                $rootScope.$broadcast('gridRefresh', false); 
                var service = $injector.get('JsonObjectFactory');
                var url = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=79wgno";
                //var jsonSettings = JSON.stringify(clob_settings);
       		 	//var jsonObj = JSON.stringify(_callName);
       		 	var jsonObj = [{
	            	"tax_year" 			: _data.rowData.TAX_YEAR,       
	            	"ho_leid"  			: _data.rowData.HO_LEID,      
	                "ho_cdr_no" 		: _data.rowData.HO_CDR_NO,                                                             
	                "filing_group"		: _data.rowData.FILING_GROUP,
	                "scenario"     		: _data.rowData.SCENARIO,
	                "col_type"           : _data.col_id,
	                "col_value"          : _data.rowData[_data.col_id],
	                "ho_combination_key" : _data.rowData.HO_COMBINATION_KEY,
	                "shareholder_leid"   : _data.rowData.SHAREHOLDER_LEID,
	                "shareholder_cdr_no" : _data.rowData.SHAREHOLDER_CDR_NO,
	                "functional_curr"    : _data.rowData.FUNCTIONAL_CURRENCY
	            }];
	            var jsonSettings = [{sso_id: logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
                var saveData = true;
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, GlobalService.globalParams, filterParams);
                params =  _.extend({jsonObj:JSON.stringify(jsonObj)}, params);
       		 	params =  _.extend({jsonSettings:JSON.stringify(jsonSettings)}, params);
                params.object_id = _data.rowData.object_id;
                params.sso_id = logged_in_user;
                var defer = $q.defer();
               
                //params.tax_year = rowData.TAX_YEAR;
                params.process_name =  "saveManualData";
                /* var promise =  $http({
                    method: "post",
                    url: url,
                    data: params
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        AlertService.add("success", "Changes have been saved", 3000);
                        var args = {combination_keys: _data.rowData.HO_COMBINATION_KEY,gridFilter: {combination_keys: _data.rowData.HO_COMBINATION_KEY}};
                       $rootScope.$emit('gridUpdate', args);
                    }else{
                        if(response.data.errorMessage){
                            AlertService.add("error", response.data.errorMessage, 4000);
                            console.log(response);
                            $rootScope.$broadcast('gridRefresh', false);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                            console.log(response);
                            $rootScope.$broadcast('gridRefresh', false);
                        }
                    }
                    return response.data;
                });
                return promise; */
                service.saveJSON(url, params).then(function (data) {
                    if (data.callSuccess === "1") {
                        AlertService.add("success", "Data saved Successfully.", 4000);
                        var args = {combination_keys: _data.rowData.HO_COMBINATION_KEY,gridFilter: {combination_keys: _data.rowData.HO_COMBINATION_KEY}};
                        $rootScope.$emit('gridUpdate', args);
                        defer.resolve(data);
                    } else {
                        if(data.errorMessage){
                            AlertService.add("error",data.errorMessage, 4000);
                            console.log(data);
                            $rootScope.$broadcast('gridRefresh', false);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                            console.log(data);
                            $rootScope.$broadcast('gridRefresh', false);
                        }
                        defer.reject(data);
                    }
                },function(data){
                    defer.reject(data);
                });
                return defer.promise;
            }

            giltiCalcGridFactory.calculationGridInputs = function(_callName ,_data){
                var defer = $q.defer();
                console.log('data from gilti calc: ' , _data);
                $rootScope.$broadcast('gridRefresh', false); 
                var service = $injector.get('JsonObjectFactory');
                var url = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=j0pcb3";
                var saveData = true;
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, GlobalService.globalParams, filterParams);
                params.object_id = _data.rowData.object_id;
               
                //params.tax_year = _data.rowData.TAX_YEAR;
                params.filing_group = _data.rowData.FILING_GROUP;
                params.ho_leid = _data.rowData.HO_LEID;
                params.ho_cdr_no = _data.rowData.HO_CDR_NO;
                params.shareholder_leid = _data.rowData.SHAREHOLDER_LEID;
                params.shareholder_cdr_no = _data.rowData.SHAREHOLDER_CDR_NO;
                params.func_curr = _data.rowData.FUNC_CURR;

                params.col_type = _data.colData.map;
                params.col_val = _data.rowData[_data.colData.map];
                params.is_issue_key_enabled = "N";
                
            
                service.saveJSON(url,params).then(function (data) {
                    if (data.callSuccess === "1") {						
                        AlertService.add("success", "Changes have been saved", 3000);
                        var args = {cfc_leid: _data.rowData.CFC_LEID};
                        $rootScope.$emit('gridUpdate', args);
                         defer.resolve(data);
                     }else {
                      AlertService.add("error", data.errorMessage, 4000);
                      defer.reject(data);
                  }},function(data){
                  defer.reject(data);
              });
              return defer.promise;
            }

            return giltiCalcGridFactory;
        }]);

    return services;
});









