define([
    'angular'
], function () {
    'use strict';
    const services = angular.module('app.formViewDirectives', [])
                            .directive('viewPdf', ["$rootScope",function ($rootScope) {

                                // 1. Takes LEID, FormName and divHeight as params
                                // 2. Checks if the form pdf exits in s3
                                // 3. Fills form and renders the PDf
                                return {
                                    controllerAs: 'ctrl',
                                    bindToController: true,
                                    controller: ['$scope', 'WorkflowService', '$sce', 'AlertService', 'workspaceFactory', 'FormViewFactory','$rootScope', 'GlobalService', viewPdfCtrl],
                                    templateUrl: 'app/components/formView/templates/viewPdfDirective.html',
                                    scope: {
                                        divHeight:'=',
                                        params:'=',
                                        toMerge:'='
                                    },
                                    link: link
                                }
                 
                                function link(scope, element, attr, ctrl) {
                 

                                    
                                }
                 
                                function viewPdfCtrl($scope, WorkflowService, $sce, AlertService, workspaceFactory, FormViewFactory,  $rootScope, GlobalService) {
                                    var vm = this;
                                    vm.formLoaded = false;
                                    vm.loading = false;
                                    vm.cacheStore = FormViewFactory["formViewCache"];

                 
                                    $scope.$on('pdf:reload', function (ev,data) {
                                            vm.formLoaded = false;
                                            vm.loadPdf();
                                    });
                                    $scope.$on('pdf:run-action', function (ev,action) {
                                            runAction(action);
                                    });

                                    function runAction (action){
                                        if(vm.runningAction){
                                            return;
                                        }
                                        $rootScope.$emit('pdf:running-pdf-action');
                                        vm.runningAction = true;
                                        vm.loadPdf(action);
                                    }

                                    function downloadPdf(arrayBuffer){
                                        let blob = new Blob([arrayBuffer], {type: "application/pdf"});
                                        let link = document.createElement('a');
                                        link.href = window.URL.createObjectURL(blob);
                                        let returnType = vm.params.returnType === "1120" ? 
                                                                "Corporate" :
                                                                 vm.params.returnType === "1065" ?
                                                                  "Partnership" : 
                                                                  "Extension";
                                        link.download = `${vm.params.taxYear}_${returnType}_${vm.params.leName}(${vm.params.leId})_${vm.params.leType.includes('HO')? vm.params.leType + "_" : ""}${vm.params.formName}.pdf`;
                                        link.click();
                                        link.remove();
                                        vm.runningAction = false;
                                        $rootScope.$emit('pdf:completed-pdf-action');
                                    }
                                    function expandPdf(arrayBuffer){
                                        let blob = new Blob([arrayBuffer], {type: "application/pdf"});
                                        let link = document.createElement('a');
                                        let url = window.URL.createObjectURL(blob);
                                        window.open(url,'_blank');
                                        vm.runningAction = false;
                                        $rootScope.$emit('pdf:completed-pdf-action');
                                    }
                 
                                    vm.loadPdf = function (action) {

                                        if(action){
                                            vm.params.withDrillDowns = "0";
                                        }else{
                                            vm.loading = true;
                                            vm.formNotAvail = false;
                                            vm.noDataAvail = false;
                                            vm.params.withDrillDowns = "1";
                                        }
                                        FormViewFactory.getPdf(
                                            vm.params.pdfToBeMerged,vm.params
                                    ).then((response) => {
                                             if (!response.data) {
                                                 AlertService.add("error", "Something went wrong with filling the form.", 4000);
                                                 return;
                                             }
                                             if(response.data && response.data.byteLength === 0){
                                                 vm.noDataAvail = true;
                                                 return;
                                             }

                                             if(action === "download"){
                                                downloadPdf(response.data);
                                                 return;
                                             }else if(action === "expand"){
                                                expandPdf(response.data);
                                                 return;
                                             }
     
                                             vm.formLoaded = true;
                                             vm.name = vm.formName;
                                             vm.file = new Blob([response.data], {type: 'application/pdf'});
                                             vm.pdfPath = URL.createObjectURL(vm.file);
                                             vm.content = $sce.trustAsResourceUrl(vm.pdfPath + '#toolbar=0&navpanes=0');
                                             $rootScope.$emit('pdf:loaded');
                                             vm.cacheStore["pdfBlob"] = vm.content;
                                         
                                        }).catch(function(e) {
                                            vm.noDataAvail = true;
                                            AlertService.add("error", "Something went wrong with filling the form.", 4000);
                                        }).finally(() => {
                                            vm.loading = false;
                                        });
                                        }
                                    if(vm.cacheStore["pdfBlob"]){
                                        vm.content = vm.cacheStore["pdfBlob"];
                                        vm.loading = false;
                                        vm.formLoaded = true;
                                        $rootScope.$emit('pdf:loaded');
                                        return;
                                    }
                                    vm.loadPdf();
                                }
                            }])
                            .directive('reviewDiagEvents', ["$rootScope",function ($rootScope) {
                                return {
                                restrict: 'A',
                                link: function (scope, $element, attr) {
                                    $element.bind('on-cancel', function(event,data) {
                                        $rootScope.$emit('review:on-cancel');

                                    });

                                    $element.bind('on-data-loaded', function(event) {
                                        $rootScope.$emit('review:on-data-loaded',event.originalEvent.detail);
                                    });

                                    $element.bind('on-ignore', function(event) {
                                        $rootScope.$emit('review:on-ignore',event.originalEvent.detail);
                                    });
                                }
                                };
                            }])
                        })