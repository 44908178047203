define([
    'angular'
], function () {
    'use strict';
    var app = angular.module('app.dstViewPdfOfEfileController', []);
    app.controller('DstViewPdfOfEfileController', ['GlobalService', 'GENERAL_CONFIG', 'USER_SETTINGS', 'TAX_YEARS', 'SERVER_CONFIG', 'workspaceFactory', 'SystemLockUnlockFactory', 'AlertService', 'rowData', 'ModalFactory','$uibModalInstance','$rootScope','$scope', 'EfileFactory', viewPdfOfEfileCtrl])
    
    function viewPdfOfEfileCtrl(GlobalService, GENERAL_CONFIG, USER_SETTINGS, TAX_YEARS, SERVER_CONFIG, workspaceFactory, SystemLockUnlockFactory,AlertService, rowData,ModalFactory,$uibModalInstance,$rootScope,$scope,EfileFactory) {
        var vm = this;
        vm.webComponentHtml = '';
        vm.clientKey = GlobalService.getClient().client_key;
        vm.baseURLs = {};
        vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
       //get system lock status
        vm.message = "Hello from DataPushToEfileController!";
        var filterParams = workspaceFactory.activeScreen.filters.filterParams;
        var extractedData = []; 
        vm.loading = true;
       
        console.log(JSON.stringify(rowData));
        
        if(rowData.COMMENTS && rowData.COMMENTS.length > 0){
            EfileFactory.viewPdf(filterParams.submission_id, rowData.ATTACHMENT_NAME).then((res) => {
                var file = new Blob([res.data], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                this.loading = false;
                $uibModalInstance.dismiss('cancel'); 
                window.open(fileURL,"_blank", 'toolbar=0,location=0,menubar=0,height='+window.outerHeight);
            }).catch((err) => {
                this.loading = false;
                $uibModalInstance.dismiss('cancel'); 
                AlertService.add('error', "Something went wrong! Please try again.");
            });

        }else{
            AlertService.add('error', "Push the selected file to Efile before viewing the PDF.");
        }

        

        vm.eventCleanup = $rootScope.$on('cancel-pdf-push-confirmation',(e,type)=>{
            $uibModalInstance.dismiss('cancel');            
        });

        $scope.$on('$destroy', function () {
            vm.eventCleanup();
           
        }); 
    }
   

    return app;
}
);
