define([
    'angular',
    './dataPushToEfileController'
], function () {
    'use strict';
    var module = angular.module('app.dstDataPushToEfile', ['app.dstDataPushToEfileController'])
        
    return module;
}
);
