define([
    'angular'

], function () {
    'use strict';

    var services =  angular.module('app.firgiltiQBAIService',[])
        .factory("firgiltiQBAIServiceFactory",['$q','$http','$rootScope','$log','JsonObjectFactory','AlertService','$injector','workspaceFactory','GlobalService','USER_SETTINGS','$stateParams','GENERAL_CONFIG', 
        	function($q,$http,$rootScope, $log,JsonObjectFactory,AlertService, $injector,workspaceFactory,GlobalService,USER_SETTINGS,$stateParams,GENERAL_CONFIG) {
        	var firgiltiQBAIServiceFactory = {};
            var returnObj = {};
            var logged_in_user = USER_SETTINGS.user.sso_id;
            firgiltiQBAIServiceFactory.saveExternalQuarterAmounts = function(_callName ,_data, columnName){
            	 console.log("data", _data);
            	 
            if(!angular.isDefined(_data.rowData[_data.col_id]))
            {
            	var intr=setInterval(function(){ 
                    AlertService.add("error", "You cannot enter null or empty values, Please enter valid values");
                   clearInterval(intr);
                   $rootScope.$apply();
                },1000);
            }
            else if(_data.col_id === 'DUAL_USER_RATIO_OVERRIDE' &&  ( (_data.rowData[_data.col_id] !== null) && (_data.rowData[_data.col_id] !== "") && !(parseFloat(_data.rowData[_data.col_id]) >= 0 && parseFloat(_data.rowData[_data.col_id]) <= 1) ) )
            {
            	//AlertService.add("error", "Dual User Ratio Override value should be between 0 and 1.", 4000);
            	_data.rowData[_data.col_id] = angular.isDefined(_data.oldRowData[_data.col_id]) ? _data.oldRowData[_data.col_id] : null;
            	var intr=setInterval(function(){ 
                    AlertService.add("error", "Dual User Ratio Override value should be between 0 and 1.");
                   clearInterval(intr);
                   $rootScope.$apply();
                },1000);
            }
            else
            {
                $rootScope.$broadcast('gridRefresh', false); 
                var service = $injector.get('JsonObjectFactory');
                var defer = $q.defer();
                var url = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=6l3d6u";
                //var jsonSettings = JSON.stringify(clob_settings);
       		 	//var jsonObj = JSON.stringify(_callName);
       		 	var jsonObj = [{
	            	"tax_year" 			: _data.rowData.TAX_YEAR,       
	            	"leid"  			: _data.rowData.LEID,      
	                "cdr_no" 			: _data.rowData.CDR_NO, 
	                "me_code"			: _data.rowData.ME_CODE,
	                "curr"    			: _data.rowData.CURR,
	                "reporting_period"	: _data.rowData.REPORTING_PERIOD,
	                "col_type"           : _data.col_id,
	                "col_value"          : _data.rowData[_data.col_id],
	                "combination_key" : _data.rowData.COMBINATION_KEY
	                
	            }];
	            var jsonSettings = [{tax_year: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,sso_id: logged_in_user, screen_key: GlobalService.globalParams.screen_key}];
                var saveData = true;
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, GlobalService.globalParams, filterParams);
                params =  _.extend({jsonObj:JSON.stringify(jsonObj)}, params);
       		 	params =  _.extend({jsonSettings:JSON.stringify(jsonSettings)}, params);
                params.object_id = _data.rowData.object_id;
                params.sso_id = logged_in_user;
               
                //params.tax_year = rowData.TAX_YEAR;
                params.process_name =  "saveQBAIManualData";
                /*var promise =  $http({
                    method: "post",
                    url: url,
                    data: params
                }).then(function (response) {
                    if(response.data.callSuccess === "1" ){
                        AlertService.add("success", "Changes have been saved", 3000);
                        var args = {combination_keys: _data.rowData.COMBINATION_KEY,gridFilter: {combination_keys: _data.rowData.COMBINATION_KEY}};
                        $rootScope.$emit('gridUpdate', args);
                    }else{
                        if(response.data.errorMessage){
                            AlertService.add("error", response.data.errorMessage, 4000);
                            console.log(response);
                            $rootScope.$broadcast('gridRefresh', false);
                        }else{
                            AlertService.add("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
                            console.log(response);
                            $rootScope.$broadcast('gridRefresh', false);
                        }
                    }
                    return response.data;
                });
                return promise;
            }
            }*/
                service.saveJSON(url,params).then(function (data) {
		        	   if (data.callSuccess === "1") {						
		        		   AlertService.add("success", "Changes have been saved", 3000);
	                        var args = {combination_keys: _data.rowData.COMBINATION_KEY,gridFilter: {combination_keys: _data.rowData.COMBINATION_KEY}};
	                        $rootScope.$emit('gridUpdate', args);
							defer.resolve(data);
						}else {
	                     AlertService.add("error", data.errorMessage, 4000);
	                     defer.reject(data);
	                 }},function(data){
	                 defer.reject(data);
	             });
	             return defer.promise;
			   }
            }

            firgiltiQBAIServiceFactory.saveqbaiExternalAmts = function(_callName , _data){
                
                var defer = $q.defer();
                console.log('data from qbai: ' , _data);
                $rootScope.$broadcast('gridRefresh', false); 
                var service = $injector.get('JsonObjectFactory');
                var url = GENERAL_CONFIG.base_url+"/saveJsonObject?action_code=r1bt42";
                var saveData = true;
                var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
                var params = _.merge({}, GlobalService.globalParams, filterParams);
                params.object_id = _data.rowData.object_id;
               
                //params.tax_year = _data.rowData.TAX_YEAR;
                params.leid = _data.rowData.LEID;
                params.cdr_no = _data.rowData.CDR_NO;
                params.reporting_period = _data.rowData.REPORTING_PERIOD;
                params.bsla_code = _data.rowData.BSLA_CODE;
                params.reporting_curr = _data.rowData.REPORTING_CURR;
                params.col_type = _data.colData.map;
                params.is_issue_key_enabled = "N";
                if(_data.colData.map !== "EXT_SOURCE") {
                    params.col_val = _data.rowData[_data.colData.map];
                } else {
                    params.col_val = 0;
                }

                if(params.col_type === 'DUAL_USER_RATIO_OVERRIDE') {
                    var checkValue = _data.rowData['DUAL_USER_RATIO_OVERRIDE']; 
                    if(checkValue < 0 || checkValue > 1) {
                        saveData = false;
                        var intr=setInterval(function(){ 
                            AlertService.add("error", "The dual user ratio override should be between 0 and 1.");
                            clearInterval(intr);
                            $rootScope.$apply();
                        },500);
                    }
                }
                
                params.source_type = _data.rowData.EXT_SOURCE;
             
                if(saveData) {

                    service.saveJSON(url,params).then(function (data) {
                        if (data.callSuccess === "1") {						
                            AlertService.add("success", "Changes have been saved", 3000);
                            var args = {cfc_leid: _data.rowData.CFC_LEID};
                            $rootScope.$emit('gridUpdate', args);
                             defer.resolve(data);
                         }else {
                          AlertService.add("error", data.errorMessage, 4000);
                          defer.reject(data);
                      }},function(data){
                      defer.reject(data);
                  });
                  return defer.promise;
                   
                }
               
            }

            return firgiltiQBAIServiceFactory;
        }]);

    return services;
});










