define([
    'angular'
], function () {
    'use strict';
    var app = angular.module('app.dstPdfPushToEfileController', []);
    app.controller('DSTPdfPushToEfileController', ['GlobalService', 'GENERAL_CONFIG', 'USER_SETTINGS', 'TAX_YEARS', 'SERVER_CONFIG', 'workspaceFactory', 'SystemLockUnlockFactory', 'gridData', 'ModalFactory','$uibModalInstance','$rootScope','$scope', pdfPushToEfileCtrl])
    app.directive('pdfPushEvent', ['$rootScope','$uibModal', function ( $rootScope,$uibModal) {
        return {
        restrict: 'A',
        link: function (scope, $element, attr) {
            $element.bind('cancel-pdf-push-confirmation', function(event) {
                $rootScope.$emit('cancel-pdf-push-confirmation');
            });
            
        }
        };
    }])
    function pdfPushToEfileCtrl(GlobalService, GENERAL_CONFIG, USER_SETTINGS, TAX_YEARS, SERVER_CONFIG, workspaceFactory, SystemLockUnlockFactory, gridData,ModalFactory,$uibModalInstance,$rootScope,$scope) {
        var vm = this;
        vm.webComponentHtml = '';
        vm.clientKey = GlobalService.getClient().client_key;
        vm.baseURLs = {};
        vm.baseURLs.admin = GENERAL_CONFIG.admin_api_url;
       //get system lock status
        vm.message = "Hello from DataPushToEfileController!";
        var filterParams = workspaceFactory.activeScreen.filters.filterParams;
        var extractedData = []; 
        
        gridData.forEach(element => {
            extractedData.push({
                statementId: element.data.STATEMENT_ID,  
                formId: element.data.FORM_ID,
                fileName: element.data.ATTACHMENT_NAME,
                pdfComments: element.data.COMMENTS,
                formSub : element.data.FORM_SUB,
            });
        });
        console.log(JSON.stringify(extractedData));
        vm.pdfPushInput = JSON.stringify({
            baseUrl: GENERAL_CONFIG.admin_api_url,
            ssoId: USER_SETTINGS.user.sso_id,
            clientKey: USER_SETTINGS.user.client_key,
            taxYear : filterParams.tax_year,
            dcnId: filterParams.dcn_id,
            submissionId : filterParams.submission_id,
            gridData:JSON.stringify(extractedData),
            modalName : ModalFactory.response.data.modalScreen.modal_name
          });

        vm.eventCleanup = $rootScope.$on('cancel-pdf-push-confirmation',(e,type)=>{
            $uibModalInstance.dismiss('cancel');     
            $rootScope.$broadcast('dataFilters:refreshGrid', {
                "refresh": true
            });       
        });

        $scope.$on('$destroy', function () {
            vm.eventCleanup();
           
        }); 
    }
   

    return app;
}
);
