define([
    'angular'
], function () {
    'use strict';
    var controllers = angular.module('app.F1065ScheduleBCtrl', [])

    	.directive("limitToMax", function() {
		return {
			link: function(scope, element, attributes) {
				element.on("keydown keyup", function(e) {
					if (Number(element.val()) > Number(attributes.max) &&
					  e.keyCode != 46 // delete
					  &&
					  e.keyCode != 8 // backspace
					) {
						e.preventDefault();
						element.val(attributes.max);
					}
					});
				}
			};
		})
		.directive('onlyNumber', function () {
            return {
                require: 'ngModel',
                restrict: 'A',
                link: function (scope, element, attr, ctrl) {
                    function inputValue(val) {
                        if (val) {
                            var digits = val.replace(/[^0-9]/g, '');

                            if (digits !== val) {
                                ctrl.$setViewValue(digits);
                                ctrl.$render();
                            }
                            return parseInt(digits);
                        }
                        return undefined;
                    }

                    ctrl.$parsers.push(inputValue);
                }
            };
        })

        .controller('F1065ScheduleBController', ['GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse','$scope','$filter','$rootScope', '$timeout','rowData', 'colData' , 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG','USER_SETTINGS',F1065ScheduleBController]);
    	function F1065ScheduleBController(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $filter,$rootScope, $timeout, rowData, colData, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS) {
        var vm = this;

        vm.modalTitle = "Schedule B Other Information";
		vm.originalData = [];
		vm.leId = rowData.LEID;
        vm.formName = '1120PC';
        vm.hgt = '96%';
        vm.cdr_no = rowData.MARS;
        vm.leType = rowData.LE_TYPE;
        vm.systemFlag = 'D';
		vm.isSaving = false;

        vm.formData = {
            "addrows"   : [],
            "editrows"  : [],
            "removerows": []
        };
        $scope.entityList = {
			"Tax Year": rowData.TAX_YEAR,
			"LEID": rowData.LEID,
			"LE Name": rowData.LE_NAME
		};

		vm.scenarioLabel = $filter("filter")(workspaceFactory.activeScreen.filters.filters,{param_name:'scenario'})[0].selected.LABEL;

        vm.title = vm.modalTitle + ' ( Tax Year : ' + rowData.TAX_YEAR + ' - Scenario : ' + vm.scenarioLabel + ' )'; 

        if(typeof rowData.ISSUE_DESC !== 'undefined') {
            vm.ISSUE_DESC =  rowData.ISSUE_DESC;
            
        }
        else
            vm.ISSUE_DESC = '';


		$scope.dropdownSetting ={
			enableSearch: true,
			selectionLimit: 1,
			scrollableHeight: '200px', 
			scrollable: true,
			showUncheckAll:false,
			showCheckAll:false,
			smartButtonTextConverter: function(itemText, originalItem) { 
                return itemText; 
			},
			smartButtonTextProvider:function(selectionArray) { return selectionArray[0].label || "Select"; },
		};

		$scope.multiselectEvents1 = {
			onItemSelect: function (item) {
                console.log(item);
			},
			onItemDeselect: function (item) {
				console.log('deselect',item);

			}
		};
		$scope.multiselectEvents2 = {

			onItemSelect: function (item) {
				if(vm.tax_year <= 2020) {
					console.log(item);
					if(item.id == "US"){
						$scope.scheduleBFormView.rows[50].attrib_value_d = [vm.allUSStates[0]];
					}else{
						$scope.scheduleBFormView.rows[50].attrib_value_d = "";
					}
					$scope.madatoryFeilds25(50);
				}
				else if(vm.tax_year > 2020 && vm.tax_year != 2023 && vm.tax_year != 2024) {
					console.log(item);
					if(item.id == "US"){
						$scope.scheduleBFormView.rows[54].attrib_value_d = [vm.allUSStates[0]];
					}else{
						$scope.scheduleBFormView.rows[54].attrib_value_d = "";
					}
					$scope.madatoryFeilds29(54);
				}
				else if(vm.tax_year == 2023) {
					console.log(item);
					if(item.id == "US"){
						$scope.scheduleBFormView.rows[59].attrib_value_d = [vm.allUSStates[0]];
					}else{
						$scope.scheduleBFormView.rows[59].attrib_value_d = "";
					}
					$scope.madatoryFeilds29(59);
				}
				else if(vm.tax_year == 2024) {
					console.log(item);
					if(item.id == "US"){
						$scope.scheduleBFormView.rows[61].attrib_value_d = [vm.allUSStates[0]];
					}else{
						$scope.scheduleBFormView.rows[61].attrib_value_d = "";
					}
					$scope.madatoryFeilds29(61);
				}
			},
			onItemDeselect: function (item) {
				if(vm.tax_year <= 2020) {
					console.log('deselect',item);
					// $scope.scheduleBFormView.rows[50].attrib_value_f = [vm.allCountries[vm.USCountry_index]]
					$scope.scheduleBFormView.rows[50].attrib_value_d = "";
					$scope.madatoryFeilds25(50);
				}
				else if(vm.tax_year > 2020 && vm.tax_year != 2023 && vm.tax_year != 2024) {
					console.log('deselect',item);
					// $scope.scheduleBFormView.rows[50].attrib_value_f = [vm.allCountries[vm.USCountry_index]]
					$scope.scheduleBFormView.rows[54].attrib_value_d = "";
					$scope.madatoryFeilds29(54);
				}
				else if(vm.tax_year == 2023) {
					console.log('deselect',item);
					// $scope.scheduleBFormView.rows[50].attrib_value_f = [vm.allCountries[vm.USCountry_index]]
					$scope.scheduleBFormView.rows[59].attrib_value_d = "";
					$scope.madatoryFeilds29(59);
				}
				else if(vm.tax_year == 2024) {
					console.log('deselect',item);
					// $scope.scheduleBFormView.rows[50].attrib_value_f = [vm.allCountries[vm.USCountry_index]]
					$scope.scheduleBFormView.rows[61].attrib_value_d = "";
					$scope.madatoryFeilds29(61);
				}
			}
		};
		$scope.multiselectEvents3 = {
			onItemSelect: function (item) {
				if(vm.tax_year <= 2020) {
					console.log(item);
					if(item.id == "US"){
						$scope.scheduleBFormView.rows[53].attrib_value_d = [vm.allUSStates[0]];
					}else{
						$scope.scheduleBFormView.rows[53].attrib_value_d = "";
					}
					$scope.madatoryFeilds25(53);
				}
				else if(vm.tax_year > 2020 && vm.tax_year != 2023 && vm.tax_year != 2024) {
					console.log(item);
					if(item.id == "US"){
						$scope.scheduleBFormView.rows[57].attrib_value_d = [vm.allUSStates[0]];
					}else{
						$scope.scheduleBFormView.rows[57].attrib_value_d = "";
					}
					$scope.madatoryFeilds29(57);
				}
				else if(vm.tax_year == 2023) {
					console.log(item);
					if(item.id == "US"){
						$scope.scheduleBFormView.rows[62].attrib_value_d = [vm.allUSStates[0]];
					}else{
						$scope.scheduleBFormView.rows[62].attrib_value_d = "";
					}
					$scope.madatoryFeilds29(62);
				}
				else if(vm.tax_year == 2024) {
					console.log(item);
					if(item.id == "US"){
						$scope.scheduleBFormView.rows[64].attrib_value_d = [vm.allUSStates[0]];
					}else{
						$scope.scheduleBFormView.rows[64].attrib_value_d = "";
					}
					$scope.madatoryFeilds29(64);
				}
			},
			onItemDeselect: function (item) {
				if(vm.tax_year <= 2020) {
					console.log('deselect',item);
					// $scope.scheduleBFormView.rows[53].attrib_value_f = [vm.allCountries[vm.USCountry_index]]
					$scope.scheduleBFormView.rows[53].attrib_value_d = "";
					$scope.madatoryFeilds25(53);
				}
				else if(vm.tax_year > 2020 && vm.tax_year != 2023 && vm.tax_year != 2024) {
					console.log('deselect',item);
					// $scope.scheduleBFormView.rows[53].attrib_value_f = [vm.allCountries[vm.USCountry_index]]
					$scope.scheduleBFormView.rows[57].attrib_value_d = "";
					$scope.madatoryFeilds29(57);
				}
				else if(vm.tax_year == 2023) {
					console.log('deselect',item);
					// $scope.scheduleBFormView.rows[53].attrib_value_f = [vm.allCountries[vm.USCountry_index]]
					$scope.scheduleBFormView.rows[62].attrib_value_d = "";
					$scope.madatoryFeilds29(62);
				}
				else if(vm.tax_year == 2024) {
					console.log('deselect',item);
					// $scope.scheduleBFormView.rows[53].attrib_value_f = [vm.allCountries[vm.USCountry_index]]
					$scope.scheduleBFormView.rows[64].attrib_value_d = "";
					$scope.madatoryFeilds29(64);
				}
			}
		};
		var saveParamDtls = [];
		var tempDataObj = {};
		$scope.scheduleBFormView = {rows:[]};
		vm.showFormLoader = true;
		vm.corporationList = [];
		vm.entityList = [];
		vm.entitiesData = [];
		vm.individualsList = [];

		vm.tax_year = rowData.TAX_YEAR;

        function fetchSchIrecords() {
            var params = { "action_id": 31259, "tax_year": rowData.TAX_YEAR, "scenario": rowData.SCENARIO_KEY, "jcd_key": rowData.JCD_KEY, /*"group_obj_key": 201473*/ "combination_key": rowData.COMBINATION_KEY, "schedule_name": "B", "part_no" : "NA"};

            return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadPivotFormJson', params).then(function (data) {

            	console.log('data.irsForm.formItemList = ', data.irsForm.formItemList);
            	for(var i=1;i<data.irsForm.formItemList.length;i++){
					saveParamDtls = [];
					tempDataObj = {};
					saveParamDtls = data.irsForm.formItemList[i].dataRowList[0].rowDimensions.object_id.split('~');
					var rows = data.irsForm.formItemList[i].dataRowList[0].dataCellList.length;
					tempDataObj.num = data.irsForm.formItemList[i].dataRowList[0].dataCellList[0].name;
					tempDataObj.description = data.irsForm.formItemList[i].dataRowList[0].dataCellList[1].name;
					if(rows > 2){
						iterateArray(rows, data.irsForm.formItemList[i].dataRowList[0].dataCellList);
					}
					tempDataObj.line_order = saveParamDtls[8];
					tempDataObj.saveParams = saveParamDtls;
					$scope.scheduleBFormView.rows.push(tempDataObj);
				}
            	if(vm.tax_year <= 2020) {
					if($scope.scheduleBFormView.rows[48].value === false){
						toggleConditionalField([49,50,51,52,53,54], false);
					}else{
						toggleConditionalField([49,50,51,52,53,54], true);
					}
				}
				else if(vm.tax_year > 2020 && vm.tax_year != 2023 && vm.tax_year != 2024) {
					if($scope.scheduleBFormView.rows[52].value === false){
						toggleConditionalField([53,54,55,56,57,58], false);
					}else{
						toggleConditionalField([53,54,55,56,57,58], true);
					}
				}else if(vm.tax_year == 2023) {
					if($scope.scheduleBFormView.rows[57].value === false){
						toggleConditionalField([58,59,60,61,62,63], false);
					}else{
						toggleConditionalField([58,59,60,61,62,63], true);
					}
				}
				else if(vm.tax_year == 2024) {
				if($scope.scheduleBFormView.rows[58].value === false){
					toggleConditionalField([60,61,62,63,64,65], false);
				}else{
					toggleConditionalField([60,61,62,63,64,65], true);
				}
			}
				vm.originalData = angular.copy($scope.scheduleBFormView);
				initFormView($scope.scheduleBFormView);
				vm.showFormLoader = false;
            });
        };

        var iterateArray = function(count, array){
			if(count == 8){
				//addressline 1
				tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
				tempDataObj.attrib_name_a = array[2].key.split('~')[0];
				tempDataObj.attrib_order_a = array[2].key.split('~')[2];
				tempDataObj.attrib_mandatory_a = true;
				tempDataObj.attrib_value_a = array[2].value;
				//addressline 2
				tempDataObj.lineAttrKey_b = array[3].key.split('~')[1];
				tempDataObj.attrib_name_b = array[3].key.split('~')[0];
				tempDataObj.attrib_order_b = array[3].key.split('~')[2];
				tempDataObj.attrib_value_b = array[3].value;
				//city
				tempDataObj.lineAttrKey_c = array[4].key.split('~')[1];
				tempDataObj.attrib_name_c = array[4].key.split('~')[0];
				tempDataObj.attrib_order_c = array[4].key.split('~')[2];
				tempDataObj.attrib_value_c = array[4].value;
				//state
				tempDataObj.lineAttrKey_d = array[5].key.split('~')[1];
				tempDataObj.attrib_name_d = array[5].key.split('~')[0];
				tempDataObj.attrib_order_d = array[5].key.split('~')[2];
				// tempDataObj.attrib_value_d = array[5].value?array[5].value : "AK";
				//zip
				tempDataObj.lineAttrKey_e = array[6].key.split('~')[1];
				tempDataObj.attrib_name_e = array[6].key.split('~')[0];
				tempDataObj.attrib_order_e = array[6].key.split('~')[2];
				tempDataObj.attrib_value_e = array[6].value;
				//country
				tempDataObj.lineAttrKey_f = array[7].key.split('~')[1];
				tempDataObj.attrib_name_f = array[7].key.split('~')[0];
				tempDataObj.attrib_order_f = array[7].key.split('~')[2];
				tempDataObj.attrib_mandatory_f = true;
				if(array[7].value){
					// tempDataObj.attrib_value_f = vm.allCountries.filter(country => array[7].value == country.COUNTRY_ID );
					for (let i =0 ; i< vm.allCountries.length ;i++){
						if(vm.allCountries[i].id == array[7].value){
							tempDataObj.attrib_value_f = [vm.allCountries[i]];
						}
					}
				}else{
					tempDataObj.attrib_value_f = [vm.allCountries[vm.USCountry_index]];
				}
				
				if(tempDataObj.attrib_value_f[0].id == "US"){
					tempDataObj.attrib_mandatory_e = true;
					tempDataObj.attrib_mandatory_d = true;
					tempDataObj.attrib_mandatory_c = true;
					if(array[5].value){
						// tempDataObj.attrib_value_d  = vm.allUSStates.filter(country => array[7].value == country.COUNTRY_ID );
						for (let i =0 ; i< vm.allUSStates.length ;i++){
							if(vm.allUSStates[i].id == array[5].value){
								tempDataObj.attrib_value_d = [vm.allUSStates[i]];
							}
						}
					}else{
						tempDataObj.attrib_value_d  = [vm.allUSStates[0]];
					}
					
				}else{
					tempDataObj.attrib_mandatory_e = false;
					tempDataObj.attrib_mandatory_d = false;
					tempDataObj.attrib_mandatory_c = false;
					tempDataObj.attrib_value_d = array[5].value ;
				}

			}else if(count == 3){
				tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
				tempDataObj.attrib_name_a = array[2].key.split('~')[0];
				tempDataObj.attrib_order_a = array[2].key.split('~')[2];
				if(array[2].value == null){
					if(tempDataObj.num == "1a" || tempDataObj.num == "1b" || tempDataObj.num == '1c' || tempDataObj.num == '1d' || tempDataObj.num == '1e' || tempDataObj.num == '11' || tempDataObj.num == '32'){
						tempDataObj.value = false;
					}else {
						tempDataObj.value = '';
					}
				}else if(array[2].value === "true"){
					if(tempDataObj.num == "1a" || tempDataObj.num == "1b" || tempDataObj.num == '1c' || tempDataObj.num == '1d' || tempDataObj.num == '1e' || tempDataObj.num == '11'|| tempDataObj.num == '32'){
						tempDataObj.value = true;
					}else {
						tempDataObj.value = '';
					}
				}else if (array[2].value === "false"){
					if(tempDataObj.num == "1a" || tempDataObj.num == "1b" || tempDataObj.num == '1c' || tempDataObj.num == '1d' || tempDataObj.num == '1e' || tempDataObj.num == '11'|| tempDataObj.num == '32'){
						tempDataObj.value = false;
					}else {
						tempDataObj.value = '';
					}
				}else if (array[2].value === "0"){
					if(tempDataObj.num == "1a" || tempDataObj.num == "1b" || tempDataObj.num == '1c' || tempDataObj.num == '1d' || tempDataObj.num == '1e' || tempDataObj.num == '11' || tempDataObj.num == '32'){
						tempDataObj.value = false;
					}else {
						tempDataObj.value = '';
					}
				}else if (array[2].value == 'NA'){
					if(tempDataObj.num == "1a" || tempDataObj.num == "1b" || tempDataObj.num == '1c' || tempDataObj.num == '1d' || tempDataObj.num == '1e' || tempDataObj.num == '11' || tempDataObj.num == '32'){
						tempDataObj.value = false;
					}else {
						tempDataObj.value = '';
					}
				}else {
					tempDataObj.value = array[2].value;
				}
			}else if(count == 4){
				if(tempDataObj.num == "1f"){
					tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
					tempDataObj.attrib_name_a = array[2].key.split('~')[0];
					tempDataObj.attrib_order_a = array[2].key.split('~')[2];
					tempDataObj.value = array[2].value;
					tempDataObj.lineAttrKey_b = array[3].key.split('~')[1];
					tempDataObj.attrib_name_b = array[3].key.split('~')[0];
					tempDataObj.attrib_order_b = array[3].key.split('~')[2];
					tempDataObj.value_user_input = array[3].value;
					if(tempDataObj.value == null){
						tempDataObj.value = false;
						tempDataObj.conditionalField = true;
					}else if(tempDataObj.value === "true"){
						tempDataObj.value = true;
						tempDataObj.conditionalField = false;
					}else if (array[2].value === "false"){
						tempDataObj.value = false;
						tempDataObj.conditionalField = true;
					}else if (array[2].value === "0"){
						tempDataObj.value = false;
						tempDataObj.conditionalField = true;
					}else {
						tempDataObj.value = false;
						tempDataObj.conditionalField = true;
					}
				}else{
					tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
					tempDataObj.attrib_name_a = array[2].key.split('~')[0];
					tempDataObj.attrib_order_a = array[2].key.split('~')[2];

					tempDataObj.lineAttrKey_b = array[3].key.split('~')[1];
					tempDataObj.attrib_name_b = array[3].key.split('~')[0];
					tempDataObj.attrib_order_b = array[3].key.split('~')[2];

					if(array[2].value === "true"){
						tempDataObj.value = true;
					}else if(array[3].value === "true"){
						tempDataObj.value = false;
					}else{
						tempDataObj.value = 'NA';
					}

					if(tempDataObj.num == '2a'){
						tempDataObj.JSON_DATA = vm.entitiesData;
					}else if(tempDataObj.num == '2b'){
						tempDataObj.JSON_DATA = vm.individualsList;
					}else if(tempDataObj.num == '3a'){
						tempDataObj.JSON_DATA = vm.corporationList;
					}else{
						tempDataObj.JSON_DATA = vm.entityList;
					}
				}
			}else{
				tempDataObj.lineAttrKey_a = array[2].key.split('~')[1];
				tempDataObj.attrib_name_a = array[2].key.split('~')[0];
				tempDataObj.attrib_order_a = array[2].key.split('~')[2];

				tempDataObj.lineAttrKey_b = array[3].key.split('~')[1];
				tempDataObj.attrib_name_b = array[3].key.split('~')[0];
				tempDataObj.attrib_order_b = array[3].key.split('~')[2];

				if(array[2].value === "true"){
					tempDataObj.value = true;
				}else if(array[3].value === "true"){
					tempDataObj.value = false;
				}else{
					tempDataObj.value = 'NA';
				}
				tempDataObj.lineAttrKey_c = array[4].key.split('~')[1];
				tempDataObj.attrib_name_c = array[4].key.split('~')[0];
				tempDataObj.attrib_order_c = array[4].key.split('~')[2];

				if(vm.tax_year <= 2020) {
					if(tempDataObj.value === true){
						if(tempDataObj.num == "8" || tempDataObj.num == "14" || tempDataObj.num == '22' || tempDataObj.num == '25' || tempDataObj.num == '26' || tempDataObj.num == '29'){
							tempDataObj.value_user_input = array[4].value;
							tempDataObj.conditionalField = false;
						}
					}else{
						if(tempDataObj.num == "8" || tempDataObj.num == "14" || tempDataObj.num == '22' || tempDataObj.num == '25' || tempDataObj.num == '26' || tempDataObj.num == '29'){
							tempDataObj.value_user_input = array[4].value;
							tempDataObj.conditionalField = true;
						}
					}
					if(tempDataObj.num == '29'){

						tempDataObj.lineAttrKey_d = array[array.length-1].key.split('~')[1];
						tempDataObj.attrib_name_d = array[array.length-1].key.split('~')[0];
						tempDataObj.attrib_order_d = array[array.length-1].key.split('~')[2];
						tempDataObj.value_user_input2 = array[array.length-1].value;
					}
				}
				else if(vm.tax_year > 2020 && vm.tax_year != 2023 && vm.tax_year != 2024) {
					if(tempDataObj.value === true){
						if(tempDataObj.num == "8" || tempDataObj.num == "14" || tempDataObj.num == '22' || tempDataObj.num == '25' || tempDataObj.num == '27' || tempDataObj.num == '28'){
							tempDataObj.value_user_input = array[4].value;
							tempDataObj.conditionalField = false;
						}
					}else{
						if(tempDataObj.num == "8" || tempDataObj.num == "14" || tempDataObj.num == '22' || tempDataObj.num == '25' || tempDataObj.num == '27' || tempDataObj.num == '28'){
							tempDataObj.value_user_input = array[4].value;
							tempDataObj.conditionalField = true;
						}
					}
					if(tempDataObj.num == '28'){

						tempDataObj.lineAttrKey_d = array[array.length-1].key.split('~')[1];
						tempDataObj.attrib_name_d = array[array.length-1].key.split('~')[0];
						tempDataObj.attrib_order_d = array[array.length-1].key.split('~')[2];
						tempDataObj.value_user_input2 = array[array.length-1].value;
					}

				}
				else if(vm.tax_year == 2023) {
					if(tempDataObj.value === true){
						if(tempDataObj.num == "8" || tempDataObj.num == "14" || tempDataObj.num == '22' || tempDataObj.num == '25' || tempDataObj.num == '27' || tempDataObj.num == '28' || tempDataObj.num == '10a' || tempDataObj.num == '10b' || tempDataObj.num == '10c' || tempDataObj.num == '10d'){
							tempDataObj.value_user_input = array[4].value;
							tempDataObj.conditionalField = false;
						}
					}else{
						if(tempDataObj.num == "8" || tempDataObj.num == "14" || tempDataObj.num == '22' || tempDataObj.num == '25' || tempDataObj.num == '27' || tempDataObj.num == '28' || tempDataObj.num == '10a' || tempDataObj.num == '10b' || tempDataObj.num == '10c' || tempDataObj.num == '10d'){
							tempDataObj.value_user_input = array[4].value;
							tempDataObj.conditionalField = true;
						}
					}
					if(tempDataObj.num == '28' || tempDataObj.num == '10b' || tempDataObj.num == '10c'){
						tempDataObj.lineAttrKey_d = array[array.length-1].key.split('~')[1];
						tempDataObj.attrib_name_d = array[array.length-1].key.split('~')[0];
						tempDataObj.attrib_order_d = array[array.length-1].key.split('~')[2];
						tempDataObj.value_user_input2 = array[array.length-1].value;
					}

				}
				else if(vm.tax_year == 2024) {
					if(tempDataObj.value === true){
						if(tempDataObj.num == "8" || tempDataObj.num == "14" || tempDataObj.num == '22' || tempDataObj.num == '25' || tempDataObj.num == '27' || tempDataObj.num == '28' || tempDataObj.num == '10a' || tempDataObj.num == '10b' || tempDataObj.num == '10c' || tempDataObj.num == '10d'){
							tempDataObj.value_user_input = array[4].value;
							tempDataObj.conditionalField = false;
						}
					}else{
						if(tempDataObj.num == "8" || tempDataObj.num == "14" || tempDataObj.num == '22' || tempDataObj.num == '25' || tempDataObj.num == '27' || tempDataObj.num == '28' || tempDataObj.num == '10a' || tempDataObj.num == '10b' || tempDataObj.num == '10c' || tempDataObj.num == '10d'){
							tempDataObj.value_user_input = array[4].value;
							tempDataObj.conditionalField = true;
						}
					}
					if(tempDataObj.num == '28' || tempDataObj.num == '10b' || tempDataObj.num == '10c'){
						tempDataObj.lineAttrKey_d = array[array.length-1].key.split('~')[1];
						tempDataObj.attrib_name_d = array[array.length-1].key.split('~')[0];
						tempDataObj.attrib_order_d = array[array.length-1].key.split('~')[2];
						tempDataObj.value_user_input2 = array[array.length-1].value;
					}

				}
			}
		};

		vm.limitDecimal = function(val, line, i, attr){
            var index = _.findIndex($scope.scheduleBFormView.rows, {num: line});
            if (Number(val) > 100){
                $scope.scheduleBFormView.rows[index].JSON_DATA[i][attr] = 100;
            }
        };

		vm.onlyPercentage = function(val, line, i, attr){
            var index = _.findIndex($scope.scheduleBFormView.rows, {num: line});
            if (Number(val) > 100){
                $scope.scheduleBFormView.rows[index][attr] = 100;
            }
        };

        function fetchSubTableRecords(){
            var params = {
            	combination_key: rowData.COMBINATION_KEY,
            	schedule: "B"
            };
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=z57kmr', params).then(function (data) {
				angular.forEach(data.jsonObject, function (row, key) {
					var temp;
					if(row.LINE_NO == '2a1'){
						// row.DATA_ATTRIB_D = vm.allCountries.filter(country => row.DATA_ATTRIB_D == country.COUNTRY_ID );
						row.DATA_ATTRIB_D = findCountryID(row.DATA_ATTRIB_D);
						row.DATA_ATTRIB_E = parseFloat(row.DATA_ATTRIB_E);
	            		vm.entitiesData.push(row);
					}else if(row.LINE_NO == '2b1'){
						// row.DATA_ATTRIB_C = vm.allCountries.filter(country => row.DATA_ATTRIB_C == country.COUNTRY_ID );
						row.DATA_ATTRIB_C = findCountryID(row.DATA_ATTRIB_C);
						row.DATA_ATTRIB_D = parseFloat(row.DATA_ATTRIB_D);
	            		vm.individualsList.push(row);
					}else if(row.LINE_NO == '3a1'){
						// row.DATA_ATTRIB_C = vm.allCountries.filter(country => row.DATA_ATTRIB_C == country.COUNTRY_ID );
						row.DATA_ATTRIB_C = findCountryID(row.DATA_ATTRIB_C);
	            		row.DATA_ATTRIB_D = parseFloat(row.DATA_ATTRIB_D);
	            		vm.corporationList.push(row);
	            	}else{
						// row.DATA_ATTRIB_D = vm.allCountries.filter(country => row.DATA_ATTRIB_D == country.COUNTRY_ID );
						row.DATA_ATTRIB_D = findCountryID(row.DATA_ATTRIB_D);
	            		row.DATA_ATTRIB_E = parseFloat(row.DATA_ATTRIB_E);
	            		vm.entityList.push(row);
	            	}
	        	});
            });
		};
		function findCountryID(temp){
			for (let i =0 ; i< vm.allCountries.length ;i++){
				if(vm.allCountries[i].id == temp){
					return [vm.allCountries[i]];
				}
			}
			return [];
		}
		
		function fetchCountryList(){
			var params = {
            	// combination_key: rowData.COMBINATION_KEY,
            	// schedule: "B"
            };
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=fyl404').then(function (data) {
				vm.allCountries = []
				data.jsonObject.forEach(function(country, index){
					if(country.COUNTRY_CODE == "US") {
						vm.USCountry_index =  index;
					}
					vm.allCountries.push({"id":country.COUNTRY_CODE,"label":country.COUNTRY_DESC})
				});
            });
		}
		function fetchUSStateList(){
			var params = {
            	// combination_key: rowData.COMBINATION_KEY,
            	// schedule: "B"
            };
			return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url+'/loadJsonObject?action_code=oppj8b').then(function (data) {
				vm.allUSStates =[];
				 data.jsonObject.forEach(function(state){
					 vm.allUSStates.push({"id":state.STATE_CODE,"label":state.STATE_DESC})
				 });
            });
		}
        function toggleConditionalField(arr, flag){
        	angular.forEach(arr, function (value, key) {

        		if($scope.scheduleBFormView.rows[value] != undefined) {

					if("conditionalField" in $scope.scheduleBFormView.rows[value])
						$scope.scheduleBFormView.rows[value].conditionalField = flag;

					if(flag){
						$scope.scheduleBFormView.rows[value].value = "";
					}
					if(vm.tax_year <= 2020) {
						if((value == 50 || value == 53 ) && flag ==true ){
							$scope.scheduleBFormView.rows[value].attrib_value_f = [vm.allCountries[vm.USCountry_index]];
							$scope.scheduleBFormView.rows[value].attrib_value_e =  '';
							$scope.scheduleBFormView.rows[value].attrib_value_d = [];
							$scope.scheduleBFormView.rows[value].attrib_value_c = '' ;
							$scope.scheduleBFormView.rows[value].attrib_value_b = '' ;
							$scope.scheduleBFormView.rows[value].attrib_value_a = '' ;
						}
					}
					else if( vm.tax_year > 2020 && vm.tax_year != 2023 && vm.tax_year != 2024) {
						if((value == 54 || value == 57 ) && flag ==true ){
							$scope.scheduleBFormView.rows[value].attrib_value_f = [vm.allCountries[vm.USCountry_index]];
							$scope.scheduleBFormView.rows[value].attrib_value_e =  '';
							$scope.scheduleBFormView.rows[value].attrib_value_d = [];
							$scope.scheduleBFormView.rows[value].attrib_value_c = '' ;
							$scope.scheduleBFormView.rows[value].attrib_value_b = '' ;
							$scope.scheduleBFormView.rows[value].attrib_value_a = '' ;
						}
					}
					else if(vm.tax_year == 2023) {
						if((value == 59 || value == 62 ) && flag ==true ){
							$scope.scheduleBFormView.rows[value].attrib_value_f = [vm.allCountries[vm.USCountry_index]];
							$scope.scheduleBFormView.rows[value].attrib_value_e =  '';
							$scope.scheduleBFormView.rows[value].attrib_value_d = [];
							$scope.scheduleBFormView.rows[value].attrib_value_c = '' ;
							$scope.scheduleBFormView.rows[value].attrib_value_b = '' ;
							$scope.scheduleBFormView.rows[value].attrib_value_a = '' ;
						}
					}
					else if(vm.tax_year == 2024) {
						if((value == 61 || value == 65 ) && flag ==true ){
							$scope.scheduleBFormView.rows[value].attrib_value_f = [vm.allCountries[vm.USCountry_index]];
							$scope.scheduleBFormView.rows[value].attrib_value_e =  '';
							$scope.scheduleBFormView.rows[value].attrib_value_d = [];
							$scope.scheduleBFormView.rows[value].attrib_value_c = '' ;
							$scope.scheduleBFormView.rows[value].attrib_value_b = '' ;
							$scope.scheduleBFormView.rows[value].attrib_value_a = '' ;
						}
					}
				}

        	});
        };

		function initSchB(){
			fetchUSStateList();
			fetchCountryList().then(function(){
				fetchSubTableRecords();
				fetchSchIrecords();
			});
			
			
			// fetchSubTableRecords();
		};
		initSchB();

		$scope.fieldVisibility = function (index, state, name){
			if(vm.tax_year <= 2020) {
				if(index <= 6){
					if(state && index == 1){
						setValue([2,3,4,5,6], [6], true);
					}else if(state && index == 2){
						setValue([1,3,4,5,6], [6], true);
					} else if(state && index == 3){
						setValue([1,2,4,5,6], [6], true);
					} else if (state && index == 4){
						setValue([1,2,3,5,6], [6], true);
					} else if (state && index == 5){
						setValue([1,2,3,4,6], [6], true);
					} else{
						setValue([1,2,3,4,5], [6], !$scope.scheduleBFormView.rows[6].conditionalField);
					}
				}else if(index == 25){
					checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
				}else if(index == 33){
					checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
				}else if(index == 42){
					checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
				}else if(index == 48){
					checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
					if((state && name == 'yes') || (!state && name == 'yes') || (state && name == 'no')){
						toggleConditionalField([49,50,51,52,53,54], true);
					}else{
						toggleConditionalField([49,50,51,52,53,54], false);
					}
				}else if(index == 55){
					checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
				}else if(index == 58){
					checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
					checkBoxToggle(index, name, state, [index], "value_user_input2", "attrib_name2");
				}else{
					$scope.scheduleBFormView.rows[index].conditionalField = !$scope.scheduleBFormView.rows[index].conditionalField;
					if((name == 'yes' && !state) || (name == 'no' && state)){
						$scope.scheduleBFormView.rows[index].value = 'NA';
					};
				}
			}
			else if(vm.tax_year > 2020 && vm.tax_year != 2023 && vm.tax_year != 2024) {
				if(index <= 6){
					if(state && index == 1){
						setValue([2,3,4,5,6], [6], true);
					}else if(state && index == 2){
						setValue([1,3,4,5,6], [6], true);
					} else if(state && index == 3){
						setValue([1,2,4,5,6], [6], true);
					} else if (state && index == 4){
						setValue([1,2,3,5,6], [6], true);
					} else if (state && index == 5){
						setValue([1,2,3,4,6], [6], true);
					} else{
						setValue([1,2,3,4,5], [6], !$scope.scheduleBFormView.rows[6].conditionalField);
					}
				}else if(index == 25){
					checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
				}else if(index == 33){
					checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
				}else if(index == 42){
					checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
				}
				else if(index == 51){
					checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
					checkBoxToggle(index, name, state, [index], "value_user_input2", "attrib_name2");
				}
				else if(index == 52){
					checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
					if((state && name == 'yes') || (!state && name == 'yes') || (state && name == 'no')){
						toggleConditionalField([53,54,55,56,57,58], true);
					}else{
						toggleConditionalField([53,54,55,56,57,58], false);
					}
				}/*else if(index == 59){
					checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
				}*/else{
					$scope.scheduleBFormView.rows[index].conditionalField = !$scope.scheduleBFormView.rows[index].conditionalField;
					if((name == 'yes' && !state) || (name == 'no' && state)){
						$scope.scheduleBFormView.rows[index].value = 'NA';
					};
				}
			}
			else if(vm.tax_year == 2023) {
				if(index <= 6){
					if(state && index == 1){
						setValue([2,3,4,5,6], [6], true);
					}else if(state && index == 2){
						setValue([1,3,4,5,6], [6], true);
					} else if(state && index == 3){
						setValue([1,2,4,5,6], [6], true);
					} else if (state && index == 4){
						setValue([1,2,3,5,6], [6], true);
					} else if (state && index == 5){
						setValue([1,2,3,4,6], [6], true);
					} else{
						setValue([1,2,3,4,5], [6], !$scope.scheduleBFormView.rows[6].conditionalField);
					}
				}else if(index == 25 || index == 27 || index == 30 || index == 34 || index ==43 || index == 49){
					checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
				}else if(index == 28 || index == 29 || index == 52){
					checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
					checkBoxToggle(index, name, state, [index], "value_user_input2", "attrib_name2");
				}
				// else if(index == 30 || index == 33){
				// 	checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
				// }else if(index == 42){
				// 	checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
				// }
				// else if(index == 52){
				// 	checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
				// 	checkBoxToggle(index, name, state, [index], "value_user_input2", "attrib_name2");
				// }
				else if(index == 57){
					checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
					if((state && name == 'yes') || (!state && name == 'yes') || (state && name == 'no')){
						toggleConditionalField([58,59,60,61,62,63], true);
					}else{
						toggleConditionalField([58,59,60,61,62,63], false);
					}
				}else{
					$scope.scheduleBFormView.rows[index].conditionalField = !$scope.scheduleBFormView.rows[index].conditionalField;
					if((name == 'yes' && !state) || (name == 'no' && state)){
						$scope.scheduleBFormView.rows[index].value = 'NA';
					};
				}
			}
			else if(vm.tax_year == 2024) {
				if(index <= 6){
					if(state && index == 1){
						setValue([2,3,4,5,6], [6], true);
					}else if(state && index == 2){
						setValue([1,3,4,5,6], [6], true);
					} else if(state && index == 3){
						setValue([1,2,4,5,6], [6], true);
					} else if (state && index == 4){
						setValue([1,2,3,5,6], [6], true);
					} else if (state && index == 5){
						setValue([1,2,3,4,6], [6], true);
					} else{
						setValue([1,2,3,4,5], [6], !$scope.scheduleBFormView.rows[6].conditionalField);
					}
				}else if(index == 25 || index == 27 || index == 30 || index == 34 || index ==43 || index == 49){
					checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
				}else if(index == 28 || index == 29 || index == 52){
					checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
					checkBoxToggle(index, name, state, [index], "value_user_input2", "attrib_name2");
				}
				// else if(index == 30 || index == 33){
				// 	checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
				// }else if(index == 42){
				// 	checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
				// }
				// else if(index == 52){
				// 	checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
				// 	checkBoxToggle(index, name, state, [index], "value_user_input2", "attrib_name2");
				// }
				// else if(index == 57){ // line 31 - Reserved for future use
				// 	checkBoxToggle(index, name, state, [index], "value_user_input", "attrib_name2");
				// 	if((state && name == 'yes') || (!state && name == 'yes') || (state && name == 'no')){
				// 		toggleConditionalField([58,59,60,61,62,63], true);
				// 	}else{
				// 		toggleConditionalField([58,59,60,61,62,63], false);
				// 	}
				// }
				else{
					$scope.scheduleBFormView.rows[index].conditionalField = !$scope.scheduleBFormView.rows[index].conditionalField;
					if((name == 'yes' && !state) || (name == 'no' && state)){
						$scope.scheduleBFormView.rows[index].value = 'NA';
					};
				}
			}
		};
		$scope.madatoryFeilds25 = function (index){
			if($scope.scheduleBFormView.rows[index].attrib_value_f.length && $scope.scheduleBFormView.rows[index].attrib_value_f[0].id == "US"){
				$scope.scheduleBFormView.rows[index].attrib_mandatory_e = true;
				$scope.scheduleBFormView.rows[index].attrib_mandatory_d = true;
				// $scope.scheduleBFormView.rows[index].attrib_value_d = "AK";
				$scope.scheduleBFormView.rows[index].attrib_mandatory_c = true;
			}else{
				$scope.scheduleBFormView.rows[index].attrib_mandatory_e = false;
				$scope.scheduleBFormView.rows[index].attrib_mandatory_d = false;
				// $scope.scheduleBFormView.rows[index].attrib_value_d = "";
				$scope.scheduleBFormView.rows[index].attrib_mandatory_c = false;
			}
		}
			$scope.madatoryFeilds29 = function (index){
				if($scope.scheduleBFormView.rows[index].attrib_value_f.length && $scope.scheduleBFormView.rows[index].attrib_value_f[0].id == "US"){
					$scope.scheduleBFormView.rows[index].attrib_mandatory_e = true;
					$scope.scheduleBFormView.rows[index].attrib_mandatory_d = true;
					// $scope.scheduleBFormView.rows[index].attrib_value_d = "AK";
					$scope.scheduleBFormView.rows[index].attrib_mandatory_c = true;
				}else{
					$scope.scheduleBFormView.rows[index].attrib_mandatory_e = false;
					$scope.scheduleBFormView.rows[index].attrib_mandatory_d = false;
					// $scope.scheduleBFormView.rows[index].attrib_value_d = "";
					$scope.scheduleBFormView.rows[index].attrib_mandatory_c = false;
				}
			}

		function setValue(arr, toggle_i, state){
			angular.forEach(arr, function (value, key) {
				$scope.scheduleBFormView.rows[value].value = false;
			});
			$scope.scheduleBFormView.rows[toggle_i].conditionalField = state;
			$scope.scheduleBFormView.rows[toggle_i].value_user_input = '';
			if(typeof $scope.scheduleBFormView.rows[value].value_user_input2 !== undefined){
				$scope.scheduleBFormView.rows[value].value_user_input2 = '';
			}
		};

		function checkBoxToggle (index, name, state, arr, val, attr){
			if((name == 'yes' && !state) || (name == 'no' && state)){
				$scope.scheduleBFormView.rows[index].value = 'NA';
				if(name == 'yes' && !state){
					angular.forEach(arr, function (value, key) {
						$scope.scheduleBFormView.rows[value].conditionalField = true;
						$scope.scheduleBFormView.rows[value].value_user_input = '';
						if(typeof $scope.scheduleBFormView.rows[value].value_user_input2 !== undefined){
							$scope.scheduleBFormView.rows[value].value_user_input2 = '';
						}
					});
				}
			}else{
				toggleCondition(arr, state, val, attr);
			}
		};

		function toggleCondition (index, state, type, attr){
			angular.forEach(index, function (value, key) {
            	if(($scope.scheduleBFormView.rows[value].conditionalField && !state) || (!$scope.scheduleBFormView.rows[value].conditionalField && state)){
					return;
				}else {
					$scope.scheduleBFormView.rows[value].conditionalField = !$scope.scheduleBFormView.rows[value].conditionalField;
					$scope.scheduleBFormView.rows[value].value_user_input = '';
					if(typeof $scope.scheduleBFormView.rows[value].value_user_input2 !== undefined){
						$scope.scheduleBFormView.rows[value].value_user_input2 = '';
					}
				}
        	});
		};

		vm.addRows = function(type, i){
			var newRow = {
				OCCURRENCE : $scope.scheduleBFormView.rows[i].JSON_DATA.length + 1,
				DATA_ATTRIB_A : "",
				DATA_ATTRIB_B : "",
				DATA_ATTRIB_C : [vm.allCountries[vm.USCountry_index]],
				DATA_ATTRIB_D : ""
			};
			if(type == 'entity'){
				newRow.DATA_ATTRIB_C = "";
				newRow.DATA_ATTRIB_D = [vm.allCountries[vm.USCountry_index]];
				newRow.DATA_ATTRIB_E = "";
			}

			$scope.scheduleBFormView.rows[i].JSON_DATA.push(newRow);
		};

        vm.saveSchdIForm = function (url, data){
            var jsonSettings = {
            	"tax_year": rowData.TAX_YEAR,
            	"scenario": rowData.SCENARIO_KEY,
            	"jcd_key": rowData.JCD_KEY,
            	"combination_key": rowData.COMBINATION_KEY,
            	"trans_type_key": data[0].editrows[0][0].trans_type_key
            };
            var filterParams = (workspaceFactory.activeScreen.filters) ?  workspaceFactory.activeScreen.filters.getFilterParams() : {};
            var jsonObj = JSON.stringify(data);
            var jsonSettings  = JSON.stringify(jsonSettings);
            var params = filterParams;
            params = _.merge({},  GlobalService.globalParams , params);
            params =  _.extend({jsonObj:jsonObj}, params);
            params =  _.extend({jsonSettings:jsonSettings}, params);
            params.process_name =  workspaceFactory.activeScreen.label;
            params.combination_key = rowData.COMBINATION_KEY;
            params.trans_type_key = data[0].editrows[0][0].trans_type_key;
            var promise = $http({
                method: "post",
                url: url,
                data: params
            }).then(function (response) {
                var status = response.status;
                
				if(response.data.callSuccess == "1" && response.data.errorMessage == "null"){
					$uibModalInstance.dismiss('cancel');
					var args = {combination_key: rowData.COMBINATION_KEY, gridFilter: {combination_key: rowData.COMBINATION_KEY}};
					$rootScope.$emit('gridUpdate', args);
					AlertService.add("success", "Transactions saved successfully", 4000);
				}else{
					if(response.data && response.data.errorMessage){
                        AlertService.add("error", response.data.errorMessage,4000);
                    }else{
                        AlertService.add("error", "Error occurred in saving the Transaction. If this continues please contact support",4000);
                    }
				}
				vm.isSaving = false;
                return response.data;
            });
            console.log("------------At the end-----------", promise);
			
            return promise;
        };

        vm.save = function(){
			var formRowDataA = {};
			var formRowDataB = {};
			var tempArray = [];
			if(!vm.isValid()){
				AlertService.add("error", "Please provide the mandatory fields.",4000);
				return;
			}
			vm.isSaving = true;
			for(var i=0; i<$scope.scheduleBFormView.rows.length ;i++){
				
				formRowDataA = {
					tax_year: rowData.TAX_YEAR,
					trans_type_key: $scope.scheduleBFormView.rows[i].saveParams[1],
					line_order: $scope.scheduleBFormView.rows[i].line_order,
                    attrib_order: $scope.scheduleBFormView.rows[i].attrib_order_a,
                    line_description: $scope.scheduleBFormView.rows[i].description,
					combination_key: rowData.COMBINATION_KEY,
					group_obj_key: rowData.GROUP_OBJ_KEY,
					form_key: $scope.scheduleBFormView.rows[i].saveParams[2],
					line_no: $scope.scheduleBFormView.rows[i].num,
					occurence: $scope.scheduleBFormView.rows[i].saveParams[7],
					line_attrib_key: $scope.scheduleBFormView.rows[i].lineAttrKey_a,
					Attribute_Name: $scope.scheduleBFormView.rows[i].attrib_name_a,
					Attribute_Value: $scope.scheduleBFormView.rows[i].value === true ? 'true' : $scope.scheduleBFormView.rows[i].value == 'NA' ? 'false' : $scope.scheduleBFormView.rows[i].value === false ? 'false' :  $scope.scheduleBFormView.rows[i].value ? $scope.scheduleBFormView.rows[i].value.toString() : '',
					trans_dtls_key: $scope.scheduleBFormView.rows[i].saveParams[3],
					trans_status: $scope.scheduleBFormView.rows[i].saveParams[6],
					is_dirty: ''
				};
				if((formRowDataA.line_no == '3a1' || formRowDataA.line_no == '3b1' || formRowDataA.line_no == '2a1' || formRowDataA.line_no == '2b1') && ($scope.scheduleBFormView.rows[i-1].value == true && $scope.scheduleBFormView.rows[i-1].JSON_DATA.length > 0)){
					var attributes = formRowDataA.line_no == '2b1' || formRowDataA.line_no == '3a1' ? ['A', 'B', 'C', 'D'] : ['A', 'B', 'C', 'D', 'E'];
					var tempDataArray = [];
					formRowDataA.JSON_DATA = [];
					angular.forEach($scope.scheduleBFormView.rows[i-1].JSON_DATA, function (row, rowKey) {
						var hasData = false;
						tempDataArray = [];
						angular.forEach(attributes, function (value, colKey) {
							var index = formRowDataA.JSON_DATA.length / 4 == 0 ? 1 : formRowDataA.JSON_DATA.length / 4 + 1;
							var tempObj, tempValue ;
							if(Array.isArray(row["DATA_ATTRIB_" + value])){
								tempValue = row["DATA_ATTRIB_" + value].length ? row["DATA_ATTRIB_" + value][0].id : "" ;
							}else{
								tempValue = row["DATA_ATTRIB_" + value] ;
							}

							tempObj = {
								OCCURRENCE : index,
								ATTRIB_NAME : "DATA_ATTRIB_" + value,
								ATTRIB_VALUE : tempValue // Array.isArray(row["DATA_ATTRIB_" + value]) ?  row["DATA_ATTRIB_" + value][0].id : row["DATA_ATTRIB_" + value] 
							}
							
							
							
							if(tempObj.ATTRIB_VALUE !== undefined && tempObj.ATTRIB_VALUE !== ""){
								hasData = true;
							}
							tempDataArray.push(tempObj);
						});
						if(hasData){
							angular.forEach(tempDataArray, function (column, colKey) {
								formRowDataA.JSON_DATA.push(column);
							});
						}
					});
				}else{
					formRowDataA.JSON_DATA = [];
				}
				tempArray.push(formRowDataA);

				if(typeof $scope.scheduleBFormView.rows[i].attrib_name_b !== "undefined"){
					if($scope.scheduleBFormView.rows[i].num == '1f'){
						formRowDataB = {
							tax_year: rowData.TAX_YEAR,
							trans_type_key: $scope.scheduleBFormView.rows[i].saveParams[1],
							line_order: $scope.scheduleBFormView.rows[i].line_order,
	                    	attrib_order: $scope.scheduleBFormView.rows[i].attrib_order_b,
	                    	line_description: $scope.scheduleBFormView.rows[i].description,
							combination_key: rowData.COMBINATION_KEY,
							group_obj_key: rowData.GROUP_OBJ_KEY,
							form_key: $scope.scheduleBFormView.rows[i].saveParams[2],
							line_no: $scope.scheduleBFormView.rows[i].num,
							occurence: $scope.scheduleBFormView.rows[i].saveParams[7],
							line_attrib_key: $scope.scheduleBFormView.rows[i].lineAttrKey_b,
							Attribute_Name: $scope.scheduleBFormView.rows[i].attrib_name_b,
							Attribute_Value: $scope.scheduleBFormView.rows[i].value_user_input,
							trans_dtls_key: $scope.scheduleBFormView.rows[i].saveParams[3],
							trans_status: $scope.scheduleBFormView.rows[i].saveParams[6],
							is_dirty: ''
						};
						tempArray.push(formRowDataB);
					}else{
						formRowDataB = {
							tax_year: rowData.TAX_YEAR,
							trans_type_key: $scope.scheduleBFormView.rows[i].saveParams[1],
							line_order: $scope.scheduleBFormView.rows[i].line_order,
	                    	attrib_order: $scope.scheduleBFormView.rows[i].attrib_order_b,
	                    	line_description: $scope.scheduleBFormView.rows[i].description,
							combination_key: rowData.COMBINATION_KEY,
							group_obj_key: rowData.GROUP_OBJ_KEY,
							form_key: $scope.scheduleBFormView.rows[i].saveParams[2],
							line_no: $scope.scheduleBFormView.rows[i].num,
							occurence: $scope.scheduleBFormView.rows[i].saveParams[7],
							line_attrib_key: $scope.scheduleBFormView.rows[i].lineAttrKey_b,
							Attribute_Name: $scope.scheduleBFormView.rows[i].attrib_name_b,
							Attribute_Value: $scope.scheduleBFormView.rows[i].value === true ? 'false' : $scope.scheduleBFormView.rows[i].value == 'NA' ? 'false' : $scope.scheduleBFormView.rows[i].value === false ? 'true' : $scope.scheduleBFormView.rows[i].value ? $scope.scheduleBFormView.rows[i].value.toString() : '',
							trans_dtls_key: $scope.scheduleBFormView.rows[i].saveParams[3],
							trans_status: $scope.scheduleBFormView.rows[i].saveParams[6],
							is_dirty: ''
						};
						tempArray.push(formRowDataB);
					}
				}

				if(typeof $scope.scheduleBFormView.rows[i].attrib_name_c !== "undefined"){
					formRowDataB = {
						tax_year: rowData.TAX_YEAR,
						trans_type_key: $scope.scheduleBFormView.rows[i].saveParams[1],
						line_order: $scope.scheduleBFormView.rows[i].line_order,
                    	attrib_order: $scope.scheduleBFormView.rows[i].attrib_order_c,
                    	line_description: $scope.scheduleBFormView.rows[i].description,
						combination_key: rowData.COMBINATION_KEY,
						group_obj_key: rowData.GROUP_OBJ_KEY,
						form_key: $scope.scheduleBFormView.rows[i].saveParams[2],
						line_no: $scope.scheduleBFormView.rows[i].num,
						occurence: $scope.scheduleBFormView.rows[i].saveParams[7],
						line_attrib_key: $scope.scheduleBFormView.rows[i].lineAttrKey_c,
						Attribute_Name: $scope.scheduleBFormView.rows[i].attrib_name_c,
						Attribute_Value: $scope.scheduleBFormView.rows[i].value_user_input,
						trans_dtls_key: $scope.scheduleBFormView.rows[i].saveParams[3],
						trans_status: $scope.scheduleBFormView.rows[i].saveParams[6],
						is_dirty: ''
					};
					tempArray.push(formRowDataB);
				}
				if(typeof $scope.scheduleBFormView.rows[i].attrib_name_d !== "undefined"){

					if(vm.tax_year <= 2020) {
						if($scope.scheduleBFormView.rows[i].num == '29'){
							formRowDataB = {
								tax_year: rowData.TAX_YEAR,
								trans_type_key: $scope.scheduleBFormView.rows[i].saveParams[1],
								line_order: $scope.scheduleBFormView.rows[i].line_order,
								attrib_order: $scope.scheduleBFormView.rows[i].attrib_order_d,
								line_description: $scope.scheduleBFormView.rows[i].description,
								combination_key: rowData.COMBINATION_KEY,
								group_obj_key: rowData.GROUP_OBJ_KEY,
								form_key: $scope.scheduleBFormView.rows[i].saveParams[2],
								line_no: $scope.scheduleBFormView.rows[i].num,
								occurence: $scope.scheduleBFormView.rows[i].saveParams[7],
								line_attrib_key: $scope.scheduleBFormView.rows[i].lineAttrKey_d,
								Attribute_Name: $scope.scheduleBFormView.rows[i].attrib_name_d,
								Attribute_Value: $scope.scheduleBFormView.rows[i].value_user_input2,
								trans_dtls_key: $scope.scheduleBFormView.rows[i].saveParams[3],
								trans_status: $scope.scheduleBFormView.rows[i].saveParams[6],
								is_dirty: ''
							};
							tempArray.push(formRowDataB);
						}
					}
					else if(vm.tax_year > 2020) {
						if($scope.scheduleBFormView.rows[i].num == '28' || $scope.scheduleBFormView.rows[i].num == '10b' || $scope.scheduleBFormView.rows[i].num == '10c'){
							formRowDataB = {
								tax_year: rowData.TAX_YEAR,
								trans_type_key: $scope.scheduleBFormView.rows[i].saveParams[1],
								line_order: $scope.scheduleBFormView.rows[i].line_order,
								attrib_order: $scope.scheduleBFormView.rows[i].attrib_order_d,
								line_description: $scope.scheduleBFormView.rows[i].description,
								combination_key: rowData.COMBINATION_KEY,
								group_obj_key: rowData.GROUP_OBJ_KEY,
								form_key: $scope.scheduleBFormView.rows[i].saveParams[2],
								line_no: $scope.scheduleBFormView.rows[i].num,
								occurence: $scope.scheduleBFormView.rows[i].saveParams[7],
								line_attrib_key: $scope.scheduleBFormView.rows[i].lineAttrKey_d,
								Attribute_Name: $scope.scheduleBFormView.rows[i].attrib_name_d,
								Attribute_Value: $scope.scheduleBFormView.rows[i].value_user_input2,
								trans_dtls_key: $scope.scheduleBFormView.rows[i].saveParams[3],
								trans_status: $scope.scheduleBFormView.rows[i].saveParams[6],
								is_dirty: ''
							};
							tempArray.push(formRowDataB);
						}
					}

				}
				['a','b','c','d','e','f'].forEach(function(col) {
					if(vm.tax_year <= 2020) {
						if((i == 50 || i == 53 )&& typeof $scope.scheduleBFormView.rows[i]["attrib_name_"+col] !== "undefined"){
							var tempValue ;
							if(Array.isArray($scope.scheduleBFormView.rows[i]["attrib_value_"+col])){
								if($scope.scheduleBFormView.rows[i]["attrib_value_"+col].length){
									tempValue = $scope.scheduleBFormView.rows[i]["attrib_value_"+col][0].id;
								}else{
									tempValue = "";
								}
							}else{
								tempValue = $scope.scheduleBFormView.rows[i]["attrib_value_"+col] || '';
							}
							tempArray.push({
								tax_year: rowData.TAX_YEAR,
								trans_type_key: $scope.scheduleBFormView.rows[i].saveParams[1],
								line_order: $scope.scheduleBFormView.rows[i].line_order,
								attrib_order: $scope.scheduleBFormView.rows[i]["attrib_order_"+col],
								line_description: $scope.scheduleBFormView.rows[i].description,
								combination_key: rowData.COMBINATION_KEY,
								group_obj_key: rowData.GROUP_OBJ_KEY,
								form_key: $scope.scheduleBFormView.rows[i].saveParams[2],
								line_no: $scope.scheduleBFormView.rows[i].num,
								occurence: $scope.scheduleBFormView.rows[i].saveParams[7],
								line_attrib_key: $scope.scheduleBFormView.rows[i]["lineAttrKey_"+col],
								Attribute_Name: $scope.scheduleBFormView.rows[i]["attrib_name_"+col],
								Attribute_Value: tempValue ,
								trans_dtls_key: $scope.scheduleBFormView.rows[i].saveParams[3],
								trans_status: $scope.scheduleBFormView.rows[i].saveParams[6],
								is_dirty: ''
							});
						}
					}
					else if(vm.tax_year > 2020 && vm.tax_year != 2023 && vm.tax_year != 2024) {
						if((i == 54 || i == 57 )&& typeof $scope.scheduleBFormView.rows[i]["attrib_name_"+col] !== "undefined"){
							var tempValue ;
							if(Array.isArray($scope.scheduleBFormView.rows[i]["attrib_value_"+col])){
								if($scope.scheduleBFormView.rows[i]["attrib_value_"+col].length){
									tempValue = $scope.scheduleBFormView.rows[i]["attrib_value_"+col][0].id;
								}else{
									tempValue = "";
								}
							}else{
								tempValue = $scope.scheduleBFormView.rows[i]["attrib_value_"+col] || '';
							}
							tempArray.push({
								tax_year: rowData.TAX_YEAR,
								trans_type_key: $scope.scheduleBFormView.rows[i].saveParams[1],
								line_order: $scope.scheduleBFormView.rows[i].line_order,
								attrib_order: $scope.scheduleBFormView.rows[i]["attrib_order_"+col],
								line_description: $scope.scheduleBFormView.rows[i].description,
								combination_key: rowData.COMBINATION_KEY,
								group_obj_key: rowData.GROUP_OBJ_KEY,
								form_key: $scope.scheduleBFormView.rows[i].saveParams[2],
								line_no: $scope.scheduleBFormView.rows[i].num,
								occurence: $scope.scheduleBFormView.rows[i].saveParams[7],
								line_attrib_key: $scope.scheduleBFormView.rows[i]["lineAttrKey_"+col],
								Attribute_Name: $scope.scheduleBFormView.rows[i]["attrib_name_"+col],
								Attribute_Value: tempValue ,
								trans_dtls_key: $scope.scheduleBFormView.rows[i].saveParams[3],
								trans_status: $scope.scheduleBFormView.rows[i].saveParams[6],
								is_dirty: ''
							});
						}
					}
					else if(vm.tax_year == 2023) {
						if((i == 59 || i == 62 )&& typeof $scope.scheduleBFormView.rows[i]["attrib_name_"+col] !== "undefined"){
							var tempValue ;
							if(Array.isArray($scope.scheduleBFormView.rows[i]["attrib_value_"+col])){
								if($scope.scheduleBFormView.rows[i]["attrib_value_"+col].length){
									tempValue = $scope.scheduleBFormView.rows[i]["attrib_value_"+col][0].id;
								}else{
									tempValue = "";
								}
							}else{
								tempValue = $scope.scheduleBFormView.rows[i]["attrib_value_"+col] || '';
							}
							tempArray.push({
								tax_year: rowData.TAX_YEAR,
								trans_type_key: $scope.scheduleBFormView.rows[i].saveParams[1],
								line_order: $scope.scheduleBFormView.rows[i].line_order,
								attrib_order: $scope.scheduleBFormView.rows[i]["attrib_order_"+col],
								line_description: $scope.scheduleBFormView.rows[i].description,
								combination_key: rowData.COMBINATION_KEY,
								group_obj_key: rowData.GROUP_OBJ_KEY,
								form_key: $scope.scheduleBFormView.rows[i].saveParams[2],
								line_no: $scope.scheduleBFormView.rows[i].num,
								occurence: $scope.scheduleBFormView.rows[i].saveParams[7],
								line_attrib_key: $scope.scheduleBFormView.rows[i]["lineAttrKey_"+col],
								Attribute_Name: $scope.scheduleBFormView.rows[i]["attrib_name_"+col],
								Attribute_Value: tempValue ,
								trans_dtls_key: $scope.scheduleBFormView.rows[i].saveParams[3],
								trans_status: $scope.scheduleBFormView.rows[i].saveParams[6],
								is_dirty: ''
							});
						}
					}
					else if(vm.tax_year == 2024) {
						if((i == 61 || i == 64 )&& typeof $scope.scheduleBFormView.rows[i]["attrib_name_"+col] !== "undefined"){
							var tempValue ;
							if(Array.isArray($scope.scheduleBFormView.rows[i]["attrib_value_"+col])){
								if($scope.scheduleBFormView.rows[i]["attrib_value_"+col].length){
									tempValue = $scope.scheduleBFormView.rows[i]["attrib_value_"+col][0].id;
								}else{
									tempValue = "";
								}
							}else{
								tempValue = $scope.scheduleBFormView.rows[i]["attrib_value_"+col] || '';
							}
							tempArray.push({
								tax_year: rowData.TAX_YEAR,
								trans_type_key: $scope.scheduleBFormView.rows[i].saveParams[1],
								line_order: $scope.scheduleBFormView.rows[i].line_order,
								attrib_order: $scope.scheduleBFormView.rows[i]["attrib_order_"+col],
								line_description: $scope.scheduleBFormView.rows[i].description,
								combination_key: rowData.COMBINATION_KEY,
								group_obj_key: rowData.GROUP_OBJ_KEY,
								form_key: $scope.scheduleBFormView.rows[i].saveParams[2],
								line_no: $scope.scheduleBFormView.rows[i].num,
								occurence: $scope.scheduleBFormView.rows[i].saveParams[7],
								line_attrib_key: $scope.scheduleBFormView.rows[i]["lineAttrKey_"+col],
								Attribute_Name: $scope.scheduleBFormView.rows[i]["attrib_name_"+col],
								Attribute_Value: tempValue ,
								trans_dtls_key: $scope.scheduleBFormView.rows[i].saveParams[3],
								trans_status: $scope.scheduleBFormView.rows[i].saveParams[6],
								is_dirty: ''
							});
						}
					}
				});
			}
			vm.formData.editrows.push(tempArray);
			vm.mainData = [];
			vm.mainData.push(vm.formData);
			console.log(vm.mainData);
			var url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=tkgaam";
			vm.saveSchdIForm(url,vm.mainData);

            $rootScope.$broadcast("gridRefresh", true);

            $timeout(function() {
                $scope.$broadcast("showLoader", true);
            },100);
        };

		vm.isValid = function(){
			let hasNoError =true;
			if(vm.tax_year <= 2020) {
				let index, arr = [50,53];
				if(!$scope.scheduleBFormView.rows[48].value){

					for(let i = 0; i<arr.length;i++){
						index =arr[i];
						if(!$scope.scheduleBFormView.rows[index].attrib_value_a){
							hasNoError = false;
							$scope.scheduleBFormView.rows[index].attrib_mandatory_color_a = true;
						}
						if($scope.scheduleBFormView.rows[index].attrib_value_f.length == 0 ){
							hasNoError = false;
							$scope.scheduleBFormView.rows[index].attrib_mandatory_color_f = true;
						}
						if( $scope.scheduleBFormView.rows[index].attrib_value_f.length && $scope.scheduleBFormView.rows[index].attrib_value_f[0].id == "US"){
							if(!$scope.scheduleBFormView.rows[index].attrib_value_c){
								hasNoError = false;
								$scope.scheduleBFormView.rows[index].attrib_mandatory_color_c = true;
							}
							if(!$scope.scheduleBFormView.rows[index].attrib_value_d.length ){
								hasNoError = false;
								$scope.scheduleBFormView.rows[index].attrib_mandatory_color_d = true;
							}
							if(!$scope.scheduleBFormView.rows[index].attrib_value_e){
								hasNoError = false;
								$scope.scheduleBFormView.rows[index].attrib_mandatory_color_e = true;
							}
						}

					}
				}
			}
			else if(vm.tax_year > 2020 && vm.tax_year != 2023 && vm.tax_year != 2024) {
				let index, arr = [54,57];
				if(!$scope.scheduleBFormView.rows[52].value){

					for(let i = 0; i<arr.length;i++){
						index =arr[i];
						if(!$scope.scheduleBFormView.rows[index].attrib_value_a){
							hasNoError = false;
							$scope.scheduleBFormView.rows[index].attrib_mandatory_color_a = true;
						}
						if($scope.scheduleBFormView.rows[index].attrib_value_f.length == 0 ){
							hasNoError = false;
							$scope.scheduleBFormView.rows[index].attrib_mandatory_color_f = true;
						}
						if( $scope.scheduleBFormView.rows[index].attrib_value_f.length && $scope.scheduleBFormView.rows[index].attrib_value_f[0].id == "US"){
							if(!$scope.scheduleBFormView.rows[index].attrib_value_c){
								hasNoError = false;
								$scope.scheduleBFormView.rows[index].attrib_mandatory_color_c = true;
							}
							if(!$scope.scheduleBFormView.rows[index].attrib_value_d.length ){
								hasNoError = false;
								$scope.scheduleBFormView.rows[index].attrib_mandatory_color_d = true;
							}
							if(!$scope.scheduleBFormView.rows[index].attrib_value_e){
								hasNoError = false;
								$scope.scheduleBFormView.rows[index].attrib_mandatory_color_e = true;
							}
						}

					}
				}
			}
			else if(vm.tax_year == 2023) {
				let index, arr = [59, 62];
				if(!$scope.scheduleBFormView.rows[57].value){

					for(let i = 0; i<arr.length;i++){
						index =arr[i];
						if(!$scope.scheduleBFormView.rows[index].attrib_value_a){
							hasNoError = false;
							$scope.scheduleBFormView.rows[index].attrib_mandatory_color_a = true;
						}
						if($scope.scheduleBFormView.rows[index].attrib_value_f.length == 0 ){
							hasNoError = false;
							$scope.scheduleBFormView.rows[index].attrib_mandatory_color_f = true;
						}
						if( $scope.scheduleBFormView.rows[index].attrib_value_f.length && $scope.scheduleBFormView.rows[index].attrib_value_f[0].id == "US"){
							if(!$scope.scheduleBFormView.rows[index].attrib_value_c){
								hasNoError = false;
								$scope.scheduleBFormView.rows[index].attrib_mandatory_color_c = true;
							}
							if(!$scope.scheduleBFormView.rows[index].attrib_value_d.length ){
								hasNoError = false;
								$scope.scheduleBFormView.rows[index].attrib_mandatory_color_d = true;
							}
							if(!$scope.scheduleBFormView.rows[index].attrib_value_e){
								hasNoError = false;
								$scope.scheduleBFormView.rows[index].attrib_mandatory_color_e = true;
							}
						}

					}
				}
			}
			else if(vm.tax_year == 2024) {
				let index, arr = [61, 64];
				if(!$scope.scheduleBFormView.rows[59].value){

					for(let i = 0; i<arr.length;i++){
						index =arr[i];
						if(!$scope.scheduleBFormView.rows[index].attrib_value_a){
							hasNoError = false;
							$scope.scheduleBFormView.rows[index].attrib_mandatory_color_a = true;
						}
						if($scope.scheduleBFormView.rows[index].attrib_value_f.length == 0 ){
							hasNoError = false;
							$scope.scheduleBFormView.rows[index].attrib_mandatory_color_f = true;
						}
						if( $scope.scheduleBFormView.rows[index].attrib_value_f.length && $scope.scheduleBFormView.rows[index].attrib_value_f[0].id == "US"){
							if(!$scope.scheduleBFormView.rows[index].attrib_value_c){
								hasNoError = false;
								$scope.scheduleBFormView.rows[index].attrib_mandatory_color_c = true;
							}
							if(!$scope.scheduleBFormView.rows[index].attrib_value_d.length ){
								hasNoError = false;
								$scope.scheduleBFormView.rows[index].attrib_mandatory_color_d = true;
							}
							if(!$scope.scheduleBFormView.rows[index].attrib_value_e){
								hasNoError = false;
								$scope.scheduleBFormView.rows[index].attrib_mandatory_color_e = true;
							}
						}

					}
				}
			}
			return hasNoError;
		}

		vm.reset = function (){
			$scope.scheduleBFormView = angular.copy(vm.originalData);
		};

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function initFormView(filteredFormData) {
			$scope.scheduleBFormView = filteredFormData;
		};

		//Dynamic Tabset Code
		vm.tabs = [
	        {id: 1, name: "Form Entry", url: "app/components/partnership/F1065-Sch-B/F1065-scheduleBEntry.html", active: true }
	        //{id: 3, name: "Audit", url: "app/components/partnership/F1065-Sch-B/F1065-scheduleBVersion.html", active: false}
	    ];
    }
});