define([
    'angular',
    './pdfPushToEfileController'
], function () {
    'use strict';
    var module = angular.module('app.dstPdfPushToEfile', ['app.dstPdfPushToEfileController'])
        
    return module;
}
);
