const { toInteger } = require("lodash");

define(["angular"], function () {
    "use strict";

    var controllers = angular
        .module("app.chartRolloverController", [])
        .controller("chartRolloverCtrl", [
            'GlobalService', 'JsonObjectFactory', '$uibModalInstance', '$parse', '$scope', '$rootScope', '$timeout', 'workspaceFactory', '$http', '$q', 'AlertService', 'GENERAL_CONFIG', 'USER_SETTINGS', 'scenarioSetupActionsService', '$filter', 'TAX_YEARS', 'rowData', 'gridData',
            chartRolloverCtrl,
        ]);

    function chartRolloverCtrl(GlobalService, JsonObjectFactory, $uibModalInstance, $parse, $scope, $rootScope, $timeout, workspaceFactory, $http, $q, AlertService, GENERAL_CONFIG, USER_SETTINGS, scenarioSetupActionsService, $filter, TAX_YEARS, rowData, gridData) {
        var vm = this;
        getNewData();
        vm.modalTitle = "Chart Roll Over";
        vm.isEdit = false;
        vm.chartKeyList = [];
        vm.selected_ChartKey = null;
        vm.chartKey = null;
        vm.chartCode = null;
        vm.current_tax_year = null;
        vm.prior_tax_year = null;
        vm.selected_Chart_Code = null;
        vm.dataType = ['CODE_COMBINATION','ENTITY','CHART_ACCT'];
        vm.chartRoll = {
            TAX_YEAR: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
            PRIOR_YR_KEY: '',
                CURR_YR_CHART_CODE: ''
        }
        if (rowData) {
            vm.isEdit = true;
            vm.chartRoll = rowData;
        }
        vm.dateOptions = {};
        vm.showEntityLoader = false;
        vm.isSaving = false;
        vm.chartRoll_backup = vm.chartRoll;

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.reset = function () {
            vm.chartRoll = {
                TAX_YEAR: workspaceFactory.activeScreen.filters.getFilterParams().tax_year,
                PRIOR_YR_KEY: '',
                CURR_YR_CHART_CODE: ''
            }
        }

        vm.isValid = function () {
            if (vm.chartRoll.TAX_YEAR == null ||
                vm.chartRoll.TAX_YEAR == undefined ||
                vm.chartRoll.TAX_YEAR == "") {
                vm.chartRoll.TAX_YEAR_NOT_VALID = true;
                return false;
            }
            if (vm.chartRoll.PRIOR_YR_KEY == null ||
                vm.chartRoll.PRIOR_YR_KEY == undefined ||
                vm.chartRoll.PRIOR_YR_KEY == "") {
                vm.chartRoll.PRIOR_YR_KEY_KEY_NOT_VALID = true;
                return false;
            }
            if (vm.chartRoll.CURR_YR_CHART_CODE == null ||
                vm.chartRoll.CURR_YR_CHART_CODE == undefined ||
                vm.chartRoll.CURR_YR_CHART_CODE == "") {
                vm.chartRoll.CURR_YR_CHART_CODE_KEY_NOT_VALID = true;
                return false;
            }
            return true;
        }

        vm.save = function () {
            if (!vm.isValid()) {
                AlertService.add("warn", "Please fill all the required fields", 1000);
                return false;
            }
            vm.isSaving = true;
           // vm.chartRoll.EDIT_FLAG = vm.isEdit ? 'U' : 'I';
          // vm.chartRoll.PRIOR_YR_KEY = vm.chartKey;
            GlobalService.globalParams.tax_year = GlobalService.globalParams ? toInteger(GlobalService.globalParams.tax_year) + 1 : '';
            GlobalService.globalParams.tax_year = GlobalService.globalParams.tax_year.toString();
             var filterParams = (workspaceFactory.activeScreen.filters) ? workspaceFactory.activeScreen.filters.getFilterParams() : {};
             let jsonSettings = {
                 tax_year: toInteger(workspaceFactory.activeScreen.filters.getFilterParams().tax_year) + 1 
              };
             jsonSettings = JSON.stringify(jsonSettings);

             let json_Obj_To_Save = {
                PRIOR_YR_KEY : vm.chartRoll.PRIOR_YR_KEY ? vm.chartRoll.PRIOR_YR_KEY.CHART_KEY : '',
                CURR_YR_CHART_CODE : vm.chartRoll.CURR_YR_CHART_CODE ? vm.chartRoll.CURR_YR_CHART_CODE : ''
             };
             let jsonObj = JSON.stringify(json_Obj_To_Save);
             let params;
             params = _.merge({}, GlobalService.globalParams, params);
             params = _.extend({
                 clob_data: jsonObj
             }, params);
             params = _.extend({
                 clob_settings: jsonSettings
             }, params);

            const url = GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=b5cgu4";

            params.is_issue_key_enabled = 'N';
            JsonObjectFactory.saveJSON(url, params).then(function (data) {
                vm.isSaving = false;

                if (data.callSuccess === "1" && data.errorMessage == "null") {
                    $uibModalInstance.dismiss('cancel');
                    $rootScope.$emit('gridUpdate', {});
                    AlertService.add("success", "Chart Roll Over Created successfully", 4000);
                    console.log("saved", data);
                } else {
                    console.log("error");
                    AlertService.add("error", data.errorMessage, 4000);
                }
            });


        }
        	
    	vm.changePriorYearKey = function(){
    		console.log("vm.selected_ChartKey--------------", vm.chartRoll.PRIOR_YR_KEY);
            vm.chartKey = vm.chartRoll.PRIOR_YR_KEY.CHART_KEY;
            vm.chartCode = vm.chartRoll.PRIOR_YR_KEY.CHART_CODE;
            generateCurrentYearChartCode();
    	}

        function generateCurrentYearChartCode (){
            vm.prior_tax_year = workspaceFactory.activeScreen.filters.getFilterParams().tax_year;
            vm.current_tax_year = toInteger(vm.prior_tax_year.slice(-2)) + 1;
            vm.selected_Chart_Code = vm.chartCode + '_'+ vm.current_tax_year;
            vm.chartRoll.CURR_YR_CHART_CODE = vm.selected_Chart_Code;
        }
       function getNewData() {
        var params = {
			"action_code": '24naqb',
			"tax_year" : GlobalService.globalParams.tax_year,
			"scenario" : GlobalService.globalParams.scenario,
			"jcd_key" : GlobalService.globalParams.jcd_key
			}
		return JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject', params).then(
				function(data) {
					if (data.callSuccess === "1") {
						vm.chartKeyList = data.jsonObject;
						//fetchFirTaxSensitiveData();
					}
					else {
						if (data.errorMessage === "no access") {
							AlertService.add("", "Sorry you do not have access to do the requested action.", 4000);
							vm.userMessage = "!! Unable to perform Selected Action...";
						} else {
							AlertService.add("", "An  has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
							vm.userMessage = "!! Unable to perform Selected Action...";
						}
					}
				});
            };
    }
    return controllers;
});