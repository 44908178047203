define([
    'angular',
    './viewPdfOfEfileController'
], function () {
    'use strict';
    var module = angular.module('app.dstViewPdfOfEfile', ['app.dstViewPdfOfEfileController'])
        
    return module;
}
);
