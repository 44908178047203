/**
 * Created by 502117899 on 10/15/2015.
 */





define([
    'angular',
    './componentDefaults/DefaultsController',
    './componentDefaults/DefaultsService',
    './attributeSetUp/AttributeCtrl',
    './attributeSetUp/AttributeBatchCtrl',
    './attributeSetUp/AttributeService',
    './attributeSetUp/AttributeBatchService',   
    './default-ftc/DefaultsFTCService',
    './default-ftc/DefaultsFTCController',
    './expenseTrans/ExpenseTransCtrl',
    './expenseTrans/ExpenseTransServices',
    './branchTax/BranchTaxService',
    './branchTax/BranchTaxDefaultsCtrl',
    './branchTax/BranchTaxCtrl',
    './branchTax/BranchAndWithholdTaxCtrl',
    './withholdTax/WithHoldTaxService',
    './withholdTax/WithHoldTaxCtrl',    
    './multiselect/multiselect',
    'd3',
    'c3',
    './sourcingSummary/SourcingCtrl',
    './sourcingSummary/SourcingService',
    './system-settings-ftc/me_sic_map/SystemSettingsFTCService',
    './system-settings-ftc/me_sic_map/SystemSettingsFTCController',    
    './system-settings-ftc/syncProcess/SyncProcessController',
    /*'./manageJobs/ManageJobsController',*/
    './ftcAdmin/ExpAnACalcAdminCtrl',
    './ftcAdmin/ExpAnACalcAdminService',
    './ftcAdmin/ExamsRolloverCtrl',
    './ftcAdmin/CompDefaultsRolloverCtrl',
    './ftcAdmin/AssetsRolloverCtrl',
    './system-settings-ftc/expenseAnA/ExpConsolCtrl',
    './system-settings-ftc/expenseAnA/ExpConsolService',      
    './system-settings-ftc/highTaxedKickOut/htkoConfigCtrl',
    './system-settings-ftc/highTaxedKickOut/htkoConfigService',
    './system-settings-ftc/861_config/Configuration861Ctrl',
    './system-settings-ftc/861_config/Configuration861Service',
    './system-settings-ftc/rd_percentage/RdSalesAdjPercentageCtrl',
    './system-settings-ftc/rd_percentage/RdSalesAdjPercentageService',
    './investmentValidation/InvestmentValidationCtrl',
	'./investmentValidation/InvestmentValidationService',
	'./cfcInvestments/cfcInvestmentAdjCtrl',
	'./cfcInvestments/cfcInvestmentAdjService',
	'./cfcInvestments/cfcSplitOnOwnerPercentCtrl',
	'./cfcInvestments/cfcSplitOnOwnerPercentService',
    './F1065-k2/FTC1065K2DataEntryController',
    './F1065-k2/FTC1065K2DataEntryService',
    './F1065-k2/Header/FTC1065-HeaderController',
    './F1065-k2/P1S0/FTC1065-P1_S0Controller',
    './F1065-k2/P3S4/FTC1065-P3-S4Controller',
    './F1065-k2/P7S1/FTC1065-P7-S1Controller',
    './F1065-k2/P7S2/FTC1065-P7-S2Controller',
    './F1065-k2/P10S3/FTC1065-P10_S3Controller',
    './F1065-k2/P11/FTC1065-P11_S0Controller',
    './F1065-k2/P12/FTC1065-P12_S0Controller',
    './F1065-k2/P13/FTC1065-P13_S0Controller',
	'./ftcComments/ftcCommentsCtrl',
    './ftcComments/ftcCommentsService',
    './system-settings-ftc/ftcSyncConfig/ftcSyncConfigCtrl',
    './system-settings-ftc/ftcSyncConfig/ftcSyncConfigService',
    './interestRnDExpenseAppr/TotalInterestExpenseCtrl',
    './interestRnDExpenseAppr/TotalRnDExpenseCtrl',
    './interestRnDExpenseAppr/InterestRnDExpenseApprService',
    './form1118/Form1118Ctrl',
    './form1118/Form1118Service',
	'./form1118/F1118AttachSchdModalCtrl',
	'./form1118/F1118GPIAttachSchdModalCtrl',
	'./form1118/F1118HPIIAttachSchdModalCtrl',
	'./form1118SchdJ/Form1118SchdJController',
    './form1118SchdJ/Form1118SchdJService',
    './form1118SchdK/Form1118SchdKController',
    './form1118SchdK/Form1118SchdKService',
    './form1118/F1118SchdCModalCtrl',
    './form1118/schd-d/F1118SchdDPIModalCtrl',
    './form1118/schd-d/F1118SchdDPIIModalCtrl',
    './form1118/schd-e/F1118SchdEPIModalCtrl',
    './form1118/schd-e/F1118SchdEPIIModalCtrl',
    './qbai/QbaiCtrl.js',
    './pshipPtr/PshipPtrCtrl.js',
    './pshipPtr/PshipPtrService.js',
    './specialAllocation/SpecialAllocationCtrl.js',
    './specialAllocation/SpecialAllocationService.js',
    './rdSalesAdjustment/RdSalesAdjustmentCtrl',
    './rdSalesAdjustment/RdSalesAdjustmentService',
    './componentDefaults/ComponentDefaultsBatchCtrl',
    './componentDefaults/DefaultBatchService',
    './Beat/BeatCtrl',
    './Beat/BeatService',
    './ftc-documentation/ftcDocumentationCtrl',
    './ftc-documentation/ftcDocumentationService',
    './rdExpenseContributed/RdExpAdjCtrl',
    './rdExpenseContributed/RdExpAdjService',
    './sourcingAssetAdjustment/SourcingAssetsAdjustmentCtrl',
    './sourcingAssetAdjustment/SourcingAssetsAdjService',
    './interestExpenseContributed/IntExpAdjCtrl',
    './interestExpenseContributed/IntExpAdjService',
    './qar2018/Qar2018Ctrl',
    './qar2018/Qar2018Service',
    './qarTaxes2018/QarTaxes2018Ctrl',
    './qarTaxes2018/QarTaxes2018Service',
    './system-settings-ftc/transJobs/TransJobsCtrl',
    './system-settings-ftc/apprConfig/ApprConfigCtrl',
    './system-settings-ftc/apprConfig/ApprConfigService',
    './form1118Config/form1118Sync/form1118SyncCtrl',
    './form1118Config/Form1118ConfigCtrl',
    './form1118Config/Form1118ConfigService',
    './pshipPtr/BeatPshipPtrCtrl',
    './expenseTrans/transSync/transSyncCtrl',
    './system-settings-ftc/syncProcess/SyncProcessService',
    './ftcAdmin/K2SyncCtrl',
    './otherOverrides/OtherOverridesCtrl',
    './otherOverrides/OtherOverridesService',
    './pshipPtrDetailsK2/pshipPtrDetailsCtrl',
    './pshipPtrDetailsK2/pshipPtrDetailsService',
   './coaDiagnostics/coaDiagnosticsDetailsCtrl',
    './coaDiagnostics/coaDiagnosticsService'


   
], function () {
    'use strict';

 
   return angular.module('app.FTC', ['app.DefaultsController','app.DefaultsService','app.AttributeCtrl','app.AttributeService','app.AttributeBatchCtrl','app.AttributeBatchService','app.DefaultsFTCService','app.DefaultsFTCController','app.SystemSettingsFTCService','app.SystemSettingsFTCController','app.ExpenseTransCtrl',
  'app.BranchTaxService','app.BranchTaxCtrl','app.BranchTaxDefaultsCtrl','app.BranchAndWithholdTaxCtrl','app.WithHoldTaxService','app.WithHoldTaxCtrl','app.multiselect','app.SourcingCtrl','app.SourcingService','app.ExpConsolCtrl','app.ExpConsolService','app.Form1118Ctrl','app.form1118Services',
  'app.htkoConfigCtrl','app.htkoConfigService','app.Form1118SchdJController','app.F1118AttachSchdCtrl','app.F1118GPIAttachSchdCtrl','app.F1118HPIIAttachSchdCtrl', 'app.f1118SchdCController', 'app.f1118SchdDPIController', 'app.f1118SchdDPIIController', 'app.f1118SchdEPIController', 'app.f1118SchdEPIIController','app.Form1118SchdJService','app.Form1118SchdKController','app.Form1118SchdKService','app.InvestmentValidationService','app.InvestmentValidationCtrl','app.cfcInvestmentAdjService','app.cfcInvestmentAdjCtrl','app.cfcSplitOnOwnerPercentService','app.cfcSplitOnOwnerPercentCtrl',
  'app.FTC1065K2DataEntryController','app.FTC1065K2DataEntryService','app.FTC1065HeaderController','app.FTC1065P1S0Controller','app.FTC1065P3S4Controller','app.FTC1065P7S1Controller','app.FTC1065P7S2Controller','app.FTC1065P10S3Controller', 'app.FTC1065P11S0Controller', 'app.FTC1065P12S0Controller','app.FTC1065P13S0Controller',
  'app.ftcCommentsService','app.ftcCommentsCtrl','app.InterestRnDExpenseApprService','app.TotalInterestExpenseCtrl','app.TotalRnDExpenseCtrl',
  'app.Configuration861Ctrl','app.Configuration861Service','app.QbaiCtrl','app.PshipPtrCtrl','app.PshipPtrService','app.SpecialAllocationCtrl','app.SpecialAllocationService','app.RdSalesAdjustmentCtrl','app.RdSalesAdjustmentService','app.SyncIntExpRnDController',/*'app.ManageJobsController',*/'app.ExpAnACalcAdmin','app.CompDefaultsRolloverCtrl','app.AssetsRolloverCtrl','app.ExamsRolloverCtrl',
  'app.ExpAnACalcAdminService','app.RdSalesAdjPercentageCtrl','app.RdSalesAdjPercentageService','app.ComponentDefaultsBatchCtrl','app.DefaultBatchService','app.BeatCtrl','app.BeatService',
  'app.DocumentationCtrl','app.DocumentationService','app.RdExpAdjCtrl', 'app.RdExpAdjService', 'app.SourcingAssetsAdjustmentCtrl', 'app.SourcingAssetsAdjService' ,'app.IntExpAdjCtrl','app.IntExpAdjService','app.Qar2018Ctrl','app.Qar2018Service','app.QarTaxes2018Ctrl','app.QarTaxes2018Service','app.TransJobsController',
  'app.ApprConfigCtrl','app.ApprConfigService','app.Form1118ConfigCtrl','app.form1118ConfigServices','app.BeatPshipPtrCtrl','app.SyncFormIIIEightController','app.transCatgSyncCtrl','app.SyncProcessService','app.K2SyncCtrl','app.otherOverrideDeleteCtrl','app.otherOverrideScreenService','app.pshipPtrDetailsCtrl','app.pshipPtrDetailsService','app.CoaDiagnosticsController','app.CoaDiagnosticsService',
  'app.ftcSyncConfigCtrl','app.ftcSyncConfigService'


 

	   //,'QbaiService'
	   ])

       .config(['$stateProvider','$urlRouterProvider', 'GENERAL_CONFIG', function ($stateProvider,$urlRouterProvider, GENERAL_CONFIG) {
           $stateProvider
           
               .state('ftc-default', {
          url: '/defaults',
          templateUrl: 'app/components/ftc/settings/default-ftc/defaults.html',
  controller: 'defaultsFTCController as ctrl',
  backdrop: 'static',
          access: ""
          })              
                .state('attribute-screen-demo', {
          url: '/attributeDemo',
              templateUrl: 'app/components/ftc/settings/attributeSetUp/attribute-tpl.html',
  controller: 'attributeData as ctrl',
  backdrop: 'static',
              access: ""
          })      
               .state('trans-category', {
          url: '/expenseTrans',
              templateUrl: 'app/components/ftc/settings/expenseTrans/expense-trans-cat-ftc-old.html',
  controller: 'expenseTransCtrl as ctrl',
  backdrop: 'static',
              access: ""
          })  
          .state('sync-process', {
          url: '/sync-process',
              templateUrl: 'app/components/ftc/settings/system-settings-ftc/syncProcess/sync-process.html',
              controller: 'syncIntExpRnDCtrl as ctrl',
              access: ""
          })
          .state('auto-tags', {
          url: '/auto-tags',
              templateUrl: 'app/components/ftc/settings/system-settings-ftc/transJobs/trans-jobs.html',
              controller: 'TransJobsCtrl as ctrl',
              access: ""
          })
           .state('1118-config', {
          url: '/1118-config',
              templateUrl: 'app/components/ftc/settings/form1118Config/form-1118-config-ftcadd.html',
              controller: 'form1118Config as ctrl',
              access: ""
          })
           .state('form-1118-sync', {
          url: '/form-1118-sync',
              templateUrl: 'app/components/ftc/settings/form1118Config/form1118Sync/form-1118-sync.html',
              controller: 'syncFormIIIEightCtrl as ctrl',
              access: ""
          })
         
          .state('trans-catg-sync', {
          url: '/trans-catg-sync',
              templateUrl: 'app/components/ftc/settings/expenseTrans/transSync/trans-sync.html',
              controller: 'transCatgSyncCtrl as ctrl',
              access: ""
          })
      /*  .state('manage-jobs', {
          url: '/manage-jobs',
              templateUrl: 'app/components/ftc/settings/manageJobs/manage-jobs.html',
              controller: 'manageJobsCtrl as ctrl',
              access: ""
          })  */
          .state('expense-a-a-calc-ftc-admin', {
          url: '/expense-a-a-calc-ftc-admin',
              templateUrl: 'app/components/ftc/settings/ftcAdmin/expense-a-a-calc-ftc-admin.html',
              controller: 'expAnACalcAdminCtrl as ctrl',
              access: ""
          })
          .state('component-defaults-rollover', {
          url: '/component-defaults-rollover',
              templateUrl: 'app/components/ftc/settings/ftcAdmin/component-defaults-rollover.html',
              controller: 'compDefaultsRolloverCtrl as ctrl',
              access: ""
          })
          .state('assets-roll-over', {
          url: '/assets-roll-over',
              templateUrl: 'app/components/ftc/settings/ftcAdmin/assets-rollover.html',
              controller: 'assetsRolloverCtrl as ctrl',
              access: ""
          })
          .state('exams-roll-over', {
          url: '/exams-roll-over',
              templateUrl: 'app/components/ftc/settings/ftcAdmin/exams-rollover.html',
              controller: 'examsRolloverCtrl as ctrl',
              access: ""
          })
         
          .state('branchtax-summary', {
             url: '/branchtax-summary',
             templateUrl: 'app/components/ftc/settings/branchTax/branch-tax-creditable-edit.html',
 controller: 'branchTaxCtrl as ctrl',
 backdrop: 'static',
             access: ""
          })
          .state('branchtax-defaults', {
             url: '/branchtax-defaults',
             templateUrl: 'app/components/ftc/settings/branchTax/branch-tax-defaults-edit.html',
 controller: 'branchTaxDefaultsCtrl as ctrl',
 backdrop: 'static',
             access: ""
          })
          .state('withholdtax-defaults', {
             url: '/withholdtax-defaults',
             templateUrl: 'app/components/ftc/settings/withholdTax/withhold-tax-summary-edit.html',
 controller: 'withHoldTaxCtrl as ctrl',
 backdrop: 'static',
             access: ""
          })
          .state('exp-consol-conf', {
             url: '/exp-consol-conf',
             templateUrl: 'app/components/ftc/settings/system-settings-ftc/expenseAnA/exp-consol-conf.html',
 controller: 'expConsolCtrl as ctrl',
 backdrop: 'static',
             access: ""
          })
          .state('systemSettings', {
             url: '/systemSettings',
             templateUrl: 'app/components/ftc/settings/system-settings-ftc/me_sic_map/me_sic_map.html',
 controller: 'parentMeController as ctrl',
 backdrop: 'static',
             access: ""
          })
          .state('investment-validations', {
                url: '/investment-validations',
                templateUrl: 'app/components/ftc/settings/investmentValidation/investment-validation-edit.html',
                controller: 'investValidationCtrl as ctrl',
                access: ""
  })
  .state('1118-schedule-j-data-entry', {
url: '/1118-schedule-j-data-entry',
templateUrl: 'app/components/ftc/settings/form1118SchdJ/schedule-j.html',
controller: 'scheduleJCtrlNEW as ctrl',
name: 'GEN-Schedule J Part I',
access: ""
  })
          .state('general-1118-schedule-k', {
          url: '/general-1118-schedule-k',
              templateUrl: 'app/components/ftc/settings/form1118SchdK/schedule-k.html',
              controller: 'scheduleKCtrl as ctrl',
              name: 'GEN-Schedule K',
              access: ""
          })
          .state('passive-1118-schedule-k', {
          url: '/passive-1118-schedule-k',
              templateUrl: 'app/components/ftc/settings/form1118SchdK/schedule-k.html',
              controller: 'scheduleKCtrl as ctrl',
              name: 'PAS-Schedule K',
              access: ""
          })
          .state('branch-1118-schedule-k', {
          url: '/branch-1118-schedule-k',
              templateUrl: 'app/components/ftc/settings/form1118SchdK/schedule-k.html',
              controller: 'scheduleKCtrl as ctrl',
              name: 'FB-Schedule K',
              access: ""
          })
          .state('gilti-1118-schedule-k', {
          url: '/gilti-1118-schedule-k',
              templateUrl: 'app/components/ftc/settings/form1118SchdK/schedule-k.html',
              controller: 'scheduleKCtrl as ctrl',
              name: '951A-Schedule K',
              access: ""
          })
          .state('treaty-1118-schedule-k', {
          url: '/treaty-1118-schedule-k',
              templateUrl: 'app/components/ftc/settings/form1118SchdK/schedule-k.html',
              controller: 'scheduleKCtrl as ctrl',
              name: 'RBT-Schedule K',
              access: ""
          })
          .state('901j-1118-schedule-k', {
          url: '/901j-1118-schedule-k',
              templateUrl: 'app/components/ftc/settings/form1118SchdK/schedule-k.html',
              controller: 'scheduleKCtrl as ctrl',
              name: '901j-Schedule K',
              access: ""
          })
          .state('ftc-k2-sync', {
          url: '/ftc-k2-sync',
	          templateUrl: 'app/components/ftc/settings/ftcAdmin/ftc-k2-sync.html',
	          controller: 'K2SyncCtrl as ctrl',
	          access: ""
          })
           .state('coa-diagnostic-fix', {
          url: '/coa-diagnostic-fix',
	          templateUrl: 'app/components/ftc/settings/coaDiagnostics/coa-diagnostics-details.html',
	          controller: 'coaDiagnosticsCtrl as ctrl',
	          access: ""
          })
          /* .state('pship-ptr-details-k2', {
          url: '/pship-ptr-details-k2',
	          templateUrl: 'app/components/ftc/settings/pshipPtrDetailsK2/pship-ptr-details.html',
	          controller: 'pshipPtrDetailsCtrl as ctrl',
	          access: ""
          })*/

           ;


       }]);

    });
