const tempAuthToken = '';

/**
 * Bootstraps angular onto the window.document node.
 * NOTE: the ng-app attribute should not be on the index.html when using ng.bootstrap
 */
define([
    'jquery',
    'js.cookie',
    'require',
    'bootstrap',
    './app/app',
    //  'handlebars',
    'lodash',
    'moment',
    'angular',
    'deferredBootstrapper',
    'ui.bootstrap.tpls',
    'angular-bootstrap-toggle',
    'angular-object-diff',
    'jquery-ui',
    //'datatables.rowButtons',
    'datatables.net-rowgroup',
    'datatables.net-responsive-bs',
    'datatables.net-scrollResize',
    'datatables.net-scroller-bs',
    'datatables.buttons',
    'datatables.buttons.html5',
    'datatables.buttons.print',
    'jsZip',
    'stompjs',
    'SockJS',
    'resizeSensor', 
    // 'angularJwt',   
    // 'oidc-client'

], function (jquery, Cookies, require,angular,$location) {

    'use strict';
    // You can place operations that need to initialize prior to app start here
    // using the `run` function on the top-level module

    //GET Home Page Settings
     const homePage = require('./home-page');
    const base_url = window.__env.apiUrl;
    const gate_way = window.__env.gateWay;
    const urlParams = new URLSearchParams(window.location.href.split('?')[1]);
    const encode = encodeURIComponent(urlParams);
    let partURL = "";
    if(encode.includes('client_key')){
        partURL = "?client_key=" + urlParams.get('client_key');
    }
    console.log("base_url , part URL : ", base_url, " , ",partURL);

    if(window.location.href.includes('localhost')){
        sessionStorage.setItem("Authorization", tempAuthToken);
    }

    let tabCount = localStorage.getItem("tabs");
    tabCount = tabCount ? parseInt(tabCount) : 0;


    //initiate a new tab
    tabCount++;
    localStorage.setItem("tabs",tabCount.toString());

    window.beforeunload = function(){
        let tabCount = localStorage.getItem("tabs");
        tabCount = tabCount ? parseInt(tabCount) : 0;
        tabCount = tabCount > 0? tabCount - 1 : 0;
        localStorage.setItem("tabs",tabCount.toString());
    };

    const tokenRequestChannel = new BroadcastChannel("tokenRequest")
    const tokenRefreshChannel = new BroadcastChannel("tokenRefresh")
    let tokenURL = base_url+ "/getToken"  + partURL ;
    const existingToken = sessionStorage.getItem("Authorization");
    if(!existingToken || existingToken === 'null'){
        tokenRequestChannel.postMessage("request-token")

        //wait for 500ms
        setTimeout(() => {
            const existingToken = sessionStorage.getItem("Authorization");

            if(existingToken && existingToken !== 'null') return;

            checkForToken(tokenURL)

            //testing only
            // callGetGtwToken(tokenURL);
        }, 500);
     }else{
        callGetGtwToken(tokenURL);
    }

    tokenRequestChannel.onmessage = e => {
        console.log("tokenRequest event received", e);
        if(e.data === "request-token"){
            const newToken = sessionStorage.getItem("Authorization");
            if(newToken != null){//if there is logged out tab then do not post null token to other tabs
                tokenRequestChannel.postMessage(newToken)
            }
            return;
        }
        const existingToken = sessionStorage.getItem("Authorization");

        if(existingToken && existingToken !== 'null') return;

        sessionStorage.setItem("Authorization", e.data);

        callGetGtwToken(tokenURL);
    };

    tokenRefreshChannel.onmessage = e =>{
        console.log("tokenRefresh event received", e);
        const existingToken = sessionStorage.getItem("Authorization");

        //sending refresh token only to those tabs that has a old token already. if no token found then the tab is probably on logout screen
        if(!existingToken || existingToken === 'null') return;

        sessionStorage.setItem("Authorization", e.data);
    }


    // $( document ).ready(function() {
    //
    //
    //
    // });
    


    /////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    function redirectToOAuth2() {
        const authUrl = gate_way + '/gtw/oauth2/authorization/gtw-services';
        window.location.replace(authUrl);
    }

    function checkForToken(tokenUrl){
            const url = new URL(location.href);
            const code= url.searchParams.get("code");
            console.log('url code',code);


            if(code != null){
                fetchIdentityToken(code,tokenUrl);
            //   window.location.href = window.location.host;
           }else if(sessionStorage.getItem('Authorization') != null){
                callGetGtwToken(tokenUrl);
           }else{
                redirectToOAuth2();
            }

    }
    function fetchIdentityToken(code,tokenUrl){
        $.ajax({
            method: "GET",
            url:  gate_way + '/gtw/getIdentityToken?authorization_code='+code,
            data: {},
            xhrFields: {
                withCredentials: true
            },
            statusCode: {
                403: function() {
                    //window.location.href = 'app/templates/denied.html';
                },
                302:function(){
                    //  window.location.replace('https://dev2.gtw.pwcinternal.com/gateway/oauth2/authorization/gtw-gateway');

                },

            }
        })
            .done(function( data ) {
                if(data) {
                    // var cookieValue = Cookies.get("app_key");
                    console.log("identitiy token response data " , data );
                    const dataToken = JSON.parse(data);
                    const bearer_token = 'Bearer ' + dataToken.id_token;
                    sessionStorage.setItem('Authorization', bearer_token);
                    console.log('session Storage',sessionStorage);
                    if(window.location.search.includes('?code')){
                        window.location.search = '';
                    }

                }
            });

    }
    function callGetGtwToken(tokenURL){
        //BEFORE WE BOOTSTRAP ANGULAR WE WILL SET XSRF-TOKEN.
        /////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////// XSRF-TOKEN ////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////
        $.ajax({
            method: "GET",
            url: tokenURL,
            headers: {
                Authorization: sessionStorage.getItem('Authorization')//'Bearer ' + data.id_token
            },
            xhrFields: {
                withCredentials: true
            },
            statusCode: {
                401: function() {
                    sessionStorage.removeItem('Authorization');
                    redirectToOAuth2();
                    //  window.location.href = 'app/templates/denied.html';
                },
                302:function(){
                    //  window.location.replace('https://dev2.gtw.pwcinternal.com/gateway/oauth2/authorization/gtw-gateway');

                },
                403: function(){
                    console.log("getToken returned 403");
                    window.location.href = 'user-onboarding.html';
                },

            }
        })
            .done(function( data ) {
                if(data.jsonString && data.jsonString.length){
                    //Cookies.set("app_key",  data.jsonString, { expires : 1 });
                    // Cookies.set("app_key",  data.jsonString, { expires : 1, secure:true  , samesite: "strict"  });
                    var cookieValue = Cookies.get("app_key");
                    console.log("cookieValue " , cookieValue );

                    bootstrapApp();
                }
            });
    }

    function bootstrapApp() {

        require(['domReady', 'jquery.slimscroll', 'moment', 'go','deferredBootstrapper','angular'],
         function (document, slimscroll, moment, go, deferredBootstrapper, angular) {
            // everything is loaded...go!
            // angular.bootstrap(document, ['smartApp']);
            // console.log(moment().toString());
            const header = {
                headers: {'Authorization':sessionStorage.getItem('Authorization')}
            }

            deferredBootstrapper.bootstrap({
                element: document,
                module: 'app',
                bootstrapConfig: {
                    strictDi: true
                },
                resolve: {
                    SERVER_CONFIG: ['$http', function ($http) {
                        console.log("WE ARE BACK ajaxGetConfigSettings");
                      
                        return $http.get(base_url + '/ajaxGetConfigSettings' + partURL,header);
                    }],

                    USER_SETTINGS: ['$http', '$q', '$timeout', function ($http, $q, $timeout) {
                        var deferred = $q.defer();
                        $timeout(function () {
                            $http.get(base_url + '/getUserSettings' + partURL,header ).then(function (res) {

                                document(function(doc) {
                                    if(res.data.user.selected_theme){
                                        function sanitize(input) {
                                            if( input && input.length && typeof input === 'string' ){
                                                var output = input.replace(/<script[^>]?>.?<\/script>/gi, '').
                                                replace(/<[\/\!]?[^<>]?>/gi, '').
                                                replace(/<style[^>]?>.?<\/style>/gi, '').
                                                replace(/<![\s\S]?--[ \t\n\r]>/gi, '');
                                                return output;
                                            }
                                            return input;
                                        };
                                        var themeLess = res.data.user.selected_theme + '.css';
                                        var link = doc.createElement('link');
                                        link.rel = "stylesheet";
                                        link.type = "text/css";
                                        link.id = "currentTheme";
                                        link.href =sanitize(themeLess);
                                        doc.head.appendChild(link);

                                        var themeAppkit = '';
                                        var link2 = doc.createElement('link');
                                        link2.rel = "stylesheet";
                                        link2.type = "text/css";
                                        link2.id = "appkit";
                                        if('theme-white' != res.data.user.selected_theme){
                                            themeAppkit = 'lib/gtw-elements/appkit-dark.css';
                                        }else{
                                            themeAppkit= 'lib/gtw-elements/appkit-light.css';
                                        }
                                        link2.href = themeAppkit;
                                        doc.head.prepend(link2);
                                        
                                        if('theme-white' != res.data.user.selected_theme){
                                            $('link[href^="theme-white"]')?.remove();
                                            $('link[href^="lib/gtw-elements/appkit-light"]')?.remove();
                                        }else{
                                            $('link[href^="theme-black"]')?.remove();
                                            $('link[href^="lib/gtw-elements/appkit-dark"]')?.remove();
                                        }
                                        
                                    }
                                    
                                })
                                
                                deferred.resolve(res);
                            });
                        }, 400);
                        return deferred.promise;
                    }],

                    USER_MENU: ['$http', '$q', '$timeout','$location', function ($http, $q, $timeout,$location) {
                        var deferred = $q.defer();
                        $timeout(function () {
                            $http.get(base_url + '/getUserNavigator' + partURL , header).then(function (res) {
                                if(res.data.length>0 && res.data[0].children){
                                  /*   if($location.$$absUrl != undefined && $location.$$absUrl.includes('/?#')){
                                        $location.$$absUrl = $location.$$host
                                     }  
                                     */
                                    const homeObj = res.data[0].children.filter(obj => obj.child_id == 22505321);
                                    if(homeObj.length == 0){
                                        res.data[0].children.push(homePage['home-navigator'])
                                    }
                                }
                                deferred.resolve(res);
                            });
                        }, 400);
                        return deferred.promise;
                    }],

                    ROUTES: ['$http', '$q', '$timeout', function ($http, $q, $timeout) {

                        var deferred = $q.defer();
                        $timeout(function () {
                            $http.get(base_url + '/getRoutes' + partURL, header).then(function (res) {
                                res.data["workspace.modules"] = homePage['home-routes']["workspace.modules"];
                                deferred.resolve(setApi(res));
                            });
                        }, 400);
                        return deferred.promise;
                    }],

                    // TAX_YEARS: ['$http', function ($http) {
                    //     return $http.get('app/json/taxYear.json');
                    // }]
                    TAX_YEARS: ['$http', '$q', '$timeout', function ($http, $q, $timeout) {
                        //    return $http.get('app/json/taxYear.json');
                        var deferred = $q.defer();


                        $timeout(function () {
                            $http.get(base_url + '/getTaxYears' + partURL ,header).then(function (res) {
                                deferred.resolve(_.mapValues(res.data.callSuccess, function (item) {
                                    var tempScenarios = [];
                                    var tempTaxYears = [];
                                    var currTxYear = {};
                                    var runxYear;
                                    _.each(item, function (o) {
                                        runxYear = o.tax_year;
                                        var tempTaxYear = {};
                                        var tempScenario = {};

                                        var existingTaxYear = _.filter(tempTaxYears, {
                                            label: o.tax_year
                                        });
                                        if (null == existingTaxYear || existingTaxYear === 'undefined' || existingTaxYear.length <= 0) {
                                            tempScenario = {
                                                scenario: o.scenario,
                                                scenario_desc: o.scenario_desc,
                                                default_scenario: o.default_scenario,
                                                is_locked: o.is_locked,
                                                jcd_key:o.jcd_key,
                                                scenario_type_code : o.scenario_type_code,
                                                channel_key : o.channel_key,
                                                is_issue_key_enabled: o.is_issue_key_enabled,
                                                
                                            };
                                            tempScenarios.push(tempScenario);
                                            if (o.default_scenario === 'Y') {
                                                tempTaxYear = {
                                                    label: o.tax_year,
                                                    value: o.tax_year,
                                                    scenario: o.scenario,
                                                    scenario_desc: o.scenario_desc,
                                                    scenarios: tempScenarios,
                                                    scenario_type_code : o.scenario_type_code,
                                                    channel_key : o.channel_key,
                                                    is_issue_key_enabled: o.is_issue_key_enabled,

                                                };
                                                tempTaxYears.push(tempTaxYear);
                                            } else {
                                                tempTaxYear = {
                                                    label: o.tax_year,
                                                    value: o.tax_year,
                                                    scenario: o.scenario,
                                                    scenario_desc: o.scenario_desc,
                                                    scenarios: tempScenarios,
                                                    scenario_type_code : o.scenario_type_code,
                                                    channel_key : o.channel_key,
                                                    is_issue_key_enabled: o.is_issue_key_enabled,

                                                };
                                            }

                                            tempTaxYear = {};
                                        } else {
                                            if (o.default_scenario === 'Y') {
                                                existingTaxYear.scenario = o.scenario;
                                                existingTaxYear.scenario_desc = o.scenario_desc;
                                                existingTaxYear.scenario_type_code = o.scenario_type_code;
                                                existingTaxYear.channel_key = o.channel_key;
                                                existingTaxYear.is_issue_key_enabled = o.is_issue_key_enabled;
                                            }
                                            var existingTempScenarios = _.filter(tempScenarios, {
                                                scenario: o.scenario
                                            });
                                            if (null == existingTempScenarios || existingTempScenarios === 'undefined' || existingTempScenarios.length <= 0) {
                                                tempScenario = {
                                                    scenario: o.scenario,
                                                    scenario_desc: o.scenario_desc,
                                                    default_scenario: o.default_scenario,
                                                    is_locked: o.is_locked,
                                                    jcd_key:o.jcd_key,
                                                    scenario_type_code : o.scenario_type_code,
                                                    channel_key : o.channel_key,
                                                    is_issue_key_enabled: o.is_issue_key_enabled,

                                                };
                                                tempScenarios.push(tempScenario);
                                            }
                                            existingTaxYear.scenarios = tempScenarios;
                                        }

                                    });

                                    if (tempTaxYears.length > 0) {
                                        currTxYear = _.filter(tempTaxYears, {
                                            label: runxYear
                                        });
                                        return {
                                            label: currTxYear[0].label,
                                            value: currTxYear[0].value,
                                            scenario: currTxYear[0].scenario,
                                            scenario_desc: currTxYear[0].scenario_desc,
                                            scenarios: currTxYear[0].scenarios,
                                            scenario_type_code : currTxYear[0].scenario_type_code,
                                            channel_key : currTxYear[0].channel_key,
                                            is_issue_key_enabled: currTxYear[0].is_issue_key_enabled,

                                        };
                                    }
                                }));
                            });
                        }, 400);
                        return deferred.promise;
                    }]

                },

                onError: function (error) {
                    if (error.status == 503) {
                        window.location.href = 'app/templates/maintenance.html';
                    } else if (error.status == 401) {
                        window.location.href = 'denied.html';
                    } else {
                        window.location.href = 'error-loading.html';

                    }

                }

            });

        });

    }

    function setApi(routes) {
        if (!("data" in routes)) 
            return routes;
        
        for (const property in routes.data) {
            let activity = routes.data[property];
            if(activity.api){
                if (("get" in activity.api)) {
                    let value = activity.api.get;
                    if (value.includes("?") || value.includes(".json") || value.includes(".JSON")) {
                        activity.api.get = base_url + '/' + value;
                    }
                }
                if (("get-good" in activity.api)) {
                    let value = activity.api["get-good"];
                    if (value.includes("?")) {
                        activity.api["get-good"] = base_url + '/' + value;
                    }
                }
                if (("get-json" in activity.api)) {
                    let value = activity.api["get-json"];
                    if (value.includes("?")) {
                        activity.api["get-json"] = base_url + '/' + value;
                    }
                }
                if (("save" in activity.api)) {
                    let value = activity.api["save"];
                    if (value.includes("?")) {
                        if (value.includes("gridBatchImportValidation")) {
                            activity.api["save"] = window.__env.commonApiUrl + value;
                        } else {
                            activity.api["save"] = base_url + '/' + value;
                        }
                    }
                }
            }
        }
        return routes;
    }

});
